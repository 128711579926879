import React, { useCallback, useState } from "react";
import { useHistory } from "react-router-dom";

import { FormattedMessage, injectIntl } from "react-intl";
import { toast } from "react-toastify";

import { Container, CreateForm, CreateFooter } from "./styles";

import { getErrorsApiValidation } from "./../../../../utils/getErrorsValidation";
import api from "./../../../../services/api";
import InputLabel from "./../../../../components/InputLabel";
import Button from "./../../../../components/ButtonDefault";
import ListSelect from "./../../../../components/ListSelect";

export const CreateCustomerClassification = ({ intl }) => {
  const [descricao, setDescricao] = useState("");
  const [codigoExterno, setCodigoExterno] = useState("");
  const [status, setStatus] = useState({
    value: 1,
    label: <FormattedMessage id="ativo" />,
  });
  const [errors, setErrors] = useState();

  const [loadingButton, setLoadingButton] = useState(false);

  const history = useHistory();

  const statusPlaceholder = intl.formatMessage({ id: "status" });

  const descricaoPlaceholder = intl.formatMessage({ id: "descricao" });

  const statusOptions = [
    { value: 1, label: <FormattedMessage id="ativo" /> },
    { value: 0, label: <FormattedMessage id="inativo" /> },
  ];

  const handleCreate = useCallback(async () => {
    setLoadingButton(true);
    const data = {
      // codigo,
      descricao,
      codigo_externo: codigoExterno,
      ativo: status.value,
    };

    try {
      await api.post("/tabela-classificacao-cliente", data);
      toast.success(<FormattedMessage id="registroCriadoComSucesso" />);
      setDescricao("");
      setCodigoExterno("");

      setStatus({ value: 1, label: <FormattedMessage id="ativo" /> });
      setLoadingButton(false);
      setErrors(null);
    } catch (error) {
      setErrors(getErrorsApiValidation(error));
      setLoadingButton(false);
      toast.error(<FormattedMessage id="erroCriarRegistro" />);
    }
  }, [descricao, codigoExterno, status]);

  const handleBackButton = useCallback(() => {
    history.goBack();
  }, [history]);

  return (
    <Container>
      <CreateForm>
        <header>
          <h4>
            <FormattedMessage id="cadastrarClassificacaoCliente" />
          </h4>
        </header>

        <form>
          {/* <InputMaskDefault
            name="codigo"
            type="text"
            label={codigoPlaceholder + ' *'}
            mask={"int5"}
            value={codigo}
            onChange={(e) => setCodigo(e.target.value)}
            geterror={errors?.codigo}
          /> */}
          <InputLabel
            name="descricao"
            type="text"
            label={descricaoPlaceholder}
            required={true}
            maxLength={40}
            value={descricao}
            onChange={(e) => setDescricao(e.target.value)}
            geterror={errors?.descricao}
          />
          <InputLabel
            name="codigo_externo"
            type="text"
            label={<FormattedMessage id="codigoExterno" />}
            value={codigoExterno}
            maxLength={10}
            onChange={(e) => setCodigoExterno(e.target.value)}
            geterror={errors?.codigo_externo}
          />

          <ListSelect
            value={status}
            onChange={(e) => setStatus(e)}
            labelPlaceholder={statusPlaceholder}
            placeholder={<FormattedMessage id="selecionar" />}
            options={statusOptions}
          />
        </form>

        <hr />

        <CreateFooter>
          <div>
            <Button type="button" themeType="back" onClick={handleBackButton}>
              <FormattedMessage id="voltar" />
            </Button>
          </div>
          <div>
            <Button
              type="button"
              onClick={handleCreate}
              loading={loadingButton}
            >
              <FormattedMessage id="salvar" />
            </Button>
          </div>
        </CreateFooter>
      </CreateForm>
    </Container>
  );
};

export default injectIntl(CreateCustomerClassification);
