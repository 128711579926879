import React, { useCallback, useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { FormattedMessage, injectIntl } from "react-intl";

import { Container, CreateForm, CreateFooter, NCMContainer } from "./styles";

import ListSelect from "./../../../components/ListSelect";
import InputLabel from "./../../../components/InputLabel";
import Button from "./../../../components/ButtonDefault";
import api from "../../../services/api";

export const DisplayProduct = ({ intl }) => {
  const [data, setData] = useState({});
  const [ncm, setNcm] = useState([]);
  const [status, setStatus] = useState("");
  const history = useHistory();

  useEffect(() => {

    console.log(api, 'api')

    api.get("produto/" + history.location.state?.id);

    if (!history.location.state?.id) {
      return history.push("/register/sodexo/products/list");
    }
    if (history.location.state?.ncm) {
      const formattedNCM = history.location.state?.ncm.map((n) => n?.codigo);

      setStatus(history.location.state?.status === 1 ? "Ativo" : "Inativo");
      setNcm(formattedNCM);
    }

    setData(history.location.state);
  }, [history]);

  const statusPlaceholder = intl.formatMessage({ id: "status" });

  const codigoPlaceholder = intl.formatMessage({ id: "codigo" });
  const descricaoPlaceholder = intl.formatMessage({ id: "descricao" });

  const handleBackButton = useCallback(() => {
    history.goBack();
  }, [history]);

  return (
    <Container>
      <CreateForm>
        <header>
          <h4>
            <FormattedMessage id="exibirProduto" />
          </h4>
        </header>

        <form>
          <InputLabel
            name="codigo"
            type="text"
            label={codigoPlaceholder}
            required={true}
            value={data?.codigo || ""}
            readOnly
          />
          <InputLabel
            name="descricao"
            type="text"
            label={descricaoPlaceholder}
            required={true}
            value={data?.descricao || ""}
            readOnly
          />

          <strong style={{ marginLeft: "10px" }}>NCM</strong>
          <NCMContainer>
            {ncm.map((n, i, arr) => {
              return (
                <span key={n}>
                  {n}
                  {i !== arr.length - 1 && ",ﾠ"}
                </span>
              );
            })}
          </NCMContainer>

          <ListSelect
            isDisabled={true}
            labelPlaceholder={statusPlaceholder}
            value={{ value: status, label: status }}
          />
        </form>

        <hr />

        <CreateFooter>
          <div>
            <Button type="button" themeType="back" onClick={handleBackButton}>
              <FormattedMessage id="voltar" />
            </Button>
          </div>
        </CreateFooter>
      </CreateForm>
    </Container>
  );
};

export default injectIntl(DisplayProduct);
