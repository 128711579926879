import React, { useContext, useState, useCallback, useEffect } from "react";

import { useHistory } from "react-router-dom";

import AuthContext from "./../../../context/auth";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";

const DropdownProfile = () => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const { signOut, user } = useContext(AuthContext);

  const [userName, setUserName] = useState("Trade");

  const history = useHistory();

  useEffect(() => {
    if (user) {
      setUserName(user.nome);
    }
  }, [user]);

  const toggle = useCallback(() => {
    setDropdownOpen(!dropdownOpen);
  }, [dropdownOpen]);

  const handleSignOut = useCallback(() => {
    signOut();
  }, [signOut]);

  const handleChangePassword = useCallback(() => {
    history.push("/change-password");
  }, [history]);

  return (
    <Dropdown
      isOpen={dropdownOpen}
      toggle={toggle}
      className="dropdown navbar-user"
      tag="li"
    >
      <DropdownToggle tag="a" style={{ cursor: "pointer" }}>
        <span className="d-none d-md-inline">{userName}</span>{" "}
        <b className="caret"></b>
      </DropdownToggle>
      <DropdownMenu className="dropdown-menu dropdown-menu-right" tag="ul">
        <DropdownItem onClick={handleChangePassword}>
          Alterar Senha
        </DropdownItem>
        <DropdownItem onClick={handleSignOut}>Sair</DropdownItem>
      </DropdownMenu>
    </Dropdown>
  );
};

export default DropdownProfile;
