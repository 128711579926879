import React, { useContext, useState, useCallback, useEffect } from "react";
import { Route } from "react-router-dom";

import SidebarNavList from "./sidebar-nav-list.jsx";
import Menus from "./../../context/menu";
import AuthContext from "./../../context/auth";

const SidebarNav = () => {
  const [active, setActive] = useState(-1);
  const [clicked, setClicked] = useState(-1);
  const [menu, setMenu] = useState([]);
  const [home, setHome] = useState([]);

  const MenuContext = useContext(Menus);
  const { user } = useContext(AuthContext);

  useEffect(() => {
    setMenu(MenuContext.menu);
    setHome(MenuContext.home);
  }, [MenuContext.menu, MenuContext.home]);

  const handleExpand = useCallback(
    (e, i, match) => {
      e.preventDefault();

      if (clicked === -1 && match) {
        setActive(-1);
        setClicked(-1);
      } else {
        setActive((state) => (state === i ? -1 : i));
        setClicked(1);
      }
    },
    [clicked]
  );

  return (
    <ul className="nav">
      {home.map((home, i) => {
        return (
          home.isPrivate && (
            <Route
              exact
              path={home.path}
              key={i}
              children={({ match }) => (
                <SidebarNavList
                  data={home}
                  key={i}
                  expand={(e) => handleExpand(e, i, match)}
                  active={i === active}
                  clicked={clicked}
                />
              )}
            />
          )
        );
      })}
      {/* <li key="xsas" className="nav-header">
        <FormattedMessage id="navegacao" />
      </li> */}
      {menu.map((menu, i) => {
        if (user.tipo_id === 2) {
          if (menu.path === "/dashboard" && menu?.permission?.length === 0) {
            return <></>;
          }
        }

        return (
          menu.isPrivate && (
            <>
              <Route
                exatc
                path={menu.path}
                key={i}
                children={({ match }) => {
                  return (
                    <SidebarNavList
                      data={menu}
                      exact
                      key={i}
                      expand={(e) => handleExpand(e, i, match)}
                      active={i === active}
                      clicked={clicked}
                    />
                  );
                }}
              />
            </>
          )
        );
      })}
    </ul>
  );
};

export default SidebarNav;
