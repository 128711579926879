import React, { useCallback, useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import api from "./../../../services/api";

import { FormattedMessage, injectIntl } from "react-intl";

import { Container, CreateForm, CreateFooter } from "./styles";

import ListSelect from "./../../../components/ListSelect";

import InputLabel from "./../../../components/InputLabel";
import Button from "./../../../components/ButtonDefault";

export const DisplayDistributionBase = ({ intl }) => {
  const [nome, setNome] = useState("");
  const [regiao, setRegiao] = useState("");
  const [uf, setUf] = useState("");
  const [cidade, setCidade] = useState("");
  const [status, setStatus] = useState(null);
  const history = useHistory();

  useEffect(() => {
    api.get("/tabela-base-distribuicao/" + history.location.state?.id)

    if (!history.location.state?.id) {
      return history.push("/register/sodexo/distribution-base/list");
    }
    setNome(history.location.state?.nome);
    setRegiao(history.location.state?.regiao);
    setUf(history.location.state?.uf);
    setCidade(history.location.state?.cidade);
    setStatus(history.location.state?.status === 1 ? "Ativo" : "Inativo");
  }, [history]);

  const statusOptions = [
    { value: "ativo", label: <FormattedMessage id="ativo" /> },
    { value: "inativo", label: <FormattedMessage id="inativo" /> },
  ];

  const nomePlaceholder = intl.formatMessage({ id: "nome" });
  const regiaoPlaceholder = intl.formatMessage({ id: "regiao" });
  const estadoPlaceholder = intl.formatMessage({ id: "estado" });
  const cidadePlaceholder = intl.formatMessage({ id: "cidade" });

  const handleBackButton = useCallback(() => {
    history.goBack();
  }, [history]);

  return (
    <Container>
      <CreateForm>
        <header>
          <h4>
            <FormattedMessage id="exibirBaseDistribuicao" />
          </h4>
        </header>

        <form>
          <InputLabel
            name="name"
            type="text"
            label={nomePlaceholder}
            required={true}
            value={nome}
            readOnly
          />

          <ListSelect
            isDisabled={true}
            value={{ value: regiao, label: regiao }}
            labelPlaceholder={regiaoPlaceholder}
            placeholder={<FormattedMessage id="selecionar" />}
          />

          <ListSelect
            labelPlaceholder={estadoPlaceholder}
            required={true}
            placeholder={<FormattedMessage id="selecionar" />}
            isDisabled={true}
            value={{ value: uf, label: uf }}
          />

          <ListSelect
            labelPlaceholder={cidadePlaceholder}
            required={true}
            placeholder={<FormattedMessage id="selecionar" />}
            isDisabled={true}
            value={{ value: cidade, label: cidade }}
          />

          <ListSelect
            labelPlaceholder="Status"
            placeholder={<FormattedMessage id="selecionar" />}
            isDisabled={true}
            value={{ value: status, label: status }}
            options={statusOptions}
          />
        </form>

        <hr />

        <CreateFooter>
          <div>
            <Button type="button" themeType="back" onClick={handleBackButton}>
              <FormattedMessage id="voltar" />
            </Button>
          </div>
        </CreateFooter>
      </CreateForm>
    </Container>
  );
};

export default injectIntl(DisplayDistributionBase);
