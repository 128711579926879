import React, { useCallback, useState } from "react";
import { useHistory } from "react-router-dom";

import { FormattedMessage, injectIntl } from "react-intl";
import { toast } from "react-toastify";
import { Container, CreateForm, CreateFooter } from "./styles";

import { getErrorsApiValidation } from "./../../../../utils/getErrorsValidation";
//import InputMaskDefault from "./../../../../components/InputMaskDefault";
import api from "./../../../../services/api";
import InputLabel from "./../../../../components/InputLabel";
import Button from "./../../../../components/ButtonDefault";
import AsyncListSelect from "../../../../components/ListSelect/AsyncListSelect";

import ListSelect from "./../../../../components/ListSelect";

export const CreateOperationState = ({ intl }) => {
  const [usuario, setUsuario] = useState("");
  //const [codigo, setCodigo] = useState("");
  const [descricao, setDescricao] = useState("");
  const [codigoExterno, setCodigoExterno] = useState("");
  const [status, setStatus] = useState({
    value: 1,
    label: <FormattedMessage id="ativo" />,
  });
  const [errors, setErrors] = useState();

  const [loadingButton, setLoadingButton] = useState(false);

  const history = useHistory();

  const statusPlaceholder = intl.formatMessage({ id: "status" });
  //const codigoPlaceholder = intl.formatMessage({ id: "codigo" });
  const mesaPlaceholder = intl.formatMessage({ id: "mesaOperacao" });
  const operadorPlaceholder = intl.formatMessage({ id: "operador" });

  const statusOptions = [
    { value: 1, label: <FormattedMessage id="ativo" /> },
    { value: 0, label: <FormattedMessage id="inativo" /> },
  ];

  const loaduserOperation = useCallback(async () => {
    try {
      const { data: response } = await api.get(`/usuario/operadores-sem-mesa`);

      const arr = response.map((user) => {
        const obj = {};

        Object.assign(obj, {
          value: String(user.id),
          label: user.name,
        });
        return obj;
      });
      return arr;
    } catch (error) {}
  }, []);

  const handleCreate = useCallback(async () => {
    setLoadingButton(true);
    const data = {
      // codigo,
      descricao,
      codigo_externo: codigoExterno,
      ativo: status.value,
      operador_id: usuario.value,
    };

    try {
      await api.post("/tabela-mesa-operacao", data);

      toast.success(<FormattedMessage id="registroCriadoComSucesso" />);

      setUsuario("");
      //setCodigo("");
      setDescricao("");
      setCodigoExterno("");
      setStatus({ value: 1, label: <FormattedMessage id="ativo" /> });
      setLoadingButton(false);
      setErrors(null);
      // history.goBack();
      document.location.reload(true);
    } catch (error) {
      setErrors(getErrorsApiValidation(error));
      setLoadingButton(false);
      toast.error(<FormattedMessage id="erroCriarRegistro" />);
    }
  }, [descricao, codigoExterno, status.value, usuario.value]);

  const handleBackButton = useCallback(() => {
    history.goBack();
  }, [history]);

  return (
    <Container>
      <CreateForm>
        <header>
          <h4>
            <FormattedMessage id="cadastrarMesaOperacao" />
          </h4>
        </header>

        <form>
          {/* <InputMaskDefault
            name="codigo"
            type="text"
            label={codigoPlaceholder + ' *'}
            mask={"int5"}
            value={codigo}
            onChange={(e) => setCodigo(e.target.value)}
            geterror={errors?.codigo}
          /> */}
          <InputLabel
            name="descricao"
            type="text"
            label={mesaPlaceholder}
            required={true}
            maxLength={40}
            value={descricao}
            onChange={(e) => setDescricao(e.target.value)}
            geterror={errors?.descricao}
          />
          <AsyncListSelect
            labelPlaceholder={operadorPlaceholder}
            required={true}
            placeholder={<FormattedMessage id="selecionar" />}
            onChange={(e) => {
              setUsuario(e);
            }}
            value={usuario}
            loadOptions={loaduserOperation}
            cacheOptions
            defaultOptions
            geterror={errors?.operador_id}
          />
          <InputLabel
            name="codigo_externo"
            type="text"
            label={<FormattedMessage id="codigoExterno" />}
            value={codigoExterno}
            maxLength={10}
            onChange={(e) => setCodigoExterno(e.target.value)}
            geterror={errors?.codigo_externo}
          />

          <ListSelect
            onChange={(e) => setStatus(e)}
            value={status}
            labelPlaceholder={statusPlaceholder}
            placeholder={<FormattedMessage id="selecionar" />}
            options={statusOptions}
          />
        </form>

        <hr />

        <CreateFooter>
          <div>
            <Button type="button" themeType="back" onClick={handleBackButton}>
              <FormattedMessage id="voltar" />
            </Button>
          </div>
          <div>
            <Button
              type="button"
              onClick={handleCreate}
              loading={loadingButton}
            >
              <FormattedMessage id="salvar" />
            </Button>
          </div>
        </CreateFooter>
      </CreateForm>
    </Container>
  );
};

export default injectIntl(CreateOperationState);
