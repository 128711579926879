import React, { useEffect, useState } from "react";
import Chart from "react-apexcharts";
import api from "../../services/api";
import ContentSpinner from "../../components/Spinner/Content";
import {
  Panel,
  PanelHeader,
  PanelBody,
} from "../../components/panel/panel.jsx";

import { format } from "date-fns";
import { ptBR } from "date-fns/locale";
import moment from "moment";

import { FormattedMessage } from "react-intl";

const SodexoFaturamento = ({ dataFilter }) => {
  const [loadingList, setLoadingList] = useState(true);

  const [labels, setLabels] = useState([]);
  const [graph, setGraph] = useState([]);
  let loadGraph = {
    series: graph,
    options: {
      chart: {
        type: "bar",
        stacked: true,
        toolbar: {
          show: false,
        },
      },
      dataLabels: {
        enabled: true,
        enabledOnSeries: undefined,
        formatter: function (val) {
          return (parseFloat(val)).toLocaleString('pt-BR')
        },
        textAnchor: 'middle',
        style: {
            fontSize: '11px',
            fontWeight: 'bold',
            colors: undefined
        },
        background: {
          enabled: true,
          foreColor: '#fff',
          padding: 4,
          opacity: 0.9,

        },
        dropShadow: {
            enabled: false,
            top: 1,
            left: 1,
            blur: 1,
            color: '#000',
            opacity: 0.45
        }
      },
      yaxis: {
        labels: {
          formatter: function(val, index) {
            return (parseFloat(val)).toLocaleString('pt-BR')
          }
        }
      },
      tooltip: {
        y: {
          formatter: function (val) {
            return (parseFloat(val)).toLocaleString('pt-BR') + " Litros"
          }
        }
      },
      colors: ["#FE2E2E", "#FE9A2E", "#FACC2E", "#C8FE2E", "#64FE2E",
      "#2EFE64", "#00FF80", "#00FFBF", "#01A9DB", "#045FB4",
      "#2E2EFE", "#2F0B3A", "#5F04B4", "#FE2EF7",
      "#DF0174", "#585858", "#BDBDBD"],
      xaxis: {
        categories: labels,
      },
    },
  };

  useEffect(() => {
    (async () => {
      try {
        const dataDeRandom = moment()
        .subtract(2, "months")
        .format("YYYY-MM-DD 00:00:00");
        const dataDe = moment(dataDeRandom).startOf('month').format('YYYY-MM-DD 00:00:00');
        const dataAte = moment().format("YYYY-MM-DD 23:59:59");

        const { data: response } = await api.get(
          `/dashboard/volume-combustivel-base-distribuidor`,
          {
            params: {
              data_de: !dataFilter?.dataDeFilter
                ? dataDe
                : dataFilter?.dataDeFilter + " 00:00:00",
              data_ate: !dataFilter?.dataAteFilter
                ? dataAte
                : dataFilter?.dataAteFilter + " 23:59:59",
              cpf_cnpj: dataFilter?.cnpjFilter
                ?.replaceAll(".", "")
                ?.replaceAll("/", "")
                ?.replaceAll("-", "")
                ?.replaceAll("_", ""),
              grupo_economico: dataFilter?.grupoEconomicoFilter,
              base_distribuicao_id: dataFilter?.bases?.value,
              municipio_id: dataFilter?.cidadeBasesDist?.value,
              estado_id: dataFilter?.estadoBasesDist?.value,
            },
          }
        );

        const dist = Object.keys(response);

        let formattedListData = [];
        dist.map((item) => {
          let data = [];
          response[item].forEach((i) => {
            data.push(i.total_litros);
          });

          return formattedListData.push({ name: item, data: data });
        });

        setGraph(formattedListData);

        let formattedListLabel = [];
        dist.map((item) => {
          let label = [];
          response[item].forEach((i) => {
            const date = i.data_group.split("-");
            label.push(
              format(new Date(date[0], date[1] - 1, 1), "MMMM/yyyy", {
                locale: ptBR,
              })
            );
          });

          return formattedListLabel.push({ label });
        });

        setLabels(formattedListLabel[0].label);

        setLoadingList(false);
      } catch (error) {
        setLoadingList(false);
      }
    })();
  }, [dataFilter]);

  return (
    <>
      {loadingList ? (
        <ContentSpinner />
      ) : (
        <>
          <Panel>
            <PanelHeader>
              <FormattedMessage id="grupoBasesDistVolumeCombustivel" />
            </PanelHeader>
            <PanelBody className="p-0">
              <Chart
                options={loadGraph.options}
                series={loadGraph.series}
                type="bar"
                width="100%"
                height="400px"
                className="graph"
              />
            </PanelBody>
          </Panel>
        </>
      )}
    </>
  );
};

export default SodexoFaturamento;
