import React, { useCallback, useState } from "react";
import { useHistory } from "react-router-dom";
import { FormattedMessage, injectIntl } from "react-intl";
import * as Yup from "yup";

import api from "./../../../services/api";
import { Container, CreateForm, CreateFooter } from "./styles";
import InputLabel from "./../../../components/InputLabel";
import Button from "./../../../components/ButtonDefault";

import { toast } from "react-toastify";

export const ChangeLoginPassword = ({ intl }) => {
  const [loadingButton, setLoadingButton] = useState(false);
  const [password, setPassword] = useState("");
  const [passwordConfirmation, setPasswordConfirmation] = useState("");
  const [errors, setErrors] = useState("");
  const history = useHistory();
  const passwordPlaceholder = intl.formatMessage({ id: "senha" });
  const passwordConfirmationPlaceholder = intl.formatMessage({
    id: "confirmacaoSenha",
  });

  const handleChangePassword = useCallback(
    async (e) => {
      e.preventDefault();
      setLoadingButton(true);
      try {
        const data = {
          new_password: password,
          confirm_password: passwordConfirmation,
        };

        const schema = Yup.object().shape({
          password: Yup.string().min(
            6,
            <FormattedMessage id="minimoSeisParaSenha" />
          ),
          confirm_password: Yup.string().when(
            "new_password",
            (password, field) =>
              password
                ? field.required().oneOf([Yup.ref("new_password")])
                : field
          ),
        });

        await schema.validate(
          { password },
          {
            abortEarly: false,
          }
        );

        if (!(await schema.isValid(data))) {
          setLoadingButton(false);

          return setErrors(<FormattedMessage id="passwordNaoConfere" />);
        }

        await api.post("/usuario/change-password", data);

        setPassword("");
        setPasswordConfirmation("");
        setErrors("");

        toast.success(<FormattedMessage id="passwordEditadoComSucesso" />);
        setLoadingButton(false);

        history.push("/home");
      } catch (error) {
        if (error?.name === "ValidationError") {
          setErrors(error.message);
        }
        setLoadingButton(false);

        if (error.response.data.erro_email) {
          setPassword("");
          setPasswordConfirmation("");
          setErrors("");
          toast.success(<FormattedMessage id="passwordEditadoComSucesso" />);
          toast.error("Erro ao enviar o e-mail");
          return;
        }

        toast.error(<FormattedMessage id="erroAtualizarSenha" />);
        return;
      }
    },
    [password, passwordConfirmation, history]
  );

  const handleBackButton = useCallback(() => {
    history.goBack();
  }, [history]);

  return (
    <Container>
      <CreateForm>
        <header>
          <h4>
            <FormattedMessage id="alterarSenhaUsuario" />
          </h4>
        </header>
        <form>
          <InputLabel
            name="password"
            type="password"
            maxLength={20}
            onChange={(e) => setPassword(e.target.value)}
            placeholder={passwordPlaceholder}
          />
          <InputLabel
            name="password_confirmation"
            type="password"
            maxLength={20}
            onChange={(e) => setPasswordConfirmation(e.target.value)}
            placeholder={passwordConfirmationPlaceholder}
            geterror={errors}
          />
          <CreateFooter>
            <div>
              <Button type="button" themeType="back" onClick={handleBackButton}>
                <FormattedMessage id="voltar" />
              </Button>
            </div>
            <div>
              <Button
                type="submit"
                onClick={handleChangePassword}
                loading={loadingButton}
              >
                <FormattedMessage id="salvar" />
              </Button>
            </div>
          </CreateFooter>
        </form>
        <hr />
      </CreateForm>
    </Container>
  );
};
export default injectIntl(ChangeLoginPassword);
