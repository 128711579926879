import React, { useCallback, useState, useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";
import MaskInput from "./../../../config/input-mask";
import { FormattedMessage, injectIntl } from "react-intl";

import { toast } from "react-toastify";

import api from "./../../../services/api";

import { Container, CreateForm, CreateFooter } from "./styles";

import InputLabel from "./../../../components/InputLabel";
import LoaderContent from "./../../../components/Spinner/Content";
import Button from "./../../../components/ButtonDefault";
import ListSelect from "./../../../components/ListSelect";
import AsyncListSelect from "./../../../components/ListSelect/AsyncListSelect";

import AuthContext from "./../../../context/auth";
import { getErrorsApiValidation } from "../../../utils/getErrorsValidation";

export const EditUser = ({ intl }) => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [celular, setCelular] = useState("");
  const [telefone, setTelefone] = useState("");
  const [nivel, setNivel] = useState("");

  const [setRoles] = useState("");

  const [label, setLabel] = useState("");

  const [status, setStatus] = useState({});
  const [errors, setErrors] = useState();
  const [loading, setLoading] = useState(true);
  const [loadingSave, setLoadingSave] = useState(false);

  const history = useHistory();

  /** obter os dados do usuario logado */
  const { user } = useContext(AuthContext);

  //Placeholders internacionalizados
  const empresaPlaceholder = intl.formatMessage({ id: "empresa" });
  const nivelPlaceholder = intl.formatMessage({ id: "nivel" });
  const rolePlaceholder = intl.formatMessage({ id: "perfil" });
  const statusPlaceholder = intl.formatMessage({ id: "status" });

  const [userTipos, setUserTipos] = useState("");

  const [listaEmpresasSelecionadas] = useState("");
  const [empresas, setEmpresas] = useState([
    { value: "todos", label: <FormattedMessage id="todos" /> },
  ]);

  const statusOptions = [
    { value: 1, label: <FormattedMessage id="ativo" /> },
    { value: 0, label: <FormattedMessage id="inativo" /> },
  ];

  useEffect(() => {
    async function loadUser() {
      if (!history.location.state?.id) {
        return history.push("/access/users/sodexo/list");
      }
      /** id do user que vem da tela anterior */
      const userId = history.location.state?.id;

      try {
        const { data: response } = await api.get(`/usuario/show/${userId}`);
        /** aba dados Cadastrais */
        var cadastros = response?.usuario[0].cadastros;
        var arrayEmpresasSelecionadas = [];
        cadastros.forEach(function (item, indice, array) {
          arrayEmpresasSelecionadas.push({
            value: item.id,
            label: item.cpf_cnpj + " - " + item.razao_social,
          });
        });

        setEmpresas(arrayEmpresasSelecionadas);
        setName(response?.usuario[0].name);
        setEmail(response?.usuario[0].email);
        setCelular(response?.usuario[0].celular);
        setTelefone(response?.usuario[0].telefone);
        setNivel({
          value: response?.usuario[0].user_tipo.id,
          label: response?.usuario[0].user_tipo.nome,
        });
        setStatus({
          value: response?.usuario[0].ativo,
          label: response?.usuario[0].ativo === 1 ? "Ativo" : "Inativo",
        });

        setLabel({
          value: response?.rolesPerfis[0].role_id,
          label: response?.rolesPerfis[0].label,
        });

        setRoles({
          value: response?.rolesPerfis[0].nameRole,
          label: response?.rolesPerfis[0].nameRole,
        });

        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    }
    loadUser();

    async function loadUserTiposDB() {
      try {
        const { data: response } = await api.get("usuario/list-types");
        const levelOptions = [];
        const resp = response.userTipo;
        resp.forEach((tipouser) => {
          levelOptions.push({ value: tipouser.id, label: tipouser.nome });
        });
        setUserTipos(levelOptions);
      } catch (error) {}
    }
    loadUserTiposDB();

    async function loadRolesDB() {
      try {
        const { data: response } = await api.post(`/role/filter`, {
          cadastro_tipo_id: 1,
        });
        const roles = [];
        const resp = response.data;
        resp.forEach((role) => {
          roles.push({ value: role.name, label: role.label });
        });
      } catch (error) {}
    }
    loadRolesDB();
  }, [history, setRoles]);

  const handleEditUser = useCallback(async () => {
    setLoadingSave(true);
    var userId = history.location.state?.id;

    const empresaid = empresas.map(function (empresa) {
      return empresa.value;
    });

    var nameTipoId;
    if (typeof nivel.value !== "undefined") {
      nameTipoId = nivel.value;
    } else {
      nameTipoId = nivel[0].value;
    }

    const data = {
      cadastro:
        empresas[0].value === "todos" ? listaEmpresasSelecionadas : empresaid,
      name: name,
      email: email,
      celular: celular,
      telefone: telefone,
      tipo_id: nameTipoId,
      roles: nameTipoId === 1 ? "" : [parseInt(label?.value)],
      ativo: status.value,
    };

    try {
      await api.post(`/usuario/update/${userId}`, data);

      toast.success(<FormattedMessage id="usuarioEditadoComSucesso" />);
      setErrors(null);
      setLoadingSave(false);
      history.goBack();
    } catch (error) {
      setErrors(getErrorsApiValidation(error));
      toast.error(<FormattedMessage id="erroDeEditarUsuario" />);
      setLoadingSave(false);
    }
  }, [
    history,
    empresas,
    listaEmpresasSelecionadas,
    nivel,
    name,
    email,
    celular,
    telefone,
    status,
    label,
  ]);

  const handleBackButton = useCallback(() => {
    history.goBack();
  }, [history]);

  /** Para carregar as Empresas - Cadastros */
  const loadEmpresas = useCallback(async () => {
    //alert(user.cadastro_tipo_id);
    try {
      /** Se usuario logado for Sodexo listará todas as Empresas. Se não listará somente os tipos de empresas do tipo cadastro logado */
      var response;
      if (parseInt(user.cadastro_tipo_id) === 1) {
        response = await api.post(`/cadastro/filter`, { cadastro_tipo_id: 1 });
      } else if (parseInt(user.cadastro_tipo_id) === 2) {
        response = await api.post(`/cadastro/filter`, { cadastro_tipo_id: 2 });
      } else if (parseInt(user.cadastro_tipo_id) === 3) {
        response = await api.post(`/cadastro/filter`, { cadastro_tipo_id: 3 });
      }

      var resp;
      if (typeof response.data.data != "undefined") {
        resp = response.data.data;
      } else {
        resp = response.data;
      }

      const empresasFormatted = resp.map((empresa) => {
        const objeto = {
          value: empresa.id,
          label: empresa.cpf_cnpj + " - " + empresa.razao_social,
        };

        return objeto;
      });

      return empresasFormatted;
    } catch (error) {}
  }, [user.cadastro_tipo_id]);

  /** Para carregar os tipos usuario cadastrados */
  /** Se usuario logado for Sodexo listará todas as Empresas. Se não listará somente os tipos de empresas do tipo cadastro logado */
  const loadUserTipos = useCallback(async () => {
    try {
      var response;
      response = await api.get(`/usuario/list-types`);

      var resp;
      if (typeof response.data.data != "undefined") {
        resp = response.data.data;
      } else {
        resp = response.data;
      }

      const userTipos = resp.map((empresa) => {
        const objeto = {
          value: empresa.id,
          label: empresa.cpf_cnpj + " - " + empresa.razao_social,
        };
        return objeto;
      });

      return userTipos;
    } catch (error) {}
  }, []);

  /** Para carregar as Roles (Perfis) cadastradas */
  const loadListRoles = useCallback(async () => {
    try {
      const { data: response } = await api.post(`/role/filter`, {
        cadastro_tipo_id: 1,
        ativo: 1,
      });

      var resp;
      resp = response.data;

      const rolesDB = resp.map((role) => {
        const objeto = {
          value: role.roleId,
          label: role.label,
        };
        return objeto;
      });

      return rolesDB;
    } catch (error) {}
  }, []);

  const handleChangeEmpresa = useCallback((item) => {
    if (!item || item?.length === 0) {
      setEmpresas([{ value: "todos", label: <FormattedMessage id="todos" /> }]);
    } else {
      if (item) {
        const empresas = item.filter((empresa) => empresa.value !== "todos");

        setEmpresas(empresas);
      }
    }
  }, []);

  return (
    <Container>
      {loading ? (
        <LoaderContent />
      ) : (
        <CreateForm>
          <header>
            <h4>
              <FormattedMessage id="editarUsuario" />
            </h4>
          </header>

          <form>
            <AsyncListSelect
              labelPlaceholder={empresaPlaceholder}
              isMulti
              cacheOptions
              defaultOptions
              loadOptions={loadEmpresas}
              value={empresas}
              onChange={handleChangeEmpresa}
              geterror={errors?.cadastro}
            />
            <InputLabel
              name="name"
              type="text"
              label={<FormattedMessage id="nome" />}
              onChange={(e) => setName(e.target.value)}
              value={name}
              geterror={errors?.name}
              maxLength={100}
            />
            <InputLabel
              name="email"
              type="text"
              label={"E-mail"}
              onChange={(e) => setEmail(e.target.value)}
              value={email}
              geterror={errors?.email}
              maxLength={80}
            />
            <InputLabel
              name="celular"
              label={<FormattedMessage id="celular" />}
              type="text"
              mask={MaskInput.cellphone}
              onChange={(e) => {
                return setCelular(e.target.value.replace(/[^\d]+/g, ""));
              }}
              value={celular || ""}
            />
            <InputLabel
              name="telefone"
              label={<FormattedMessage id="telefone" />}
              type="text"
              mask={MaskInput.phone}
              onChange={(e) =>
                setTelefone(e.target.value.replace(/[^\d]+/g, ""))
              }
              value={telefone || ""}
            />
            <ListSelect
              labelPlaceholder={nivelPlaceholder}
              placeholder={<FormattedMessage id="selecionar" />}
              onChange={(e) => {
                setNivel(e);
                setLabel("");
              }}
              value={nivel}
              options={userTipos}
              loadOptions={loadUserTipos}
              geterror={errors?.tipo_id}
            />
            <AsyncListSelect
              labelPlaceholder={rolePlaceholder}
              placeholder={<FormattedMessage id="selecione" />}
              onChange={(e) => {
                setLabel(e);
              }}
              cacheOptions
              defaultOptions
              value={label}
              isDisabled={nivel.value === 1 ? true : false}
              loadOptions={loadListRoles}
              geterror={errors?.roles}
            />
            <ListSelect
              labelPlaceholder={statusPlaceholder}
              onChange={(e) => setStatus(e)}
              options={statusOptions}
              value={status}
            />
          </form>
          <hr />
          <CreateFooter>
            <div>
              <Button type="button" themeType="back" onClick={handleBackButton}>
                <FormattedMessage id="voltar" />
              </Button>
            </div>
            <div>
              <Button
                type="submit"
                onClick={handleEditUser}
                loading={loadingSave}
              >
                <FormattedMessage id="salvar" />
              </Button>
            </div>
          </CreateFooter>
        </CreateForm>
      )}
    </Container>
  );
};

export default injectIntl(EditUser);
