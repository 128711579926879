import React, { useContext } from "react";

import { Card, InfoOrder } from "./styles";

import AuthContext from "./../../context/auth";

const Cards = ({ width, height, src, type, text, number, progress, icon, subtitle, href, permission,  ...rest }) => {

  const { user } = useContext(AuthContext);

  const checkPermission = user.permission.filter(
    (p) => p.namePermission === permission
  );

  if (permission) {
    if (user.tipo_id === 2 && checkPermission.length === 0) {
      return <></>;
    }
  }

  return (
    <Card>
      {/* <a href={href} style={{textDecoration: "none"}}>
        <div className="widget widget-stats bg-gradient-muted m-b-10" style={{height: '120px'}}>
          <div className="stats-icon stats-icon-lg"><i className={icon}></i></div>
          <div className="stats-content">
            <div className="stats-title" style={{fontWeight:"700", fontSize:"13px"}}>{text}</div>
            <div className="stats-number">{number}</div>
            <div className="stats-progress progress">
              <div className="progress-bar" style={{width: progress}}></div>
            </div>
            <div className="stats-desc">{subtitle}</div>
            </div>
          </div>
      </a> */}

      <InfoOrder>
        <a href={href} style={{textDecoration: "none"}}>
          <p>
            {text}
          </p>
          <div>
            {number}
          </div>
        </a>
      </InfoOrder>
    </Card>
  );
};

export default Cards;
