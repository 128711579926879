import React, { forwardRef, useContext } from "react";
import { Button } from "./styles";
import AuthContext from "./../../context/auth";
import Loader from "./../../assets/images/loader.svg";

const ButtonDefault = forwardRef(
  ({ loading = false, permission, children, program, ...rest }, ref) => {
    const { user } = useContext(AuthContext);

    const checkPermission = user?.permission.filter(
      (p) => p?.namePermission === permission
    );

    if (permission) {
      if (user?.tipo_id === 2 && checkPermission.length === 0) {
        return <></>;
      }
    }

    return (
      <Button {...rest} disabled={loading} ref={ref} program={program}>
        {loading ? <img src={Loader} alt="loader" /> : children}
      </Button>
    );
  }
);

export default ButtonDefault;
