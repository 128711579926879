import React, {
  useCallback,
  useEffect,
  useState,
  createRef,
  useContext,
} from "react";
import { useTable, usePagination, useExpanded } from "react-table";
import { useHistory, useLocation, Link } from "react-router-dom";
import { format, parseISO } from "date-fns";
import { FormattedMessage, injectIntl } from "react-intl";
import { FiChevronLeft } from "react-icons/fi";

import swal from "sweetalert";
import Alert from "./../../../../../assets/images/alert.png";
import moment from "moment";
import DatePicker, { registerLocale } from "react-datepicker";
import { ptBR } from "date-fns/locale";

import iconFiltro from "./../../../../../assets/images/icone-filtro.png";
import iconLixeira from "./../../../../../assets/images/icone-lixeira.png";

import {
  FiEdit,
  FiEye,
  FiPlus,
  FiXCircle,
  FiTrash2,
  FiAlertCircle
} from "react-icons/fi";
import { AiOutlineFolderOpen, AiOutlineClockCircle } from "react-icons/ai";
import { FaEllipsisH, FaSortAmountUp } from "react-icons/fa";
import { MdExpandMore, MdExpandLess, MdAttachMoney, MdFavorite } from "react-icons/md";
import iconEspelhoCliente from "./../../../../../assets/images/icone-extrato1.png";
import iconEspelhoDistribuidor from "./../../../../../assets/images/icone-extrato2.png";


import { ImCross, ImCancelCircle } from "react-icons/im";
import { FaHistory } from "react-icons/fa";
import { AiOutlineMail } from "react-icons/ai";

import { Container, ActionContainer, ContainerPedidoLink } from "./styles";
import api from "./../../../../../services/api";
import UploadNFServico from "./../../../../../components/UploadNFServico";
import UploadNFDistribuidor from "./../../../../../components/UploadNFDistribuidor";
import DownloadBoleto from "./../../../../../components/DownloadBoleto";
import ButtonIcon from "./../../../../../components/ButtonIcon";
import InputMaskDefault from "./../../../../../components/InputMaskDefault";
import ButtonImage from "./../../../../../components/ButtonImage";
import OptionsList from "./../../../../../components/OptionsList";
import OptionsIconPlaceholder from "./../../../../../components/OptionsList/OptionsIconPlaceholder";
import InputLabel from "./../../../../../components/InputLabel";
import ListSelect from "./../../../../../components/ListSelect";
import ContentSpinner from "./../../../../../components/Spinner/Content";
import AsyncListSelect from "./../../../../../components/ListSelect/AsyncListSelect";
import iconVoltar from "./../../../../../assets/images/icone-voltar.svg";

import ExcelLogo from "./../../../../../assets/images/logo-excel.png";

import Paginate from "./../../../../../components/Paginate";

import { toast } from "react-toastify";
import ReactExport from "react-data-export";
import AuthContext from "./../../../../../context/auth";
// import DownloadEspelho from "../../../../../components/DownloadEspelho";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;

const PainelList = ({ intl }) => {

  registerLocale("ptBR", ptBR);

  /* Data Criação */
  const [DateCreateRange, setDateRange] = useState([null, null]);
  const [startDateCreate, endDateCreate] = DateCreateRange;

  /* Data Entrega */
  const [DateEntregaRange, setEntregaRange] = useState([null, null]);
  const [startDateEntrega, endDateEntrega] = DateEntregaRange;


  // Estados da lista painel
  const [listaPainel, setListaPainel] = useState([]);

  const [check, setCheck] = useState(false);
  // const [actualDate, setActualDate] = useState("");

  const [integrateButton, setIntegrateButton] = useState(false);
  const [filterButton, setFilterButton] = useState(false);
  const [integrateMultiButtonLoading, setIntegrateMultiButtonLoading] =
    useState(false);
  const [loadingList, setLoadingList] = useState(false);
  const [loadingListFilter, setLoadingListFilter] = useState(false);
  const [loadingEmail, setLoadingEmail] = useState(false);
  const [loadingEspelhoCliente, setLoadingEspelhoCliente] = useState(false);
  const [loadingEspelhoDistribuidor, setLoadingEspelhoDistribuidor] = useState(false);
  const [selectedOrders, setSelectedOrders] = useState([]);
  const [showAction, setShowAction] = useState(false);
  const [showLegends, setShowLegends] = useState(false);
  // const [criadoPorOptions, setCriadoPorOptions] = useState([]);
  // const [responsavelOptions, setResponsavelOptions] = useState([]);

  const [filterNumeroPedido, setFilterNumeroPedido] = useState("");
  const [filterNumeroPedidoCliente, setFilterNumeroPedidoCliente] =
    useState("");
  // const [filterDataPedido, setFilterDataPedido] = useState("");
  const [filterCliente, setFilterCliente] = useState("");
  const [filterOperador, setFilterOperador] = useState("");
  const [filterProduto, setFilterProduto] = useState("");
  const [filterDistribuidorTRR, setFilterDistribuidorTRR] = useState("");
  const [filterPrazoPagamento, setFilterPrazoPagamento] = useState("");
  // const [filterDataEntrega, setFilterDataEntrega] = useState("");
  const [filterStatus, setFilterStatus] = useState("");
  const [filterStatusCotacao, setFilterStatusCotacao] = useState("");
  const [filterIntegrado, setFilterIntegrado] = useState("");
  const [filterCargaWizeo, setFilterCargaWizeo] = useState("");
  const [filterCotacao, setFilterCotacao] = useState("");
  const [filterNotaServiço, setFilterNotaServiço] = useState("");
  const [filterNotaDitribuidor, setFilterNotaDistribuidor] = useState("");
  const [filterBoleto, setFilterBoleto] = useState("");
  const [filterProg, setFilterProg] = useState("");
  const [filterCotacaoOriginal, setFilterCotacaoOriginal] = useState("");
  // const [filterResponsavel, setFilterResponsavel] = useState("");
  // const [filterCriador, setFilterCriador] = useState("");

  // Paginação
  const location = useLocation();
  const [totalCotacao, setTotalCotacao] = useState("");
  const [totalPedido, setTotalPedido] = useState("");
  const [firstPage, setFirstPage] = useState("");
  const [lastPage, setLastPage] = useState("");
  const [lastPageUrl, setLastPageUrl] = useState("");
  const [nextPage, setNextPage] = useState("");
  const [prevPage, setPrevPage] = useState("");
  const [currentPage, setCurrentPage] = useState(() => {
    return new URLSearchParams(location.search).get("page") || 1;
  });
  const [countPage, setCountPage] = useState([]);

  const history = useHistory();

  const refExcel = createRef(null);

  const { user } = useContext(AuthContext);

  const voceTemCerteza = intl.formatMessage({ id: "voceTemCerteza" });
  const opcaoSim = intl.formatMessage({ id: "opcaoSim" });
  const cancelar = intl.formatMessage({ id: "cancelar" });

  const integradoOptions = [
    { value: 1, label: <FormattedMessage id="opcaoSim" /> },
    { value: 0, label: <FormattedMessage id="opcaoNao" /> },
    { value: 2, label: <FormattedMessage id="Erro" /> },
  ];

  const notaServiço = [
    { value: "s", label: <FormattedMessage id="opcaoSim" /> },
    { value: "n", label: <FormattedMessage id="opcaoNao" /> },
  ];

  const notaDistribuidor = [
    { value: "s", label: <FormattedMessage id="opcaoSim" /> },
    { value: "n", label: <FormattedMessage id="opcaoNao" /> },
  ];

  const boleto = [
    { value: "s", label: <FormattedMessage id="opcaoSim" /> },
    { value: "n", label: <FormattedMessage id="opcaoNao" /> },
  ];

  const statusOptions = [
    { value: "cotacao", label: <FormattedMessage id="cotacao" /> },
    {
      value: "Aguardando Aprovação",
      label: <FormattedMessage id="aguardandoAprovacao" />,
    },
    { value: "aprovado", label: <FormattedMessage id="aprovado" /> },
    { value: "reprovado", label: <FormattedMessage id="reprovado" /> },
    { value: "cancelado", label: <FormattedMessage id="cancelado" /> },
    {
      value: "Aguardando Faturamento",
      label: <FormattedMessage id="aguardando" />,
    },
    { value: "faturado", label: <FormattedMessage id="faturado" /> },
  ];

  const statusCotacao = [
    {
      value: "Análise Cliente",
      label: <FormattedMessage id="analiseCliente" />,
    },
    { value: "Análise Distribuidor/TRR", label: "Análise Distribuidor/TRR" },
    { value: "Análise Mesa de Operação", label: "Análise Mesa de Operação" },
    {
      value: "Reanálise Distribuidor/TRR",
      label: <FormattedMessage id="reanalise" />,
    },
    { value: "Aprovado", label: <FormattedMessage id="aprovado" /> },
    { value: "Reprovado", label: <FormattedMessage id="reprovado" /> },
    { value: "cancelado", label: <FormattedMessage id="cancelado" /> },
    { value: "faturado", label: <FormattedMessage id="faturado" /> },
  ];

  const handleHistory = useCallback(
    (pedido, cotacaoIdProduto) => {
      const data = {
        pedido,
        cotacaoIdProduto
      };

      history.push("/orders/painel/history", data);
    },
    [history]
  );

  const handleDisplay = useCallback(
    async (data) => {
      delete data.original?.historico;
      delete data.original?.produto;
      delete data.original?.valorTotal;
      delete data.original?.notaServico;
      delete data.original?.notaDistribuidor;
      delete data.original?.boleto;
      delete data.original?.cotacaoOriginal;

      history.push("/orders/painel/show", data.original);
    },
    [history]
  );

  const PedidoLink = useCallback(({ item, menorPreco }) => {
    return (
      <ContainerPedidoLink>
        <Link
          to={{
            pathname: "/orders/painel/show",
            state: {
              numeroPedido: item?.numero_cotacao_sistema,
              pedido: item?.pedido,
              id: item?.pedido_id,
              id_cotacao: item?.id,
              original: item,
              cotacaoIdProduto: menorPreco
            },
          }}
        >
          {item?.numero_cotacao_sistema && item?.numero_cotacao_sistema}
          {item?.numero_cotacao_sistema && <AiOutlineFolderOpen />}
        </Link>
      </ContainerPedidoLink>
    );
  }, []);

  function formatExcelData(data) {
    const dataFormat = data.map((item) => {
      return [
        {
          value: item?.numeroPedido || "",
          style: {
            alignment: {
              wrapText: false,
              horizontal: "center",
            },
          },
        },
        {
          value: item?.numeroCotacao || "",
          style: {
            alignment: {
              wrapText: false,
              horizontal: "center",
            },
          },
        },
        {
          value: item?.cotacaoOriginalExcel || "",
          style: {
            alignment: {
              wrapText: false,
              horizontal: "center",
            },
          },
        },
        {
          value: item?.numeroPedidoCliente || "",
          style: {
            alignment: {
              wrapText: false,
              horizontal: "center",
            },
          },
        },
        {
          value: item?.numeroPedidoWizeo || "",
          style: {
            alignment: {
              wrapText: false,
              horizontal: "center",
            },
          },
        },
        {
          value: item?.programados || "",
          style: {
            alignment: {
              wrapText: false,
              horizontal: "center",
            },
          },
        },
        {
          value: item?.dataPedido || "",
          style: {
            alignment: {
              wrapText: false,
              horizontal: "center",
            },
          },
        },
        {
          value: item?.cliente || "",
          style: {
            alignment: {
              wrapText: false,
              horizontal: "center",
            },
          },
        },
        {
          value: item?.operador || "",
          style: {
            alignment: {
              wrapText: false,
              horizontal: "center",
            },
          },
        },
        {
          value: item?.criador || "",
          style: {
            alignment: {
              wrapText: false,
              horizontal: "center",
            },
          },
        },
        {
          value: item?.produtoExcel || "",
          style: {
            alignment: {
              wrapText: false,
              horizontal: "center",
            },
          },
        },

        {
          value: item?.valorTotalExcel || "",
          style: {
            alignment: {
              wrapText: false,
              horizontal: "center",
            },
          },
        },
        {
          value: item?.distribuidorTRR || "",
          style: {
            alignment: {
              wrapText: false,
              horizontal: "center",
            },
          },
        },
        {
          value: item?.prazoPagamento || "",
          style: {
            alignment: {
              wrapText: false,
              horizontal: "center",
            },
          },
        },
        {
          value: item?.dataEntrega || "",
          style: {
            alignment: {
              wrapText: false,
              horizontal: "center",
            },
          },
        },
        {
          value: item?.status || "",
          style: {
            alignment: {
              wrapText: false,
              horizontal: "center",
            },
          },
        },
        {
          value: item?.statusCotacao || "",
          style: {
            alignment: {
              wrapText: false,
              horizontal: "center",
            },
          },
        },
        {
          value: item?.integrado || "",
          style: {
            alignment: {
              wrapText: false,
              horizontal: "center",
            },
          },
        },
      ];
    });

    return [
      {
        columns: [
          {
            title: "Nº Pedido Sistema",
            width: { wpx: 100 },
            style: {
              alignment: {
                wrapText: false,
                horizontal: "center",
              },
              font: { bold: true },
            },
          },
          {
            title: "Número Cotação",
            width: { wpx: 100 },
            style: {
              alignment: {
                wrapText: false,
                horizontal: "center",
              },
              font: { bold: true },
            },
          },
          {
            title: "Nº Cotação Incompleta",
            width: { wpx: 120 },
            style: {
              alignment: {
                wrapText: false,
                horizontal: "center",
              },
              font: { bold: true },
            },
          },
          {
            title: "Nº Pedido Cliente",
            width: { wpx: 100 },
            style: {
              alignment: {
                wrapText: false,
                horizontal: "center",
              },
              font: { bold: true },
            },
          },
          {
            title: "Carga Wizeo",
            width: { wpx: 100 },
            style: {
              alignment: {
                wrapText: false,
                horizontal: "center",
              },
              font: { bold: true },
            },
          },
          {
            title: "Gerou Programação",
            width: { wpx: 100 },
            style: {
              alignment: {
                wrapText: false,
                horizontal: "center",
              },
              font: { bold: true },
            },
          },
          {
            title: "Data",
            width: { wpx: 120 },
            style: {
              alignment: {
                wrapText: false,
                horizontal: "center",
              },
              font: { bold: true },
            },
          },
          {
            title: "Cliente",
            width: { wpx: 300 },
            style: {
              alignment: {
                wrapText: false,
                horizontal: "center",
              },
              font: { bold: true },
            },
          },
          {
            title: "Operador",
            width: { wpx: 120 },
            style: {
              alignment: {
                wrapText: false,
                horizontal: "center",
              },
              font: { bold: true },
            },
          },
          {
            title: "Criado por",
            width: { wpx: 150 },
            style: {
              alignment: {
                wrapText: false,
                horizontal: "center",
              },
              font: { bold: true },
            },
          },

          {
            title: "Produtos",
            width: { wpx: 250 },
            style: {
              alignment: {
                wrapText: false,
                horizontal: "center",
              },
              font: { bold: true },
            },
          },

          {
            title: "Volume M³",
            width: { wpx: 100 },
            style: {
              alignment: {
                wrapText: false,
                horizontal: "center",
              },
              font: { bold: true },
            },
          },

          {
            title: "Distribuidores/TRR",
            width: { wpx: 300 },
            style: {
              alignment: {
                wrapText: false,
                horizontal: "center",
              },
              font: { bold: true },
            },
          },

          {
            title: "Prazo de Pagamento",
            width: { wpx: 150 },
            style: {
              alignment: {
                wrapText: false,
                horizontal: "center",
              },
              font: { bold: true },
            },
          },

          {
            title: "Data Entrega",
            width: { wpx: 80 },
            style: {
              alignment: {
                wrapText: false,
                horizontal: "center",
              },
              font: { bold: true },
            },
          },

          {
            title: "Status Pedido",
            width: { wpx: 150 },
            style: {
              alignment: {
                wrapText: false,
                horizontal: "center",
              },
              font: { bold: true },
            },
          },
          {
            title: "Status Cotação",
            width: { wpx: 150 },
            style: {
              alignment: {
                wrapText: false,
                horizontal: "center",
              },
              font: { bold: true },
            },
          },
          {
            title: "Integrado",
            width: { wpx: 70 },
            style: {
              alignment: {
                wrapText: false,
                horizontal: "center",
              },
              font: { bold: true },
            },
          },
        ],
        data: dataFormat,
      },
    ];
  }

  //Pedidos OBJ
  useEffect(() => {
    (async () => {
      setLoadingList(true);
      // const { data: date } = await api.get("/utilitarios/get-utc-time");

      // setActualDate(format(parseISO(date), "dd/MM/yyyy"));

      localStorage.getItem("dataFilterStorage")
      var storedArray = localStorage.getItem("dataFilterStorage");
      const dataFilterStorage = JSON.parse(storedArray);

      try {
        // const today = new Date();
        // const formattedDate = format(today, "dd/MM/yyyy");

        var dataFilter = {}

        if (history.location?.state?.statusCard === "Análise Mesa de Operação" || history.location?.state?.statusCard === "Análise Distribuidor/TRR") {
        // statusCard vem o status do card em HOME
          const status = history.location?.state?.statusCard
          dataFilter = {
            status_filtro_cotacao: status,
            "order_by[campo]": "id",
            "order_by[order]": "desc",
            data_criacao_de: moment().startOf('month').format('YYYY-MM-DD 00:00:00'),
            data_criacao_ate: moment().endOf('month').format('YYYY-MM-DD 23:59:59'),
           limit: 40,
          };
          localStorage.setItem("dataFilterStorage",JSON.stringify(dataFilter));

        } else if (history.location?.state?.statusCard === "Faturado") {
          const status = history.location?.state?.statusCard

          dataFilter = {
            status_filtro_cotacao: status,
            "order_by[campo]": "id",
            "order_by[order]": "desc",
            data_faturamento_de: moment().startOf('month').format('YYYY-MM-DD 00:00:00'),
            data_faturamento_ate: moment().endOf('month').format('YYYY-MM-DD 23:59:59'),
            limit: 40,
          };
          localStorage.setItem("dataFilterStorage",JSON.stringify(dataFilter));

        } else if (history.location?.state?.painelControle) {
          // painelControle vem de todos botões de voltar que englobam o pedido
          dataFilter = dataFilterStorage;

        } else {
          dataFilter = {
            limit: 40,
            data_criacao_de: moment().format("YYYY-MM-DD 00:00:00"),
            data_criacao_ate: moment().format("YYYY-MM-DD 23:59:59"),
            "order_by[campo]": "id",
            "order_by[order]": "desc",
          };
          localStorage.setItem("dataFilterStorage",JSON.stringify(dataFilter));
        }

        const { data: response } = await api.get("/pedido/filter", {
          params: dataFilter,
        });

        // const criadoPor = Object.keys(response.lista_criado_por).map((key) => {
        //   return {
        //     value: response.lista_criado_por[key]?.id,
        //     label: response.lista_criado_por[key]?.name,
        //   };
        // });
        // setCriadoPorOptions(criadoPor);

        // const responsavel = Object.keys(response.ultimo_editor_preco).map(
        //   (key) => {
        //     return {
        //       value: response.ultimo_editor_preco[key]?.id,
        //       label: response.ultimo_editor_preco[key]?.name,
        //     };
        //   }
        // );
        // setResponsavelOptions(responsavel);

        let totalCotação = 0;
        let totalPedido = 0;

        response.dados.data.forEach((pedido) => {
          totalPedido = totalPedido + 1;
          let pedidoRow = {};
          let cotacoesRows = [];
          let obj = {};
          let cotacaoIdProduto = [];
          let menorPreco = [];

          let produtoFormatted = [];
          produtoFormatted = pedido?.produtos_pedido.map((prod) => {
            return prod.descricao;
          });

          //Integração Status
          var integradoSim = ( pedido.cotacoes.filter( cotacao => (cotacao.status_integracao === 1) ) )
          var integradoNao = ( pedido.cotacoes.filter( cotacao => (cotacao.status_integracao === 0) ) )
          var integradoErro = ( pedido.cotacoes.filter( cotacao => (cotacao.status_integracao >= 2) ) )

          let cotacaoMap = [];
          if( integradoSim.length > 0 ){
            cotacaoMap['integrado'] = "Sim"
          }else if(integradoNao.length > 0 && integradoSim.length === 0){
            cotacaoMap['integrado'] = "Não"
          }else if(integradoErro.length > 0){
            cotacaoMap['integrado'] = "Erro"
          }

          var idCotacaoIntegrar = "";
          var idCotacaoEmail = "";

          pedido.cotacoes.forEach((cotacao) => {
            if (cotacao.status_cotacao === "Aprovado"){
              idCotacaoIntegrar = cotacao.id;
              idCotacaoEmail = cotacao.id;
            }
          })

          var numeroCargaWizeo = "";

          pedido.cotacoes.forEach((cotacao) => {
            if (cotacao.numero_pedido_sodexo !== null){
              numeroCargaWizeo = cotacao.numero_pedido_sodexo
            }
          })

          var totalPedidoAprovado = ""

          pedido.cotacoes.forEach((cotacao) => {
            const taxAdm = cotacao?.taxa_administrativa_unitaria;
            const taxFinanceira = pedido?.cadastro?.taxa_financeira

            if (cotacao.status_cotacao === "Aprovado" || cotacao.status_cotacao === "Faturado" || cotacao.status_cotacao === "Aguardando Faturamento") {
              var prazoAprovado = cotacao.prazos.filter((item) => {
                return item.prazo === pedido.prazo;
              });

              let precoLitroFinal = 0;
              let totalLinhaSemTaxas = 0;
              let totalLinhaProduto = 0;
              let totalProdutoSemTaxa = 0;
              let totalProdutoComTaxa = 0;
              let idPrazo = prazoAprovado[0].id;
              let prazoCotacao = prazoAprovado[0].prazo;
              let totalLinhaFrete = 0;
              let totalLinhaTaxaFinanceira = 0;
              let totalLitros = 0;
              let taxaFinan = "";


              const arr = prazoAprovado[0].produtos.map((prod) => {
                const obj = {};
                let filterTaxa = [];

                //Total Litros/Quantidade da Cotação
                totalLitros = prazoAprovado[0].produtos.reduce((total, b) => {
                  const totalM3 = parseFloat(b.quantidade);
                  return total + totalM3;
                }, 0);

                const ProdutoCotacao = cotacao.produtos_cotacao.filter(
                  (cot_prod) => {
                    return cot_prod.produto_id === prod.produto_id;
                  }
                );

                if (!prod.taxa_financeira_percentual) {
                  filterTaxa = taxFinanceira.filter((tax) => {
                    if (
                      parseInt(prazoAprovado[0].prazo) >=
                        parseInt(tax.taxa_financeira_de) &&
                      parseInt(prazoAprovado[0].prazo) <= parseInt(tax.taxa_financeira_ate)
                    ) {
                      return true;
                    }

                    return false;
                  });

                  taxaFinan = filterTaxa[0]?.taxa_financeira_percentual || 0;
                } else {
                  taxaFinan = prod.taxa_financeira_percentual;
                }

                if (parseInt(user.cadastro_tipo_id) !== 2) {
                  // Valor Preço Litro Final
                  precoLitroFinal = (
                    parseFloat(
                      parseFloat(prod?.valor_unitario) +
                        parseFloat(prod.valor_frete?.replaceAll(",", ".") || 0)
                    ) *
                      (parseFloat(taxaFinan) / 100 + 1) +
                    parseFloat(taxAdm)
                  )
                  .toLocaleString("pt-br", {
                    minimumFractionDigits: 8,
                  });
                } else {
                  // Valor Preço Litro Final
                  precoLitroFinal = (
                    parseFloat(prod?.valor_unitario) +
                    parseFloat(prod.valor_frete?.replaceAll(",", ".") || 0)
                  ).toLocaleString("pt-br", {
                    minimumFractionDigits: 8,
                  });
                }

                // Valor Total sem taxas produto linha
                totalLinhaSemTaxas = (
                  parseFloat(prod?.valor_unitario) * parseFloat(prod?.quantidade)
                ).toLocaleString("pt-br", {
                  minimumFractionDigits: 2,
                });

                if (parseInt(user.cadastro_tipo_id) !== 2) {
                  // Valor Total produto linha
                  totalLinhaProduto = (
                    parseFloat(
                      (
                        (parseFloat(prod?.valor_unitario) +
                          parseFloat(prod?.valor_frete || 0)) *
                        (parseFloat(taxaFinan) / 100 + 1)
                      )
                    ) *
                      parseFloat(prod?.quantidade) +
                    parseFloat(prod?.quantidade) * parseFloat(taxAdm)
                  ).toFixed(2); //arredondamento;

                  totalLinhaProduto = parseFloat(
                    totalLinhaProduto
                  ).toLocaleString("pt-br", {
                    minimumFractionDigits: 8,
                  });
                } else {
                  // Valor Total produto linha
                  totalLinhaProduto = (
                    (parseFloat(prod?.valor_unitario) +
                      parseFloat(prod?.valor_frete || 0)) *
                    parseFloat(prod?.quantidade)
                  ).toFixed(2);

                  totalLinhaProduto = parseFloat(
                    totalLinhaProduto
                  ).toLocaleString("pt-br", {
                    minimumFractionDigits: 2,
                  });
                }

                //Soma total Sem taxas Com Frete
                totalProdutoSemTaxa =
                  totalProdutoSemTaxa +
                  parseFloat(prod?.quantidade) *
                    (parseFloat(prod?.valor_unitario) +
                      parseFloat(prod?.valor_frete || 0));

                //Soma total Com taxas + Frete
                // Calcula o total do pedido inteiro com todas as taxas.

                // Valor total da cotação com todas as taxas
                totalProdutoComTaxa =
                  parseFloat(totalProdutoComTaxa) +
                  parseFloat(
                    totalLinhaProduto.replaceAll(".", "").replaceAll(",", ".")
                  );

                totalLinhaFrete =
                  parseFloat(prod?.valor_frete || 0) *
                  parseFloat(prod?.quantidade);

                totalLinhaTaxaFinanceira =
                  parseFloat(
                    (
                      (parseFloat(prod?.valor_unitario) +
                        parseFloat(prod?.valor_frete || 0)) *
                      (parseFloat(taxaFinan) / 100)
                    )
                  ) * parseFloat(prod?.quantidade);

                totalLinhaTaxaFinanceira = parseFloat(
                  totalLinhaTaxaFinanceira
                );

                Object.assign(obj, {
                  id: prod?.id,
                  id_produto: prod?.produto_id,
                  id_cotacao_produto: ProdutoCotacao[0]?.id || "",
                  descricao: prod.produto.descricao,
                  prazo: prazoAprovado[0].prazo,
                  quantidade: prod.quantidade || 0,
                  valor_referencia: prod?.valor_referencia
                    ? parseFloat(prod?.valor_referencia)
                        .toFixed(4)
                        ?.replaceAll(".", ",")
                    : (0).toFixed(4).replaceAll(".", ","),
                  valor_unitario: prod?.valor_unitario
                    ? parseFloat(prod?.valor_unitario)
                        .toFixed(4)
                        ?.replaceAll(".", ",")
                    : (0).toFixed(4).replaceAll(".", ","),
                  valor_frete: prod?.valor_frete
                    ? parseFloat(prod?.valor_frete)
                        ?.toFixed(4)
                        ?.replaceAll(".", ",")
                    : (0).toFixed(4).replaceAll(".", ","),
                  total_frete: totalLinhaFrete,
                  total_taxa_financeira: totalLinhaTaxaFinanceira,
                  preco_litro_final: precoLitroFinal,
                  total: totalLinhaProduto || 0,
                  total_sem_taxas: totalLinhaSemTaxas,
                  taxa_financeira: taxaFinan || 0,
                });

                return obj;
              });

              arr.map((prod) => {
                prod.total_cotacao_sem_taxa =
                  parseFloat(totalProdutoSemTaxa.toFixed(4)) || 0;
                prod.total_cotacao_com_taxa =
                  parseFloat(totalProdutoComTaxa.toFixed(4)) || 0;
                return prod;
              });

              totalPedidoAprovado = arr[0].total_cotacao_com_taxa;

              return { id: idPrazo, prazo: prazoCotacao, produtos: arr };
            }
          })

          // Cria objeto com os pedidos
          Object.assign(pedidoRow, {
            original: pedido,
            id: pedido.id,
            id_cotacao: idCotacaoIntegrar,
            numeroPedidoSodexo: numeroCargaWizeo,
            numeroPedidoWizeo: numeroCargaWizeo,
            numeroPedido: pedido.numero_pedido_sistema,
            numeroCotacao: "",
            cotacaoOriginal: "",
            totalPedidoAprovado: totalPedidoAprovado.toLocaleString(
              "pt-br",
              {
                style: "currency",
                currency: "BRL",
              }
            ),
            numeroPedidoCliente: pedido.numero_pedido_cliente,
            dataPedido: format(parseISO(pedido.created_at), "dd/MM/yyyy - HH:mm"),
            dataSemFormatacao: pedido.created_at,
            cliente:
              pedido.cotacoes[0].cliente?.cpf_cnpj.replace(
                /(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g,
                "$1.$2.$3/$4-$5"
              ) +
              " - " +
              pedido.cotacoes[0].cliente?.razao_social,
            produto: "",
            distribuidorTRR: "",
            prazoPagamento: pedido?.prazo,
            dataEntrega: format(
              parseISO(pedido.data_entrega_desejada),
              "dd/MM/yyyy"
            ),
            status: pedido?.status_pedido,
            statusCotacao: "",
            valorTotal: "",
            integrado: cotacaoMap['integrado'],
            operador: pedido?.operador?.name,
            criador: pedido?.criado_por?.name,
            responsavel: "",
            historico: (
              <FaHistory
                size={18}
                onClick={() => handleHistory(pedido.id, cotacaoIdProduto)}
              />
            ),
            notaServico: (
              <UploadNFServico
                idCotacao={pedido?.cotacao_faturada[0]?.id}
                hasNF={pedido?.cotacao_faturada[0]?.nota_cliente_nome}
              />
            ),
            notaDistribuidor: (
              <UploadNFDistribuidor
                idCotacao={pedido?.cotacao_faturada[0]?.id}
                hasNF={pedido?.cotacao_faturada[0]?.nota_distribuidor_nome}
              />
            ),
            // espelhoCliente: pedido?.status_pedido === "Aprovado" || pedido?.status_pedido === "Faturado" ||pedido?.status_pedido === "Aguardando Faturamento" ? (
            //   <DownloadEspelho
            //     idCotacao={pedido.id}
            //     hasBoleto={true}
            //     tipoPDF={"cliente"}
            //   />
            // ) : (<div></div>),
            // espelhoDistribuidor: pedido?.status_pedido === "Aprovado" || pedido?.status_pedido === "Faturado" ||pedido?.status_pedido === "Aguardando Faturamento" ? (
            //   <DownloadEspelho
            //     idCotacao={pedido.id}
            //     hasBoleto={true}
            //     tipoPDF={"distribuidor"}
            //   />
            // ) : (<div></div>),
            boleto: pedido?.cotacao_faturada[0]?.boleto_cliente_nome && (
              <DownloadBoleto
                idCotacao={pedido?.cotacao_faturada[0]?.id}
                hasBoleto={pedido?.cotacao_faturada[0]?.boleto_cliente_nome}
              />
            ),
            programados: pedido?.programacao.length > 0 ? "Sim" : "Não",
            cotacaoOriginalExcel: "",
            produtoExcel: produtoFormatted.toString(),
            valorTotalExcel: "",
            distribuidor_id: "",
            distribuidor_preferencia_id: "",
          });

          pedido.cotacoes.forEach((cotacao) => {
            totalCotação = totalCotação + 1;
            let arrayQuantidadeTotal = [];
            let valorTotalFormatted = 0;

            arrayQuantidadeTotal = cotacao?.produtos_cotacao.map((prod) => {
              return parseFloat(prod.quantidade / 1000)
                .toFixed(2)
                ?.replace(".", ",");
            });

            if (arrayQuantidadeTotal.length > 0) {
              valorTotalFormatted = arrayQuantidadeTotal;
            }

            // Cria objeto com as Cotações
            cotacao.produtos_cotacao.map((prod) => {
              obj = {};
              Object.assign(obj, {
                original: pedido,
                id: pedido.id,
                id_cotacao: cotacao.id,
                numeroPedidoSodexo:"",
                numeroPedidoWizeo: "",
                numeroPedido: "",
                numeroCotacao: cotacao.numero_cotacao_sistema,
                cotacaoOriginal: (
                  <PedidoLink item={cotacao?.cotacao_original} menorPreco={cotacaoIdProduto} />
                ),
                numeroPedidoCliente: pedido.numero_pedido_cliente,
                dataPedido: format(parseISO(pedido.created_at), "dd/MM/yyyy - HH:mm"),
                dataSemFormatacao: pedido.created_at,
                cliente: "",
                produto: prod?.produto?.descricao,
                cotacao_produto_id: prod?.id,
                valorTotal: (parseFloat(prod?.quantidade) / 1000).toFixed(2),
                preco: prod?.valor_unitario,
                distribuidorTRR: cotacao.distribuidor
                  ? cotacao?.distribuidor.cpf_cnpj.replace(
                      /(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g,
                      "$1.$2.$3/$4-$5"
                    ) +
                    " - " +
                    cotacao?.distribuidor.razao_social
                  : "",
                prazoPagamento: "",
                dataEntrega: "",
                status: pedido?.status_pedido,
                statusCotacao: cotacao?.status_cotacao,
                integrado: "",
                operador: "",
                criador: "",
                responsavel: cotacao?.ultimo_editor_preco?.name,
                historico: "",
                notaServico: "",
                notaDistribuidor: "",
                boleto: "",
                programados: "",
                cotacaoOriginalExcel:
                  cotacao?.cotacao_original?.numero_cotacao_sistema,
                produtoExcel: produtoFormatted.toString(),
                valorTotalExcel: valorTotalFormatted.toString(),
                distribuidor_id: cotacao?.distribuidor?.id,
                distribuidor_preferencia_id:
                  cotacao?.cliente?.distribuidor_preferencia_id,
                cotacaoIdProduto: cotacaoIdProduto
              });

              cotacoesRows.push(obj);
              return prod;
            });
          });

          // Ordena Cotação por produto e preço
          cotacoesRows = cotacoesRows.sort((a, b) => {
            function value(el) {
              var x = el;
              return x === "0.0000" ? Infinity : x;
            }

            return (
              a.produto.localeCompare(b.produto) ||
              value(a.preco) - value(b.preco)
            );
          });

          // Sempre adiciona a primeira posição do array quando valor diferente de 0
          if (cotacoesRows[0].preco !== "0.0000") {
            cotacoesRows[0].blink = true;
            cotacaoIdProduto.push(cotacoesRows[0].cotacao_produto_id)
            menorPreco.push([cotacoesRows[0].preco, cotacoesRows[0].produto])
          }

          // Faz uma busca pelo restante do array e adiciona os menores preços caso os produtos sejam diferentes e diferente de 0
          cotacoesRows = cotacoesRows.map((i, index) => {
            if (i.produto !== cotacoesRows[index - 1 < 0 ? 0 : index - 1]?.produto && cotacoesRows[index].preco !== "0.0000") {
              i.blink = true;
              cotacaoIdProduto.push(i.cotacao_produto_id)
              menorPreco.push([i?.preco, i?.produto])
            }

            // "menorPrecoIgual" existe para adicionar o menor preço que possui o mesmo valor em outras cotações
            if (menorPreco.find( menorPrecoIgual =>  menorPrecoIgual[0] === i?.preco && menorPrecoIgual[1] === i?.produto)) {
              i.blink = true;
              cotacaoIdProduto.push(i.cotacao_produto_id)
            }

            return i;
          });

          Object.assign(pedidoRow, {
            subRows: cotacoesRows,
          });

          setTotalCotacao(totalCotação);
          setTotalPedido(totalPedido);
          setListaPainel((state) => [...state, pedidoRow]);
        });

        setFirstPage(response?.dados?.first_page_url);
        setLastPage(response?.dados?.last_page);
        setLastPageUrl(response?.dados?.last_page_url);
        setNextPage(response?.dados?.next_page_url);
        setPrevPage(response?.dados?.prev_page_url);
        setCurrentPage(response?.dados?.current_page);
        setCountPage(() => {
          const arr = [];

          const current_Page = response?.dados?.current_page;
          let initialPageNumber = 0;
          let limitPageNumber = 0;

          let lastPage = response?.dados?.last_page;

          if (current_Page > 2) {
            initialPageNumber = current_Page - 3;
          } else if (current_Page <= 2) {
            initialPageNumber = current_Page - current_Page;
          }

          // define qual é a pagina final pelos numeros
          if (current_Page > 2) {
            limitPageNumber = current_Page + 2;
          } else if (current_Page <= 2) {
            limitPageNumber = current_Page - current_Page + 5;
          }

          // define qual é a pagina final pelos numeros
          if (limitPageNumber > lastPage) {
            initialPageNumber = lastPage - 5;
            limitPageNumber = lastPage;
          }

          // Cria o Array com a paginação numérica
          for (
            let index = initialPageNumber;
            index < limitPageNumber;
            index++
          ) {
            arr.push(index + 1);
          }

          return arr.filter((page) => page > 0);
        });

        setLoadingList(false);
      } catch (error) {
        console.log(error);
        setLoadingList(false);
      }
    })();
  }, [handleHistory, history, user]);

  //Pedidos OBJ
  const handleFilter = useCallback(async () => {

    /* Data Criação */
    const dataDeCreate = DateCreateRange[0] === null ? "" : moment(DateCreateRange[0]).format("YYYY-MM-DD 00:00:00");
    const dataAteCreate = DateCreateRange[1] === null ? "" : moment(DateCreateRange[1]).format("YYYY-MM-DD 23:59:59");

    /* Data Entrega */
    const dataDeEntrega = DateEntregaRange[0] === null ? null : moment(DateEntregaRange[0]).format("YYYY-MM-DD 00:00:00");
    const dataAteEntrega = DateEntregaRange[1] === null ? null : moment(DateEntregaRange[1]).format("YYYY-MM-DD 23:59:59");

    const checkProduto = !(typeof filterProduto === 'object') ? filterProduto : ""
    const checkCotacaoOriginal = !(typeof filterCotacaoOriginal === 'object') ? filterCotacaoOriginal : ""
    const checkProg = !(typeof filterProg === 'object') ? filterProg : ""
    const checkOperador = !(typeof filterOperador === 'object') ? filterOperador : ""
    // const checkResponsavel = !(typeof filterResponsavel === 'object') ? filterResponsavel : ""
    // const checkCriador = !(typeof filterCriador === 'object') ? filterCriador : ""
    const checkStatus = !(typeof filterStatus === 'object') ? filterStatus : ""
    const checkStatusCotacao = !(typeof filterStatusCotacao === 'object') ? filterStatusCotacao : ""
    const checkIntegrado = !(typeof filterIntegrado === 'object') ? filterIntegrado : ""
    const checkNotaServico = !(typeof filterNotaServiço === 'object') ? filterNotaServiço : ""
    const checkNotaDistribuidor = !(typeof filterNotaDitribuidor === 'object') ? filterNotaDitribuidor : ""
    const checkBoleto = !(typeof filterBoleto === 'object') ? filterBoleto : ""

    const dataFilter = {
      numero_pedido_sistema: filterNumeroPedido,
      numero_pedido_cliente: filterNumeroPedidoCliente,
      data_criacao_de: dataDeCreate,
      data_criacao_ate: dataAteCreate,
      cliente: filterCliente,
      distribuidor: filterDistribuidorTRR,
      operador_id: checkOperador,
      prazo: filterPrazoPagamento,
      data_entrega_desejada_de: dataDeEntrega,
      data_entrega_desejada_ate: dataAteEntrega,
      status_filtro_pedido: checkStatus,
      status_filtro_cotacao: checkStatusCotacao,
      numero_pedido_sodexo: filterCargaWizeo,
      numero_cotacao_sistema: filterCotacao,
      produto_id: checkProduto,
      // criado_por_user_id: checkCriador,
      // ultimo_editor_preco: checkResponsavel,
      nota_cliente_nome: checkNotaServico,
      boleto_cliente_nome: checkBoleto,
      nota_distribuidor_nome: checkNotaDistribuidor,
      status_integracao: checkIntegrado,
      programacao: checkProg,
      cotacao_id_original: checkCotacaoOriginal,
      limit: 40,
      "order_by[campo]": "id",
      "order_by[order]": "desc",
    };

    // salva o histórico de filtros no storage
    localStorage.setItem("dataFilterStorage",JSON.stringify(dataFilter));

    /** zero a tabela para mostrar somente o retorno */
    setListaPainel([]);
    setLoadingListFilter(true);
    setFilterButton(true);
    try {
      const { data: response } = await api.get("/pedido/filter", {
        params: dataFilter,
      });

      // const criadoPor = Object.keys(response.lista_criado_por).map((key) => {
      //   return {
      //     value: response.lista_criado_por[key]?.id,
      //     label: response.lista_criado_por[key]?.name,
      //   };
      // });
      // setCriadoPorOptions(criadoPor);

      // const responsavel = Object.keys(response.ultimo_editor_preco).map(
      //   (key) => {
      //     return {
      //       value: response.ultimo_editor_preco[key]?.id,
      //       label: response.ultimo_editor_preco[key]?.name,
      //     };
      //   }
      // );
      // setResponsavelOptions(responsavel);

      let totalCotação = 0;
      let totalPedido = 0;

      response.dados.data.forEach((pedido) => {
        totalPedido = totalPedido + 1;
        let pedidoRow = {};
        let cotacoesRows = [];
        let obj = {};
        let cotacaoIdProduto = [];
        let menorPreco = [];

        let produtoFormatted = [];
        produtoFormatted = pedido?.produtos_pedido.map((prod) => {
          return prod.descricao;
        });

        //Integração Status
        var integradoSim = ( pedido.cotacoes.filter( cotacao => (cotacao.status_integracao === 1) ) )
        var integradoNao = ( pedido.cotacoes.filter( cotacao => (cotacao.status_integracao === 0) ) )
        var integradoErro = ( pedido.cotacoes.filter( cotacao => (cotacao.status_integracao >= 2) ) )

        let cotacaoMap = [];
        if( integradoSim.length > 0 ){
          cotacaoMap['integrado'] = "Sim"
        }else if(integradoNao.length > 0 && integradoSim.length === 0){
          cotacaoMap['integrado'] = "Não"
        }else if(integradoErro.length > 0){
          cotacaoMap['integrado'] = "Erro"
        }

        var idCotacaoIntegrar = "";

        pedido.cotacoes.forEach((cotacao) => {
          if (cotacao.status_cotacao === "Aprovado"){
            idCotacaoIntegrar = cotacao.id
          }
        })

        var numeroCargaWizeo = "";

        pedido.cotacoes.forEach((cotacao) => {
          if (cotacao.numero_pedido_sodexo !== null){
            numeroCargaWizeo = cotacao.numero_pedido_sodexo
          }
        })

        var totalPedidoAprovado = ""

          pedido.cotacoes.forEach((cotacao) => {
            const taxAdm = cotacao?.taxa_administrativa_unitaria;
            const taxFinanceira = pedido?.cadastro?.taxa_financeira

            if (cotacao.status_cotacao === "Aprovado" || cotacao.status_cotacao === "Faturado" || cotacao.status_cotacao === "Aguardando Faturamento") {
              var prazoAprovado = cotacao.prazos.filter((item) => {
                return item.prazo === pedido.prazo;
              });

              let precoLitroFinal = 0;
              let totalLinhaSemTaxas = 0;
              let totalLinhaProduto = 0;
              let totalProdutoSemTaxa = 0;
              let totalProdutoComTaxa = 0;
              let idPrazo = prazoAprovado[0].id;
              let prazoCotacao = prazoAprovado[0].prazo;
              let totalLinhaFrete = 0;
              let totalLinhaTaxaFinanceira = 0;
              let totalLitros = 0;
              let taxaFinan = "";


              const arr = prazoAprovado[0].produtos.map((prod) => {
                const obj = {};
                let filterTaxa = [];

                //Total Litros/Quantidade da Cotação
                totalLitros = prazoAprovado[0].produtos.reduce((total, b) => {
                  const totalM3 = parseFloat(b.quantidade);
                  return total + totalM3;
                }, 0);

                const ProdutoCotacao = cotacao.produtos_cotacao.filter(
                  (cot_prod) => {
                    return cot_prod.produto_id === prod.produto_id;
                  }
                );

                if (!prod.taxa_financeira_percentual) {
                  filterTaxa = taxFinanceira.filter((tax) => {
                    if (
                      parseInt(prazoAprovado[0].prazo) >=
                        parseInt(tax.taxa_financeira_de) &&
                      parseInt(prazoAprovado[0].prazo) <= parseInt(tax.taxa_financeira_ate)
                    ) {
                      return true;
                    }

                    return false;
                  });

                  taxaFinan = filterTaxa[0]?.taxa_financeira_percentual || 0;
                } else {
                  taxaFinan = prod.taxa_financeira_percentual;
                }

                if (parseInt(user.cadastro_tipo_id) !== 2) {
                  // Valor Preço Litro Final
                  precoLitroFinal = (
                    parseFloat(
                      parseFloat(prod?.valor_unitario) +
                        parseFloat(prod.valor_frete?.replaceAll(",", ".") || 0)
                    ) *
                      (parseFloat(taxaFinan) / 100 + 1) +
                    parseFloat(taxAdm)
                  )
                  .toLocaleString("pt-br", {
                    minimumFractionDigits: 8,
                  });
                } else {
                  // Valor Preço Litro Final
                  precoLitroFinal = (
                    parseFloat(prod?.valor_unitario) +
                    parseFloat(prod.valor_frete?.replaceAll(",", ".") || 0)
                  ).toLocaleString("pt-br", {
                    minimumFractionDigits: 8,
                  });
                }

                // Valor Total sem taxas produto linha
                totalLinhaSemTaxas = (
                  parseFloat(prod?.valor_unitario) * parseFloat(prod?.quantidade)
                ).toLocaleString("pt-br", {
                  minimumFractionDigits: 2,
                });

                if (parseInt(user.cadastro_tipo_id) !== 2) {
                  // Valor Total produto linha
                  totalLinhaProduto = (
                    parseFloat(
                      (
                        (parseFloat(prod?.valor_unitario) +
                          parseFloat(prod?.valor_frete || 0)) *
                        (parseFloat(taxaFinan) / 100 + 1)
                      )
                    ) *
                      parseFloat(prod?.quantidade) +
                    parseFloat(prod?.quantidade) * parseFloat(taxAdm)
                  ).toFixed(2); //arredondamento;

                  totalLinhaProduto = parseFloat(
                    totalLinhaProduto
                  ).toLocaleString("pt-br", {
                    minimumFractionDigits: 8,
                  });
                } else {
                  // Valor Total produto linha
                  totalLinhaProduto = (
                    (parseFloat(prod?.valor_unitario) +
                      parseFloat(prod?.valor_frete || 0)) *
                    parseFloat(prod?.quantidade)
                  ).toFixed(2);

                  totalLinhaProduto = parseFloat(
                    totalLinhaProduto
                  ).toLocaleString("pt-br", {
                    minimumFractionDigits: 2,
                  });
                }

                //Soma total Sem taxas Com Frete
                totalProdutoSemTaxa =
                  totalProdutoSemTaxa +
                  parseFloat(prod?.quantidade) *
                    (parseFloat(prod?.valor_unitario) +
                      parseFloat(prod?.valor_frete || 0));

                //Soma total Com taxas + Frete
                // Calcula o total do pedido inteiro com todas as taxas.

                // Valor total da cotação com todas as taxas
                totalProdutoComTaxa =
                  parseFloat(totalProdutoComTaxa) +
                  parseFloat(
                    totalLinhaProduto.replaceAll(".", "").replaceAll(",", ".")
                  );

                totalLinhaFrete =
                  parseFloat(prod?.valor_frete || 0) *
                  parseFloat(prod?.quantidade);

                totalLinhaTaxaFinanceira =
                  parseFloat(
                    (
                      (parseFloat(prod?.valor_unitario) +
                        parseFloat(prod?.valor_frete || 0)) *
                      (parseFloat(taxaFinan) / 100)
                    )
                  ) * parseFloat(prod?.quantidade);

                totalLinhaTaxaFinanceira = parseFloat(
                  totalLinhaTaxaFinanceira
                );

                Object.assign(obj, {
                  id: prod?.id,
                  id_produto: prod?.produto_id,
                  id_cotacao_produto: ProdutoCotacao[0]?.id || "",
                  descricao: prod.produto.descricao,
                  prazo: prazoAprovado[0].prazo,
                  quantidade: prod.quantidade || 0,
                  valor_referencia: prod?.valor_referencia
                    ? parseFloat(prod?.valor_referencia)
                        .toFixed(4)
                        ?.replaceAll(".", ",")
                    : (0).toFixed(4).replaceAll(".", ","),
                  valor_unitario: prod?.valor_unitario
                    ? parseFloat(prod?.valor_unitario)
                        .toFixed(4)
                        ?.replaceAll(".", ",")
                    : (0).toFixed(4).replaceAll(".", ","),
                  valor_frete: prod?.valor_frete
                    ? parseFloat(prod?.valor_frete)
                        ?.toFixed(4)
                        ?.replaceAll(".", ",")
                    : (0).toFixed(4).replaceAll(".", ","),
                  total_frete: totalLinhaFrete,
                  total_taxa_financeira: totalLinhaTaxaFinanceira,
                  preco_litro_final: precoLitroFinal,
                  total: totalLinhaProduto || 0,
                  total_sem_taxas: totalLinhaSemTaxas,
                  taxa_financeira: taxaFinan || 0,
                });

                return obj;
              });

              arr.map((prod) => {
                prod.total_cotacao_sem_taxa =
                  parseFloat(totalProdutoSemTaxa.toFixed(4)) || 0;
                prod.total_cotacao_com_taxa =
                  parseFloat(totalProdutoComTaxa.toFixed(4)) || 0;
                return prod;
              });

              totalPedidoAprovado = arr[0].total_cotacao_com_taxa;

              return { id: idPrazo, prazo: prazoCotacao, produtos: arr };
            }
          })

        // Cria objeto com os pedidos
        Object.assign(pedidoRow, {
          original: pedido,
          id: pedido.id,
          id_cotacao: idCotacaoIntegrar,
          numeroPedidoSodexo: numeroCargaWizeo,
          numeroPedidoWizeo: numeroCargaWizeo,
          numeroPedido: pedido.numero_pedido_sistema,
          numeroCotacao: "",
          cotacaoOriginal: "",
          totalPedidoAprovado: totalPedidoAprovado.toLocaleString(
            "pt-br",
            {
              style: "currency",
              currency: "BRL",
            }
          ),
          numeroPedidoCliente: pedido.numero_pedido_cliente,
          dataPedido: format(parseISO(pedido.created_at), "dd/MM/yyyy - HH:mm"),
          dataSemFormatacao: pedido.created_at,
          cliente:
            pedido.cotacoes[0].cliente?.cpf_cnpj.replace(
              /(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g,
              "$1.$2.$3/$4-$5"
            ) +
            " - " +
            pedido.cotacoes[0].cliente?.razao_social,
          produto: "",
          distribuidorTRR: "",
          prazoPagamento: pedido?.prazo,
          dataEntrega: format(
            parseISO(pedido.data_entrega_desejada),
            "dd/MM/yyyy"
          ),
          status: pedido?.status_pedido,
          statusCotacao: "",
          valorTotal: "",
          integrado: cotacaoMap['integrado'],
          operador: pedido?.operador?.name,
          criador: pedido?.criado_por?.name,
          responsavel: "",
          historico: (
            <FaHistory
              size={18}
              onClick={() => handleHistory(pedido.id, cotacaoIdProduto)}
            />
          ),
          notaServico: (
            <UploadNFServico
              idCotacao={pedido?.cotacao_faturada[0]?.id}
              hasNF={pedido?.cotacao_faturada[0]?.nota_cliente_nome}
            />
          ),
          notaDistribuidor: (
            <UploadNFDistribuidor
              idCotacao={pedido?.cotacao_faturada[0]?.id}
              hasNF={pedido?.cotacao_faturada[0]?.nota_distribuidor_nome}
            />
          ),
          // espelhoCliente: pedido?.status_pedido === "Aprovado" || pedido?.status_pedido === "Faturado" ||pedido?.status_pedido === "Aguardando Faturamento" ? (
          //   <DownloadEspelho
          //     idCotacao={pedido.id}
          //     hasBoleto={true}
          //     tipoPDF={"cliente"}
          //   />
          // ) : (<div></div>),
          // espelhoDistribuidor: pedido?.status_pedido === "Aprovado" || pedido?.status_pedido === "Faturado" ||pedido?.status_pedido === "Aguardando Faturamento" ? (
          //   <DownloadEspelho
          //     idCotacao={pedido.id}
          //     hasBoleto={true}
          //     tipoPDF={"distribuidor"}
          //   />
          // ) : (<div></div>),
          boleto: pedido?.cotacao_faturada[0]?.boleto_cliente_nome && (
            <DownloadBoleto
              idCotacao={pedido?.cotacao_faturada[0]?.id}
              hasBoleto={pedido?.cotacao_faturada[0]?.boleto_cliente_nome}
            />
          ),
          programados: pedido?.programacao.length > 0 ? "Sim" : "Não",
          cotacaoOriginalExcel: "",
          produtoExcel: produtoFormatted.toString(),
          valorTotalExcel: "",
          distribuidor_id: "",
          distribuidor_preferencia_id: "",
        });

        // Cria objeto com as Cotações
        pedido.cotacoes.forEach((cotacao) => {
          totalCotação = totalCotação + 1;
          let arrayQuantidadeTotal = [];
          let valorTotalFormatted = 0;

          arrayQuantidadeTotal = cotacao?.produtos_cotacao.map((prod) => {
            return parseFloat(prod.quantidade / 1000)
              .toFixed(2)
              ?.replace(".", ",");
          });

          if (arrayQuantidadeTotal.length > 0) {
            valorTotalFormatted = arrayQuantidadeTotal;
          }

          cotacao.produtos_cotacao.map((prod) => {
            obj = {};

            Object.assign(obj, {
              original: pedido,
              id: pedido.id,
              id_cotacao: cotacao.id,
              numeroPedidoSodexo: "",
              numeroPedidoWizeo: "",
              numeroPedido: "",
              numeroCotacao: cotacao.numero_cotacao_sistema,
              cotacaoOriginal: <PedidoLink item={cotacao?.cotacao_original} menorPreco={cotacaoIdProduto} />,
              numeroPedidoCliente: pedido.numero_pedido_cliente,
              dataPedido: format(parseISO(pedido.created_at), "dd/MM/yyyy - HH:mm"),
              dataSemFormatacao: pedido.created_at,
              cliente: "",
              produto: prod?.produto?.descricao,
              cotacao_produto_id: prod?.id,
              valorTotal: (parseFloat(prod?.quantidade) / 1000).toFixed(2),
              preco: prod?.valor_unitario,
              distribuidorTRR: cotacao.distribuidor
                ? cotacao?.distribuidor.cpf_cnpj.replace(
                    /(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g,
                    "$1.$2.$3/$4-$5"
                  ) +
                  " - " +
                  cotacao?.distribuidor.razao_social
                : "",
              prazoPagamento: "",
              dataEntrega: "",
              status: pedido?.status_pedido,
              statusCotacao: cotacao?.status_cotacao,
              integrado:"",
              operador: "",
              criador: "",
              responsavel: cotacao?.ultimo_editor_preco?.name,
              historico: "",
              notaServico: "",
              notaDistribuidor: "",
              boleto: "",
              programados: "",
              cotacaoOriginalExcel:
                cotacao?.cotacao_original?.numero_cotacao_sistema,
              produtoExcel: produtoFormatted.toString(),
              valorTotalExcel: valorTotalFormatted.toString(),
              distribuidor_id: cotacao?.distribuidor?.id,
              distribuidor_preferencia_id:
                cotacao?.cliente?.distribuidor_preferencia_id,
              cotacaoIdProduto: cotacaoIdProduto
            });


            cotacoesRows.push(obj);
            return prod;
          });
        });

        // Ordena Cotação por produto e preço
        cotacoesRows = cotacoesRows.sort((a, b) => {
          function value(el) {
            var x = el;
            return x === "0.0000" ? Infinity : x;
          }

          return (
            a.produto.localeCompare(b.produto) ||
            value(a.preco) - value(b.preco)
          );
        });

          // Sempre adiciona a primeira posição do array quando valor diferente de 0
          if (cotacoesRows[0].preco !== "0.0000") {
            cotacoesRows[0].blink = true;
            cotacaoIdProduto.push(cotacoesRows[0].cotacao_produto_id)
            menorPreco.push([cotacoesRows[0].preco, cotacoesRows[0].produto])
          }

          // Faz uma busca pelo restante do array e adiciona os menores preços caso os produtos sejam diferentes e diferente de 0
          cotacoesRows = cotacoesRows.map((i, index) => {
            if (i.produto !== cotacoesRows[index - 1 < 0 ? 0 : index - 1]?.produto && cotacoesRows[index].preco !== "0.0000") {
              i.blink = true;
              cotacaoIdProduto.push(i.cotacao_produto_id)
              menorPreco.push([i?.preco, i?.produto])
            }

            // "menorPrecoIgual" existe para adicionar o menor preço que possui o mesmo valor em outras cotações
            if (menorPreco.find( menorPrecoIgual =>  menorPrecoIgual[0] === i?.preco && menorPrecoIgual[1] === i?.produto)) {
              i.blink = true;
              cotacaoIdProduto.push(i.cotacao_produto_id)
            }
            return i;
          });


        Object.assign(pedidoRow, {
          subRows: cotacoesRows,
        });

        setTotalCotacao(totalCotação);
        setTotalPedido(totalPedido);
        setListaPainel((state) => [...state, pedidoRow]);
      });

      setFirstPage(response?.dados?.first_page_url);
      setLastPage(response?.dados?.last_page);
      setLastPageUrl(response?.dados?.last_page_url);
      setNextPage(response?.dados?.next_page_url);
      setPrevPage(response?.dados?.prev_page_url);
      setCurrentPage(response?.dados?.current_page);
      setCountPage(() => {
        const arr = [];

        const current_Page = response?.dados?.current_page;
        let initialPageNumber = 0;
        let limitPageNumber = 0;

        let lastPage = response?.dados?.last_page;

        if (current_Page > 2) {
          initialPageNumber = current_Page - 3;
        } else if (current_Page <= 2) {
          initialPageNumber = current_Page - current_Page;
        }

        // define qual é a pagina final pelos numeros
        if (current_Page > 2) {
          limitPageNumber = current_Page + 2;
        } else if (current_Page <= 2) {
          limitPageNumber = current_Page - current_Page + 5;
        }

        // define qual é a pagina final pelos numeros
        if (limitPageNumber > lastPage) {
          initialPageNumber = lastPage - 5;
          limitPageNumber = lastPage;
        }

        // Cria o Array com a paginação numérica
        for (let index = initialPageNumber; index < limitPageNumber; index++) {
          arr.push(index + 1);
        }

        return arr.filter((page) => page > 0);
      });
      setLoadingListFilter(false);
      setFilterButton(false);

      if (dataDeCreate > dataAteCreate){
        toast.error("Data de criação inicial maior do que a data final");
      }

      if (dataDeEntrega > dataAteEntrega){
        toast.error("Data de entrega inicial maior do que a data final");
      }
    } catch (error) {
      console.log(error);
      setFilterButton(false);
      setLoadingListFilter(false);
    }
  }, [
    user,
    filterNumeroPedido,
    filterNumeroPedidoCliente,
    filterCliente,
    filterDistribuidorTRR,
    filterOperador,
    filterPrazoPagamento,
    filterStatus,
    filterStatusCotacao,
    filterCargaWizeo,
    filterCotacao,
    filterProduto,
    // filterCriador,
    // filterResponsavel,
    filterNotaServiço,
    filterBoleto,
    filterNotaDitribuidor,
    filterIntegrado,
    filterProg,
    filterCotacaoOriginal,
    handleHistory,

    DateCreateRange,
    DateEntregaRange,
  ]);

  //Pedidos OBJ
  const handleRemoveFilter = useCallback(async () => {
    const today = new Date();
    const formattedDate = format(today, "dd/MM/yyyy");

    const dataFilter = {
      numero_pedido_sistema: "",
      numero_pedido_cliente: "",
      created_at: formattedDate,
      cliente: "",
      distribuidor: "",
      operador_id: "",
      prazo: "",
      data_entrega_desejada: "",
      status_filtro_pedido: "",
      status_filtro_cotacao: "",
      numero_pedido_sodexo: "",
      numero_cotacao_sistema: "",
      produto_id: "",
      criado_por_user_id: "",
      ultimo_editor_preco: "",
      nota_cliente_nome: "",
      boleto_cliente_nome: "",
      nota_distribuidor_nome: "",
      status_integracao: "",
      programacao: "",
      cotacao_id_original: "",
      "order_by[campo]": "id",
      "order_by[order]": "desc",
    };

    localStorage.setItem("dataFilterStorage",JSON.stringify(dataFilter));

    setFilterNumeroPedido("")
    setFilterCotacao("")
    setDateRange([null, null])
    setFilterCliente("")
    setFilterProduto({value: "", label :""})
    setFilterDistribuidorTRR("")
    setFilterCotacaoOriginal({value: "", label :""})
    setFilterNumeroPedidoCliente("")
    setFilterCargaWizeo("")
    setFilterProg({value: "", label :""})
    setFilterOperador({value: "", label :""})
    // setFilterCriador({value: "", label :""})
    // setFilterResponsavel({value: "", label :""})
    setFilterPrazoPagamento("")
    setEntregaRange([null, null])
    setFilterStatus({value: "", label :""})
    setFilterStatusCotacao({value: "", label :""})
    setFilterIntegrado({value: "", label :""})
    setFilterNotaServiço({value: "", label :""})
    setFilterNotaDistribuidor({value: "", label :""})
    setFilterBoleto({value: "", label :""})

    /** zero a tabela para mostrar somente o retorno */
    setListaPainel([]);
    setLoadingListFilter(true);
    setFilterButton(true);
    try {
      const { data: response } = await api.get("/pedido/filter", {
        params: dataFilter,
      });

      // const criadoPor = Object.keys(response.lista_criado_por).map((key) => {
      //   return {
      //     value: response.lista_criado_por[key]?.id,
      //     label: response.lista_criado_por[key]?.name,
      //   };
      // });
      // setCriadoPorOptions(criadoPor);

      // const responsavel = Object.keys(response.ultimo_editor_preco).map(
      //   (key) => {
      //     return {
      //       value: response.ultimo_editor_preco[key]?.id,
      //       label: response.ultimo_editor_preco[key]?.name,
      //     };
      //   }
      // );
      // setResponsavelOptions(responsavel);

      let totalCotação = 0;
      let totalPedido = 0;

      response.dados.data.forEach((pedido) => {
        totalPedido = totalPedido + 1;
        let pedidoRow = {};
        let cotacoesRows = [];
        let obj = {};
        let cotacaoIdProduto = [];
        let menorPreco = [];

        let produtoFormatted = [];
        produtoFormatted = pedido?.produtos_pedido.map((prod) => {
          return prod.descricao;
        });

        //Integração Status
        var integradoSim = ( pedido.cotacoes.filter( cotacao => (cotacao.status_integracao === 1) ) )
        var integradoNao = ( pedido.cotacoes.filter( cotacao => (cotacao.status_integracao === 0) ) )
        var integradoErro = ( pedido.cotacoes.filter( cotacao => (cotacao.status_integracao >= 2) ) )

        let cotacaoMap = [];
        if( integradoSim.length > 0 ){
          cotacaoMap['integrado'] = "Sim"
        }else if(integradoNao.length > 0 && integradoSim.length === 0){
          cotacaoMap['integrado'] = "Não"
        }else if(integradoErro.length > 0){
          cotacaoMap['integrado'] = "Erro"
        }

        var idCotacaoIntegrar = "";

        pedido.cotacoes.forEach((cotacao) => {
          if (cotacao.status_cotacao === "Aprovado"){
            idCotacaoIntegrar = cotacao.id
          }
        })

        var numeroCargaWizeo = "";

        pedido.cotacoes.forEach((cotacao) => {
          if (cotacao.numero_pedido_sodexo !== null){
            numeroCargaWizeo = cotacao.numero_pedido_sodexo
          }
        })

        var totalPedidoAprovado = ""

          pedido.cotacoes.forEach((cotacao) => {
            const taxAdm = cotacao?.taxa_administrativa_unitaria;
            const taxFinanceira = pedido?.cadastro?.taxa_financeira

            if (cotacao.status_cotacao === "Aprovado" || cotacao.status_cotacao === "Faturado" || cotacao.status_cotacao === "Aguardando Faturamento") {
              var prazoAprovado = cotacao.prazos.filter((item) => {
                return item.prazo === pedido.prazo;
              });

              let precoLitroFinal = 0;
              let totalLinhaSemTaxas = 0;
              let totalLinhaProduto = 0;
              let totalProdutoSemTaxa = 0;
              let totalProdutoComTaxa = 0;
              let idPrazo = prazoAprovado[0].id;
              let prazoCotacao = prazoAprovado[0].prazo;
              let totalLinhaFrete = 0;
              let totalLinhaTaxaFinanceira = 0;
              let totalLitros = 0;
              let taxaFinan = "";


              const arr = prazoAprovado[0].produtos.map((prod) => {
                const obj = {};
                let filterTaxa = [];

                //Total Litros/Quantidade da Cotação
                totalLitros = prazoAprovado[0].produtos.reduce((total, b) => {
                  const totalM3 = parseFloat(b.quantidade);
                  return total + totalM3;
                }, 0);

                const ProdutoCotacao = cotacao.produtos_cotacao.filter(
                  (cot_prod) => {
                    return cot_prod.produto_id === prod.produto_id;
                  }
                );

                if (!prod.taxa_financeira_percentual) {
                  filterTaxa = taxFinanceira.filter((tax) => {
                    if (
                      parseInt(prazoAprovado[0].prazo) >=
                        parseInt(tax.taxa_financeira_de) &&
                      parseInt(prazoAprovado[0].prazo) <= parseInt(tax.taxa_financeira_ate)
                    ) {
                      return true;
                    }

                    return false;
                  });

                  taxaFinan = filterTaxa[0]?.taxa_financeira_percentual || 0;
                } else {
                  taxaFinan = prod.taxa_financeira_percentual;
                }

                if (parseInt(user.cadastro_tipo_id) !== 2) {
                  // Valor Preço Litro Final
                  precoLitroFinal = (
                    parseFloat(
                      parseFloat(prod?.valor_unitario) +
                        parseFloat(prod.valor_frete?.replaceAll(",", ".") || 0)
                    ) *
                      (parseFloat(taxaFinan) / 100 + 1) +
                    parseFloat(taxAdm)
                  )
                  .toLocaleString("pt-br", {
                    minimumFractionDigits: 8,
                  });
                } else {
                  // Valor Preço Litro Final
                  precoLitroFinal = (
                    parseFloat(prod?.valor_unitario) +
                    parseFloat(prod.valor_frete?.replaceAll(",", ".") || 0)
                  ).toLocaleString("pt-br", {
                    minimumFractionDigits: 8,
                  });
                }

                // Valor Total sem taxas produto linha
                totalLinhaSemTaxas = (
                  parseFloat(prod?.valor_unitario) * parseFloat(prod?.quantidade)
                ).toLocaleString("pt-br", {
                  minimumFractionDigits: 2,
                });

                if (parseInt(user.cadastro_tipo_id) !== 2) {
                  // Valor Total produto linha
                  totalLinhaProduto = (
                    parseFloat(
                      (
                        (parseFloat(prod?.valor_unitario) +
                          parseFloat(prod?.valor_frete || 0)) *
                        (parseFloat(taxaFinan) / 100 + 1)
                      )
                    ) *
                      parseFloat(prod?.quantidade) +
                    parseFloat(prod?.quantidade) * parseFloat(taxAdm)
                  ).toFixed(2); //arredondamento;

                  totalLinhaProduto = parseFloat(
                    totalLinhaProduto
                  ).toLocaleString("pt-br", {
                    minimumFractionDigits: 8,
                  });
                } else {
                  // Valor Total produto linha
                  totalLinhaProduto = (
                    (parseFloat(prod?.valor_unitario) +
                      parseFloat(prod?.valor_frete || 0)) *
                    parseFloat(prod?.quantidade)
                  ).toFixed(2);

                  totalLinhaProduto = parseFloat(
                    totalLinhaProduto
                  ).toLocaleString("pt-br", {
                    minimumFractionDigits: 2,
                  });
                }

                //Soma total Sem taxas Com Frete
                totalProdutoSemTaxa =
                  totalProdutoSemTaxa +
                  parseFloat(prod?.quantidade) *
                    (parseFloat(prod?.valor_unitario) +
                      parseFloat(prod?.valor_frete || 0));

                //Soma total Com taxas + Frete
                // Calcula o total do pedido inteiro com todas as taxas.

                // Valor total da cotação com todas as taxas
                totalProdutoComTaxa =
                  parseFloat(totalProdutoComTaxa) +
                  parseFloat(
                    totalLinhaProduto.replaceAll(".", "").replaceAll(",", ".")
                  );

                totalLinhaFrete =
                  parseFloat(prod?.valor_frete || 0) *
                  parseFloat(prod?.quantidade);

                totalLinhaTaxaFinanceira =
                  parseFloat(
                    (
                      (parseFloat(prod?.valor_unitario) +
                        parseFloat(prod?.valor_frete || 0)) *
                      (parseFloat(taxaFinan) / 100)
                    )
                  ) * parseFloat(prod?.quantidade);

                totalLinhaTaxaFinanceira = parseFloat(
                  totalLinhaTaxaFinanceira
                );

                Object.assign(obj, {
                  id: prod?.id,
                  id_produto: prod?.produto_id,
                  id_cotacao_produto: ProdutoCotacao[0]?.id || "",
                  descricao: prod.produto.descricao,
                  prazo: prazoAprovado[0].prazo,
                  quantidade: prod.quantidade || 0,
                  valor_referencia: prod?.valor_referencia
                    ? parseFloat(prod?.valor_referencia)
                        .toFixed(4)
                        ?.replaceAll(".", ",")
                    : (0).toFixed(4).replaceAll(".", ","),
                  valor_unitario: prod?.valor_unitario
                    ? parseFloat(prod?.valor_unitario)
                        .toFixed(4)
                        ?.replaceAll(".", ",")
                    : (0).toFixed(4).replaceAll(".", ","),
                  valor_frete: prod?.valor_frete
                    ? parseFloat(prod?.valor_frete)
                        ?.toFixed(4)
                        ?.replaceAll(".", ",")
                    : (0).toFixed(4).replaceAll(".", ","),
                  total_frete: totalLinhaFrete,
                  total_taxa_financeira: totalLinhaTaxaFinanceira,
                  preco_litro_final: precoLitroFinal,
                  total: totalLinhaProduto || 0,
                  total_sem_taxas: totalLinhaSemTaxas,
                  taxa_financeira: taxaFinan || 0,
                });

                return obj;
              });

              arr.map((prod) => {
                prod.total_cotacao_sem_taxa =
                  parseFloat(totalProdutoSemTaxa.toFixed(4)) || 0;
                prod.total_cotacao_com_taxa =
                  parseFloat(totalProdutoComTaxa.toFixed(4)) || 0;
                return prod;
              });

              totalPedidoAprovado = arr[0].total_cotacao_com_taxa;

              return { id: idPrazo, prazo: prazoCotacao, produtos: arr };
            }
          })

        // Cria objeto com os pedidos
        Object.assign(pedidoRow, {
          original: pedido,
          id: pedido.id,
          id_cotacao: idCotacaoIntegrar,
          numeroPedidoSodexo: numeroCargaWizeo,
          numeroPedidoWizeo: numeroCargaWizeo,
          numeroPedido: pedido.numero_pedido_sistema,
          numeroCotacao: "",
          cotacaoOriginal: "",
          totalPedidoAprovado: totalPedidoAprovado.toLocaleString(
            "pt-br",
            {
              style: "currency",
              currency: "BRL",
            }
          ),
          numeroPedidoCliente: pedido.numero_pedido_cliente,
          dataPedido: format(parseISO(pedido.created_at), "dd/MM/yyyy - HH:mm"),
          dataSemFormatacao: pedido.created_at,
          cliente:
            pedido.cotacoes[0].cliente?.cpf_cnpj.replace(
              /(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g,
              "$1.$2.$3/$4-$5"
            ) +
            " - " +
            pedido.cotacoes[0].cliente?.razao_social,
          produto: "",
          distribuidorTRR: "",
          prazoPagamento: pedido?.prazo,
          dataEntrega: format(
            parseISO(pedido.data_entrega_desejada),
            "dd/MM/yyyy"
          ),
          status: pedido?.status_pedido,
          statusCotacao: "",
          valorTotal: "",
          integrado: cotacaoMap['integrado'],
          operador: pedido?.operador?.name,
          criador: pedido?.criado_por?.name,
          responsavel: "",
          historico: (
            <FaHistory
              size={18}
              onClick={() => handleHistory(pedido.id, cotacaoIdProduto)}
            />
          ),
          notaServico: "",
          notaDistribuidor: "",
          boleto: "",
          programados: pedido?.programacao.length > 0 ? "Sim" : "Não",
          cotacaoOriginalExcel: "",
          produtoExcel: produtoFormatted.toString(),
          valorTotalExcel: "",
          distribuidor_id: "",
          distribuidor_preferencia_id: "",
        });

        // Cria objeto com as Cotações
        pedido.cotacoes.forEach((cotacao) => {
          totalCotação = totalCotação + 1;
          let arrayQuantidadeTotal = [];
          let valorTotalFormatted = 0;

          arrayQuantidadeTotal = cotacao?.produtos_cotacao.map((prod) => {
            return parseFloat(prod.quantidade / 1000)
              .toFixed(2)
              ?.replace(".", ",");
          });

          if (arrayQuantidadeTotal.length > 0) {
            valorTotalFormatted = arrayQuantidadeTotal;
          }

          cotacao.produtos_cotacao.map((prod) => {
            obj = {};

            Object.assign(obj, {
              original: pedido,
              id: pedido.id,
              id_cotacao: cotacao.id,
              numeroPedidoSodexo: "",
              numeroPedidoWizeo: "",
              numeroPedido: "",
              numeroCotacao: cotacao.numero_cotacao_sistema,
              cotacaoOriginal: <PedidoLink item={cotacao?.cotacao_original} menorPreco={cotacaoIdProduto} />,
              numeroPedidoCliente: pedido.numero_pedido_cliente,
              dataPedido: format(parseISO(pedido.created_at), "dd/MM/yyyy - HH:mm"),
              dataSemFormatacao: pedido.created_at,
              cliente: "",
              produto: prod?.produto?.descricao,
              cotacao_produto_id: prod?.id,
              valorTotal: (parseFloat(prod?.quantidade) / 1000).toFixed(2),
              preco: prod?.valor_unitario,
              distribuidorTRR: cotacao.distribuidor
                ? cotacao?.distribuidor.cpf_cnpj.replace(
                    /(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g,
                    "$1.$2.$3/$4-$5"
                  ) +
                  " - " +
                  cotacao?.distribuidor.razao_social
                : "",
              prazoPagamento: "",
              dataEntrega: "",
              status: pedido?.status_pedido,
              statusCotacao: cotacao?.status_cotacao,
              integrado: "",
              operador: "",
              criador: "",
              responsavel: cotacao?.ultimo_editor_preco?.name,
              historico: "",
              notaServico: (
                <UploadNFServico
                  idCotacao={cotacao.id}
                  hasNF={cotacao.nota_cliente_nome}
                />
              ),
              notaDistribuidor: (
                <UploadNFDistribuidor
                  idCotacao={cotacao.id}
                  hasNF={cotacao.nota_distribuidor_nome}
                />
              ),
              boleto: cotacao.boleto_cliente_nome && (
                <DownloadBoleto
                  idCotacao={cotacao.id}
                  hasBoleto={cotacao.boleto_cliente_nome}
                />
              ),
              programados: "",
              cotacaoOriginalExcel:
                cotacao?.cotacao_original?.numero_cotacao_sistema,
              produtoExcel: produtoFormatted.toString(),
              valorTotalExcel: valorTotalFormatted.toString(),
              distribuidor_id: cotacao?.distribuidor?.id,
              distribuidor_preferencia_id:
                cotacao?.cliente?.distribuidor_preferencia_id,
              cotacaoIdProduto: cotacaoIdProduto
            });


            cotacoesRows.push(obj);
            return prod;
          });
        });

        // Ordena Cotação por produto e preço
        cotacoesRows = cotacoesRows.sort((a, b) => {
          function value(el) {
            var x = el;
            return x === "0.0000" ? Infinity : x;
          }

          return (
            a.produto.localeCompare(b.produto) ||
            value(a.preco) - value(b.preco)
          );
        });

          // Sempre adiciona a primeira posição do array quando valor diferente de 0
          if (cotacoesRows[0].preco !== "0.0000") {
            cotacoesRows[0].blink = true;
            cotacaoIdProduto.push(cotacoesRows[0].cotacao_produto_id)
            menorPreco.push([cotacoesRows[0].preco, cotacoesRows[0].produto])
          }

          // Faz uma busca pelo restante do array e adiciona os menores preços caso os produtos sejam diferentes e diferente de 0
          cotacoesRows = cotacoesRows.map((i, index) => {
            if (i.produto !== cotacoesRows[index - 1 < 0 ? 0 : index - 1]?.produto && cotacoesRows[index].preco !== "0.0000") {
              i.blink = true;
              cotacaoIdProduto.push(i.cotacao_produto_id)
              menorPreco.push([i?.preco, i?.produto])
            }

            // "menorPrecoIgual" existe para adicionar o menor preço que possui o mesmo valor em outras cotações
            if (menorPreco.find( menorPrecoIgual =>  menorPrecoIgual[0] === i?.preco && menorPrecoIgual[1] === i?.produto)) {
              i.blink = true;
              cotacaoIdProduto.push(i.cotacao_produto_id)
            }
            return i;
          });


        Object.assign(pedidoRow, {
          subRows: cotacoesRows,
        });

        setTotalCotacao(totalCotação);
        setTotalPedido(totalPedido);
        setListaPainel((state) => [...state, pedidoRow]);
      });

      setFirstPage(response?.dados?.first_page_url);
      setLastPage(response?.dados?.last_page);
      setLastPageUrl(response?.dados?.last_page_url);
      setNextPage(response?.dados?.next_page_url);
      setPrevPage(response?.dados?.prev_page_url);
      setCurrentPage(response?.dados?.current_page);
      setCountPage(() => {
        const arr = [];

        const current_Page = response?.dados?.current_page;
        let initialPageNumber = 0;
        let limitPageNumber = 0;

        let lastPage = response?.dados?.last_page;

        if (current_Page > 2) {
          initialPageNumber = current_Page - 3;
        } else if (current_Page <= 2) {
          initialPageNumber = current_Page - current_Page;
        }

        // define qual é a pagina final pelos numeros
        if (current_Page > 2) {
          limitPageNumber = current_Page + 2;
        } else if (current_Page <= 2) {
          limitPageNumber = current_Page - current_Page + 5;
        }

        // define qual é a pagina final pelos numeros
        if (limitPageNumber > lastPage) {
          initialPageNumber = lastPage - 5;
          limitPageNumber = lastPage;
        }

        // Cria o Array com a paginação numérica
        for (let index = initialPageNumber; index < limitPageNumber; index++) {
          arr.push(index + 1);
        }

        return arr.filter((page) => page > 0);
      });

      setLoadingListFilter(false);
      setFilterButton(false);
    } catch (error) {
      console.log(error);
      setFilterButton(false);
      setLoadingListFilter(false);
    }
  }, [handleHistory, user]);

  const handleCreate = useCallback(() => {
    history.push("/orders/painel/new");
  }, [history]);

  const handleCancelOrders = useCallback(() => {
    history.push("/orders/painel/cancel", { pedidosId: selectedOrders });
  }, [history, selectedOrders]);

  const handleCancelOrdersLine = useCallback(
    (idPedido) => {
      history.push("/orders/painel/cancel", { pedidosId: [idPedido] });
    },
    [history]
  );

  const handleReenviarEmail = useCallback(async (cotacao) => {
    setLoadingEmail(true);
    try {
      await api.get(`/email/pedido-aprovado/${cotacao}`);
      setLoadingEmail(false);
      toast.success("E-mails de confirmação de pedido reenviados com sucesso.");

    } catch (error) {
      toast.error("Erro no envio do e-mails da confirmação do pedido.");
      setLoadingEmail(false);
    }
  }, []);

  const handleDownloadCliente = useCallback(async (id) => {
    setLoadingEspelhoCliente(true);

      try {
        const download = await api.get(
          `/cotacao/${id}/download-espelho-cliente`,
          {
            responseType: "blob",
          }
        );

        const downloadUrl = window.URL.createObjectURL(new Blob([download.data]));

        const link = document.createElement("a");

        link.href = downloadUrl;

        link.setAttribute("download", "Espelho Cliente.pdf"); //any other extension

        document.body.appendChild(link);

        link.click();

        link.remove();

        setLoadingEspelhoCliente(false);

      } catch (error) {
        setLoadingEspelhoCliente(false);
        console.log(error);
      }
  }, []);

  const handleDownloadDistribuidor = useCallback(async (id) => {
    setLoadingEspelhoDistribuidor(true);

    try {
      const download = await api.get(
        `/cotacao/${id}/download-espelho-distribuidor`,
        {
          responseType: "blob",
        }
      );

      const downloadUrl = window.URL.createObjectURL(new Blob([download.data]));

      const link = document.createElement("a");

      link.href = downloadUrl;

      link.setAttribute("download", "Espelho Distribuidor/TRR.pdf"); //any other extension

      document.body.appendChild(link);

      link.click();

      link.remove();
      setLoadingEspelhoDistribuidor(false);

    } catch (error) {
      setLoadingEspelhoDistribuidor(false);
      console.log(error);
    }
}, []);

  const handleCancelCotacaoLine = useCallback(
    (idCotacao) => {
      history.push("/orders/painel/cancel", { cotacaoId: idCotacao });
    },
    [history]
  );

  const handleGoBack = useCallback(() => {
    history.goBack();
  }, [history]);

  const handleExportToExcel = useCallback(() => {
    refExcel.current.click();
  }, [refExcel]);

  const handleEdit = useCallback(
    (data) => {
      delete data.original?.historico;
      delete data.original?.produto;
      delete data.original?.valorTotal;
      delete data.original?.notaServico;
      delete data.original?.notaDistribuidor;
      delete data.original?.boleto;
      delete data.original?.cotacaoOriginal;

      history.push("/orders/painel/edit", data.original);
    },
    [history]
  );

  const columns = React.useMemo(
    () => [
      {
        // Build our expander column
        id: "expander", // Make sure it has an ID
        Header: ({ getToggleAllRowsExpandedProps, isAllRowsExpanded }) => (
          <span {...getToggleAllRowsExpandedProps()}
            title="Expandir Todos"
          >
            {isAllRowsExpanded ? <MdExpandLess /> : <MdExpandMore />}
          </span>
        ),
        Cell: ({ row }) =>
          // Use the row.canExpand and row.getToggleRowExpandedProps prop getter
          // to build the toggle for expanding a row
          row.canExpand ? (
            <span
              {...row.getToggleRowExpandedProps({
                style: {
                  // We can even use the row.depth property
                  // and paddingLeft to indicate the depth
                  // of the row
                  //  paddingLeft: `${row.depth * 2}rem`,
                },
              })}
              title="Expandir"
            >
              {row.isExpanded ? <MdExpandLess color={"#ff7375"} /> : <MdExpandMore color={"#ff7375"}/>}
            </span>
          ) : null,
      },
      {
        Header: <FormattedMessage id="numPedidoSistema" />,
        accessor: "numeroPedido",
      },
      // {
      //   Header: <FormattedMessage id="numeroCotacao" />,
      //   accessor: "numeroCotacao",
      // },
      {
        Header: <FormattedMessage id="statusPedidos" />,
        accessor: "status",
      },
      {
        Header: "Data Criação (De - Até)",
        accessor: "dataPedido",
      },
      {
        Header: <FormattedMessage id="cliente" />,
        accessor: "cliente",
      },
      {
        Header: <FormattedMessage id="produtos" />,
        accessor: "produto",
      },
      {
        Header: <FormattedMessage id="volumeM2" />,
        accessor: "valorTotal",
      },
      {
        Header: <FormattedMessage id="precoLitro" />,
        accessor: "preco",
      },
      {
        Header: "Valor Total Pedido",
        accessor: "totalPedidoAprovado",
      },
      {
        Header: <FormattedMessage id="distribuidores" />,
        accessor: "distribuidorTRR",
      },

      {
        Header: "Nº Cotação Incompleta",
        accessor: "cotacaoOriginal",
      },

      {
        Header: <FormattedMessage id="numPedidoCliente" />,
        accessor: "numeroPedidoCliente",
      },

      {
        Header: "Carga Wizeo",
        accessor: "numeroPedidoWizeo",
      },

      {
        Header: "Gerou Programação",
        accessor: "programados",
      },

      {
        Header: <FormattedMessage id="operador" />,
        accessor: "operador",
      },
      {
        Header: <FormattedMessage id="criador" />,
        accessor: "criador",
      },
      {
        Header: "Responsável Cotação",
        accessor: "responsavel",
      },

      {
        Header: <FormattedMessage id="prazoPagamento" />,
        accessor: "prazoPagamento",
      },
      {
        Header: "Data Entrega (De - Até)",
        accessor: "dataEntrega",
      },
      // {
      //   Header: <FormattedMessage id="statusPedidos" />,
      //   accessor: "status",
      // },
      {
        Header: <FormattedMessage id="numeroCotacao" />,
        accessor: "numeroCotacao",
      },
      {
        Header: <FormattedMessage id="statusCotacao" />,
        accessor: "statusCotacao",
      },
      {
        Header: <FormattedMessage id="integrado" />,
        accessor: "integrado",
      },
      {
        Header: <FormattedMessage id="notaServiço" />,
        accessor: "notaServico",
      },
      {
        Header: <FormattedMessage id="notaDistribuidor" />,
        accessor: "notaDistribuidor",
      },
      // {
      //   Header: "Espelho Cliente",
      //   accessor: "espelhoCliente",
      // },
      // {
      //   Header: "Espelho Distribuidor",
      //   accessor: "espelhoDistribuidor",
      // },
      {
        Header: <FormattedMessage id="boleto" />,
        accessor: "boleto",
      },
      {
        Header: <FormattedMessage id="historicoNegociacao" />,
        accessor: "historico",
      },
    ],
    []
  );

  const tableInstance = useTable(
    { columns, data: listaPainel, initialState: { pageIndex: 1 } },
    useExpanded,
    usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    state: { expanded },
  } = tableInstance;

  // Paginação //Pedidos OBJ
  const handleUpdateList = useCallback(
    async (url) => {
      try {
        setLoadingListFilter(true);
        setListaPainel([]);
        const { data: response } = await api.get(url);

        // const criadoPor = Object.keys(response.lista_criado_por).map((key) => {
        //   return {
        //     value: response.lista_criado_por[key]?.id,
        //     label: response.lista_criado_por[key]?.name,
        //   };
        // });
        // setCriadoPorOptions(criadoPor);

        // const responsavel = Object.keys(response.ultimo_editor_preco).map(
        //   (key) => {
        //     return {
        //       value: response.ultimo_editor_preco[key]?.id,
        //       label: response.ultimo_editor_preco[key]?.name,
        //     };
        //   }
        // );
        // setResponsavelOptions(responsavel);

        let totalCotação = 0;
        let totalPedido = 0;

        response.dados.data.forEach((pedido) => {
          totalPedido = totalPedido + 1;
          let pedidoRow = {};
          let cotacoesRows = [];
          let obj = {};
          let cotacaoIdProduto = [];
          let menorPreco = [];

          let produtoFormatted = [];
          produtoFormatted = pedido?.produtos_pedido.map((prod) => {
            return prod.descricao;
          });

          //Integração Status
          var integradoSim = ( pedido.cotacoes.filter( cotacao => (cotacao.status_integracao === 1) ) )
          var integradoNao = ( pedido.cotacoes.filter( cotacao => (cotacao.status_integracao === 0) ) )
          var integradoErro = ( pedido.cotacoes.filter( cotacao => (cotacao.status_integracao >= 2) ) )

          let cotacaoMap = [];
          if( integradoSim.length > 0 ){
            cotacaoMap['integrado'] = "Sim"
          }else if(integradoNao.length > 0 && integradoSim.length === 0){
            cotacaoMap['integrado'] = "Não"
          }else if(integradoErro.length > 0){
            cotacaoMap['integrado'] = "Erro"
          }

          var idCotacaoIntegrar = "";

          pedido.cotacoes.forEach((cotacao) => {
            if (cotacao.status_cotacao === "Aprovado"){
              idCotacaoIntegrar = cotacao.id
            }
          })

          var numeroCargaWizeo = "";

          pedido.cotacoes.forEach((cotacao) => {
            if (cotacao.numero_pedido_sodexo !== null){
              numeroCargaWizeo = cotacao.numero_pedido_sodexo
            }
          })

          var totalPedidoAprovado = ""

          pedido.cotacoes.forEach((cotacao) => {
            const taxAdm = cotacao?.taxa_administrativa_unitaria;
            const taxFinanceira = pedido?.cadastro?.taxa_financeira

            if (cotacao.status_cotacao === "Aprovado" || cotacao.status_cotacao === "Faturado" || cotacao.status_cotacao === "Aguardando Faturamento") {
              var prazoAprovado = cotacao.prazos.filter((item) => {
                return item.prazo === pedido.prazo;
              });

              let precoLitroFinal = 0;
              let totalLinhaSemTaxas = 0;
              let totalLinhaProduto = 0;
              let totalProdutoSemTaxa = 0;
              let totalProdutoComTaxa = 0;
              let idPrazo = prazoAprovado[0].id;
              let prazoCotacao = prazoAprovado[0].prazo;
              let totalLinhaFrete = 0;
              let totalLinhaTaxaFinanceira = 0;
              let totalLitros = 0;
              let taxaFinan = "";


              const arr = prazoAprovado[0].produtos.map((prod) => {
                const obj = {};
                let filterTaxa = [];

                //Total Litros/Quantidade da Cotação
                totalLitros = prazoAprovado[0].produtos.reduce((total, b) => {
                  const totalM3 = parseFloat(b.quantidade);
                  return total + totalM3;
                }, 0);

                const ProdutoCotacao = cotacao.produtos_cotacao.filter(
                  (cot_prod) => {
                    return cot_prod.produto_id === prod.produto_id;
                  }
                );

                if (!prod.taxa_financeira_percentual) {
                  filterTaxa = taxFinanceira.filter((tax) => {
                    if (
                      parseInt(prazoAprovado[0].prazo) >=
                        parseInt(tax.taxa_financeira_de) &&
                      parseInt(prazoAprovado[0].prazo) <= parseInt(tax.taxa_financeira_ate)
                    ) {
                      return true;
                    }

                    return false;
                  });

                  taxaFinan = filterTaxa[0]?.taxa_financeira_percentual || 0;
                } else {
                  taxaFinan = prod.taxa_financeira_percentual;
                }

                if (parseInt(user.cadastro_tipo_id) !== 2) {
                  // Valor Preço Litro Final
                  precoLitroFinal = (
                    parseFloat(
                      parseFloat(prod?.valor_unitario) +
                        parseFloat(prod.valor_frete?.replaceAll(",", ".") || 0)
                    ) *
                      (parseFloat(taxaFinan) / 100 + 1) +
                    parseFloat(taxAdm)
                  )
                  .toLocaleString("pt-br", {
                    minimumFractionDigits: 8,
                  });
                } else {
                  // Valor Preço Litro Final
                  precoLitroFinal = (
                    parseFloat(prod?.valor_unitario) +
                    parseFloat(prod.valor_frete?.replaceAll(",", ".") || 0)
                  ).toLocaleString("pt-br", {
                    minimumFractionDigits: 8,
                  });
                }

                // Valor Total sem taxas produto linha
                totalLinhaSemTaxas = (
                  parseFloat(prod?.valor_unitario) * parseFloat(prod?.quantidade)
                ).toLocaleString("pt-br", {
                  minimumFractionDigits: 2,
                });

                if (parseInt(user.cadastro_tipo_id) !== 2) {
                  // Valor Total produto linha
                  totalLinhaProduto = (
                    parseFloat(
                      (
                        (parseFloat(prod?.valor_unitario) +
                          parseFloat(prod?.valor_frete || 0)) *
                        (parseFloat(taxaFinan) / 100 + 1)
                      )
                    ) *
                      parseFloat(prod?.quantidade) +
                    parseFloat(prod?.quantidade) * parseFloat(taxAdm)
                  ).toFixed(2); //arredondamento;

                  totalLinhaProduto = parseFloat(
                    totalLinhaProduto
                  ).toLocaleString("pt-br", {
                    minimumFractionDigits: 8,
                  });
                } else {
                  // Valor Total produto linha
                  totalLinhaProduto = (
                    (parseFloat(prod?.valor_unitario) +
                      parseFloat(prod?.valor_frete || 0)) *
                    parseFloat(prod?.quantidade)
                  ).toFixed(2);

                  totalLinhaProduto = parseFloat(
                    totalLinhaProduto
                  ).toLocaleString("pt-br", {
                    minimumFractionDigits: 2,
                  });
                }

                //Soma total Sem taxas Com Frete
                totalProdutoSemTaxa =
                  totalProdutoSemTaxa +
                  parseFloat(prod?.quantidade) *
                    (parseFloat(prod?.valor_unitario) +
                      parseFloat(prod?.valor_frete || 0));

                //Soma total Com taxas + Frete
                // Calcula o total do pedido inteiro com todas as taxas.

                // Valor total da cotação com todas as taxas
                totalProdutoComTaxa =
                  parseFloat(totalProdutoComTaxa) +
                  parseFloat(
                    totalLinhaProduto.replaceAll(".", "").replaceAll(",", ".")
                  );

                totalLinhaFrete =
                  parseFloat(prod?.valor_frete || 0) *
                  parseFloat(prod?.quantidade);

                totalLinhaTaxaFinanceira =
                  parseFloat(
                    (
                      (parseFloat(prod?.valor_unitario) +
                        parseFloat(prod?.valor_frete || 0)) *
                      (parseFloat(taxaFinan) / 100)
                    )
                  ) * parseFloat(prod?.quantidade);

                totalLinhaTaxaFinanceira = parseFloat(
                  totalLinhaTaxaFinanceira
                );

                Object.assign(obj, {
                  id: prod?.id,
                  id_produto: prod?.produto_id,
                  id_cotacao_produto: ProdutoCotacao[0]?.id || "",
                  descricao: prod.produto.descricao,
                  prazo: prazoAprovado[0].prazo,
                  quantidade: prod.quantidade || 0,
                  valor_referencia: prod?.valor_referencia
                    ? parseFloat(prod?.valor_referencia)
                        .toFixed(4)
                        ?.replaceAll(".", ",")
                    : (0).toFixed(4).replaceAll(".", ","),
                  valor_unitario: prod?.valor_unitario
                    ? parseFloat(prod?.valor_unitario)
                        .toFixed(4)
                        ?.replaceAll(".", ",")
                    : (0).toFixed(4).replaceAll(".", ","),
                  valor_frete: prod?.valor_frete
                    ? parseFloat(prod?.valor_frete)
                        ?.toFixed(4)
                        ?.replaceAll(".", ",")
                    : (0).toFixed(4).replaceAll(".", ","),
                  total_frete: totalLinhaFrete,
                  total_taxa_financeira: totalLinhaTaxaFinanceira,
                  preco_litro_final: precoLitroFinal,
                  total: totalLinhaProduto || 0,
                  total_sem_taxas: totalLinhaSemTaxas,
                  taxa_financeira: taxaFinan || 0,
                });

                return obj;
              });

              arr.map((prod) => {
                prod.total_cotacao_sem_taxa =
                  parseFloat(totalProdutoSemTaxa.toFixed(4)) || 0;
                prod.total_cotacao_com_taxa =
                  parseFloat(totalProdutoComTaxa.toFixed(4)) || 0;
                return prod;
              });

              totalPedidoAprovado = arr[0].total_cotacao_com_taxa;

              return { id: idPrazo, prazo: prazoCotacao, produtos: arr };
            }
          })

          // Cria objeto com os pedidos
          Object.assign(pedidoRow, {
            original: pedido,
            id: pedido.id,
            id_cotacao: idCotacaoIntegrar,
            numeroPedidoSodexo: numeroCargaWizeo,
            numeroPedidoWizeo: numeroCargaWizeo,
            numeroPedido: pedido.numero_pedido_sistema,
            numeroCotacao: "",
            cotacaoOriginal: "",
            totalPedidoAprovado: totalPedidoAprovado.toLocaleString(
              "pt-br",
              {
                style: "currency",
                currency: "BRL",
              }
            ),
            numeroPedidoCliente: pedido.numero_pedido_cliente,
            dataPedido: format(parseISO(pedido.created_at), "dd/MM/yyyy - HH:mm"),
            dataSemFormatacao: pedido.created_at,
            cliente:
              pedido.cotacoes[0].cliente?.cpf_cnpj.replace(
                /(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g,
                "$1.$2.$3/$4-$5"
              ) +
              " - " +
              pedido.cotacoes[0].cliente?.razao_social,
            produto: "",
            distribuidorTRR: "",
            prazoPagamento: pedido?.prazo,
            dataEntrega: format(
              parseISO(pedido.data_entrega_desejada),
              "dd/MM/yyyy"
            ),
            status: pedido?.status_pedido,
            statusCotacao: "",
            valorTotal: "",
            integrado: cotacaoMap['integrado'],
            operador: pedido?.operador?.name,
            criador: pedido?.criado_por?.name,
            responsavel: "",
            historico: (
              <FaHistory
                size={18}
                onClick={() => handleHistory(pedido.id, cotacaoIdProduto)}
              />
            ),
            notaServico: (
              <UploadNFServico
                idCotacao={pedido?.cotacao_faturada[0]?.id}
                hasNF={pedido?.cotacao_faturada[0]?.nota_cliente_nome}
              />
            ),
            notaDistribuidor: (
              <UploadNFDistribuidor
                idCotacao={pedido?.cotacao_faturada[0]?.id}
                hasNF={pedido?.cotacao_faturada[0]?.nota_distribuidor_nome}
              />
            ),
            // espelhoCliente: pedido?.status_pedido === "Aprovado" || pedido?.status_pedido === "Faturado" ||pedido?.status_pedido === "Aguardando Faturamento" ? (
            //   <DownloadEspelho
            //     idCotacao={pedido.id}
            //     hasBoleto={true}
            //     tipoPDF={"cliente"}
            //   />
            // ) : (<div></div>),
            // espelhoDistribuidor: pedido?.status_pedido === "Aprovado" || pedido?.status_pedido === "Faturado" ||pedido?.status_pedido === "Aguardando Faturamento" ? (
            //   <DownloadEspelho
            //     idCotacao={pedido.id}
            //     hasBoleto={true}
            //     tipoPDF={"distribuidor"}
            //   />
            // ) : (<div></div>),
            boleto: pedido?.cotacao_faturada[0]?.boleto_cliente_nome && (
              <DownloadBoleto
                idCotacao={pedido?.cotacao_faturada[0]?.id}
                hasBoleto={pedido?.cotacao_faturada[0]?.boleto_cliente_nome}
              />
            ),
            programados: pedido?.programacao.length > 0 ? "Sim" : "Não",
            cotacaoOriginalExcel: "",
            produtoExcel: produtoFormatted.toString(),
            valorTotalExcel: "",
            distribuidor_id: "",
            distribuidor_preferencia_id: "",
          });

          // Cria objeto com as Cotações
          pedido.cotacoes.forEach((cotacao) => {
            totalCotação = totalCotação + 1;
            let arrayQuantidadeTotal = [];
            let valorTotalFormatted = 0;

            arrayQuantidadeTotal = cotacao?.produtos_cotacao.map((prod) => {
              return parseFloat(prod.quantidade / 1000)
                .toFixed(2)
                ?.replace(".", ",");
            });

            if (arrayQuantidadeTotal.length > 0) {
              valorTotalFormatted = arrayQuantidadeTotal;
            }

            cotacao.produtos_cotacao.map((prod) => {
              obj = {};

              Object.assign(obj, {
                original: pedido,
                id: pedido.id,
                id_cotacao: cotacao.id,
                numeroPedidoSodexo:"",
                numeroPedidoWizeo: "",
                numeroPedido: "",
                numeroCotacao: cotacao.numero_cotacao_sistema,
                cotacaoOriginal: (
                  <PedidoLink item={cotacao?.cotacao_original} menorPreco={cotacaoIdProduto}/>
                ),
                numeroPedidoCliente: pedido.numero_pedido_cliente,
                dataPedido: format(parseISO(pedido.created_at), "dd/MM/yyyy - HH:mm"),
                dataSemFormatacao: pedido.created_at,
                cliente:"",
                produto: prod?.produto?.descricao,
                cotacao_produto_id: prod?.id,
                valorTotal: (parseFloat(prod?.quantidade) / 1000).toFixed(2),
                preco: prod?.valor_unitario,
                distribuidorTRR: cotacao.distribuidor
                  ? cotacao?.distribuidor.cpf_cnpj.replace(
                      /(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g,
                      "$1.$2.$3/$4-$5"
                    ) +
                    " - " +
                    cotacao?.distribuidor.razao_social
                  : "",
                prazoPagamento: "",
                dataEntrega: "",
                status: pedido?.status_pedido,
                statusCotacao: cotacao?.status_cotacao,
                integrado:"",
                operador: "",
                criador: "",
                responsavel: "",
                historico: "",
                notaServico: "",
                notaDistribuidor: "",
                boleto: "",
                programados: "",
                cotacaoOriginalExcel:
                  cotacao?.cotacao_original?.numero_cotacao_sistema,
                produtoExcel: produtoFormatted.toString(),
                valorTotalExcel: valorTotalFormatted.toString(),
                distribuidor_id: cotacao?.distribuidor?.id,
                distribuidor_preferencia_id:
                  cotacao?.cliente?.distribuidor_preferencia_id,
                cotacaoIdProduto: cotacaoIdProduto
              });

              cotacoesRows.push(obj);
              return prod;
            });
          });

          // Ordena Cotação por produto e preço
          cotacoesRows = cotacoesRows.sort((a, b) => {
            function value(el) {
              var x = el;
              return x === "0.0000" ? Infinity : x;
            }

            return (
              a.produto.localeCompare(b.produto) ||
              value(a.preco) - value(b.preco)
            );
          });

          // Sempre adiciona a primeira posição do array quando valor diferente de 0
          if (cotacoesRows[0].preco !== "0.0000") {
            cotacoesRows[0].blink = true;
            cotacaoIdProduto.push(cotacoesRows[0].cotacao_produto_id)
            menorPreco.push([cotacoesRows[0].preco, cotacoesRows[0].produto])
          }

          // Faz uma busca pelo restante do array e adiciona os menores preços caso os produtos sejam diferentes e diferente de 0
          cotacoesRows = cotacoesRows.map((i, index) => {
            if (i.produto !== cotacoesRows[index - 1 < 0 ? 0 : index - 1]?.produto && cotacoesRows[index].preco !== "0.0000") {
              i.blink = true;
              cotacaoIdProduto.push(i.cotacao_produto_id)
              menorPreco.push([i?.preco, i?.produto])
            }

            // "menorPrecoIgual" existe para adicionar o menor preço que possui o mesmo valor em outras cotações
            if (menorPreco.find( menorPrecoIgual =>  menorPrecoIgual[0] === i?.preco && menorPrecoIgual[1] === i?.produto)) {
              i.blink = true;
              cotacaoIdProduto.push(i.cotacao_produto_id)
            }
            return i;
          });

          Object.assign(pedidoRow, {
            subRows: cotacoesRows,
          });

          setTotalCotacao(totalCotação);
          setTotalPedido(totalPedido);
          setListaPainel((state) => [...state, pedidoRow]);
        });

        setFirstPage(response?.dados?.first_page_url);
        setLastPage(response?.dados?.last_page);
        setLastPageUrl(response?.dados?.last_page_url);
        setNextPage(response?.dados?.next_page_url);
        setPrevPage(response?.dados?.prev_page_url);
        setCurrentPage(response?.dados?.current_page);
        setCountPage(() => {
          const arr = [];

          const current_Page = response?.dados?.current_page;
          let initialPageNumber = 0;
          let limitPageNumber = 0;

          let lastPage = response?.dados?.last_page;

          if (current_Page > 2) {
            initialPageNumber = current_Page - 3;
          } else if (current_Page <= 2) {
            initialPageNumber = current_Page - current_Page;
          }

          // define qual é a pagina final pelos numeros
          if (current_Page > 2) {
            limitPageNumber = current_Page + 2;
          } else if (current_Page <= 2) {
            limitPageNumber = current_Page - current_Page + 5;
          }

          // define qual é a pagina final pelos numeros
          if (limitPageNumber > lastPage) {
            initialPageNumber = lastPage - 5;
            limitPageNumber = lastPage;
          }

          // Cria o Array com a paginação numérica
          for (
            let index = initialPageNumber;
            index < limitPageNumber;
            index++
          ) {
            arr.push(index + 1);
          }

          return arr.filter((page) => page > 0);
        });
        // setLoadingList(false);
        setLoadingListFilter(false);
      } catch (error) {
        console.log(error);
        // setLoadingList(false);
      }
    },
    [handleHistory, user]
  );

  const handleClickCheckbox = useCallback(
    (e) => {
      // Seleciona Todas os Checkbox
      e.checked = true;
      setCheck(!check);
      const inputs = document.querySelectorAll("input[type=checkbox]");

      inputs.forEach((input) => {
        if (check) {
          input.checked = check;

          let id = [];

          listaPainel.map((item) => {
            return id.push(`${item.id_cotacao}-${item.status}-${item.id}`);
          });

          id = id.filter((elem, i) => id.indexOf(elem) === i);

          setSelectedOrders(id);
        } else {
          input.checked = check;

          setSelectedOrders([]);
        }
      });
    },
    [check, listaPainel]
  );

  const handleSelectOrders = useCallback((row, e) => {
    // Seleciona um checkbox

    if (e.checked === true) {
      setSelectedOrders((state) => [
        ...state,
        `${row.original.id_cotacao}-${row.original.status}-${row.original.id}`,
      ]);
    } else {
      setSelectedOrders((state) =>
        state.filter(
          (order) =>
            order !==
            `${row.original.id_cotacao}-${row.original.status}-${row.original.id}`
        )
      );
    }
  }, []);

  const handleIntegrateOrder = useCallback(
    async (data, tipo) => {
      if (tipo === "reintegrar") {
        const reintegrarAlert = await swal({
          title: voceTemCerteza,
          text: "Deseja reintegrar o pedido?",
          icon: Alert,
          buttons: {
            cancel: {
              text: cancelar,
              value: null,
              visible: true,
              closeModal: true,
              className: "teste",
            },
            confirm: {
              text: opcaoSim,
              value: true,
              visible: true,
              closeModal: true,
            },
          },
          dangerMode: true,
        });

        if (reintegrarAlert) {
          try {
            setIntegrateButton(true);

            await api.post(`/cotacao/integrar`, { ids: [data?.id_cotacao] });

            toast.success("Pedido reintegrado com sucesso!");
            setIntegrateButton(false);
            history.go(0);
          } catch (err) {
            toast.error("Erro ao reintegrar o pedido");
            setIntegrateButton(false);
          }
        }
      } else {
        try {
          setIntegrateButton(true);

          await api.post(`/cotacao/integrar`, { ids: [data?.id_cotacao] });

          toast.success("Pedido integrado com sucesso!");
          setIntegrateButton(false);
          history.go(0);
        } catch (err) {
          toast.error("Erro ao integrar o pedido");
          setIntegrateButton(false);
        }
      }
    },
    [voceTemCerteza, cancelar, opcaoSim, history]
  );

  const handleIntegrateMultiOrder = useCallback(async () => {
    setIntegrateMultiButtonLoading(true);
    const formattedOrders = selectedOrders.map((elem) => {
      return { id: elem.split("-")[0], status: elem.split("-")[1] };
    });

    let checkOrders = formattedOrders.filter(
      (elem) =>
        elem.status === "Aprovado" ||
        elem.status === "Aguardando Faturamento" ||
        elem.status === "Faturado"
    );

    if (checkOrders.length === 0) {
      setIntegrateMultiButtonLoading(false);
      return toast.warning("Nenhum pedido selecionado com status aprovado.");
    }

    checkOrders = checkOrders.map((item) => item.id && parseInt(item.id));

    try {
      await api.post(`/cotacao/integrar`, { ids: checkOrders });

      toast.success("Pedido integrado com sucesso!");
      history.go(0);
      setIntegrateMultiButtonLoading(false);
    } catch (err) {
      toast.error("Erro ao integrar o pedido");
      setIntegrateMultiButtonLoading(false);
    }
  }, [history, selectedOrders]);

  return (
    <>
      <Container>
        <header>
          <nav>
            <ButtonImage
              ImageSrc={iconVoltar}
              border={true}
              color={"#fff"}
              onClick={handleGoBack}
              placeholder={<FormattedMessage id="voltar" />}
            />
            <h2>
              <FormattedMessage id="painelControle" />
            </h2>
          </nav>

          <div>
            <>
              <ButtonImage
                ImageSrc={iconFiltro}
                backgroundColor={"#221C46"}
                placeholder={<FormattedMessage id="filtrar" />}
                onClick={() => handleFilter()}
                loading={filterButton}
              />

              <ButtonImage
                ImageSrc={iconLixeira}
                backgroundColor={"#B30000"}
                placeholder={"Limpar Filtro"}
                onClick={() => handleRemoveFilter()}
                loading={filterButton}
              />

              <ButtonIcon
                Icon={FiAlertCircle}
                backgroundColor={"#B26019"}
                placeholder={"Legendas"}
                onClick={() => setShowLegends(!showLegends)}
              />

              <ButtonIcon
                Icon={FiPlus}
                backgroundColor={"#221c46"}
                onClick={handleCreate}
                permission="pedido-store[1]"
                placeholder={<FormattedMessage id="novoPedido" />}
              />

              <ButtonIcon
                Icon={FaEllipsisH}
                backgroundColor={"#5A5469"}
                placeholder={<FormattedMessage id="acoes" />}
                onClick={() => setShowAction(!showAction)}
              />
            </>

            <ButtonImage
              ImageSrc={ExcelLogo}
              placeholder={<FormattedMessage id="exportarExcel" />}
              onClick={handleExportToExcel}
            ></ButtonImage>
          </div>
        </header>

        {loadingList ? (
          <ContentSpinner />
        ) : (
          <>
            <main>
              <ExcelFile
                element={
                  <button style={{ display: "none" }} ref={refExcel}></button>
                }
                filename="Painel de Controle"
              >
                <ExcelSheet
                  dataSet={formatExcelData(listaPainel)}
                  name="Painel de Controle"
                />
              </ExcelFile>

              <table {...getTableProps()}>
                <thead>
                  {
                    // Loop over the header rows
                    headerGroups.map((headerGroup) => (
                      // Apply the header row props
                      <tr {...headerGroup.getHeaderGroupProps()}>
                        <th>
                          <FormattedMessage id="selecionar" />
                        </th>
                        <th>
                          <FormattedMessage id="acoes" />
                        </th>

                        {
                          // Loop over the headers in each row
                          headerGroup.headers.map((column) => (
                            // Apply the header cell props
                            <th {...column.getHeaderProps()}>
                              {
                                // Render the header
                                column.render("Header")
                              }
                            </th>
                          ))
                        }
                        <th>
                          <FormattedMessage id="acoes" />
                        </th>
                      </tr>
                    ))
                  }
                </thead>
                {/* Apply the table body props */}
                <tbody {...getTableBodyProps()}>
                  <tr>
                    {/* Exibe o check box apenas quando tiver pelo menos 1 pedido expandido */}
                    {Object.keys(expanded).length > 0 ? (
                      <td>
                        <input
                          onClick={(e) => handleClickCheckbox(e)}
                          type="checkbox"
                        />
                      </td>
                    ) : (
                      <td></td>
                    )}

                    <td></td>
                    <td></td>

                    <td>
                      <InputMaskDefault
                        name="numero_pedido"
                        style={{ borderColor: "#cccccc" }}
                        type="text"
                        mask={"int15"}
                        maxLength={15}
                        onChange={(e) => setFilterNumeroPedido(e.target.value)}
                        // onBlur={() => {
                        //   return handleFilter();
                        // }}
                        value={filterNumeroPedido}
                      />
                    </td>
                    {/* <td>
                      <InputLabel
                        name="cotacao"
                        style={{ borderColor: "#cccccc" }}
                        type="text"
                        onChange={(e) => setFilterCotacao(e.target.value)}
                        // onBlur={() => {
                        //   return handleFilter();
                        // }}
                        value={filterCotacao}
                      />
                    </td> */}
                    <td style={{textAlign:"left"}}>
                      <ListSelect
                        name="status"
                        placeholder={<FormattedMessage id="selecionar" />}
                        cacheOptions
                        defaultOptions
                        options={statusOptions}
                        onChange={(e) => {
                          setFilterStatus(e?.value);
                        }}
                        // onBlur={() => {
                        //   return handleFilter();
                        // }}
                        isClearable={true}
                        value={filterStatus?.value}
                      />
                    </td>

                    {/* Data Criação */}
                    <td style={{paddingTop: "0px"}}>
                      <DatePicker
                        selectsRange={true}
                        startDate={startDateCreate}
                        endDate={endDateCreate}
                        onChange={(update) => {
                          setDateRange(update);
                        }}
                        locale="ptBR"
                        dateFormat="dd/MM/yyyy"
                        withPortal
                        rangeColors={['#3ecf8e']}
                      />
                    </td>

                    <td>
                      <InputLabel
                        name="cliente"
                        style={{ borderColor: "#cccccc" }}
                        type="text"
                        onChange={(e) => setFilterCliente(e.target.value)}
                        // onBlur={() => {
                        //   return handleFilter();
                        // }}
                        value={filterCliente}
                      />
                    </td>

                    <td style={{textAlign:"left"}}>
                      <AsyncListSelect
                        name="produto"
                        placeholder={<FormattedMessage id="selecionar" />}
                        cacheOptions
                        defaultOptions
                        myOptions={"produtos"}
                        onChange={(e) => {
                          setFilterProduto(e?.value);
                        }}
                        // onBlur={() => {
                        //   return handleFilter();
                        // }}
                        isClearable={true}
                        value={filterProduto?.value}
                      />
                    </td>

                    <td></td>
                    <td></td>
                    <td></td>

                    <td>
                      <InputLabel
                        name="distribuidor"
                        style={{ borderColor: "#cccccc" }}
                        type="text"
                        onChange={(e) =>
                          setFilterDistribuidorTRR(e.target.value)
                        }
                        // onBlur={() => {
                        //   return handleFilter();
                        // }}
                        value={filterDistribuidorTRR?.value}
                      />
                    </td>

                    <td style={{textAlign:"left"}}>
                      <ListSelect
                        name="cotacaoOriginal"
                        placeholder={<FormattedMessage id="selecionar" />}
                        cacheOptions
                        defaultOptions
                        options={notaServiço}
                        onChange={(e) => {
                          setFilterCotacaoOriginal(e?.value);
                        }}
                        isClearable={true}
                        value={filterCotacaoOriginal?.value}
                      />
                    </td>

                    <td>
                      <InputLabel
                        name="numero_pedido_cliente"
                        style={{ borderColor: "#cccccc" }}
                        type="text"
                        onChange={(e) =>
                          setFilterNumeroPedidoCliente(e.target.value)
                        }
                        // onBlur={() => {
                        //   return handleFilter();
                        // }}
                        value={filterNumeroPedidoCliente}
                      />
                    </td>

                    <td>
                      <InputLabel
                        name="numeroPedidoWizeo"
                        style={{ borderColor: "#cccccc" }}
                        type="text"
                        onChange={(e) => setFilterCargaWizeo(e.target.value)}
                        // onBlur={() => {
                        //   return handleFilter();
                        // }}
                        value={filterCargaWizeo}
                      />
                    </td>

                    <td style={{textAlign:"left"}}>
                      <ListSelect
                        name="programados"
                        placeholder={<FormattedMessage id="selecionar" />}
                        cacheOptions
                        defaultOptions
                        options={notaServiço}
                        onChange={(e) => {
                          setFilterProg(e?.value);
                        }}
                        // onBlur={() => {
                        //   return handleFilter();
                        // }}
                        isClearable={true}
                        value={filterProg?.value}
                      />
                    </td>

                    <td style={{textAlign:"left"}}>
                      <AsyncListSelect
                        name="operador_id"
                        placeholder={<FormattedMessage id="selecionar" />}
                        cacheOptions
                        defaultOptions
                        myOptions={"operadores"}
                        onChange={(e) => {
                          setFilterOperador(e?.value);
                        }}
                        // onBlur={() => {
                        //   return handleFilter();
                        // }}
                        isClearable={true}
                        value={filterOperador?.value}
                      />
                    </td>

                    <td>

                    </td>

                    <td>

                    </td>

                    <td>
                      <InputMaskDefault
                        name="prazo_pgt"
                        style={{ borderColor: "#cccccc" }}
                        mask={"int3"}
                        type="text"
                        onChange={(e) =>
                          setFilterPrazoPagamento(e.target.value)
                        }
                        // onBlur={() => {
                        //   return handleFilter();
                        // }}
                        value={filterPrazoPagamento}
                      />
                    </td>

                    {/* Data Entrega */}
                    <td style={{paddingTop: "0px"}}>
                      <DatePicker
                        selectsRange={true}
                        startDate={startDateEntrega}
                        endDate={endDateEntrega}
                        onChange={(update) => {
                          setEntregaRange(update);
                        }}
                        locale="ptBR"
                        dateFormat="dd/MM/yyyy"
                        withPortal
                      />
                    </td>

                    {/* <td>
                      <ListSelect
                        name="status"
                        placeholder={<FormattedMessage id="selecionar" />}
                        cacheOptions
                        defaultOptions
                        options={statusOptions}
                        onChange={(e) => {
                          setFilterStatus(e?.value);
                        }}
                        // onBlur={() => {
                        //   return handleFilter();
                        // }}
                        isClearable={true}
                        value={filterStatus?.value}
                      />
                    </td> */}

                    <td>
                      <InputLabel
                        name="cotacao"
                        style={{ borderColor: "#cccccc" }}
                        type="text"
                        onChange={(e) => setFilterCotacao(e.target.value)}
                        // onBlur={() => {
                        //   return handleFilter();
                        // }}
                        value={filterCotacao}
                      />
                    </td>

                    <td style={{textAlign:"left"}}>
                      <ListSelect
                        name="status_cotacao"
                        placeholder={<FormattedMessage id="selecionar" />}
                        cacheOptions
                        defaultOptions
                        options={statusCotacao}
                        onChange={(e) => {
                          setFilterStatusCotacao(e?.value);
                        }}
                        // onBlur={() => {
                        //   return handleFilter();
                        // }}
                        isClearable={true}
                        value={filterStatusCotacao?.value}
                      />
                    </td>

                    <td style={{textAlign:"left"}}>
                      <ListSelect
                        name="integrado"
                        placeholder={<FormattedMessage id="selecionar" />}
                        cacheOptions
                        defaultOptions
                        options={integradoOptions}
                        onChange={(e) => {
                          setFilterIntegrado(e?.value);
                        }}
                        // onBlur={() => {
                        //   return handleFilter();
                        // }}
                        isClearable={true}
                        value={filterIntegrado?.value}
                      />
                    </td>

                    <td style={{textAlign:"left"}}>
                      <ListSelect
                        name="notaServiço"
                        placeholder={<FormattedMessage id="selecionar" />}
                        cacheOptions
                        defaultOptions
                        options={notaServiço}
                        onChange={(e) => {
                          setFilterNotaServiço(e?.value);
                        }}
                        // onBlur={() => {
                        //   return handleFilter();
                        // }}
                        isClearable={true}
                        value={filterNotaServiço?.value}
                      />
                    </td>

                    <td style={{textAlign:"left"}}>
                      <ListSelect
                        name="notaDistribuidor"
                        placeholder={<FormattedMessage id="selecionar" />}
                        cacheOptions
                        defaultOptions
                        options={notaDistribuidor}
                        onChange={(e) => {
                          setFilterNotaDistribuidor(e?.value);
                        }}
                        // onBlur={() => {
                        //   return handleFilter();
                        // }}
                        isClearable={true}
                        value={filterNotaDitribuidor?.value}
                      />
                    </td>

                    <td style={{textAlign:"left"}}>
                      <ListSelect
                        name="boleto"
                        placeholder={<FormattedMessage id="selecionar" />}
                        cacheOptions
                        defaultOptions
                        options={boleto}
                        onChange={(e) => {
                          setFilterBoleto(e?.value);
                        }}
                        // onBlur={() => {
                        //   return handleFilter();
                        // }}
                        isClearable={true}
                        value={filterBoleto?.value}
                      />
                    </td>
                    <td></td>
                    <td></td>
                  </tr>
                  {loadingListFilter
                    ? null
                    : rows.map((row, index) => {
                        // Prepare the row for display
                        prepareRow(row);
                        return (
                          // Apply the row props
                          <tr {...row.getRowProps()}>
                            <td>
                              {/* Valida se é uma cotação e nao o pedido */}
                              {row.id.indexOf(".") !== -1 && (
                                <input
                                  type="checkbox"
                                  onClick={(e) =>
                                    handleSelectOrders(row, e.target)
                                  }
                                />
                              )}
                            </td>

                            {
                              // Acões
                              /* Valida se é uma cotação e nao o pedido */
                              row.id.indexOf(".") === -1 ? (
                                <td>
                                  <OptionsList style={{position: 'inherit'}}>
                                    <li>
                                      <OptionsIconPlaceholder
                                        Icon={ImCancelCircle}
                                        placeholder={"Cancelar Pedido"}
                                        onClick={() =>
                                          handleCancelOrdersLine(
                                            `${row.original.id_cotacao}-${row.original.status}-${row.original.id}`
                                          )
                                        }
                                        permission="pedido-cancelar[1]"
                                      />
                                    </li>

                                      {row.original.status === "Aprovado" ||
                                          row.original.status === "Aguardando Faturamento" ||
                                          row.original.status === "Faturado" ? (
                                            <>
                                              <li>
                                                <OptionsIconPlaceholder
                                                  Icon={AiOutlineMail}
                                                  placeholder={"Reenviar E-mails"}
                                                  onClick={() =>
                                                    handleReenviarEmail(
                                                      `${row.original.id_cotacao}`
                                                    )
                                                  }
                                                  loading={loadingEmail}
                                                />
                                              </li>
                                              <li>
                                                <OptionsIconPlaceholder
                                                  Icon={iconEspelhoCliente}
                                                  image={true}
                                                  placeholder={"Espelho Cliente"}
                                                  onClick={() =>
                                                    handleDownloadCliente(
                                                      `${row.original.id}`
                                                    )
                                                  }
                                                  loading={loadingEspelhoCliente}
                                                />
                                              </li>
                                              <li>
                                                <OptionsIconPlaceholder
                                                  Icon={iconEspelhoDistribuidor}
                                                  image={true}
                                                  placeholder={"Espelho Distribuidor/TRR"}
                                                  onClick={() =>
                                                    handleDownloadDistribuidor(
                                                      `${row.original.id}`
                                                    )
                                                  }
                                                  loading={loadingEspelhoDistribuidor}
                                                />
                                              </li>
                                            </>
                                          ) : (
                                            ""
                                          ) }

                                    {/* integrado pedido */}
                                    {row.original.status ===
                                    "Aprovado" || row.original.status ===
                                    "Aguardando Faturamento" || row.original.status ===
                                    "Faturado"  ? (
                                      !(row.original.integrado === "Sim") ? (
                                        <li>
                                          <OptionsIconPlaceholder
                                            Icon={FaSortAmountUp}
                                            placeholder={
                                              <FormattedMessage id="integrar" />
                                            }
                                            onClick={() =>
                                              handleIntegrateOrder(
                                                row.original,
                                                "integrar"
                                              )
                                            }
                                            loading={integrateButton}
                                            permission="pedido-integrar[1]"
                                          />
                                        </li>
                                      ) : (
                                        <li>
                                          <div></div>
                                          <OptionsIconPlaceholder
                                            Icon={FaSortAmountUp}
                                            placeholder={
                                              <FormattedMessage id="reintegrar" />
                                            }
                                            onClick={() =>
                                              handleIntegrateOrder(
                                                row.original,
                                                "reintegrar"
                                              )
                                            }
                                            loading={integrateButton}
                                            permission="pedido-integrar[1]"
                                          />
                                        </li>
                                      )
                                    ) : (
                                      <></>
                                    )}
                                    {/* <li>
                                      <div style={{ margin: "0 10px" }}></div>
                                    </li> */}
                                  </OptionsList>
                                </td>
                              ) : (
                                <td>
                                  <OptionsList>
                                    <li>
                                      <OptionsIconPlaceholder
                                        Icon={FiEye}
                                        placeholder={
                                          <FormattedMessage id="exibir" />
                                        }
                                        onClick={() => {
                                          return handleDisplay(row);
                                        }}
                                        permission="pedido-show[1]"
                                      />
                                    </li>

                                    {row.original.status === "Aprovado" ||
                                    row.original.status === "Faturado" ||
                                    row.original.status ===
                                      "Aguardando Faturamento" ||
                                    row.original.status === "Reprovado" ||
                                    row.original.status === "Cancelado" ||
                                    row.original.status ===
                                      "Aguardando Aprovação" ||
                                    row.original.statusCotacao ===
                                      "Cancelado" ? (
                                      <></>
                                    ) : (
                                      <li>
                                        <OptionsIconPlaceholder
                                          Icon={FiEdit}
                                          placeholder={
                                            <FormattedMessage id="editar" />
                                          }
                                          onClick={() => handleEdit(row)}
                                          permission="pedido-update[1]"
                                        />
                                      </li>
                                    )}

                                    {row.original.status !== "Cotação" ? (
                                      <></>
                                    ) : (
                                      <li>
                                        <OptionsIconPlaceholder
                                          Icon={FiTrash2}
                                          placeholder={"Cancelar Cotação"}
                                          onClick={() =>
                                            handleCancelCotacaoLine(
                                              row.original.id_cotacao
                                            )
                                          }
                                          permission="pedido-cancelar[1]"
                                        />
                                      </li>
                                    )}

                                    {/* <li>
                                      <OptionsIconPlaceholder
                                        Icon={ImCancelCircle}
                                        placeholder={"Cancelar Pedido"}
                                        onClick={() =>
                                          handleCancelOrdersLine(
                                            `${row.original.id_cotacao}-${row.original.status}-${row.original.id}`
                                          )
                                        }
                                        permission="pedido-cancelar[1]"
                                      />
                                    </li> */}

                                    {/* integrado cotacao */}
                                    {/* {row.original.statusCotacao ===
                                    "Aprovado" ? (
                                      !(row.original.integrado === "Sim") ? (
                                        <li>
                                          <OptionsIconPlaceholder
                                            Icon={FaSortAmountUp}
                                            placeholder={
                                              <FormattedMessage id="integrar" />
                                            }
                                            onClick={() =>
                                              handleIntegrateOrder(
                                                row.original,
                                                "integrar"
                                              )
                                            }
                                            loading={integrateButton}
                                            permission="pedido-integrar[1]"
                                          />
                                        </li>
                                      ) : (
                                        <li>
                                          <div></div>
                                          <OptionsIconPlaceholder
                                            Icon={FaSortAmountUp}
                                            placeholder={
                                              <FormattedMessage id="reintegrar" />
                                            }
                                            onClick={() =>
                                              handleIntegrateOrder(
                                                row.original,
                                                "reintegrar"
                                              )
                                            }
                                            loading={integrateButton}
                                            permission="pedido-integrar[1]"
                                          />
                                        </li>
                                      )
                                    ) : (
                                      <></>
                                    )} */}
                                  </OptionsList>
                                </td>
                              )
                            }

                            {
                              // Loop over the rows cells
                              row.cells.map((cell) => {
                                // Apply the cell props
                                var style;
                                var colorCell;

                                var horaAgora = format(new Date(), "HH:mm")
                                var dataAgora = format(new Date(), "dd/MM/yyyy")

                                var limiteHoraPedido = moment(
                                  row.original.dataSemFormatacao
                                ).add(
                                    'hours', 2
                                );

                                var limiteDataPedido = row.original.dataPedido.split(" ");

                                if (cell.column.id === "dataPedido") {
                                  if (horaAgora > limiteHoraPedido.format("HH:mm") && (cell.row.original.statusCotacao === "Análise Distribuidor/TRR" || (cell.row.original.status === "Cotação" && cell.row.original.statusCotacao === ""))) {
                                    colorCell = "#f33e3ec7"
                                  }

                                  if (dataAgora > limiteDataPedido[0] && (cell.row.original.statusCotacao === "Análise Distribuidor/TRR" || (cell.row.original.status === "Cotação" && cell.row.original.statusCotacao === ""))) {
                                    colorCell = "#f33e3ec7"
                                  }

                                  style = {
                                    background: colorCell,
                                    fontWeight: "bold",
                                  };
                                }

                                if (
                                  cell.column.id === "status" &&
                                  cell.row.id.indexOf(".") !== -1
                                ) {
                                  style = {
                                    color: "transparent"
                                  };
                                }

                                if (cell.column.id === "integrado") {
                                  if (cell.row.original.integrado === "Não") {
                                    colorCell = "#F0CD01";
                                  } else if ( cell.row.original.integrado === "Sim") {
                                    colorCell = "#01AD43";
                                  } else if ( cell.row.original.integrado === "Erro") {
                                    colorCell = "#B30000";
                                  }

                                  style = {
                                    background: colorCell,
                                    fontWeight: "bold",
                                  };
                                }

                                if (
                                  cell.column.id === "distribuidorTRR" &&
                                  cell.row.id.indexOf(".") !== -1
                                ) {
                                  if (
                                    cell.row.original.distribuidor_id ===
                                    cell.row.original
                                      .distribuidor_preferencia_id
                                  ) {
                                    colorCell = "#d2dce7";
                                  }

                                  style = {
                                    background: colorCell,
                                  };
                                }

                                let blink = false;

                                if (
                                  cell.column.id === "preco" &&
                                  cell.row.id.indexOf(".") !== -1
                                ) {
                                  if (
                                    cell.row.original?.blink &&
                                    cell.row.original?.preco !== "0.0000"
                                  ) {
                                    blink = true;
                                  }
                                }

                                return (
                                  <td
                                    {...cell.getCellProps()}
                                    style={style}
                                    className={blink ? "blink" : ""}
                                  >
                                    {
                                      // Render the cell contents
                                      cell.render("Cell")
                                    }
                                  </td>
                                );
                              })
                            }

                            {
                              // Acões
                              /* Valida se é uma cotação e nao o pedido */
                              row.id.indexOf(".") === -1 ? (
                                <td>
                                  <OptionsList>
                                    <li>
                                      <OptionsIconPlaceholder
                                        Icon={ImCancelCircle}
                                        placeholder={"Cancelar Pedido"}
                                        onClick={() =>
                                          handleCancelOrdersLine(
                                            `${row.original.id_cotacao}-${row.original.status}-${row.original.id}`
                                          )
                                        }
                                        permission="pedido-cancelar[1]"
                                      />
                                    </li>

                                    {row.original.status === "Aprovado" ||
                                      row.original.status === "Aguardando Faturamento" ||
                                      row.original.status === "Faturado" ? (
                                        <>
                                        <li>
                                          <OptionsIconPlaceholder
                                          Icon={AiOutlineMail}
                                          placeholder={"Reenviar E-mails"}
                                          onClick={() =>
                                            handleReenviarEmail(
                                              `${row.original.id_cotacao}`
                                            )
                                          }
                                          loading={loadingEmail}

                                        />
                                      </li>
                                      <li>
                                        <OptionsIconPlaceholder
                                          Icon={iconEspelhoCliente}
                                          image={true}
                                          placeholder={"Espelho Cliente"}
                                          onClick={() =>
                                            handleDownloadCliente(
                                              `${row.original.id}`
                                            )
                                          }
                                          loading={loadingEspelhoCliente}
                                        />
                                      </li>
                                      <li>
                                        <OptionsIconPlaceholder
                                          Icon={iconEspelhoDistribuidor}
                                          image={true}
                                          placeholder={"Espelho Distribuidor/TRR"}
                                          onClick={() =>
                                            handleDownloadDistribuidor(
                                              `${row.original.id}`
                                            )
                                          }
                                          loading={loadingEspelhoDistribuidor}
                                        />
                                      </li>
                                      </>
                                      ) : (
                                        ""
                                      )}

                                     {row.original.status ===
                                    "Aprovado" || row.original.status ===
                                    "Aguardando Faturamento"  || row.original.status ===
                                    "Faturado" ? (
                                      !(row.original.integrado === "Sim") ? (
                                        <li>
                                          <OptionsIconPlaceholder
                                            Icon={FaSortAmountUp}
                                            placeholder={
                                              <FormattedMessage id="integrar" />
                                            }
                                            onClick={() =>
                                              handleIntegrateOrder(
                                                row.original,
                                                "integrar"
                                              )
                                            }
                                            loading={integrateButton}
                                            permission="pedido-integrar[1]"
                                          />
                                        </li>
                                      ) : (
                                        <li>
                                          <div></div>
                                          <OptionsIconPlaceholder
                                            Icon={FaSortAmountUp}
                                            placeholder={
                                              <FormattedMessage id="reintegrar" />
                                            }
                                            onClick={() =>
                                              handleIntegrateOrder(
                                                row.original,
                                                "reintegrar"
                                              )
                                            }
                                            loading={integrateButton}
                                            permission="pedido-integrar[1]"
                                          />
                                        </li>
                                      )
                                    ) : (
                                      <></>
                                    )}

                                    <li>
                                      <div style={{ margin: "0 10px" }}></div>
                                    </li>
                                  </OptionsList>
                                </td>
                              ) : (
                                <td>
                                  <OptionsList>
                                    <li>
                                      <OptionsIconPlaceholder
                                        Icon={FiEye}
                                        placeholder={
                                          <FormattedMessage id="exibir" />
                                        }
                                        onClick={() => {
                                          return handleDisplay(row);
                                        }}
                                        permission="pedido-show[1]"
                                      />
                                    </li>

                                    {row.original.status === "Aprovado" ||
                                    row.original.status === "Faturado" ||
                                    row.original.status ===
                                      "Aguardando Faturamento" ||
                                    row.original.status === "Reprovado" ||
                                    row.original.status === "Cancelado" ||
                                    row.original.status ===
                                      "Aguardando Aprovação" ||
                                    row.original.statusCotacao ===
                                      "Cancelado" ? (
                                      <></>
                                    ) : (
                                      <li>
                                        <OptionsIconPlaceholder
                                          Icon={FiEdit}
                                          placeholder={
                                            <FormattedMessage id="editar" />
                                          }
                                          onClick={() => handleEdit(row)}
                                          permission="pedido-update[1]"
                                        />
                                      </li>
                                    )}

                                    {row.original.status !== "Cotação" ? (
                                      <></>
                                    ) : (
                                      <li>
                                        <OptionsIconPlaceholder
                                          Icon={FiTrash2}
                                          placeholder={"Cancelar Cotação"}
                                          onClick={() =>
                                            handleCancelCotacaoLine(
                                              row.original.id_cotacao
                                            )
                                          }
                                          permission="pedido-cancelar[1]"
                                        />
                                      </li>
                                    )}

                                    {/* <li>
                                      <OptionsIconPlaceholder
                                        Icon={ImCancelCircle}
                                        placeholder={"Cancelar Pedido"}
                                        onClick={() =>
                                          handleCancelOrdersLine(
                                            `${row.original.id_cotacao}-${row.original.status}-${row.original.id}`
                                          )
                                        }
                                        loading={integrateButton}
                                        permission="cotacao-integrar[1]"
                                      />
                                    </li> */}

                                    {row.original.statusCotacao ===
                                    "Aprovado" || row.original.statusCotacao ===
                                    "Faturado" ? (
                                      !(row.original.integrado === "Sim") ? (
                                        <li>
                                          <OptionsIconPlaceholder
                                            Icon={FaSortAmountUp}
                                            placeholder={
                                              <FormattedMessage id="integrar" />
                                            }
                                            onClick={() =>
                                              handleIntegrateOrder(
                                                row.original,
                                                "integrar"
                                              )
                                            }
                                            loading={integrateButton}
                                            permission="pedido-integrar[1]"
                                          />
                                        </li>
                                      ) : (
                                        <li>
                                          <div></div>
                                          <OptionsIconPlaceholder
                                            Icon={FaSortAmountUp}
                                            placeholder={
                                              <FormattedMessage id="reintegrar" />
                                            }
                                            onClick={() =>
                                              handleIntegrateOrder(
                                                row.original,
                                                "reintegrar"
                                              )
                                            }
                                            loading={integrateButton}
                                            permission="pedido-integrar[1]"
                                          />
                                        </li>
                                      )
                                    ) : (
                                      <></>
                                    )}
                                  </OptionsList>
                                </td>
                              )
                            }
                          </tr>
                        );
                      })}
                </tbody>
              </table>
              {loadingListFilter ? (
                <ContentSpinner
                  style={{
                    zIndex: "-1",
                    height: "65vh",
                    width: "90%",
                    position: "fixed",
                  }}
                />
              ) : null}
            </main>
            <Paginate
              handleUpdateListPaginate={handleUpdateList}
              countPage={countPage}
              currentPg={currentPage}
              firstPage={firstPage}
              lastPage={lastPage}
              lastPageUrl={lastPageUrl}
              nextPage={nextPage}
              prevPage={prevPage}
              totalCotacao={totalCotacao}
              totalPedido={totalPedido}
              local={"painel"}
            />
          </>
        )}
      </Container>
      {showAction && (
        <ActionContainer>
          <header>
            <h4>
              <FormattedMessage id="acoes" />
            </h4>
            <FiXCircle onClick={() => setShowAction(!showAction)} />
          </header>
          <div>
            {(user.tipo_id === 1 ||
              user.permission.filter(
                (p) => p.namePermission === "pedido-cancelar[1]"
              ).length > 0) && (
              <>
                <ButtonIcon
                  Icon={ImCross}
                  backgroundColor={"#b50000"}
                  permission="pedido-cancelar[1]"
                  onClick={handleCancelOrders}
                />
                <label>Cancelar Pedido</label>
              </>
            )}
            {(user.tipo_id === 1 ||
              user.permission.filter(
                (p) => p.namePermission === "pedido-cancelar[1]"
              ).length > 0) && (
              <>
                <ButtonIcon
                  Icon={FaSortAmountUp}
                  backgroundColor={"#d4bb31"}
                  onClick={handleIntegrateMultiOrder}
                  loading={integrateMultiButtonLoading}
                />
                <label>Integrar</label>
              </>
            )}
          </div>
        </ActionContainer>
      )}

      {showLegends && (
        <ActionContainer>
          <header>
            <h4>
              Legendas (Indicação das Cores)
            </h4>
            <FiXCircle onClick={() => setShowLegends(!showLegends)} />
          </header>
          <div>

                <ButtonIcon
                  Icon={MdAttachMoney}
                  backgroundColor={"#32a932"}
                  disabled
                />
                <label style={{marginRight: "0!important", width: "195px"}}>Melhor preço referente ao pedido</label>

                <ButtonIcon
                  Icon={AiOutlineClockCircle}
                  backgroundColor={"#f33e3ec7"}
                />
                <label  style={{marginRight: "0!important", width: "210px"}}>Tempo limite para resposta (2 horas)</label>

                <ButtonIcon
                  Icon={MdFavorite}
                  backgroundColor={"#d2dce7"}
                />
                <label  style={{marginRight: "0!important", width: "195px"}}>Distribuidor/TRR de preferência</label>
          </div>
        </ActionContainer>
      )}
    </>
  );
};

export default injectIntl(PainelList);
