import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  max-height: 400px;

  position: relative;

  header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;

    nav {
      display: flex;

            h2 {
        margin: 0;
        padding: 0;
        margin-left: 15px;
        text-align: center;
        color: #221c46;
      }
    }

    div {
      display: flex;
      width: 200px;

      align-items: center;
      justify-content: center;

      button {
        height: 30px;
        width: 30px;
        /* margin: 5px; */
        margin-left: 5px;
        margin-right: 5px;
            /* border-radius: 50%; */
        border: none;
        outline: none;
        color: #fff;
        transition: all 0.2s;
      }

      button:last-child {
        margin: 0 5px;
        cursor: pointer;
        transition: all 0.4s;
        background-color: transparent;
        border-radius: 0;

        img {
          height: 30px;
          width: 30px;
        }
      }
    }
  }

  main {
    height: 80vh;
    overflow: hidden;
    overflow-x: scroll;
    overflow-y: scroll;

    ::-webkit-scrollbar-track {
      background-color: #f4f4f4;
    }

    ::-webkit-scrollbar {
      height: 7px;
      width: 7px;
      background: #f4f4f4;
    }
    ::-webkit-scrollbar-thumb {
       /* border-radius: 5px; */
      background: #221c46;
    }

    ::-webkit-scrollbar-thumb:hover {
      background: lighten($dark, 75%);
    }

    table {
      table-layout: auto;
      border-collapse: collapse;
      width: 100%;
      border: 1px solid #dddddd;
      position: relative;
      overflow-x: scroll;

      thead {
        tr {
          th {
            height: 35px;
            text-align: center;
            padding: 8px;
            padding: 0 30px;
            position: -webkit-sticky;
            position: sticky;
            background-color: #221c46;
            color: #fff;
          }
          th:last-child {
            text-align: center;
            white-space: nowrap;
            transition: width 1.5s ease, max-width 1.5s ease;
          }
        }
      }

      tbody {
        td {
          border: 1px solid #dddddd;
          width: max-content;
        }

        tr {
          background-color: #FCECEA;
          height: 40px;
          td {
            div {
              margin-bottom: 0 !important;
            }
            input {
              border-radius: 2px !important;
              background-color: transparent;
              border: none;
            }

            span {
              margin-left: 10px;
              white-space: nowrap;
            }
          }

          td:first-child {
            width: 82px;
          }
          td:first-child + td {
            width: 180px;
          }
          td:first-child + td + td {
            width: 280px;
          }
        }

        tr:disabled {
        }

        tr:nth-child(even) {
          background-color: #fff;
        }

        td:last-child {
          position: relative;
          padding: 0;
        }
      }

      th {
        top: 0;
        z-index: 5;
        background: #fff;
        border: 1px solid #dddddd;
      }
    }
  }
`;

export const FilterContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;

  right: 9%;
  top: 10%;

  border: 1px solid #221c46;

  width: 450px !important;
  height: auto;

/* border-radius: 10px; */
  background-color: #fff;
  z-index: 101;

  header {
    width: 100%;
    height: 45px;
    background-color: #221c46;
        /* border-radius: 10px 10px 0 0; */

    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #fff;
    padding: 0 10px;

    h4 {
      margin: auto 0;
      margin-left: 5px;
    }
    svg {
      width: 24px;
      height: 24px;
      transition: all 0.4s;
      color: #fff;

      :hover {
        color: #e05262;
      }
    }
  }

  main {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100% !important;

    padding: 0 15px;
    margin-top: 10px;
  }

  footer {
    width: 100%;
    margin-bottom: 10px;
    padding: 0 15px;
  }

  @media screen and (max-width: 530px) {
    top: 20%;
    left: 5%;
  }

  @media screen and (max-width: 765px) {
    top: 17%;
  }
`;

export const FormPagination = styled.div`
  display: flex;
  height: 30px;
  justify-content: center;
  align-items: center;
  margin-top: 5px;

  button {
    width: 30px;
    height: 100%;
    outline: none;
    border: none;
    font-weight: bold;
    font-family: 16px;
    margin-right: 6px;
    border-radius: 5px;
    background-color: #e05262;
    transition: all 0.4s;

    :hover {
      background-color: #221c46;
      color: #f8f8f8;
    }

    :disabled {
      background-color: #e05262;
      opacity: 0.6;
      color: #000;
      cursor: default;
    }
  }

  span {
    margin-left: 5px;
    margin-right: 10px;
  }

  select {
    border: none;
    border-radius: 5px;
  }
`;

export const NoDataContainer = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  h1 {
    color: #000;
  }
`;
