import React, { createContext, useCallback } from "react";

import api from "./../../../services/api";

const QueriesContext = createContext();

export const OperatorSelect = ({ children }) => {
  const operatorOptions = useCallback(async (inputValue) => {
    try {
      var response;
      response = await api.post("/usuario/filter-user-cadastro", {
        cadastro_tipo_id: 1,
        status_usuario_cadastro: 1,
      });

      const arr = response.data.data.map((user) => {
        const obj = {};

        Object.assign(obj, {
          value: String(user.user_id),
          label: user.user_name,
        });

        return obj;
      });

      return new Promise((resolve) => {
        return resolve(
          arr.filter((i) =>
            i.label.toLowerCase().includes(inputValue.toLowerCase())
          )
        );
      });
    } catch (error) {}
  }, []);

  return (
    <QueriesContext.Provider value={{ operatorOptions }}>
      {children}
    </QueriesContext.Provider>
  );
};

export default QueriesContext;
