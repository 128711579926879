import React from "react";
import Routes from "./routes";
import AppProvider from "./context";

import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";

const App = () => {
  return (
    <AppProvider>
      <Routes />
      <ToastContainer autoClose={3500} pauseOnHover={false} />
    </AppProvider>
  );
};

export default App;
