import React, { useCallback, useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import TreeComponent from "./../../../components/TreeMenu/TreeComponent";
import { FormattedMessage, injectIntl } from "react-intl";

import { getErrorsApiValidation } from "./../../../utils/getErrorsValidation";

import api from "./../../../services/api";

import { toast } from "react-toastify";

import AuthContext from "./../../../context/auth";

import {
  Container,
  CreateForm,
  CreateFooter,
  CheckForm,
  FormPermission,
  TitlePermission,
} from "./styles";

import InputLabel from "./../../../components/InputLabel";
import Button from "./../../../components/ButtonDefault";
import ContentSpinner from "./../../../components/Spinner/Content";

import ListSelect from "./../../../components/ListSelect";
import AsyncListSelect from "./../../../components/ListSelect/AsyncListSelect";

export const EditAccessProfiles = ({ intl }) => {
  const [loadingButton, setLoadingButton] = useState(false);
  const [idPerfil, setIdPerfil] = useState();
  // const [cadastroId, setCadastroId] = useState();
  const [empresa, setEmpresa] = useState([{}]);
  const [descricao, setDescricao] = useState("");

  const [listaPermissoes, setListaPermissoes] = useState([]);
  const [permissions, setPermissions] = useState([]);
  const [allPermissions, setAllPermissions] = useState([]);
  const [status, setStatus] = useState("");
  const [label, setLabel] = useState("");

  const [errors, setErrors] = useState(null);

  const [checkBoxAll, setCheckBoxAll] = useState([]);

  const [loading, setLoading] = useState(true);

  const history = useHistory();
  const { user } = useContext(AuthContext);

  //Placeholders internacionalizados
  const selecionarPlaceholder = intl.formatMessage({ id: "selecionar" });

  const statusOptions = [
    { value: 1, label: <FormattedMessage id="ativo" /> },
    { value: 0, label: <FormattedMessage id="inativo" /> },
  ];

  useEffect(() => {
    if (!history.location.state?.id) {
      return history.push("/access/profiles/customers/list");
    }

    const id = history.location.state?.id;

    setIdPerfil(id);

    async function loadInfo() {
      try {
        const { data: response } = await api.get(`role/show/${id}`);

        setDescricao(response?.perfil?.description);
        setStatus(
          response?.perfil?.ativo === 1
            ? {
                value: 1,
                label: <FormattedMessage id="ativo" />,
              }
            : {
                value: 0,
                label: <FormattedMessage id="inativo" />,
              }
        );

        const cadastrosEmpresas = response.rolecadastro.map((item) => {
          return {
            value: item.id,
            label:
              item.cpf_cnpj.replace(
                /(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g,
                "$1.$2.$3/$4-$5"
              ) +
              " - " +
              item.razao_social,
          };
        });

        setEmpresa(cadastrosEmpresas);

        setLabel(history.location.state?.label);

        // setCadastroId(history.location.state?.cadastroId);

        response.perfilpermissoes.map((p) => {
          setCheckBoxAll((checkbox) => [...checkbox, p.name + 3]);
          return setPermissions((permissions) => [...permissions, p.name]);
        });
        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    }
    loadInfo();
  }, [user.cadastro_id, history]);

  useEffect(() => {
    async function loadPermissions() {
      try {
        const { data: response } = await api.get(`/permission`);

        const arr = Object.keys(response);

        // Formata Objeto de permissões da API

        const arrFormmated = arr.map((item) => {
          let arrPermission = [];
          const permissions = Object.keys(response[item]);

          permissions.map((p) => {
            return arrPermission.push({
              id: p,
              permission: item + "-" + response[item][p],
              name: item,
            });
          });

          const b = { [item]: arrPermission };

          return b;
        });

        setAllPermissions(arrFormmated);
      } catch (error) {}
    }

    loadPermissions();
  }, []);

  useEffect(() => {
    const arrFormmated = allPermissions;

    let menuData = [];

    // Acessos
    let acessosPermissoes;
    let usuarioPermissoes;

    arrFormmated.forEach((e) => {
      if (Object.keys(e)[0] === "roles") {
        acessosPermissoes = e.roles;
      }
    });

    arrFormmated.forEach((e) => {
      if (Object.keys(e)[0] === "usuario") {
        usuarioPermissoes = e.usuario;
      }
    });

    const acessos = {
      title: <FormattedMessage id="acessos" />,
      children: [
        {
          title: <FormattedMessage id="perfis" />,
          children: [
            {
              title: <FormattedMessage id="clientes" />,
              component: (
                <Permissions
                  data={acessosPermissoes}
                  cadastroTipoId={3}
                  permissions={permissions}
                />
              ),
            },
          ],
        },
        {
          title: <FormattedMessage id="usuarios" />,
          children: [
            {
              title: <FormattedMessage id="clientes" />,
              component: (
                <Permissions
                  data={usuarioPermissoes}
                  cadastroTipoId={3}
                  permissions={permissions}
                />
              ),
            },
          ],
        },
      ],
    };

    menuData.push(acessos);

    // Cadastro
    let cadastroPermissoes;

    arrFormmated.forEach((e) => {
      if (Object.keys(e)[0] === "cadastro") {
        // Remove Permissões do cadastro de distribuidor
        const arr = e.cadastro.filter((item) => {
          return (
            item.permission.indexOf("cadastro-cliente-dadosentrega") === -1 &&
            item.permission.indexOf("cadastro-cliente-dadoscobranca") === -1 &&
            item.permission.indexOf("cadastro-cliente-consumocapacidade") ===
              -1 &&
            item.permission.indexOf("cadastro-cliente-dadoscadastrais") ===
              -1 &&
            item.permission.indexOf("cadastro-cliente-dadosendereco") === -1 &&
            item.permission.indexOf("cadastro-cliente-basesdistribuicao") ===
              -1 &&
            item.permission.indexOf("cadastro-cliente-list") === -1 &&
            item.permission.indexOf("cadastro-cliente-store") === -1 &&
            item.permission.indexOf("cadastro-cliente-show") === -1 &&
            item.permission.indexOf("cadastro-cliente-destroy") === -1 &&
            item.permission.indexOf("cadastro-cliente-update") === -1 &&
            item.permission.indexOf("cadastro-dadosentrega") === -1 &&
            item.permission.indexOf("cadastro-dadoscobranca") === -1 &&
            item.permission.indexOf("cadastro-consumocapacidade") === -1 &&
            item.permission.indexOf("cadastro-dadoscadastrais") === -1 &&
            item.permission.indexOf("cadastro-dadosendereco") === -1 &&
            item.permission.indexOf("cadastro-basesdistribuicao") === -1 &&
            item.permission.indexOf("cadastro-list") === -1 &&
            item.permission.indexOf("cadastro-store") === -1 &&
            item.permission.indexOf("cadastro-show") === -1 &&
            item.permission.indexOf("cadastro-destroy") === -1 &&
            item.permission.indexOf("cadastro-sodexo-store") === -1 &&
            item.permission.indexOf("cadastro-sodexo-destroy") === -1 &&
            item.permission.indexOf("cadastro-sodexo-show") === -1 &&
            item.permission.indexOf("cadastro-sodexo-update") === -1 &&
            item.permission.indexOf("cadastro-sodexo-list") === -1 &&
            item.permission.indexOf("cadastro-distribuidor-store") === -1 &&
            item.permission.indexOf("cadastro-distribuidor-destroy") === -1 &&
            item.permission.indexOf("cadastro-distribuidor-show") === -1 &&
            item.permission.indexOf("cadastro-distribuidor-update") === -1 &&
            item.permission.indexOf("cadastro-distribuidor-list") === -1 &&
            item.permission.indexOf("cadastro-dadoscadastrais") === -1 &&
            item.permission.indexOf("cadastro-dadosendereco") === -1 &&
            item.permission.indexOf("cadastro-dadosentrega") === -1 &&
            item.permission.indexOf("cadastro-dadoscobranca") === -1 &&
            item.permission.indexOf("cadastro-consumocapacidade") === -1 &&
            item.permission.indexOf("cadastro-certificadodigitalnfe") === -1 &&
            item.permission.indexOf("cadastro-basesdistribuicao") === -1 &&
            item.permission.indexOf("cadastro-sodexo-dadoscadastrais") === -1 &&
            item.permission.indexOf("cadastro-sodexo-dadosendereco") === -1 &&
            item.permission.indexOf("cadastro-sodexo-dadosentrega") === -1 &&
            item.permission.indexOf("cadastro-sodexo-dadoscobranca") === -1 &&
            item.permission.indexOf("cadastro-sodexo-consumocapacidade") ===
              -1 &&
            item.permission.indexOf("cadastro-sodexo-certificadodigitalnfe") ===
              -1 &&
            item.permission.indexOf("cadastro-sodexo-basesdistribuicao") ===
              -1 &&
            item.permission.indexOf("cadastro-distribuidor-dadoscadastrais") ===
              -1 &&
            item.permission.indexOf("cadastro-distribuidor-dadosendereco") ===
              -1 &&
            item.permission.indexOf("cadastro-distribuidor-dadosentrega") ===
              -1 &&
            item.permission.indexOf("cadastro-distribuidor-dadoscobranca") ===
              -1 &&
            item.permission.indexOf(
              "cadastro-distribuidor-consumocapacidade"
            ) === -1 &&
            item.permission.indexOf(
              "cadastro-distribuidor-certificadodigitalnfe"
            ) === -1 &&
            item.permission.indexOf(
              "cadastro-distribuidor-basesdistribuicao"
            ) === -1
          );
        });

        cadastroPermissoes = arr;
      }
    });

    const cadastro = {
      title: <FormattedMessage id="cadastrar" />,
      children: [
        {
          title: <FormattedMessage id="certificadoDigital" />,
          component: (
            <Permissions
              data={cadastroPermissoes}
              cadastroTipoId={3}
              permissions={permissions}
            />
          ),
        },
      ],
    };

    menuData.push(cadastro);

    /////// DASHBOARD ///////////
    let dashboardPermissoes;

    arrFormmated.forEach((e) => {
      if (Object.keys(e)[0] === "dashboard") {
        dashboardPermissoes = e.dashboard.filter(
          (item) => item.permission.indexOf("dashboard-cliente") !== -1
        );
      }
    });

    const dashboard = {
      title: "Dashboard",
      children: [
        {
          title: "Dashboard",
          component: (
            <Permissions
              data={dashboardPermissoes}
              cadastroTipoId={3}
              permissions={permissions}
            />
          ),
        },
      ],
    };
    menuData.push(dashboard);

    /////// HOME ///////////
    let homePermissoes;

    arrFormmated.forEach((e) => {
      if (Object.keys(e)[0] === "cards") {
        homePermissoes = e.cards.filter(
          (item) => item.permission.indexOf("cards-home") !== -1
        );
      }
    });

    const home = {
      title: "Home",
      children: [
        {
          title: "Home",
          component: (
            <Permissions
              data={homePermissoes}
              cadastroTipoId={3}
              permissions={permissions}
            />
          ),
        },
      ],
    };
    menuData.push(home);

    /////// PEDIDOS ///////////
    let precosPermissoes;
    let pedidosPermissoes;

    arrFormmated.forEach((e) => {
      if (Object.keys(e)[0] === "preco") {
        precosPermissoes = e.preco.filter(
          (item) =>
            item.permission.indexOf("preco-store") === -1 &&
            item.permission.indexOf("preco-show") === -1 &&
            item.permission.indexOf("preco-update") === -1 &&
            item.permission.indexOf("preco-destroy") === -1 &&
            item.permission.indexOf("preco-importar") === -1
        );
      }

      if (Object.keys(e)[0] === "pedido") {
        pedidosPermissoes = e.pedido.filter(
          (item) =>
            item.permission.indexOf("pedido-destroy") === -1 &&
            item.permission.indexOf("pedido-liberar") === -1 &&
            item.permission.indexOf("pedido-cancelar") === -1 &&
            item.permission.indexOf("pedido-integrar") === -1
        );
      }
    });

    const pedido = {
      title: <FormattedMessage id="pedidos" />,
      children: [
        {
          title: <FormattedMessage id="precosCombustiveis" />,
          component: (
            <Permissions
              data={precosPermissoes}
              cadastroTipoId={3}
              permissions={permissions}
            />
          ),
        },
        {
          title: <FormattedMessage id="painelControle" />,
          component: (
            <Permissions
              data={pedidosPermissoes}
              cadastroTipoId={3}
              permissions={permissions}
            />
          ),
        },
      ],
    };

    menuData.push(pedido);

    /////// RELATÓRIOS ///////////
    let reportPermissoes;

    arrFormmated.forEach((e) => {
      if (Object.keys(e)[0] === "relatorio") {
        reportPermissoes = e.relatorio.filter(
          (item) =>
            item.permission.indexOf("relatorio-cadastroclientes") === -1 &&
            item.permission.indexOf("relatorio-cadastrodistribuidorestrrs") ===
              -1 &&
            item.permission.indexOf("relatorio-condicoescomerciaisclientes") ===
              -1 &&
            item.permission.indexOf("relatorio-precomedio") === -1 &&
            item.permission.indexOf("relatorio-historiconegociacoes") === -1 &&
            item.permission.indexOf("relatorio-saving") === -1 &&
            item.permission.indexOf("relatorio-reembolso") === -1
        );
      }
    });

    const reports = {
      title: <FormattedMessage id="relatorio" />,
      children: [
        {
          title: <FormattedMessage id="relatorio" />,
          component: (
            <Permissions
              data={reportPermissoes}
              cadastroTipoId={3}
              permissions={permissions}
            />
          ),
        },
      ],
    };

    menuData.push(reports);

    setListaPermissoes(menuData);
  }, [permissions, allPermissions]);

  const handleSetInput = useCallback(
    (e, p, cadastroTipoId) => {
      const permissionName =
        p.permission.substr(0, p.permission.length - 3) + `[${cadastroTipoId}]`;

      let addListPermission = "";

      if (
        permissionName.indexOf("store") !== -1 ||
        permissionName.indexOf("update") !== -1 ||
        permissionName.indexOf("show") !== -1 ||
        permissionName.indexOf("destroy") !== -1 ||
        permissionName.indexOf("redefinirsenha") !== -1 ||
        permissionName.indexOf("aprovar") !== -1 ||
        permissionName.indexOf("redefinirsenha") !== -1 ||
        permissionName.indexOf("excel") !== -1
      ) {
        addListPermission = permissionName
          .replace("store", "list")
          .replace("update", "list")
          .replace("show", "list")
          .replace("destroy", "list")
          .replace("redefinirsenha", "list")
          .replace("aprovar", "list")
          .replace("reprovar", "list")
          .replace("excel", "list");
      }

      if (e.checked) {
        setPermissions((permissions) => {
          if (addListPermission) {
            return [...permissions, permissionName, addListPermission];
          } else {
            return [...permissions, permissionName];
          }
        });
      } else {
        setPermissions((permissions) =>
          permissions.filter((p) => p !== permissionName)
        );

        if (checkBoxAll) {
          var arr = [];
          var check = checkBoxAll.forEach((checkbox) => {
            if (
              checkbox?.indexOf(
                p.permission.substr(0, p.permission.length - 3).split("-")[0]
              ) === -1
            ) {
              arr.push(checkbox);
              return checkbox;
            } else {
              return null;
            }
          });

          check = check?.filter((i) => i !== undefined) || [];

          setCheckBoxAll(check);
        }
      }
    },
    [checkBoxAll]
  );

  const handleClickCheckbox = useCallback(
    (e, permissionObj, cadastroTipoId) => {
      try {
        if (e.checked === true) {
          setCheckBoxAll((checkbox) => [
            ...checkbox,
            permissionObj.permission + cadastroTipoId,
          ]);

          const x = e.parentNode.parentNode.getElementsByTagName("div");

          const indice = Object.keys(x);

          // Marca Todos
          indice.map((item) => {
            if (x[item].childNodes[0].value !== "on") {
              setPermissions((permissions) => [
                ...permissions,
                x[item].childNodes[0].value,
              ]);
            }
            return (x[item].childNodes[0].checked = true);
          });
        }

        // Desmarca Todos
        if (e.checked === false) {
          setCheckBoxAll((checkbox) =>
            checkbox.filter(
              (p) => p !== permissionObj.permission + cadastroTipoId
            )
          );

          const x = e.parentNode.parentNode.getElementsByTagName("div") || [];

          const indice = Object.keys(x);

          indice.map((item) => {
            setPermissions((permissions) =>
              permissions.filter((p) => p !== x[item].childNodes[0].value)
            );

            return (x[item].childNodes[0].checked = false);
          });
        }
      } catch (error) {
        console.log(error);
      }
    },
    []
  );

  const Permissions = useCallback(
    ({ data, cadastroTipoId, permissions }) => {
      return (
        <CheckForm>
          <main style={{ backgroundColor: "transparent" }}>
            {data.length > 0 &&
              data.map((permissionObj, index) => {
                let labelPermission = "";

                const namePermission =
                  permissionObj.permission.substr(
                    0,
                    permissionObj.permission.length - 3
                  ) + `[${cadastroTipoId}]`;

                const checkNamePermission = permissionObj.permission.substr(
                  0,
                  permissionObj.permission.length - 3
                );

                if (
                  checkNamePermission === "pedido-store" ||
                  checkNamePermission === "pedido-update"
                ) {
                  // Ajustes de Nome Especificos solicitado pelo cliente.
                  switch (
                    permissionObj.permission
                      .replace(`${permissionObj.name}-`, "")
                      .substr(
                        0,
                        permissionObj.permission.replace(
                          `${permissionObj.name}-`,
                          ""
                        ).length - 3
                      )
                  ) {
                    case "store":
                      labelPermission = <FormattedMessage id="novoPedido" />;
                      break;
                    case "update":
                      labelPermission = <FormattedMessage id="editarPedido" />;
                      break;
                    default:
                      labelPermission = permissionObj.permission.substr(
                        0,
                        permissionObj.permission.length - 3
                      );
                      break;
                  }
                } else {
                  // Nome Padrões
                  switch (
                    permissionObj.permission
                      .replace(`${permissionObj.name}-`, "")
                      .substr(
                        0,
                        permissionObj.permission.replace(
                          `${permissionObj.name}-`,
                          ""
                        ).length - 3
                      )
                  ) {
                    case "list":
                      labelPermission = <FormattedMessage id="listar" />;
                      break;
                    case "store":
                      labelPermission = <FormattedMessage id="cadastrar" />;
                      break;
                    case "show":
                      labelPermission = <FormattedMessage id="exibir" />;
                      break;
                    case "update":
                      labelPermission = <FormattedMessage id="editar" />;
                      break;
                    case "destroy":
                      labelPermission = <FormattedMessage id="excluir" />;
                      break;
                    case "redefinirsenha":
                      labelPermission = (
                        <FormattedMessage id="redefinirSenha" />
                      );
                      break;
                    case "excel":
                      labelPermission = <FormattedMessage id="exportarExcel" />;
                      break;
                    case "importar":
                      labelPermission = <FormattedMessage id="importar" />;
                      break;
                    case "filter":
                      labelPermission = <FormattedMessage id="filtrar" />;
                      break;
                    case "aprovar":
                      labelPermission = <FormattedMessage id="aprovarPedido" />;
                      break;
                    case "reprovar":
                      labelPermission = (
                        <FormattedMessage id="reprovarPedido" />
                      );
                      break;
                    case "liberar":
                      labelPermission = <FormattedMessage id="liberarPedido" />;
                      break;
                    case "cancelar":
                      labelPermission = (
                        <FormattedMessage id="cancelarPedido" />
                      );
                      break;
                    //Cadastro
                    case "dadoscadastrais":
                      labelPermission = (
                        <FormattedMessage id="dadosCadastrais" />
                      );
                      break;
                    case "dadosendereco":
                      labelPermission = <FormattedMessage id="dadosEndereco" />;
                      break;
                    case "dadosentrega":
                      labelPermission = <FormattedMessage id="dadosEntrega" />;
                      break;
                    case "dadoscobranca":
                      labelPermission = <FormattedMessage id="dadosCobranca" />;
                      break;
                    case "consumocapacidade":
                      labelPermission = (
                        <FormattedMessage id="dadosCapacidade" />
                      );
                      break;
                    case "certificadodigitalnfe":
                      labelPermission = (
                        <FormattedMessage id="editarCertificado" />
                      );
                      break;
                    case "cliente-certificadodigitalnfe":
                      labelPermission = (
                        <FormattedMessage id="editarCertificado" />
                      );
                      break;
                    case "basesdistribuicao":
                      labelPermission = (
                        <FormattedMessage id="basesDistribuicao" />
                      );
                      break;
                    // Dashboard
                    case "cliente":
                      labelPermission = "Exibir Dashboard";
                      break;
                    case "home":
                      labelPermission = "Exibir Cards";
                      break;
                    //Relatórios
                    case "cadastroclientes":
                      labelPermission = (
                        <FormattedMessage id="cadastroCliente" />
                      );
                      break;
                    case "cadastrodistribuidorestrrs":
                      labelPermission = (
                        <FormattedMessage id="cadastroDistribuidorTRR" />
                      );
                      break;
                    case "condicoescomerciaisclientes":
                      labelPermission = (
                        <FormattedMessage id="condicaoComercialCliente" />
                      );
                      break;
                    case "logs":
                      labelPermission = "Logs";
                      break;
                    case "precomedio":
                      labelPermission = (
                        <FormattedMessage id="precoMedioCombustiveis" />
                      );
                      break;
                    case "historiconegociacoes":
                      labelPermission = (
                        <FormattedMessage id="historicoNegociacaoDistribuidor" />
                      );
                      break;
                    case "saving":
                      labelPermission = "Saving";
                      break;
                    case "pedidosdetalhados":
                      labelPermission = (
                        <FormattedMessage id="pedidoDetalhado" />
                      );
                      break;
                    case "reembolso":
                      labelPermission = (
                        <FormattedMessage id="extratoReembolsoDistribuidor" />
                      );
                      break;
                    default:
                      labelPermission = permissionObj.permission.substr(
                        0,
                        permissionObj.permission.length - 3
                      );
                      break;
                  }
                }
                let checkAll = true;
                let checkValue = permissions.filter(
                  (p) => p === namePermission
                );

                // remove duplicado
                var noDuplicateArray = permissions.filter(
                  (item, i) => permissions.indexOf(item) === i
                );

                let checkArray = [];

                data.map((item) => {
                  const t = noDuplicateArray.find((i) => i === item.permission);

                  if (t) {
                    checkArray.push(t);
                  }

                  return null;
                });

                checkAll = checkBoxAll.find(
                  (item) => item === permissionObj.permission + cadastroTipoId
                );

                if (checkAll) {
                  checkAll = true;
                } else {
                  checkAll = false;
                }

                if (checkValue?.length > 0) {
                  checkValue = true;
                } else {
                  checkValue = false;
                }

                // Bloqueia Permissões que usuário não tem acesso

                // Valida caso o usuário não seja MASTER

                // var verifyPermission = "";
                // var checkPermission = true;

                // if (user?.tipo_id !== 1) {
                //   verifyPermission = permissionObj.permission.substr(
                //     0,
                //     permissionObj.permission.length - 3
                //   );

                //   console.log(verifyPermission);

                //   checkPermission = user?.permission?.find(
                //     (item) =>
                //       item.namePermission.indexOf(verifyPermission) !== -1
                //   );

                //   console.log("check", checkPermission);

                //   return checkPermission;
                // }

                // **********************************************

                const CheckAll = () => (
                  <div>
                    <input
                      type="checkbox"
                      defaultChecked={checkAll}
                      onClick={(e) =>
                        handleClickCheckbox(
                          e.target,
                          permissionObj,
                          cadastroTipoId
                        )
                      }
                    />
                    <label>
                      <FormattedMessage id="todos" />
                    </label>
                  </div>
                );

                const CheckOne = ({ hasCheckAll }) => (
                  <>
                    {hasCheckAll === 0 && <CheckAll />}
                    {/* {checkPermission ? ( */}
                    <div>
                      <input
                        type="checkbox"
                        name={namePermission}
                        defaultChecked={checkValue}
                        onClick={(e) =>
                          handleSetInput(
                            e.target,
                            permissionObj,
                            cadastroTipoId
                          )
                        }
                        value={namePermission}
                      />
                      <label>{labelPermission}</label>
                    </div>
                    {/* ) : (
                     <div>
                       <input
                         type="checkbox"
                         name={namePermission}
                         disabled={true}
                       />
                       <label>{labelPermission}</label>
                     </div>
                   )} */}
                  </>
                );
                // Retorna as permissoes nos inputs
                return <CheckOne key={index} hasCheckAll={index} />;
              })}
          </main>
        </CheckForm>
      );
    },
    [checkBoxAll, handleClickCheckbox, handleSetInput]
  );

  const handleBackButton = useCallback(() => {
    history.goBack();
  }, [history]);

  const handleEdit = useCallback(async () => {
    setLoadingButton(true);

    if (!empresa) {
      setLoadingButton(false);
      return toast.warn("Nenhuma empresa selecionada!");
    }

    const cadastroId = empresa && empresa.map((item) => item.value);

    const data = {
      cadastro: cadastroId,
      name: label,
      description: descricao,
      permission: permissions,
      ativo: status.value,
    };

    try {
      await api.put(`/role/${idPerfil}`, data);
      toast.success("Perfil editado com sucesso.");
      history.goBack();
      setLoadingButton(false);
    } catch (error) {
      setErrors(getErrorsApiValidation(error));

      const err = getErrorsApiValidation(error);

      if (err?.permission) {
        toast.warn("Por favor insira as permissões");
      } else {
        toast.error("Erro ao cadastrar perfil");
      }
      setLoadingButton(false);
    }
  }, [empresa, label, descricao, permissions, status.value, idPerfil, history]);

  return (
    <Container>
      {/* <button onClick={handleteste}>Entrar</button> */}
      <CreateForm>
        <header>
          <h4>
            <FormattedMessage id="editarPerfilAcessoCliente" />
          </h4>
        </header>
        {loading ? (
          <ContentSpinner />
        ) : (
          <form>
            <AsyncListSelect
              value={empresa}
              myOptions={"clientes"}
              isMulti
              cacheOptions
              defaultOptions
              labelPlaceholder={<FormattedMessage id="empresa" />}
              placeholder={<FormattedMessage id="selecionar" />}
              loadingMessage={() => <FormattedMessage id="carregando" />}
              geterror={errors?.cadastro}
              onChange={(e) => setEmpresa(e)}
              // isDisabled={true}
            />

            <InputLabel
              name="name"
              type="text"
              maxLength={100}
              label={<FormattedMessage id="nome" />}
              value={label}
              onChange={(e) => setLabel(e.target.value)}
              geterror={errors?.name}
            />

            <InputLabel
              name="descricao"
              type="text"
              value={descricao}
              maxLength={200}
              label={<FormattedMessage id="descricao" />}
              onChange={(e) => setDescricao(e.target.value)}
              geterror={errors?.description}
            />

            <ListSelect
              labelPlaceholder={"Status"}
              onChange={(e) => setStatus(e)}
              options={statusOptions}
              placeholder={selecionarPlaceholder}
              value={status}
            />

            <hr />

            <TitlePermission>Menu</TitlePermission>

            <FormPermission>
              <TreeComponent data={listaPermissoes} />
            </FormPermission>
          </form>
        )}

        <hr />

        <CreateFooter>
          <div>
            <Button type="button" themeType="back" onClick={handleBackButton}>
              <FormattedMessage id="voltar" />
            </Button>
          </div>
          <div>
            <Button type="submit" onClick={handleEdit} loading={loadingButton}>
              <FormattedMessage id="salvar" />
            </Button>
          </div>
        </CreateFooter>
      </CreateForm>
    </Container>
  );
};

export default injectIntl(EditAccessProfiles);
