import React, { useCallback, useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { FormattedMessage, injectIntl } from "react-intl";

import { Container, CreateForm, CreateFooter } from "./styles";

import InputLabel from "./../../../components/InputLabel";
import Button from "./../../../components/ButtonDefault";
import { toast } from "react-toastify";
import api from "../../../services/api";
import { getErrorsApiValidation } from "../../../utils/getErrorsValidation";

export const ChangePassword = ({ intl }) => {
  const [loadingButton, setLoadingButton] = useState(false);
  const [errors, setErrors] = useState(null);

  const [id, setId] = useState("");
  const [nome, setNome] = useState("");
  const [email, setEmail] = useState("");
  const [senha, setSenha] = useState("");
  const [confirmaSenha, setConfirmaSenha] = useState("");

  const history = useHistory();

  useEffect(() => {
    if (!history.location.state?.id) {
      return history.push("/access/users/sodexo/list");
    }
    setId(history.location.state?.id);
    setNome(history.location.state?.name);
    setEmail(history.location.state?.email);
  }, [history]);

  const passwordPlaceholder = intl.formatMessage({ id: "senha" });
  const passwordConfirmationPlaceholder = intl.formatMessage({
    id: "confirmacaoSenha",
  });

  const handleChangePassword = useCallback(async () => {
    setLoadingButton(true);

    if (senha !== confirmaSenha) {
      toast.error(<FormattedMessage id="asPasswordsNaoCorrespondem" />);
      setLoadingButton(false);
      return;
    }

    try {
      await api.put(`/usuario/passwordupdate/${id}`, {
        password: senha,
      });

      setSenha("");
      setConfirmaSenha("");
      toast.success(<FormattedMessage id="senhaAlteradaComSucesso" />);
      setLoadingButton(false);
    } catch (error) {
      if (error.response.data.erro_email) {
        setSenha("");
        setConfirmaSenha("");
        setLoadingButton(false);
        toast.success(<FormattedMessage id="senhaAlteradaComSucesso" />);
        toast.error("Erro ao enviar o e-mail");
        return;
      }

      setErrors(getErrorsApiValidation(error));
      toast.error(<FormattedMessage id="erroAlterarSenha" />);
      setLoadingButton(false);
      return;
    }
  }, [id, senha, confirmaSenha]);

  const handleBackButton = useCallback(() => {
    history.goBack();
  }, [history]);

  return (
    <Container>
      <CreateForm>
        <header>
          <h4>
            <FormattedMessage id="redefinirSenha" />
          </h4>
        </header>

        <form>
          <InputLabel
            value={nome}
            name="name"
            type="text"
            label={<FormattedMessage id="nome" />}
            readOnly
          />
          <InputLabel
            value={email}
            name="email"
            type="text"
            label="E-mail"
            readOnly
          />

          <InputLabel
            name="password"
            type="password"
            label={passwordPlaceholder}
            value={senha}
            onChange={(e) => setSenha(e.target.value)}
            geterror={errors?.password}
          />
          <InputLabel
            name="password_confirmation"
            type="password"
            label={passwordConfirmationPlaceholder}
            value={confirmaSenha}
            onChange={(e) => setConfirmaSenha(e.target.value)}
          />

          <CreateFooter>
            <div>
              <Button type="button" themeType="back" onClick={handleBackButton}>
                <FormattedMessage id="voltar" />
              </Button>
            </div>
            <div>
              <Button
                type="button"
                onClick={handleChangePassword}
                loading={loadingButton}
              >
                <FormattedMessage id="salvar" />
              </Button>
            </div>
          </CreateFooter>
        </form>
        <hr />
      </CreateForm>
    </Container>
  );
};

export default injectIntl(ChangePassword);
