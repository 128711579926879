import React, { useCallback, useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import MaskInput from "./../../../config/input-mask";

import { FormattedMessage, injectIntl } from "react-intl";

import { Container, CreateForm, CreateFooter } from "./styles";

import InputLabel from "./../../../components/InputLabel";
import Button from "./../../../components/ButtonDefault";

export const DisplayUser = ({ intl }) => {
  const [data, setData] = useState({});

  const history = useHistory();

  //Placeholders internacionalizados
  const empresaPlaceholder = intl.formatMessage({ id: "empresa" });
  const nivelPlaceholder = intl.formatMessage({ id: "nivel" });
  const perfilPlaceholder = intl.formatMessage({ id: "perfil" });

  useEffect(() => {
    if (!history.location.state?.id) {
      return history.push("/access/users/sodexo/list");
    }
    setData(history.location.state);
  }, [history]);

  const handleCreateUser = useCallback(async (e) => {
    e.preventDefault();
  }, []);

  const handleBackButton = useCallback(() => {
    history.goBack();
  }, [history]);

  return (
    <Container>
      <CreateForm>
        <header>
          <h4>Exibir Usuário</h4>
        </header>

        <form onSubmit={handleCreateUser}>
          <InputLabel
            name="name"
            type="text"
            label={empresaPlaceholder}
            value={data?.nome_razao_social || ""}
            readOnly
          />
          <InputLabel
            name="cnpj"
            type="text"
            label="CNPJ"
            value={data?.cnpj || ""}
            readOnly
            mask={MaskInput.CNPJ}
          />
          <InputLabel
            name="name"
            type="text"
            label={<FormattedMessage id="nome" />}
            value={data?.name || ""}
            readOnly
          />
          <InputLabel
            name="email"
            type="text"
            label="E-mail"
            value={data?.email || ""}
            readOnly
          />

          <InputLabel
            name="celular"
            label={<FormattedMessage id="celular" />}
            type="text"
            mask={MaskInput.cellphone}
            value={data?.celular || ""}
            readOnly
          />
          <InputLabel
            name="telefone"
            label={<FormattedMessage id="telefone" />}
            type="text"
            mask={MaskInput.phone}
            value={data?.telefone || ""}
            readOnly
          />
          <InputLabel
            name="nivel"
            label={nivelPlaceholder}
            type="text"
            value={data?.nivel || ""}
            readOnly
          />

          {data.perfil !== "" ? (
            <InputLabel
              name="perfil"
              label={perfilPlaceholder}
              type="text"
              value={data?.perfil || ""}
              readOnly
            />
          ) : (
            ""
          )}

          <InputLabel
            name="status"
            label="Status"
            type="text"
            value={data.ativo || ""}
            readOnly
          />

          <CreateFooter>
            <div>
              <Button type="button" themeType="back" onClick={handleBackButton}>
                <FormattedMessage id="voltar" />
              </Button>
            </div>
          </CreateFooter>
        </form>
        <hr />
      </CreateForm>
    </Container>
  );
};

export default injectIntl(DisplayUser);
