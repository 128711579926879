import React, {
  useState,
  useCallback,
  useContext,
  useEffect,
  useRef,
} from "react";
import { Container, ButtonIcon } from "./styles";

import { FiXCircle } from "react-icons/fi";
import { FaFileInvoice } from "react-icons/fa";
import { toast } from "react-toastify";

import AuthContext from "./../../context/auth";
import Button from "./../ButtonDefault";
import api from "./../../services/api";
import { FormattedMessage } from "react-intl";

const UploadNFServico = ({ idCotacao, hasNF }) => {
  const [show, setShow] = useState(false);
  const [hasNFState, setHasNFState] = useState(() => hasNF);
  const [loadingUpload, setLoadingUpload] = useState(false);
  const [loadingDownload, setLoadingDownload] = useState(false);
  const [file, setFile] = useState(null);
  const [numeroNF, setNumeroNF] = useState("");

  const { user } = useContext(AuthContext);

  const wrapperRef = useRef(null);

  useEffect(() => {
    // Verifica se foi clicado fora do container
    function handleClickOutside(event) {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        setShow(false);
      }
    }
    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [wrapperRef]);

  const handleUploadNotaServivo = useCallback(async () => {
    setLoadingUpload(true);

    if (!numeroNF) {
      setLoadingUpload(false);
      toast.warning("Informe o número da nota.");
      return;
    }

    if (!file) {
      setLoadingUpload(false);
      return toast.warning("Nenhum arquivo selecionado");
    }

    var formData = new FormData();
    formData.append("nota", file);
    formData.append("numero_nfe_cliente", numeroNF);

    try {
      await api.post(`/cotacao/${idCotacao}/upload-nota-cliente`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      toast.success("Upload feito com sucesso!");
      setLoadingUpload(false);
      setHasNFState("nf");
    } catch (error) {
      if (error.response.data.error) {
        Object.keys(error.response.data.error).map((erro) => {
          return toast.error(error.response.data.error[erro].toString());
        });
      } else {
        toast.error("Erro ao fazer o upload");
      }

      setLoadingUpload(false);
      setHasNFState(null);
    }

    setShow(false);
  }, [file, idCotacao, numeroNF]);

  // const handleDownload = useCallback(async () => {
  //   setLoadingDownload(true);
  //   try {
  //     const download = await api.get(
  //       `/cotacao/${idCotacao}/download-nota-cliente`,
  //       {
  //         responseType: "blob",
  //       }
  //     );

  //     const downloadUrl = window.URL.createObjectURL(new Blob([download.data]));

  //     const link = document.createElement("a");

  //     link.href = downloadUrl;

  //     link.setAttribute("download", "Nota Fiscal Serviço.pdf"); //any other extension

  //     document.body.appendChild(link);

  //     link.click();

  //     link.remove();

  //     toast.success("Download feito com sucesso!");
  //     setLoadingDownload(false);
  //   } catch (error) {
  //     console.log(error);
  //     toast.error("Erro ao fazer o download");
  //     setLoadingDownload(false);
  //   }
  // }, [idCotacao]);

  return (
    <>
      {show ? (
        <Container ref={wrapperRef}>
          <header>
            <h5>
              <FormattedMessage id="notaServiço" />
            </h5>
            <FiXCircle onClick={() => setShow(!show)} />
          </header>

          {user.cadastro_tipo_id === 1 ? (
            <div>
              <div>
                <header>
                  <strong>Número NF</strong>
                  <input
                    type="text"
                    onChange={(e) => setNumeroNF(e.target.value)}
                    value={numeroNF}
                    maxLength={15}
                  />
                </header>
                <div>
                  <label htmlFor="selecao-arquivo">
                    <FormattedMessage id="selecioneArquivo" />
                  </label>
                  <input
                    id="selecao-arquivo"
                    type="file"
                    onChange={(e) => {
                      setFile(e.target.files[0]);
                    }}
                  ></input>

                  <Button
                    onClick={handleUploadNotaServivo}
                    loading={loadingUpload}
                  >
                    <FormattedMessage id="fazerUpload" />
                  </Button>
                </div>
              </div>
              <span>{file ? file.name : ""}</span>
            </div>
          ) : (
            <div></div>
          )}

          {hasNFState ? (
            // <div>
            //   <Button onClick={handleDownload} loading={loadingDownload}>
            //     Download Nfe
            //   </Button>
            // </div>
            <div>
            <a href={"https://wizeo-public-images.s3.sa-east-1.amazonaws.com/trade/public/cliente/nota/" + hasNF.toString()} target="_blank">
              <Button
                // onClick={handleDownload}
                loading={loadingDownload}
                >
                Download Nota Serviço
              </Button>
            </a>
          </div>
          ) : (
            <div></div>
          )}
        </Container>
      ) : (
        <ButtonIcon colorIcon={hasNFState}>
          <FaFileInvoice onClick={() => setShow(!show)} />
        </ButtonIcon>
      )}
    </>
  );
};

export default UploadNFServico;
