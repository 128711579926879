import React, { useCallback, useState } from "react";
import { Link } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import * as Yup from "yup";

import { getErrorsValidation } from "./../../utils/getErrorsValidation";

import api from "./../../services/api";

import Input from "./../../components/InputDefault";
import Button from "./../../components/ButtonDefault";

import { Container, SignContainer, Background, Separator } from "./styles";

import Language from "./../../components/header/dropdown/language";

import SodexoLogo from "./../../assets/images/Sodexo-logo.png";
import BackgroundImage from "./../../assets/images/background2.png";
import { toast } from "react-toastify";

const SetEmailRecovery = () => {
  let search = window.location.search;
  let params = new URLSearchParams(search);

  const [email, setEmail] = useState("");
  const [token] = useState(() => {
    return params.get("token");
  });
  const [new_password, setNewPassword] = useState("");
  const [errors, setErrors] = useState();
  const [loadingButton, setLoadButton] = useState(false);

  const handleRecoveryPassword = useCallback(async () => {
    //Validando campos

    try {
      const schema = Yup.object().shape({
        email: Yup.string()
          .required(<FormattedMessage id="emailObrigatorio" />)
          .email(<FormattedMessage id="emailInvalido" />),
      });

      await schema.validate(
        { email },
        {
          abortEarly: false,
        }
      );
    } catch (error) {
      return setErrors(getErrorsValidation(error));
    }

    try {
      setLoadButton(true);
      setErrors("");
      await api.get(
        "/resetarsenha?email=" +
          email +
          "&token=" +
          token +
          "&new_password=" +
          new_password
      );
      toast.success("Senha alterada com sucesso!!!", { autoClose: 5000 });
      setEmail("");
      setNewPassword("");
      setLoadButton(false);
    } catch (error) {
      setLoadButton(false);
      return toast.error("Erro ao redefinir a senha. Tente novamente.");
    }
  }, [email, token, new_password]);

  return (
    <Container>
      <Background>
        <img src={BackgroundImage} alt="img" />
      </Background>
      <SignContainer>
        <div>
          <img src={SodexoLogo} alt="Sodexo" />
          <header>
            <h5>
              <FormattedMessage id="encontreSuaSenha" />
            </h5>
            <Language />
          </header>
          <p>Redefinir Senha</p>

          <Input
            placeholder="E-mail"
            type="text"
            maxLength="80"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            geterror={errors?.email}
          />

          <Input
            placeholder="Nova Senha"
            type="password"
            maxLength="80"
            value={new_password}
            onChange={(e) => setNewPassword(e.target.value)}
            geterror={errors?.email}
          />

          <Button
            type="button"
            onClick={handleRecoveryPassword}
            loading={loadingButton}
          >
            <FormattedMessage id="enviar" />
          </Button>

          <footer>
            <p>
              <FormattedMessage id="volteLogin" />{" "}
              <Link to="/signin">
                {" "}
                <FormattedMessage id="cliqueAqui" />
              </Link>
            </p>
          </footer>

          <Separator />

          <span>
            <FormattedMessage id="copyright" />
          </span>
        </div>
      </SignContainer>
    </Container>
  );
};

export default SetEmailRecovery;
