import React, {
  useState,
  //useCallback,
  // useContext,
  useEffect,
  useRef,
} from "react";
import { Container, ButtonIcon } from "./styles";

import { FiXCircle } from "react-icons/fi";
import { FaFileInvoice } from "react-icons/fa";

//import AuthContext from "./../../context/auth";
import Button from "./../ButtonDefault";
//import api from "./../../services/api";

const DownloadBoleto = ({ idCotacao, hasBoleto }) => {
  const [show, setShow] = useState(false);
  const [hasBoletoState] = useState(() => hasBoleto);

  const [loadingDownload, setLoadingDownload] = useState(false);

  //const { user } = useContext(AuthContext);

  const wrapperRef = useRef(null);

  useEffect(() => {
    // Verifica se foi clicado fora do container
    function handleClickOutside(event) {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        setShow(false);
      }
    }
    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [wrapperRef]);

  /*const handleDownload = useCallback(async () => {
    setLoadingDownload(true);
    try {
      const download = await api.get(
        `/cotacao/${idCotacao}/download-boleto-cliente`,
        {
          responseType: "blob",
        }
      );

      const downloadUrl = window.URL.createObjectURL(new Blob([download.data]));

      const link = document.createElement("a");

      link.href = downloadUrl;

      link.setAttribute("download", "Boleto.pdf"); //any other extension

      document.body.appendChild(link);

      link.click();

      link.remove();

      setLoadingDownload(false);
    } catch (error) {
      console.log(error);
      setLoadingDownload(false);
    }
  }, [idCotacao]);*/

  return (
    <>
      {show ? (
        <Container ref={wrapperRef}>
          <header>
            <h5>Boleto</h5>
            <FiXCircle onClick={() => setShow(!show)} />
          </header>

          {hasBoletoState ? (
            <div>
              <a href={"https://wizeo-public-images.s3.sa-east-1.amazonaws.com/trade/public/cliente/boleto/" + hasBoleto.toString()} target="_blank">
                <Button
                  // onClick={handleDownload}
                  loading={loadingDownload}
                  >
                  Download Boleto
                </Button>
              </a>
            </div>
          ) : (
            <div></div>
          )}
        </Container>
      ) : (
        <ButtonIcon colorIcon={hasBoleto}>
          <FaFileInvoice onClick={() => setShow(!show)} />
        </ButtonIcon>
      )}
    </>
  );
};

export default DownloadBoleto;
