import React, { useCallback, useState } from "react";

import { useHistory } from "react-router-dom";
import { FormattedMessage, injectIntl } from "react-intl";

import ListSelect from "./../../../components/ListSelect";
import InputLabel from "./../../../components/InputLabel";

import api from "./../../../services/api";
import { Container, CreateForm, CreateFooter } from "./styles";

import Button from "./../../../components/ButtonDefault";

import AsyncListSelect from "../../../components/ListSelect/AsyncListSelect";
import { toast } from "react-toastify";

// Relatório de preço médio
export const ReportAveragePrice = ({ intl }) => {
  const [dataInicial, setDataInicial] = useState("");
  const [dataFinal, setDataFinal] = useState("");

  const [distribuidor, setDistribuidor] = useState("");
  const [produto, setProduto] = useState([]);
  const [baseDistribuicao, setBaseDistribuicao] = useState("");
  const [uf, setUf] = useState("");
  const [cidade, setCidade] = useState("");
  const [cidadeBasesDist, setCidadeBasesDist] = useState("");
  const [tipoRelatorio, setTipoRelatorio] = useState();
  const [listaCidadesBasesDist, setListaCidadesBasesDist] = useState(
    "cidadeBasesDist"
  );

  const [loadingButton, setLoadingButton] = useState(false);

  const history = useHistory();

  const todosPlaceholder = intl.formatMessage({
    id: "todos",
  });

  const tipoRelatorioOptions = [
    { value: "distribuidor", label: <FormattedMessage id="distribuidor" /> },
    { value: "base", label: <FormattedMessage id="base" /> },
    { value: "cidade", label: <FormattedMessage id="cidade" /> },
    { value: "estado", label: <FormattedMessage id="estado" /> },
  ];

  const handleBackButton = useCallback(() => {
    history.goBack();
  }, [history]);

  const handleCreate = useCallback(async () => {
    setLoadingButton(true);

    let produtoFormmated;
    if (produto.length > 0) {
      produtoFormmated = produto.map((prod) => prod.value);
    }

    const checkedTipoRelatorio = !tipoRelatorio?.value ? "" : tipoRelatorio?.value;

    if (checkedTipoRelatorio === "") {
      toast.error("Selecionar um tipo de relatório");
      setLoadingButton(false);
      return
    }

    try {
      const data = {
        dataDe: dataInicial,
        dataAte: dataFinal,
        saida: "excel",
        filtroCliente: distribuidor?.value || "",
        filtroProduto: produtoFormmated || "",
        baseDistribuicao: baseDistribuicao?.value || "",
        estadoUF: uf?.label || "",
        cidade: cidade?.value || "",
        tipo_relatorio: tipoRelatorio.value,
      };

        await api
          .get(`/relatorio/preco-medio-faturado`, {
            params: data,
            responseType: "blob",
          })
          .then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", "Relatorio-Preco-Medio-Faturados.xlsx");
            document.body.appendChild(link);
            link.click();

            toast.success(<FormattedMessage id="relatorioCriadoComSucesso" />);
          })
          .catch((err) => console.log(err));

      setLoadingButton(false);
    } catch (error) {
      setLoadingButton(false);
      console.log(error);

      toast.error(<FormattedMessage id="errorExportarRelatorio" />);
    }
  }, [
    baseDistribuicao,
    cidade,
    dataFinal,
    dataInicial,
    distribuidor,
    produto,
    uf,
    tipoRelatorio
  ]);

  const handleLoadUF = useCallback(async () => {
    try {
      const { data } = await api.get(`/estado`);

      const arr = data.map((uf) => {
        const obj = {};

        Object.assign(obj, {
          value: String(uf.id),
          label: uf.sigla,
        });

        return obj;
      });

      return arr;
    } catch (error) {}
  }, []);

  const handleFilterCitiesByUf = useCallback(async (id) => {
    if (!id) {
      return;
    }

    try {
      const { data } = await api.post(`/municipio/filter`, {
        estado: id,
      });

      const arr = data.map((municipio) => {
        const obj = {};

        Object.assign(obj, {
          value: String(municipio.id),
          label: municipio.nome,
        });

        return obj;
      });

      return arr;
    } catch (error) {}
  }, []);

  return (
    <Container>
      <CreateForm>
        <header>
          <h4>
            Preço Médio Produto Faturado
          </h4>
        </header>

        <form>
          <div className="sideBySide">
            <InputLabel
              name="date"
              label={<FormattedMessage id="dataInicio" />}
              type="date"
              style={{ color: "hsl(0,0%,50%)" }}
              value={dataInicial}
              onChange={(e) => setDataInicial(e.target.value)}
            />

            <AsyncListSelect
              value={distribuidor}
              labelPlaceholder={<FormattedMessage id="distribuidores" />}
              placeholder={todosPlaceholder}
              onChange={(e) => {
                setDistribuidor(e);
              }}
              myOptions={"distribuidores"}
              cacheOptions
              defaultOptions
              isClearable
            />

            <AsyncListSelect
              name="uf"
              labelPlaceholder={"UF"}
              placeholder={<FormattedMessage id="selecionar" />}
              loadOptions={handleLoadUF}
              cacheOptions
              defaultOptions
              value={uf}
              onChange={(e) => {
                setCidadeBasesDist("");
                setListaCidadesBasesDist(e?.label);
                setUf(e)
              }}
              isClearable
            />

            <AsyncListSelect
              value={baseDistribuicao}
              labelPlaceholder={<FormattedMessage id="baseDistribuicao" />}
              placeholder={todosPlaceholder}
              onChange={(e) => {
                setBaseDistribuicao(e);
              }}
              myOptions={"basesDistribuição"}
              cacheOptions
              defaultOptions
              isClearable
            />
          </div>

          <div className="sideBySideTwo">
            <InputLabel
              name="date"
              label={<FormattedMessage id="dataFim" />}
              type="date"
              style={{ color: "hsl(0,0%,50%)" }}
              value={dataFinal}
              onChange={(e) => setDataFinal(e.target.value)}
            />

            <AsyncListSelect
              value={produto}
              labelPlaceholder={<FormattedMessage id="produto" />}
              placeholder={<FormattedMessage id="selecionar" />}
              onChange={(e) => {
                setProduto(e);
              }}
              myOptions={"produtos"}
              cacheOptions
              defaultOptions
              isMulti
            />

              <AsyncListSelect
                key={listaCidadesBasesDist}
                value={cidadeBasesDist}
                cacheOptions
                defaultOptions
                loadOptions={() => {
                  return handleFilterCitiesByUf(listaCidadesBasesDist);
                }}
                onChange={(e) => {
                  setCidadeBasesDist(e);
                  setCidade(e);
                }}
                labelPlaceholder={<FormattedMessage id="cidade" />}
                placeholder={<FormattedMessage id="selecionar" />}
                noOptionsMessage={() => (
                  <FormattedMessage id="selecioneEstadoCarregar" />
                )}
                loadingMessage={() => <FormattedMessage id="carregando" />}
                isSearchable={false}
              />

            <ListSelect
              labelPlaceholder={<FormattedMessage id="Tipo Relatório" />}
              placeholder={<FormattedMessage id="selecione" />}
              cacheOptions
              defaultOptions
              options={tipoRelatorioOptions}
              onChange={(e) => {
                setTipoRelatorio(e);
              }}
              value={tipoRelatorio}
            />
          </div>
        </form>
        <hr />

        <CreateFooter>
          <div>
            <Button type="button" themeType="back" onClick={handleBackButton}>
              <FormattedMessage id="voltar" />
            </Button>
          </div>
          <div>
            <Button
              type="button"
              onClick={handleCreate}
              loading={loadingButton}
            >
              {<FormattedMessage id="gerar" />}
            </Button>
          </div>
        </CreateFooter>
      </CreateForm>
    </Container>
  );
};

export default injectIntl(ReportAveragePrice);
