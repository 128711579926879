import React, { useCallback, useState, useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";
import { Tabs, TabList, TabPanel } from "react-tabs";
import { FormattedMessage, injectIntl } from "react-intl";
import { toast } from "react-toastify";
import AuthContext from "./../../../context/auth";

import { FiAlertCircle } from "react-icons/fi";

import ButtonIcon from "./../../../components/ButtonIcon";

import api from "./../../../services/api";
import { searchCep } from "./../../../services/cep";

import MaskInput from "./../../../config/input-mask";
import { getErrorsApiValidation } from "./../../../utils/getErrorsValidation";
import { Container, CreateForm, CreateFooter, TabStyled } from "./styles";

import InputLabel from "./../../../components/InputLabel";
import Button from "./../../../components/ButtonDefault";
import ListSelect from "./../../../components/ListSelect";
import AsyncListSelect from "./../../../components/ListSelect/AsyncListSelect";
import InputMaskDefault from "./../../../components/InputMaskDefault";
import MessageDefault from "./../../../components/MessageDefault";
import LoadingContent from "./../../../components/Spinner/Content";

export const EditDistributors = ({ intl }) => {
  const [showContent, setShowContent] = useState(true);
  /** o campo abaixo define o tipo de Cadastro */
  /** Cliente = 3, Distribuidor = 2, Sodexo = 1 */

  const [tipoCadastro] = useState(2);
  const [cadastro_id, setCadastroId] = useState("");
  /** aba Dados Cadastrais */
  const [cnpj, setCNPJ] = useState("");
  const [razaoSocial, setRazaoSocial] = useState("");
  const [nomeFantasia, setNomeFantasia] = useState("");
  const [dataCadastro, setDataCadastro] = useState("");
  const [inscricaoEstadual, setInscricaoEstadual] = useState("");
  const [grupoEconomico, setGrupoEconomico] = useState("");
  const [perfil, setPerfil] = useState("");
  const [matriz, setMatriz] = useState("");
  const [empresaMatriz, setEmpresaMatriz] = useState("");
  const [classificacao, setClassificacao] = useState("");
  const [contato, setContato] = useState("");
  const [email, setEmail] = useState("");
  const [contatoDois, setContatoDois] = useState("");
  const [emailDois, setEmailDois] = useState("");
  const [telefone, setTelefone] = useState("");
  const [celular, setCelular] = useState("");
  const [celularDois, setCelularDois] = useState("");
  const [consultor, setConsultor] = useState({});
  const [merchant, setMerchant] = useState("");
  const [status, setStatus] = useState({
    value: 1,
    label: <FormattedMessage id="ativo" />,
  });
  /** aba Dados de Endereço */
  const [cep, setCep] = useState("");
  const [logradouro, setLogradouro] = useState("");
  const [numero, setNumero] = useState("");
  const [complemento, setComplemento] = useState("");
  const [bairro, setBairro] = useState("");
  const [estado, setEstado] = useState("");
  const [listaCidades, setListaCidades] = useState("");
  const [cidade, setCidade] = useState({});
  const [cidadeFilter, setCidadeFilter] = useState("");

  const [permissions, setPermissions] = useState("");

  const { user } = useContext(AuthContext);

  /** aba Base Distribuicao */
  const [basesDistribuicao, setBaseDistribuicao] = useState([
    { value: "selecione", label: <FormattedMessage id="selecione" /> },
  ]);
  // const [listaBasesDistribuicao, setListaBasesDistribuicao] = useState("");
  const [loadingButton, setLoadingButton] = useState(false);
  // State de erros
  const [errors, setErrors] = useState();
  const history = useHistory();
  const listPerfis = [
    { value: "Distribuidor", label: "Distribuidor" },
    { value: "TRR", label: "TRR" },
  ];

  const YesNoOptions = [
    { value: 1, label: <FormattedMessage id="opcaoSim" /> },
    { value: 0, label: <FormattedMessage id="opcaoNao" /> },
  ];

  const statusOptions = [
    { value: 1, label: <FormattedMessage id="ativo" /> },
    { value: 0, label: <FormattedMessage id="inativo" /> },
  ];

  const estadoPlaceholder = intl.formatMessage({ id: "estado" });
  const cidadePlaceholder = intl.formatMessage({ id: "cidade" });

  //Dados Cadastrais
  const razaoSocialPlaceholder = intl.formatMessage({ id: "razaoSocial" });
  const nomeFantasiaPlaceholder = intl.formatMessage({ id: "nomeFantasia" });
  const inscricaoEstadualPlaceholder = intl.formatMessage({
    id: "inscricaoEstadual",
  });
  const baseDistribuicaoPlaceholder = intl.formatMessage({
    id: "baseDistribuicao",
  });
  const grupoEconomicoPlaceholder = intl.formatMessage({
    id: "grupoEconomico",
  });
  const perfilPlaceholder = intl.formatMessage({ id: "perfil" });
  const matrizPlaceholder = intl.formatMessage({ id: "matriz" });
  const empresaMatrizPlaceholder = intl.formatMessage({ id: "empresaMatriz" });
  const classificacaoPlaceholder = intl.formatMessage({ id: "classificacao" });
  const contatoPlaceholder = intl.formatMessage({ id: "contato" });
  const telefonePlaceholder = intl.formatMessage({ id: "telefone" });
  const celularPlaceholder = intl.formatMessage({ id: "celular" });
  // const consultorPlaceholder = intl.formatMessage({ id: "consultor" });

  // Dados endereço
  const cepPlaceholder = intl.formatMessage({ id: "cep" });
  const logradouroPlaceholder = intl.formatMessage({ id: "logradouro" });
  const numeroPlaceholder = intl.formatMessage({ id: "numero" });
  const complementoPlaceholder = intl.formatMessage({ id: "complemento" });
  const bairroPlaceholder = intl.formatMessage({ id: "bairro" });

  /** trazer dados para edição do Distribuidor/TRRs e setar dados trazidos do BD nos campos */
  useEffect(() => {
    async function loadCustomer() {
      if (!history.location?.state) {
        return history.push("/register/sodexo/distributors-trrs/list");
      }
      /** id do cadastro que vem da tela anterior */
      const id = history.location?.state;

      try {
        const { data: response } = await api.get(`/cadastro/${id}`);

        function formatDate(date) {
          var d = new Date(date),
              month = '' + (d.getMonth() + 1),
              day = '' + d.getDate(),
              year = d.getFullYear();

          if (month.length < 2)
              month = '0' + month;
          if (day.length < 2)
              day = '0' + day;

          return [day, month, year].join('/');
      }

        setDataCadastro(formatDate(response.data_cadastro))

        /** aba dados Cadastrais */

        setCadastroId(id);
        setCNPJ(response?.cpf_cnpj);
        setRazaoSocial(response?.razao_social);
        setNomeFantasia(response?.fantasia);
        setInscricaoEstadual(response?.inscricao_estadual);
        setGrupoEconomico(response?.grupo_economico);
        setPerfil(
          response?.perfil === "Distribuidor"
            ? {
                value: "Distribuidor",
                label: <FormattedMessage id="distribuidorOnly" />,
              }
            : { value: "TRR", label: <FormattedMessage id="TRR" /> }
        );
        setMatriz(
          response?.matriz === 1
            ? { value: 1, label: <FormattedMessage id="opcaoSim" /> }
            : { value: 0, label: <FormattedMessage id="opcaoNao" /> }
        );
        setEmpresaMatriz(() => {
          if (response.empresa_matriz) {
            return {
              value: response?.empresa_matriz?.id,
              label:
                response?.empresa_matriz?.cpf_cnpj +
                " - " +
                response?.empresa_matriz?.razao_social,
            };
          }
        });
        setClassificacao({
          value: response?.classificacao_distribuidor?.id,
          label: response?.classificacao_distribuidor?.descricao,
        });
        setContato(response?.contato);
        setEmail(response?.email);
        setContatoDois(response.contato_2);
        setEmailDois(response.contato_email_2);

        setTelefone(response?.telefone);
        setCelular(response?.celular);
        setCelularDois(response?.cel_contato_2);
        setConsultor({
          value: response?.consultores?.id,
          label: response?.consultores?.descricao,
        });
        setMerchant({
          value: response?.merchant?.id,
          label: response?.merchant?.descricao,
        })
        /** aba dados Endereço */
        setCep(response?.endereco?.cep);
        setLogradouro(response?.endereco?.logradouro);
        setNumero(response?.endereco?.numero);
        setComplemento(response?.endereco?.complemento);
        setBairro(response?.endereco?.bairro);
        setEstado(
          response?.endereco.estado_id !== ""
            ? {
                value: response.endereco.estado.id,
                label: response.endereco.estado.sigla,
              }
            : { value: "", label: "Selecione o Estado" }
        );

        setListaCidades(response.endereco.estado.sigla);
        setCidade(
          response?.endereco.municipio_id !== ""
            ? {
                value: response.endereco.municipio.id,
                label: response.endereco.municipio.nome,
              }
            : { value: "", label: "Selecione antes o Estado" }
        );
        /** aba base Distribuicao */
        if (response?.base_distribuicao) {
          const formattedBases = response?.base_distribuicao.map((base) => ({
            label: base.nome,
            value: base.id,
          }));

          setBaseDistribuicao(formattedBases);
        }
        setShowContent(false);
      } catch (error) {
        setShowContent(false);
      }
    }
    loadCustomer();

    const permissoes = user?.permission;

    const objPermissoes = {
      dadosCadastrais: permissoes?.find(
        (item) =>
          item.namePermission === "cadastro-distribuidor-dadoscadastrais[1]"
      ),
      dadosEnderecos: permissoes?.find(
        (item) =>
          item.namePermission === "cadastro-distribuidor-dadosendereco[1]"
      ),
      dadosBasesDist: permissoes?.find(
        (item) =>
          item.namePermission === "cadastro-distribuidor-basesdistribuicao[1]"
      ),
    };

    setPermissions(objPermissoes);
  }, [history, user.permission]);

  /** Para carregar as Classificacoes Cliente */
  const handleLoadClassificaoClientes = useCallback(async () => {
    try {
      const { data: response } = await api.get(
        `/tabela-classificacao-distribuidor`
      );

      const arr = response.data.map((mesa) => {
        const obj = {};

        Object.assign(obj, {
          value: mesa.id,
          label: mesa.descricao,
        });

        return obj;
      });

      return arr;
    } catch (error) {}
  }, []);

  const statusPlaceholder = intl.formatMessage({ id: "status" });

  //Função que atualiza registro
  const handleUpdate = useCallback(async () => {
    setLoadingButton(true);

    const basesDistribuicaoId = basesDistribuicao.map((base) => {
      return base.value;
    });

    var consultorCheck = consultor?.value;
    if (consultor?.label === "Sem Registro") {
      consultorCheck = null;
    }

    var classificacaoCheck = classificacao?.value;
    if (classificacao?.label === "Sem Registro") {
      classificacaoCheck = null;
    }

    var estadoCheck = estado?.value;
    if (estado?.label === "0") {
      estadoCheck = null;
    }

    var cidadeCheck = cidade?.value;
    if (cidade?.label === "Sem Registro") {
      cidadeCheck = null;
    }

    const objEndereco = {
      cep: cep?.replace(/[^0-9]+/g, ""),
      logradouro: logradouro,
      numero: numero?.replace(/[^0-9]+/g, ""),
      complemento: complemento,
      bairro: bairro,
      estado_id: estadoCheck,
      municipio_id: cidadeCheck,
      pais_id: 1,
    };

    const data = {
      dados: {
        ativo: status.value,
        cpf_cnpj: cnpj?.replace(/[^0-9]+/g, ""),
        razao_social: razaoSocial,
        fantasia: nomeFantasia,
        telefone: telefone?.replace(/[^0-9]+/g, ""),
        celular: celular?.replace(/[^0-9]+/g, ""),
        cel_contato_2: celularDois?.replace(/[^0-9]+/g, ""),
        email,
        contato,
        contato_2: contatoDois,
        contato_email_2: emailDois,
        grupo_economico: grupoEconomico,
        perfil: perfil?.value,
        matriz: matriz?.value,
        empresa_matriz_id: empresaMatriz?.value,
        inscricao_estadual: inscricaoEstadual,
        cadastro_tipo_id: tipoCadastro,
        merchant_id: merchant?.value,
        tabela_classificacao_distribuidor_id: classificacaoCheck,
        tabela_classificacao_clientes_id: 1 /** valor fixo pois ainda nao existe este campo */,
        tabela_mesa_operacao_id: 1 /** 2020-09-24 - Bruno Nogueira - Sem mesa de operacao */,
        consultor_id: consultorCheck,
      },
      endereco: objEndereco,
      base_distribuicao:
        basesDistribuicao[0]?.value === "selecione" ? [] : basesDistribuicaoId,
    };

    try {
      await api.put(`/cadastro/${cadastro_id}`, data);
      setLoadingButton(false);
      toast.success(<FormattedMessage id="registroEditadoComSucesso" />);
      history.goBack();
    } catch (error) {
      setErrors(getErrorsApiValidation(error));
      setLoadingButton(false);
      return toast.error(<FormattedMessage id="erroEditarRegistro" />);
    }

    setErrors(null);
  }, [
    basesDistribuicao,
    cep,
    logradouro,
    numero,
    complemento,
    bairro,
    estado,
    cidade,
    status,
    cnpj,
    razaoSocial,
    nomeFantasia,
    telefone,
    celular,
    celularDois,
    email,
    contatoDois,
    emailDois,
    grupoEconomico,
    perfil,
    matriz,
    empresaMatriz,
    inscricaoEstadual,
    contato,
    tipoCadastro,
    classificacao,
    consultor,
    cadastro_id,
    history,
    merchant
  ]);

  const handleBackButton = useCallback(() => {
    history.goBack();
  }, [history]);
  const [tabIndex, setTabIndex] = useState(0);

  // função responsável por buscar os dados do cep nos dados de endereço
  const handleSearchCEP = useCallback(async (cep) => {
    try {
      const response = await searchCep(cep);

      const { data: estado } = await api.post(`/estado/filter`, {
        sigla: response.uf,
      });

      const { data: cidade } = await api.post(`/municipio/filter`, {
        codigo: response.ibge,
      });

      setLogradouro(response.logradouro);
      setBairro(response.bairro);
      setBairro(response.bairro);
      setEstado(response.localidade);
      setListaCidades(estado[0].sigla);
      setEstado({ value: estado[0].id, label: estado[0].sigla });
      setCidade({ value: cidade[0]?.id, label: cidade[0]?.nome });
    } catch (error) {}
  }, []);

  const handleLoadUF = useCallback(async () => {
    try {
      const { data } = await api.get(`/estado`);

      const arr = data.map((uf) => {
        const obj = {};

        Object.assign(obj, {
          value: String(uf.id),
          label: uf.sigla,
        });

        return obj;
      });

      return arr;
    } catch (error) {}
  }, []);

  const handleFilterCitiesByUf = useCallback(
    async (id) => {
      if (!id) {
        return;
      }
      try {
        const { data } = await api.post(`/municipio/filter`, {
          estado: id,
          nome: cidadeFilter,
        });
        const arr = data.map((municipio) => {
          const obj = {};
          Object.assign(obj, {
            value: String(municipio.id),
            label: municipio.nome,
          });
          return obj;
        });
        return arr;
      } catch (error) {}
    },
    [cidadeFilter]
  );

  const loadBases = useCallback(async (inputValue) => {

    try {
      const { data: response } = await api.post(
        `/tabela-base-distribuicao-lista`,
        {
          nome: inputValue,
          ativo: 1
        }
      );

      const basesFormatted = response.map((bases) => {
        const objeto = {
          value: bases.id,
          label: bases.nome,
        };

        return objeto;
      });

      return basesFormatted;
  } catch (error) {}
}, []);

  const handleChangeBase = useCallback((item) => {
    if (!item || item?.length === 0) {
      setBaseDistribuicao([
        { value: "selecione", label: <FormattedMessage id="selecione" /> },
      ]);
    } else {
      if (item) {
        const basesDistribuicao = item.filter(
          (base) => base.value !== "selecione"
        );

        setBaseDistribuicao(basesDistribuicao);
      }
    }
  }, []);

  if (showContent) {
    return <LoadingContent />;
  }

  return (
    <Container>
      <CreateForm>
        <header>
          <h4>
            <FormattedMessage id="editarDistribuidorTRR" />: {cnpj.replace(
                  /(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g,
                  "$1.$2.$3/$4-$5"
                )} - {razaoSocial}
          </h4>
          {errors && <ButtonIcon Icon={FiAlertCircle} geterror={errors} />}
        </header>
        <form>
          <Tabs
            selectedIndex={tabIndex}
            onSelect={(index) => setTabIndex(index)}
          >
            {
              <>
                <TabList>
                  {(user?.tipo_id === 1 || permissions.dadosCadastrais) && (
                    <TabStyled className={"react-tabs__tab"}>
                      <FormattedMessage id="dadosCadastrais" />
                      {(errors?.cpf_cnpj ||
                        errors?.razao_social ||
                        errors?.fantasia ||
                        errors?.inscricao_estadual ||
                        errors?.grupo_economico ||
                        errors?.perfil ||
                        errors?.matriz ||
                        errors?.errors?.classificacao ||
                        errors?.contato ||
                        errors?.email ||
                        errors?.telefone ||
                        errors?.celular ||
                        errors?.celularDois ||
                        errors?.consultor_id) && (
                        <FiAlertCircle
                          size={15}
                          style={{ marginLeft: "5px", color: "#ff3232" }}
                        />
                      )}
                    </TabStyled>
                  )}
                  {(user?.tipo_id === 1 || permissions.dadosEnderecos) && (
                    <TabStyled className={"react-tabs__tab"}>
                      <FormattedMessage id="dadosEndereco" />
                      {(errors?.cep ||
                        errors?.logradouro ||
                        errors?.numero ||
                        errors?.bairro ||
                        errors?.estado_id ||
                        errors?.municipio_id) && (
                        <FiAlertCircle
                          size={15}
                          style={{ marginLeft: "5px", color: "#ff3232" }}
                        />
                      )}
                    </TabStyled>
                  )}
                  {(user?.tipo_id === 1 || permissions.dadosBasesDist) && (
                    <TabStyled className={"react-tabs__tab"}>
                      <FormattedMessage id="baseDistribuicao" />
                      {errors?.estado_id ||
                        (errors?.["0"] && (
                          <FiAlertCircle
                            size={15}
                            style={{ marginLeft: "5px", color: "#ff3232" }}
                          />
                        ))}
                    </TabStyled>
                  )}
                </TabList>

                {/* Dados cadastrais */}
                {(user?.tipo_id === 1 || permissions.dadosCadastrais) && (
                  <TabPanel>
                    <InputLabel
                      name="cnpj"
                      type="text"
                      label={"CNPJ"}
                      mask={MaskInput.CNPJ}
                      value={cnpj}
                      onChange={(e) => {
                        setCNPJ(e.target.value.replace(/[^\d]+/g, ""));
                      }}
                      readOnly
                    />

                    <InputLabel
                      name="razaoSocial"
                      type="text"
                      label={razaoSocialPlaceholder}
            required={true}
                      maxLength={100}
                      value={razaoSocial}
                      onChange={(e) => setRazaoSocial(e.target.value)}
                      geterror={errors?.razao_social}
                    />

                    <InputLabel
                      name="nomeFantasia"
                      type="text"
                      label={nomeFantasiaPlaceholder}
            required={true}
                      maxLength={100}
                      value={nomeFantasia}
                      onChange={(e) => setNomeFantasia(e.target.value)}
                      geterror={errors?.fantasia}
                    />

                    <InputLabel
                      name="dataCadastro"
                      type="text"
                      label={"Data Cadastro"}
                      value={dataCadastro}
                      readOnly
                    />

                    <InputLabel
                      name="inscricaoEstadual"
                      type="text"
                      label={inscricaoEstadualPlaceholder}
                      maxLength={15}
                      value={inscricaoEstadual}
                      onChange={(e) => setInscricaoEstadual(e.target.value)}
                      geterror={errors?.inscricao_estadual}
                    />

                    <InputLabel
                      name="grupoEconomico"
                      type="text"
                      label={grupoEconomicoPlaceholder}
            required={true}
                      maxLength={15}
                      value={grupoEconomico}
                      onChange={(e) => setGrupoEconomico(e.target.value)}
                      geterror={errors?.grupo_economico}
                    />

                    <ListSelect
                      labelPlaceholder={perfilPlaceholder}
            required={true}
                      placeholder={<FormattedMessage id="selecionar" />}
                      options={listPerfis}
                      onChange={(e) => setPerfil(e)}
                      value={perfil}
                      geterror={errors?.perfil}
                    />

                    <ListSelect
                      labelPlaceholder={matrizPlaceholder}
            required={true}
                      placeholder={<FormattedMessage id="selecionar" />}
                      options={YesNoOptions}
                      onChange={(e) => {
                        setMatriz(e);
                        setEmpresaMatriz("");
                      }}
                      value={matriz}
                    />

                    <AsyncListSelect
                      value={empresaMatriz}
                      onChange={(e) => {
                        setEmpresaMatriz(e);
                      }}
                      myOptions={"distribuidores"}
                      cacheOptions
                      defaultOptions
                      labelPlaceholder={empresaMatrizPlaceholder}
                      placeholder={<FormattedMessage id="selecionar" />}
                      loadingMessage={() => (
                        <FormattedMessage id="carregando" />
                      )}
                      noOptionsMessage={() => (
                        <FormattedMessage id="nenhumRegistroEncontrado" />
                      )}
                      isSearchable={false}
                      isDisabled={matriz.value === 1}
                      geterror={errors?.municipio_id}
                    />

                    <AsyncListSelect
                      labelPlaceholder={classificacaoPlaceholder}
            required={true}
                      placeholder={<FormattedMessage id="selecionar" />}
                      onChange={(e) => setClassificacao(e)}
                      value={classificacao}
                      loadOptions={handleLoadClassificaoClientes}
                      cacheOptions
                      defaultOptions
                      geterror={errors?.tabela_classificacao_distribuidor_id}
                    />

                    <InputLabel
                      name="contato"
                      type="text"
                      label={contatoPlaceholder}
            required={true}
                      maxLength={100}
                      value={contato}
                      onChange={(e) => setContato(e.target.value)}
                      geterror={errors?.contato}
                    />

                    <InputLabel
                      name="email"
                      type="text"
                      label={"E-mail"}
                      maxLength={80}
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      geterror={errors?.email}
                      readOnly
                    />

                    <InputLabel
                      name="telefone"
                      type="text"
                      label={telefonePlaceholder}
            required={true}
                      mask={MaskInput.phone}
                      value={telefone}
                      onChange={(e) => setTelefone(e.target.value)}
                      geterror={errors?.telefone}
                    />

                    <InputLabel
                      name="celular"
                      type="text"
                      label={celularPlaceholder}
                      mask={MaskInput.cellphone}
                      value={celular}
                      onChange={(e) => setCelular(e.target.value)}
                      geterror={errors?.celular}
                    />

                    <InputLabel
                      name="contato"
                      type="text"
                      label={"Segundo Contato"}
                      maxLength={100}
                      value={contatoDois}
                      onChange={(e) => setContatoDois(e.target.value)}
                      geterror={errors?.contato_2}
                    />

                    <InputLabel
                      name="email"
                      type="text"
                      label={"Segundo E-mail"}
                      maxLength={80}
                      value={emailDois}
                      onChange={(e) => setEmailDois(e.target.value)}
                      geterror={errors?.contato_email_2}
                    />

                    <InputLabel
                      name="celular"
                      type="text"
                      label={"Segundo Celular"}
                      mask={MaskInput.cellphone}
                      value={celularDois}
                      onChange={(e) => setCelularDois(e.target.value)}
                      geterror={errors?.cel_contato_2}
                    />

                    {/* <AsyncListSelect
                      labelPlaceholder={consultorPlaceholder}
            required={true}
                      placeholder={<FormattedMessage id="selecionar" />}
                      onChange={(e) => setConsultor(e)}
                      value={consultor}
                      myOptions={"classificaoConsultores"}
                      cacheOptions
                      defaultOptions
                      geterror={errors?.consultor_id}
                    /> */}

                    <AsyncListSelect
                      labelPlaceholder={"Consultor *"}
                  required={true}
                      placeholder={<FormattedMessage id="selecionar" />}
                      cacheOptions
                      defaultOptions
                      onChange={(e) => setMerchant(e)}
                      value={merchant}
                      myOptions={"merchant"}
                      geterror={errors?.merchant}
                    />

                    <ListSelect
                      value={status}
                      onChange={(e) => setStatus(e)}
                      labelPlaceholder={statusPlaceholder}
                      placeholder={<FormattedMessage id="selecionar" />}
                      cacheOptions
                      defaultOptions
                      options={statusOptions}
                    />
                  </TabPanel>
                )}

                {/* Dados de endereço */}
                {(user?.tipo_id === 1 || permissions.dadosEnderecos) && (
                  <TabPanel>
                    <InputLabel
                      name="cep"
                      type="text"
                      label={cepPlaceholder}
            required={true}
                      mask={MaskInput.CEP}
                      onChange={(e) => setCep(e.target.value)}
                      value={cep}
                      onBlur={() => handleSearchCEP(cep)}
                      geterror={errors?.cep}
                    />
                    <InputLabel
                      name="logradouro"
                      type="text"
                      label={logradouroPlaceholder}
            required={true}
                      maxLength={100}
                      value={logradouro}
                      onChange={(e) => setLogradouro(e.target.value)}
                      geterror={errors?.logradouro}
                    />
                    <InputMaskDefault
                      name="numero"
                      type="text"
                      label={numeroPlaceholder}
            required={true}
                      mask={"int6"}
                      value={numero}
                      onChange={(e) => setNumero(e.target.value)}
                      geterror={errors?.numero}
                    />
                    <InputLabel
                      name={"complemento"}
                      type="text"
                      label={complementoPlaceholder}
                      onChange={(e) => setComplemento(e.target.value)}
                      maxLength={50}
                      value={complemento}
                    />
                    <InputLabel
                      name={"bairro"}
                      type="text"
                      label={bairroPlaceholder}
            required={true}
                      maxLength={30}
                      value={bairro}
                      onChange={(e) => setBairro(e.target.value)}
                      geterror={errors?.bairro}
                    />
                    <AsyncListSelect
                      value={estado}
                      onChange={(e) => {
                        setCidade("");
                        setEstado(e);
                        setListaCidades(e?.label);
                      }}
                      onInput={(e) => {}}
                      loadOptions={handleLoadUF}
                      cacheOptions
                      defaultOptions
                      labelPlaceholder={estadoPlaceholder}
            required={true}
                      placeholder={<FormattedMessage id="selecionar" />}
                      loadingMessage={() => (
                        <FormattedMessage id="carregando" />
                      )}
                      isSearchable={false}
                      geterror={errors?.estado_id}
                    />
                    <AsyncListSelect
                      key={listaCidades}
                      value={cidade}
                      cacheOptions
                      defaultOptions
                      labelPlaceholder={cidadePlaceholder}
            required={true}
                      placeholder={<FormattedMessage id="selecionar" />}
                      loadOptions={() => {
                        return handleFilterCitiesByUf(listaCidades);
                      }}
                      onChange={(e) => {
                        setCidade(e);
                      }}
                      onInputChange={(e) => {
                        setCidadeFilter(e);
                      }}
                      noOptionsMessage={() => (
                        <FormattedMessage id="selecioneEstadoCarregar" />
                      )}
                      loadingMessage={() => (
                        <FormattedMessage id="carregando" />
                      )}
                      // isSearchable={false}
                      geterror={errors?.municipio_id}
                    />
                  </TabPanel>
                )}

                {/* Base de distribuição */}
                {(user?.tipo_id === 1 || permissions.dadosBasesDist) && (
                  <TabPanel>
                    <AsyncListSelect
                      // key={loadBases}
                      labelPlaceholder={baseDistribuicaoPlaceholder}
            required={true}
                      placeholder={<FormattedMessage id="selecionar" />}
                      isMulti
                      isSearchable
                      cacheOptions
                      defaultOptions
                      loadOptions={loadBases}
                      value={basesDistribuicao}
                      onChange={handleChangeBase}
                    />

                    <MessageDefault geterror={errors?.[`0`]} />
                  </TabPanel>
                )}
              </>
            }
          </Tabs>
        </form>
        <hr />
        <CreateFooter>
          <div>
            <Button type="button" themeType="back" onClick={handleBackButton}>
              <FormattedMessage id="voltar" />
            </Button>
          </div>
          <div>
            <Button
              type="button"
              onClick={handleUpdate}
              loading={loadingButton}
            >
              <FormattedMessage id="salvar" />
            </Button>
          </div>
        </CreateFooter>
      </CreateForm>
    </Container>
  );
};

export default injectIntl(EditDistributors);
