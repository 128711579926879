import styled from "styled-components";

export const Container = styled.div`
  position: absolute;
  border-radius: 5px;
  border: 2px solid #bfbfbf;
  bottom: 0;
  right: 30%;

  transform: translateY(25%);
  background-color: #f3f3f3;

  height: auto !important;
  width: 300px !important;
  z-index: 99999;

  display: flex;
  flex-direction: column;

  > header {
    display: flex;
    justify-content: space-between;
    width: 100%;

    > svg {
      height: 24px;
      width: 24px;
      margin: 5px;
      transition: all 0.4s;
      :hover {
        color: #e05262;
      }
    }
  }

  > :nth-child(2) {
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #bfbfbf;

    > div {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;

      > header {
        > input {
          margin-top: 4px;
          padding: 3px;
          border-radius: 5px;
          border: 1px solid #bfbfbf;
        }
      }

      > div {
        display: flex;
        justify-content: center;
        align-items: center;
      }

      input[type="file"] {
        display: none;
      }

      label {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 45px;
        width: 150px;
        background-color: #3498db;
        border-radius: 5px;
        color: #fff;
        cursor: pointer;
        margin: 10px;

        padding: 6px 20px;
        transition: all 0.4s;
        :hover {
          background-color: #1b6698;
        }
      }

      span {
        margin-left: 12px;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }

    button {
      height: 32px;
      border-radius: 5px;
      border: none;
      background-color: #54f;
      padding: 5px;
      color: #fff;
      font-weight: bold;
      margin-right: 10px;
      transition: all 0.4s;

      :hover {
        background-color: #0b0080;
      }
    }
  }

  > :nth-child(3) {
    padding: 5px 10px;
  }
`;

export const ButtonIcon = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;

  svg {
    height: 25px;
    width: 25px;
    margin-right: 5px;
    transition: all 0.4s;
    color: ${(props) => (props.colorIcon ? "#242424" : "#c2c2c2")};
    :hover {
      color: #e05262;
    }
  }
`;
