import React, { useCallback, useState, useRef } from "react";
import { useHistory } from "react-router-dom";
import { FormattedMessage, injectIntl } from "react-intl";
import { toast } from "react-toastify";

import InputLabel from "./../../../../components/InputLabel";
import { getErrorsApiValidation } from "./../../../../utils/getErrorsValidation";

import api from "./../../../../services/api";
import { Container, CreateForm, CreateFooter } from "./styles";
import Button from "./../../../../components/ButtonDefault";
import AsyncListSelect from "../../../../components/ListSelect/AsyncListSelect";
//import AuthContext from "./../../../../context/auth";

export const CreateOrders = ({ intl }) => {
  const [loadingButton, setLoadingButton] = useState(false);
  const [errors, setErrors] = useState(null);

  const [distribuidores, setDistribuidores] = useState("");
  const [preco] = useState();
  //const { user } = useContext(AuthContext);

  const [file, setFile] = useState();

  const history = useHistory();

  const refInput = useRef();

  const handleCreate = useCallback(
    async (e) => {
      if (!distribuidores) {
        toast.error(<FormattedMessage id="erroImportarRegistro" />);
        return;
      }

      setLoadingButton(true);

      var formData = new FormData();
      formData.append("file", file);

      try {
        await api.post("/importar/preco", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
        toast.success(<FormattedMessage id="arquivoImportadoComSucesso" />);
        setErrors(null);
        setDistribuidores("");
        setFile([]);
        document.getElementById("file").value = "";
        setLoadingButton(false);
      } catch (error) {
        setFile([]);
        document.getElementById("file").value = "";
        console.log(error);
        setErrors(getErrorsApiValidation(error));
        setLoadingButton(false);

        toast.error(<FormattedMessage id="erroImportarRegistro" />);
      }
    },
    [file, distribuidores]
  );

  const handleBackButton = useCallback(() => {
    history.goBack();
  }, [history]);

  const handleDownload = useCallback(async () => {
    const distribuidor = distribuidores.value;

    if (!distribuidor) {
      toast.warn(<FormattedMessage id="selecioneDistribuidorParaDownload" />);
      return;
    }

    await api
      .get(`/modelo/preco/${distribuidor}`, {
        responseType: "blob",
      })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "Precos.xlsx");
        document.body.appendChild(link);
        link.click();
      });
  }, [distribuidores]);

  return (
    <Container>
      <CreateForm>
        <header>
          <h4>
            <FormattedMessage id="cadastrarPrecos" />
          </h4>
        </header>

        <div style={{ marginLeft: "30px", marginRight: "30px" }}>
          <AsyncListSelect
            labelPlaceholder={<FormattedMessage id="distribuidores" />}
            placeholder={<FormattedMessage id="selecionar" />}
            onChange={(e) => {
              setDistribuidores(e);
            }}
            value={distribuidores}
            myOptions={"distribuidores"}
            cacheOptions
            defaultOptions
            geterror={errors?.distribuidor}
          />
        </div>

        <form>
          <InputLabel
            id={"file"}
            ref={refInput}
            name="preco"
            type="file"
            label={<FormattedMessage id="precosCombustiveis" />}
            value={preco}
            onChange={(e) => {
              setFile(e.target.files[0]);
            }}
          />

          <br />

          <div style={{ width: "160px" }}>
            <Button type="button" onClick={handleDownload} style={{fontSize: "11.5px"}}>
              <FormattedMessage id="precosCombustiveisExportar" />
            </Button>
          </div>
        </form>

        <hr />

        <CreateFooter>
          <div>
            <Button type="button" themeType="back" onClick={handleBackButton}>
              <FormattedMessage id="voltar" />
            </Button>
          </div>
          <div>
            <Button onClick={handleCreate} loading={loadingButton}>
              <FormattedMessage id="enviar" />
            </Button>
          </div>
        </CreateFooter>
      </CreateForm>
    </Container>
  );
};

export default injectIntl(CreateOrders);
