

const lang = {
  "pt-BR": {
    cadastroGeral: "Cadastro Geral",
    editarDadosCadastrais: "Editar Dados Cadastrais",
    editarDadosEndereco: "Editar Dados de Endereço",
    editarDadosEntrega: "Editar Dados Entrega",
    editarDadosCobrança: "Editar Dados Cobrança",
    editarConsumoCapacidade: "Editar Consumo e Capacidade",
    editarCertificado: "Editar Certificado Digital",
    editarBasesDistribuicao: "Editar Bases de Distribuição",
    editarPedido: "Editar Pedido",
    acessos: "Acessos",
    acoes: "Ações",
    aguardando: "Aguardando Faturamento",
    aguardandoAprovacao: "Aguardando aprovação",
    aguardandoIntegracao: "Aguardando integração",
    aguardandoFaturamento: "Aguardando Faturamento",
    ajusteDistribuidor: "Solicitar Ajuste Distribuidor/TRR",
    algoErradoExcluirPerfil: "Algo de errado ao tentar excluir perfil",
    algoErradoExcluirRegistro: "Algo de errado ao tentar excluir o registro",
    alterarSenhaUsuario: "Alterar Senha Usuário",
    anterior: "Anterior",
    aprovar: "Aprovar",
    aprovado: "Aprovado",
    arquivo: "Arquivo",
    arquivoImportadoComSucesso: "Arquivo importado com Sucesso",
    asPasswordsNaoCorrespondem:
      "Os campos Senha e Confirmar senha nao correspondem.",
    ate: "Até",
    ativo: "Ativo",
    bairro: "Bairro",
    banner: "Banner",
    base: "Base",
    baseDistribuicao: "Bases de Distribuição",
    baseDistribuiçãoCriadoSucesso: "Base de distribuição criado com sucesso",
    basesDistribuicao: "Bases Distribuição",
    boleto: "Boleto",
    cadastrar: "Cadastrar",
    cadastrarBaseDistribuicao: "Cadastrar Base de Distribuição",
    cadastrarClassificacaoCliente: "Cadastrar Classificação de Cliente",
    cadastrarClassificacaoDistribuidores:
      "Cadastrar Classificação de Distribuidores",
    cadastrarCliente: "Cadastrar Cliente",
    cadastrarComunicado: "Cadastrar Comunicado",
    cadastrarConsultor: "Cadastrar Consultor",
    cadastrarDistribuidorTRR: "Cadastrar Distribuidores/TRR",
    cadastrarMesaOperacao: "Cadastrar Mesa de Operação",
    cadastrarPerfilAcessoCliente: "Cadastrar Perfil de Acesso Cliente",
    cadastrarPerfilAcessoDistribuidor:
      "Cadastrar Perfil de Acesso Distribuidor/TRR",
    cadastrarPerfilAcessoSodexo: "Cadastrar Perfil de Acesso Pluxee",
    cadastrarPrecos: "Cadastrar Preços",
    cadastrarProduto: "Cadastrar Produto",
    relatorioCadastroCliente: "Relatório Cadastro de Clientes",
    relatorioCadastroDistribuidorTRR:
      "Relatório Cadastro de Distribuidores/TRRs",
    relatorioCondicoesComerciaisClientes:
      "Relatório Condições Comerciais Clientes",
    relatorioExtratoReembolsoDistribuidoresTRRs:
      "Relatório Extrato Reembolso Distribuidores/TRRs",
    relatorioHistoricoNegociacoesDistribuidoresTRRs:
      "Relatório Histórico de Negociações com Distribuidores/TRRs",
    relatorioLog: "Relatório de Logs",
    relatorioPedidosDetalhados: "Relatório Pedidos Detalhados",
    relatorioPrecoMedioCombustiveis: "Relatório Preço Médio dos Combustiveis",
    relatorioSaving: "Relatório Saving",
    acao: "Ação",
    statusAtivo: "Ativo",
    statusInativo: "Inativo",
    nivelMaster: "Master",
    nivelComum: "Comum",
    cadastro: "Cadastro",
    cadastroCliente: "Cadastro de Clientes",
    cadastroDistribuidorTRR: "Cadastro de Distribuidores/TRRs",
    cadastros: "Cadastros",
    cancelado: "Cancelado",
    cancelarEdicao: "Cancelar Edição",
    capacidadeTanque: "Capacidade Tanque",
    carregando: "Carregando...",
    celular: "Celular",
    cep: "CEP",
    certificadoDigital: "Certificado Digital",
    certificadoDigital30: "Certificados a Vencer: 30 dias",
    certificadoDigital60: "Certificados a Vencer: 60 dias",
    certificadoDigitalA1: "Certificado Digital A1 (.pfx)",
    cidade: "Cidade",
    classificacao: "Classificação",
    classificacaoClientes: "Classificação de Clientes",
    classificacaoDistribuidores: "Classificação de Distribuidores",
    cliente: "Cliente",
    clienteCriadoComSucesso: "Cliente criado com sucesso",
    clientes: "Clientes",
    cliqueAqui: "Clique aqui",
    cnpjInvalido: "CNPJ invalído",
    codigo: "Código",
    codigoExterno: "Código Externo",
    complemento: "Complemento",
    comunicacao: "Comunicação",
    comunicadoInformacoes: "Comunicados e Informações",
    condicaoComercialCliente: "Condições Comerciais de Clientes",
    confirmacaoSenha: "Confirmar senha",
    consultaPedidoCotacao: "Consulta de Pedido/Cotação",
    consultaPrecosCombustiveis: "Consulta de Preços de Combustível",
    consultaPrecosDia: "Consulta de Preços do dia",
    consultor: "Consultor",
    consultores: "Consultores",
    consumoCapacidade: "Consumo e Capacidade",
    contato: "Contato",
    copyright: "© 2023 Trade Pluxee. Todos os direitos reservados.",
    cotacao: "Cotação",
    statusCotacao: "Status Cotação",
    cotacaoEnviadaComSucesso: "Cotação enviada com sucesso",
    cotacaoRedirecionadaCliente: "Cotação redirecionada para o cliente",
    cotacaoEnviadaCliente: "Está cotação já foi enviada o cliente",
    cancelar: "Cancelar",
    cotacaoRedirecionadaDistribuidor:
      "Cotação redirecionada para o distribuidor",
    cotacoesAprovar: "Cotações a Aprovar",
    cotacoesResponder: "Pedidos Pendentes",
    cotacoesRespondidas: "Cotações Respondidas",
    cotacoesValidar: "Pedidos a Validar",
    criar: "Criar",
    criarNovoUsuario: "Cadastrar Usuário",
    criarPerfilAcesso: "Cadastrar Perfil de Acesso",
    dadosCadastrais: "Dados Cadastrais",
    dadosCapacidade: "Dados Capacidade",
    dadosCobranca: "Dados Cobrança",
    dadosEndereco: "Dados de Endereço",
    dadosEntrega: "Dados Entrega",
    dadosPedido: "Dados do Pedido",
    data: "Data",
    dataEntrega: "Data Entrega",
    dataEntregaDesejada: "Data de Entrega Desejada",
    dataEntregaInvalidaPedido: "Data de entrega inválida para este pedido",
    dataFim: "Data fim",
    dataInicio: "Data início",
    de: "De",
    descricao: "Descrição",
    descricaoPerfil: "Descrição do Perfil",
    desejaExcluirRegistro: "Deseja realmente excluir o registro?",
    detalhes: "Detalhes",
    detalhesProdutos: "Detalhes Produtos",
    digiteNcM: "Digite um ou mais NCMs",
    distanciaKM: "Distância km (Endereço Cliente x Endereço Distribuidor/TRR)",
    distribuidor: "Distribuidor/TRR",
    distribuidorOnly: "Distribuidor",
    distribuidorPreferencia: "Distribuidor de Preferência",
    distribuidores: "Distribuidores/TRR",
    distribuidores_trrs: "Distribuidores/TRRs",
    editar: "Editar",
    editarBaseDistribuicao: "Editar Base de Distribuição",
    editarClassificacaoCliente: "Editar Classificação de Cliente",
    editarClassificacaoDistribuidores: "Editar Classificação de Distribuidores",
    editarCliente: "Editar Cliente",
    editarComunicado: "Editar Comunicado",
    editarConsultor: "Editar Consultor",
    editarDistribuidorTRR: "Editar Distribuidores/TRR",
    editarMesaOperacao: "Editar Mesa de Operação",
    editarPerfilAcesso: "Editar Perfil de Acesso",
    editarPerfilAcessoCliente: "Editar Perfil de Acesso Cliente",
    editarPerfilAcessoDistribuidor: "Editar Perfil de Acesso Distribuidor/TRR",
    editarPerfilAcessoSodexo: "Editar Perfil de Acesso Pluxee",
    editarProduto: "Editar Produto",
    editarUsuario: "Editar Usuário",
    emailInvalido: "Use um e-mail válido",
    emailObrigatorio: "E-mail obrigatório",
    erroAoEnviarEmail: "Erro ao tentar enviar E-mail.",
    liberarPedido: "Liberar Pedido",
    cancelarPedido: "Cancelar Pedido/Cotação",
    empresa: "Empresa",
    empresaMatriz: "Empresa Matriz",
    encontreSuaSenha: "Encontre sua conta do Trade",
    endereco: "Endereço",
    entrar: "Entrar",
    entrega: "Entrega",
    enviar: "Enviar",
    erroAlterarSenha: "Erro ao alterar a senha",
    erroAtualizarSenha: "Erro ao atualizar senha",
    erroCadastrarPerfil: "Erro ao cadastrar perfil",
    erroCarregarRegioes: "Erro ao carregar as regiões",
    erroCredenciais:
      "Algo deu errado! Verifique as credenciais e tente novamente.",
    erroCriarPedidoProgramado: "Erro ao criar o pedido programado",
    erroCriarRegistro: "Erro ao criar o registro",
    erroDeCriarUsuario: "Erro ao criar usuário.",
    erroDeEditarPassword: "Erro ao atualizar senha.",
    erroDeEditarUsuario: "Erro ao editar usuário.",
    erroEditarRegistro: "Erro ao editar o registro",
    erroCadastroIncompleto: "Cadastro incompleto. Contate a mesa de operação.",
    erroExcluirRegistro: "Erro ao excluir o registro",
    erroImportarRegistro: "Erro ao importar o registro",
    errorExportarRelatorio: "Erro ao exportar relatório",
    escolhaSaidaGerarRelatorio: "Escolha uma saída para gerar o relatório",
    espanhol: "Espanhol",
    esqueceuSenha: "Esqueceu sua senha?",
    estado: "Estado",
    excluir: "Excluir",
    exibicao: "Exibição",
    exibir: "Exibir",
    exibirBaseDistribuicao: "Exibir Base de Distribuição",
    exibirClassificacaoCliente: "Exibir Classificação de Cliente",
    exibirClassificacaoDistribuidores: "Exibir Classificação de Distribuidores",
    exibirCliente: "Exibir Cliente",
    exibirComunicado: "Exibir Comunicado",
    exibirConsultor: "Exibir Consultor",
    exibirDistribuidorTRR: "Exibir Distribuidores/TRR",
    exibirMesaOperacao: "Exibir Mesa de Operação",
    maximoDe: "Tamanho máximo de",
    exibirPara: "Exibir para",
    exibirPerfilAcesso: "Exibir Perfil de Acesso",
    exibirPerfilAcessoCliente: "Exibir Perfil de Acesso Cliente",
    exibirPerfilAcessoDistribuidor: "Exibir Perfil de Acesso Distribuidor/TRR",
    exibirPerfilAcessoSodexo: "Exibir Perfil de Acesso Pluxee",
    exibirPreco: "Exibir Preços",
    exibirProduto: "Exibir Produto",
    exportarExcel: "Exportar Excel",
    exportarTemplate: "Exportar Template",
    extratoReembolsoDistribuidor: "Extrato Reembolso Distribuidor/TRR",
    faturado: "Faturado",
    faturados: "Faturados",
    filtrar: "Filtrar",
    financeiro: "Financeiro",
    frete: "Frete",
    gerar: "Gerar",
    grupo: "Grupo",
    grupoBaseDistribuicaoReceita: "Grupo Base Distribuição - Receita R$",
    grupoBasesDistFaturamento: "Grupo Bases Distribuição - Faturamento R$",
    grupoBasesDistPrecosMedios:
      "Grupo Bases Distribuição - Preços Médios dos Litros R$",
    grupoBasesDistMediaCliente:
      "Grupo Bases Distribuição - Taxas Médias Cliente R$/Litro",
    grupoBasesDistVolumeCombustivel:
      "Grupo Bases Distribuição - Volume Combustível (Litros)",
    grupoCombustiveisPrecosMediosLitros:
      "Grupo Combustível - Preços Médios dos Litros R$",
    grupoCombustivelFaturamento: "Grupo Combustível - Faturamento R$",
    grupoCombustivelVolume: "Grupo Combustível - Volume Combustível (Litros)",
    grupoDistFaturamento: "Grupo Distribuidor/TRR - Faturamento R$",
    grupoDistPrecosMedios: "Grupo Distribuidor/TRR - Preços Médios dos Litros R$",
    grupoDistVolumeCombustivel: "Grupo Distribuidor/TRR - Volume Combustível (Litros)",
    grupoEconomico: "Grupo Econômico",
    grupoGeralFaturamento: "Grupo Geral - Faturamento R$",
    grupoGeralReceita: "Grupo Geral - Receita R$",
    grupoGeralTaxasMediasClientes: "Grupo Geral - Taxas Médias Clientes R$",
    grupoGeralTaxasMediasClientesPorcentagem: "Grupo Geral - Taxas Médias Clientes %",
    grupoGeralVolumeCombustiveisLitrosReais:
      "Grupo Geral - Volume Combustível em Litros (Litros)",
    grupoGeralVolumeVendidoENaoVendidoDistTRR:
      " Grupo Geral - Volume Vendido e Não Vendido",
    grupoNegociacoesRealizadosXPedidosFaturados:
      "Grupo Geral - Cotações Realizadas x Pedidos Faturados",
    grupoPrazoMediosPagamentoReembolso:
      "Grupo Geral - Prazos Médios de Pagamento e Reembolso",
    grupoPrecosMedio: "Grupo Geral - Preços Médios dos Litros R$",
    grupoRankingClientes: "Grupo Ranking - Ranking de Clientes (Litros)",
    grupoRankingDistribuidores: "Grupo Ranking - Ranking de Distribuidores/TRR (Litros)",
    grupos: "Grupos",
    historicoNavegacao: "Hist. Navegação",
    historicoNegociacaoDistribuidor: "Histórico de Neg. Distribuidores/TRRs",
    horario: "Horário",
    horarioDesejado: "Horário Desejado",
    imagem: "Imagem",
    importar: "Importar",
    inativo: "Inativo",
    incluir: "Incluir",
    informacoesEndereco: "Informações Endereço",
    informativo: "Informativo",
    informeMotivoReprovacaoCotacao:
      "Informe o motivo da reprovação do pedido/cotação",
    ingles: "Inglês",
    inscricaoEstadual: "Inscrição Estadual",
    insiraPermissoes: "Por favor insira as permissões",
    integracao: "Integração",
    integrado: "Integrado",
    integrar: "Integrar",
    interativo: "Interativo",
    justifique: "Justifique",
    lembrarMe: "Lembrar-me",
    liberarCotacaoCliente: "Liberar Cotação para Cliente",
    limiteCreditoDisponivel: "Limite de Crédito Disponível",
    limiteCreditoDisponivelParaPedidos:
      "Seu Limite de Crédito Disponível para Pedidos",
    limiteDisponivel: "R$ Limite Disponível",
    limiteUtilizado: "R$ Limite Utilizado",
    limparTudo: "Limpar tudo",
    listar: "Listar",
    litrosComprados: "M³/Litros Comprados",
    litrosVendidos: "M³/Litros Vendidos",
    local: "Local",
    logradouro: "Logradouro",
    matriz: "Matriz",
    melhoresPrecosHoje: "Melhores Preços de Hoje – Atualizado em",
    mesaOperacao: "Mesa de Operação",
    messageHomeCliente:
      "Por gentileza insira seu pedido (cotação) até às 14:00 para obter melhores negociações.",
    messageHomeDistribuidor:
      "Por gentileza atualize seus preços diários e responda as cotações recebidas.",
    messageHomeSodexo:
      "Por gentileza verifique as cotações a atender e valide as informações.",
    messageLogin: "Faça o login para continuar",
    meusPedidos: "Meus Pedidos",
    minimoSeisParaSenha: "Sua senha deve ter no mínimo 6 digitos",
    modalidadeTransporte: "Modalidade de Transporte",
    modoAbastecimento: "Tipo de transporte",
    motivos: "Motivos",
    motivosCancelamento: "Motivos Cancelamento",
    motivosReprovacao: "Motivos Reprovação",
    naoExistePrecosCadastrados: "Não existem preços cadastrados",
    naoPermitidoExcederPrazo: "Não é permitido exceder o prazo máximo",
    navegacao: "Menu",
    nenhumPedidoSelecionado: "Nenhum pedido selecionado",
    nenhumRegistroEncontrado: "Nenhum registro encontrado",
    nfDistribuidorTRR: "NF Distribuidor/TRR",
    nfeCompra: "NFe Compra",
    nfeServico: "NFe Serviço",
    nivel: "Nível",
    nome: "Nome",
    nomeCertificado: "Nome certificado",
    nomeFantasia: "Nome Fantasia",
    nomePerfil: "Nome do Perfil",
    notaFiscal: "Nota fiscal",
    novoPedido: "Novo Pedido",
    numPedidoBase: "Nº Pedido Base",
    numPedidoCliente: "Nº Pedido Cliente",
    numPedidoSistema: "Nº Pedido Sistema",
    numPedidoIntegrado: "Nº Pedido Integrado",
    numero: "Número",
    numeroCotacao: "Número Cotação",
    numeroNFDistribuidores: "Número NF Distribuidor/TRR",
    numeroPedidoCliente: "Número do Pedido Cliente",
    numeroPedidoSistema: "Número do Pedido Sistema",
    observacoes: "Observações",
    opcaoSim: "Sim",
    opcaoNao: "Não",
    operacao: "Operação",
    operador: "Operador",
    pagamento: "Pagamento",
    paginaNaoEncontrada: "Página não encontrada",
    painelControle: "Painel de Controle",
    parametrosGeracao: "Parâmetros de Geração",
    passwordEditadoComSucesso: "Senha Atualizada com sucesso.",
    passwordNaoConfere: "As senhas não conferem",
    pedidoCotacao: "Pedido (Cotação)",
    pedidoCriadoComSucesso: "Pedido criado com sucesso",
    pedidoDetalhado: "Pedidos Detalhados",
    pedidoProgramadoComSucesso: "Pedido Programado com sucesso",
    pedidos: "Pedidos",
    statusPedidos: "Status Pedido",
    pedidosFaturados: "Pedidos Faturados",
    pedidosProgramado: "Pedidos Programados",
    pedidosVendidos: "Pedidos Vendidos",
    perfil: "Perfil",
    perfilCriadoComSucesso: "Perfil criado com sucesso",
    perfilDeAcessoCliente: "Perfis de Acessos Cliente",
    perfilDeAcessoDistribuidor: "Perfis de Acessos Distribuidor/TRR",
    perfilDeAcessoSodexo: "Perfis de Acessos Pluxee",
    perfilEditadoComSucesso: "Perfil editado com sucesso",
    perfilExcluidoComSucesso: "Perfil excluído com sucesso",
    perfis: "Perfis",
    periodo: "Período",
    periodoDesejado: "Período Desejado",
    porcentagemTotal: "Taxas média (%)",
    portugues: "Português",
    posicao: "Posição",
    prazo: "Prazo",
    prazoPagamento: "Prazo de Pagamento",
    prazoPagamentoEmDias: "Prazo do Pagamento (Em Dias)",
    preco: "Preço",
    precoLitro: "Preço (Litro)",
    precoFinalLitro: "Preço Final (Litro)",
    precoFreteLitro: "Preço Frete (Litro)",
    precoMedio: "Preço Médio",
    precoMedioCombustiveis: "Preço Médio dos Combustíveis",
    precoNegociadoLitro: "Preço Negociado (Litro)",
    precoReferenciaLitro: "Preço Referência (Litro)",
    precoReferencia: "Preço Referência Litro",
    precosCombustiveis: "Preços Combustíveis",
    precosCombustiveisExportar: "Exportar Template Preços Combustíveis",
    privado: "Privado",
    produto: "Produto",
    produtos: "Produtos",
    programacaoPedido: "Programação de Pedido",
    programar: "Programar",
    proximo: "Próximo",
    publico: "Público",
    quantidade: "Quantidade",
    quantidadeTotal: "Quantidade Pedidos",
    quanto: "Quanto?",
    rankingDistribuidores: "Ranking de Distribuidores/TRR",
    razaoSocial: "Razão Social",
    realizados: "Realizados",
    receita: "Receita",
    redefinirSenha: "Redefinir Senha",
    reembolso: "Reembolso",
    regiao: "Região",
    registroANP: "Registro ANP",
    registroCriadoComSucesso: "Registro Criado com Sucesso",
    registroEditadoComSucesso: "Registro Editado com Sucesso",
    registroExcluidoComSucesso: "Registro Excluído com Sucesso",
    reintegrar: "Reintegrar",
    relatorio: "Relatórios",
    relatorioCriadoComSucesso: "Relatório criado com sucesso",
    reprovado: "Reprovado",
    reprovar: "Reprovar",
    reprovarPedido: "Reprovar Pedido",
    aprovarPedido: "Aprovar Pedido",
    toleranciaMaxima: "Tolerância Máxima de Limite",
    rotina: "Rotina",
    saida: "Saída",
    saldoCreditoDisponivel: "Saldo de Crédito Disponível",
    salvar: "Salvar",
    searchEmail: "Procurar e-mail",
    selecionar: "Selecionar",
    selecionarCotacao: "Selecionar Cotação",
    selecionarUmaOpcaoAcima: "Necessário selecionar umas das opções acima",
    selecione: "Selecione",
    selecioneCidadeCarregarBasesDistribuicao:
      "Selecione um cidade para carregar as bases de distribuição",
    selecioneDistribuidorParaDownload:
      "Selecione um distribuidor/TRR para fazer o download.",
    selecioneEmpresaParaInserirPerfil:
      "Selecione uma empresa para inserir os perfis",
    selecioneEstadoCarregar: "Selecione um estado para carregar",
    selecionePrazoLiberarPedido: "Selecione um prazo para liberar o pedido.",
    selecioneRegiaoCarregar: "Selecione uma região para carregar",
    semUsuariosCadastrados: "Não há usuários cadastrados",
    senha: "Senha",
    senhaAlteradaComSucesso: "Senha alterada com sucesso",
    seuLimiteSaldoDisponivel: "Saldo Disponível para Pedidos",
    status: "Status",
    tabelas: "Tabelas",
    tanquesProprios: "Tanques Próprios",
    taxaAdministrativa: "Taxa Administrativa",
    taxaFinanceira: "Taxa Financeira",
    taxaMedia: "Taxa Média",
    taxaTotal: "Taxa Total",
    telefone: "Telefone",
    tipo: "Tipo",
    tipoRecebimento: "Tipo de Recebimento",
    todos: "Todos",
    topClientes: "Top Clientes",
    topDistribuidores: "Top Distribuidores",
    usuario: "Usuário",
    usuarioCriadoComSucesso: "Usuário criado com sucesso.",
    usuarioEditadoComSucesso: "Usuário editado com sucesso.",
    usuarios: "Usuários",
    validadeCertificado: "Validade certificado",
    valorCotacoes: "Valor Pedidos",
    valorFaturadoComTaxas: "BVI - Valor faturado com taxas",
    valorFaturadoSemTaxas: "BV – Valor faturado sem taxas",
    valorPedido: "Valor pedido",
    valorTotalPedidoTaxas: "Valor Total Pedido com taxas",
    vendidos: "Vendidos",
    voltar: "Voltar",
    volteLogin: "Volte e faça o login",
    volumeCombustivelLitrosValorReais:
      "Volume Combustível em litros e valor em reais (Litros)",
    volumeComprasMensal: "Volume de Compras Mensal",
    volumeM2: "Volume M³",
    volumeNaoVendido: "Volume não vendido",
    volumeVendido: "Volume vendido",
    voceTemCerteza: "Você tem certeza?",
    volumeVendidoENaoVendidoDistribuidor: "Volume Vendido e Não Vendido (Litros)",
    selecioneClienteCarregar: "Selecione um cliente para carregar",
    prazoCotacaoNaoEditado:
      "Prazo da cotação ainda não editado pelo distribuidor/TRR, aguarde para liberar.",
    historicoNegociacao: "Histórico de Negociação",
    dadosCliente: "Dados do Cliente",
    analise: "Análise",
    entregue: "Entregue",
    reanalise: "Reanálise Distribuidor/TRR",
    analiseCliente: "Análise Cliente",
    naoExisteTopClientes: "Não possui Top Clientes",
    naoExisteTopDistribuidores: "Não possui Top Distribuidores",
    naoExisteMelhorPreco: "Não possui melhores preços hoje",
    selecioneCliente:
      "Selecione um cliente que possua preços cadastrados na base",
    sodexo: "Pluxee",
    direitaAlta: "Direita Alta",
    direitaBaixa: "Direita Baixa",
    rodape: "Rodapé",
    centro: "Centro",
    totalRegistros: "Total de registros",
    notaServiço: "Nota Fiscal Serviço",
    notaDistribuidor: "Nota Fiscal Distribuidor/TRR",
    criador: "Criado por",
    selecioneArquivo: "Selecione o arquivo",
    fazerUpload: "Fazer Upload",
    infoMotorista: "Informações Motorista",
    nomeMotorista: "Nome Motorista",
    rgMotorista: "RG Motorista",
    cpfMotorista: "CPF Motorista",
    placaVeiculo: "Placa Veículo",
    atualizarTaxas: "Atualizar taxas do pedido",
    dias: "dias",
    dataCriacao: "Data Criação",
    totalLitrosPendentes: "M³/Litros Pendentes"
  },
  en: {
    cadastroGeral: "General Registration",
    editarDadosCadastrais: "Edit Registration Data",
    editarDadosEndereco: "Edit Address Data",
    editarDadosEntrega: "Edit Delivery Data",
    editarDadosCobrança: "Edit Billing Data",
    editarConsumoCapacidade: "Edit Consumption and Capacity",
    editarCertificado: "Edit Digital Certificate",
    editarBasesDistribuicao: "Edit Distribution Bases",
    editarPedido: "Edit Order",
    acessos: "Access",
    acoes: "Actions",
    aguardandoIntegracao: "Awaiting integration",
    aguardando: "Awaiting Billing",
    aguardandoAprovacao: "Waiting for approval",
    ajusteDistribuidor: "Request Distributor/TRR Adjustment",
    algoErradoExcluirPerfil: "Something wrong when trying to delete profile",
    algoErradoExcluirRegistro: "Something wrong to try to exclude or register",
    alterarSenhaUsuario: "Change User Password",
    anterior: "Previous",
    aprovar: "Approve",
    aprovado: "Approved",
    arquivo: "File",
    arquivoImportadoComSucesso: "Successfully imported file",
    asPasswordsNaoCorrespondem:
      "The Password and Confirm password fields do not match",
    ate: "Up until",
    ativo: "Active",
    bairro: "Neighborhood",
    banner: "Banner",
    baseDistribuicao: "Distribution Bases",
    base: "Base",
    baseDistribuiçãoCriadoSucesso: "Distribution base successfully created",
    basesDistribuicao: "Distribution Bases",
    boleto: "Payment Slip",
    cadastrar: "Register",
    cadastrarBaseDistribuicao: "Register Distribution Base",
    cadastrarClassificacaoCliente: "Register Client Classification",
    cadastrarClassificacaoDistribuidores:
      "Register Classification of Distributors",
    cadastrarCliente: "Register Client",
    cadastrarComunicado: "Register Communication",
    cadastrarConsultor: "Register Consultant",
    cadastrarDistribuidorTRR: "Register Distributors/TRR",
    cadastrarMesaOperacao: "Register Operation Table",
    cadastrarPerfilAcessoCliente: "Register Client Access Profile",
    cadastrarPerfilAcessoDistribuidor:
      "Register Distributor/TRR Access Profile",
    cadastrarPerfilAcessoSodexo: "Register Pluxee Access Profile",
    cadastrarPrecos: "Register Prices",
    cadastrarProduto: "Product Registration",
    relatorioCadastroCliente: "Customer Registration Report",
    relatorioCadastroDistribuidorTRR: "Distributor Registration / TRRs Report",
    relatorioCondicoesComerciaisClientes: "Business Conditions Report Clients",
    relatorioExtratoReembolsoDistribuidoresTRRs:
      "Extract Refund Report Distributors / TRRs",
    relatorioHistoricoNegociacoesDistribuidoresTRRs:
      "Historical Report of Negotiations with Distributors / TRRs",
    relatorioLog: "Logs Report",
    relatorioPedidosDetalhados: "Detailed Orders report",
    relatorioPrecoMedioCombustiveis: "Average Fuel Price Report",
    relatorioSaving: "Saving report",
    acao: "Action",
    statusAtivo: "Active",
    statusInativo: "Inactive",
    nivelMaster: "Master",
    nivelComum: "Common",
    cadastro: "Register",
    cadastroCliente: "Customer base",
    cadastroDistribuidorTRR: "Registration of Distributors/TRRs",
    cadastros: "Registration",
    cancelado: "Canceled",
    cancelarEdicao: "Cancel Edit",
    capacidadeTanque: "Tank Capacity",
    carregando: "Loading...",
    celular: "Cell phone",
    cep: "Zip code",
    certificadoDigital: "Digital certificate",
    certificadoDigital30: "Certificates Expiring: 30 Days",
    certificadoDigital60: "Certificates Expiring: 60 Days",
    certificadoDigitalA1: "A1 Digital Certificate  (.pfx)",
    cidade: "City",
    classificacao: "Classification",
    classificacaoClientes: "Customer Classification",
    classificacaoDistribuidores: "Distributors Classification",
    cliente: "Client",
    clienteCriadoComSucesso: "Customer successfully created",
    clientes: "Customers",
    cliqueAqui: "Click here",
    cnpjInvalido: "CNPJ invalid",
    codigo: "Code",
    codigoExterno: "External Code",
    complemento: "Complement",
    comunicacao: "Communication",
    comunicadoInformacoes: "Announcements and Information",
    condicaoComercialCliente: "Customer Commercial Conditions",
    confirmacaoSenha: "Confirm password",
    consultaPedidoCotacao: "Consultation of Order/Quotation",
    consultaPrecosCombustiveis: "Consult Fuel Prices",
    consultaPrecosDia: "Consult prices of the day ",
    consultor: "Consultant",
    consultores: "Consultants",
    consumoCapacidade: "Consumption and Capacity",
    contato: "Contact",
    copyright: "© Trade Pluxee. All Right Reserved 2022",
    cotacao: "Price",
    statusCotacao: "Status Price",
    cotacaoEnviadaComSucesso: "Quotation sent successfully",
    cotacaoRedirecionadaCliente: "Quote redirected to the client",
    cotacaoEnviadaCliente: "This quote has already been sent to the customer",
    cancelar: "Cancel",
    cotacaoRedirecionadaDistribuidor: "Quote redirected to the distributor",
    cotacoesAprovar: "Quotes to Approve",
    cotacoesResponder: "Quotes to Respond",
    cotacoesRespondidas: "Answered Quotes",
    cotacoesValidar: "Quotes to Validate",
    criar: "Create",
    criarNovoUsuario: "Create a new user",
    criarPerfilAcesso: "Create a new access profile",
    dadosCadastrais: "Registration data",
    dadosCapacidade: "Data Capacity",
    dadosCobranca: "Data Collection",
    dadosEndereco: "Address Data",
    dadosEntrega: "Delivery Details",
    dadosPedido: "Order Data",
    data: "Date",
    dataEntrega: "Delivery date",
    dataEntregaDesejada: "Desired Delivery Date",
    dataEntregaInvalidaPedido: "Invalid delivery date for this order",
    dataFim: "End date",
    dataInicio: "Start date",
    de: "In",
    descricao: "Description",
    descricaoPerfil: "Profile Description",
    desejaExcluirRegistro: "Do you really want to exclude or record?",
    detalhes: "Details",
    detalhesProdutos: "Product Details",
    digiteNcM: "Enter one or more NCMs",
    distanciaKM: "Distance km (Customer Address x Distributor/TRR Address)",
    distribuidor: "Distributor/TRR",
    distribuidorOnly: "Distributor",
    distribuidorPreferencia: "Preference Distributor",
    distribuidores: "Distributors/TRR",
    distribuidores_trrs: "Distributors/TRRs",
    editar: "Edit",
    editarBaseDistribuicao: "Edit Distribution Base",
    editarClassificacaoCliente: "Edit Client Classification",
    editarClassificacaoDistribuidores: "Edit Distributor Classification",
    editarCliente: "Edit Client",
    editarComunicado: "Edit Announcement",
    editarConsultor: "Edit Consultant",
    editarDistribuidorTRR: "Edit Distributors / TRR",
    editarMesaOperacao: "Edit Operation Table",
    editarPerfilAcesso: "Edit access profile",
    editarPerfilAcessoCliente: "Edit Client Access Profile",
    editarPerfilAcessoSodexo: "Edit Pluxee Access Profile",
    editarProduto: "Edit Product",
    editarUsuario: "Edit User",
    emailInvalido: "Use a valid email",
    emailObrigatorio: "E-mail is required",
    erroAoEnviarEmail: "Error trying to send E-mail",
    liberarPedido: "Release Order",
    cancelarPedido: "Cancel Order/Quote",
    empresa: "Company",
    empresaMatriz: "Parent Company",
    encontreSuaSenha: "Find your Trade account",
    endereco: "Address",
    entrar: "Sign in",
    entrega: "Delivery",
    enviar: "Send",
    erroAlterarSenha: "Error changing password",
    erroAtualizarSenha: "Error updating password",
    erroCadastrarPerfil: "Error registering profile",
    erroCarregarRegioes: "Error loading regions",
    erroCredenciais: "Something it´s wrong. Verify your data",
    erroCriarPedidoProgramado: "Error creating scheduled order",
    erroCriarRegistro: "Error creating record",
    erroDeCriarUsuario: "Error creating user",
    erroDeEditarPassword: "Error editing password.",
    erroDeEditarUsuario: "Error editing user",
    erroEditarRegistro: "Error editing the registry",
    erroCadastroIncompleto:
      "Incomplete registration. Contact the operating table",
    erroExcluirRegistro: "Failed to or exclude or record",
    erroImportarRegistro: "Error importing the record",
    errorExportarRelatorio: "Error exporting report",
    escolhaSaidaGerarRelatorio: "Choose an output to generate the report",
    espanhol: "Spanish",
    esqueceuSenha: "Forgot password?",
    estado: "State",
    excluir: "Delete",
    exibicao: "Exhibition",
    exibir: "Display",
    exibirBaseDistribuicao: "Display Distribution Base",
    exibirClassificacaoCliente: "View Client Classification",
    exibirClassificacaoDistribuidores: "View Distributor Classification",
    exibirCliente: "View Client",
    exibirComunicado: "Display Announcement",
    exibirConsultor: "View Consultant",
    exibirDistribuidorTRR: "Display Distributors/TRR",
    exibirMesaOperacao: "Display Operating Table",
    maximoDe: "Maximum size of",
    exibirPara: "View to",
    exibirPerfilAcesso: "View access profile",
    exibirPerfilAcessoCliente: "View Client Access Profile",
    exibirPerfilAcessoDistribuidor: "View Distributor/TRR Access Profile",
    exibirPerfilAcessoSodexo: "View Pluxee Access Profile",
    exibirPreco: "View Prices",
    exibirProduto: "View Product",
    exportarExcel: "Export Excel",
    exportarTemplate: "Export Template",
    extratoReembolsoDistribuidor: "Distributor Refund Statement/TRR",
    faturado: "Billed",
    faturados: "Billed",
    filtrar: "Filter",
    financeiro: "Financial",
    frete: "Freight",
    gerar: "Generate",
    grupo: "Group",
    grupoBaseDistribuicaoReceita: "Base Group Distribution - Revenue R$",
    grupoBasesDistFaturamento: "Base Group Distribution - Revenue in R$",
    grupoBasesDistPrecosMedios:
      "Bases Distribution Group - Average Liters Prices",
    grupoBasesDistMediaCliente:
      "Base Group Distribution - Average Liters Prices",
    grupoBasesDistVolumeCombustivel: "Base Group Distribution - Fuel Volume",
    grupoCombustiveisPrecosMediosLitros: "Fuel Group - Average Liters Prices",
    grupoCombustivelFaturamento: "Fuel Group - Revenue in R$",
    grupoCombustivelVolume: "Fuel Group - Fuel Volume",
    grupoDistFaturamento: "Distribution Group - Revenue in R$",
    grupoDistPrecosMedios: "Distribution Group - Average Liters Prices",
    grupoDistVolumeCombustivel: "Distribution Group - Fuel Volume",
    grupoEconomico: "Economic group",
    grupoGeralFaturamento: "General Group - Revenue in R$",
    grupoGeralReceita: "General Group - Revenue in R$",
    grupoGeralTaxasMediasClientes: "General Group - Average Customer Rates R$",
    grupoGeralTaxasMediasClientesPorcentagem: "General Group - Average Customer Rates %",
    grupoGeralVolumeCombustiveisLitrosReais:
      "General Group - Fuel Volume in Liters",
    grupoGeralVolumeVendidoENaoVendidoDistTRR:
      "General Group - Volume Sold and Unsold",
    grupoNegociacoesRealizadosXPedidosFaturados:
      "General Group - Quotes Made x Billed Orders",
    grupoPrazoMediosPagamentoReembolso:
      "General Group - Average Payment and Refund Deadlines",
    grupoPrecosMedio: "General Group - Average Liters Prices",
    grupoRankingClientes: "Group Ranking - Customer Ranking (Liters)",
    grupoRankingDistribuidores: "Group Ranking - Distributor/TRR Ranking (Liters)" ,
    grupos: "Groups",
    historicoNavegacao: "History Navigation",
    historicoNegociacaoDistribuidor: "Trading History Distributors/TRRs",
    horario: "Hours",
    horarioDesejado: "Desired Time",
    imagem: "Image",
    importar: "Import",
    inativo: "Inactive",
    incluir: "Add",
    informacoesEndereco: "Information Address",
    informativo: "Informative",
    informeMotivoReprovacaoCotacao:
      "Enter the reason for the disapproval of the order/quote",
    ingles: "English",
    inscricaoEstadual: "State registration",
    insiraPermissoes: "Please enter permissions",
    integracao: "Integration",
    integrado: "Integrated",
    integrar: "Integrate",
    interativo: "Interactive",
    justifique: "Justify",
    lembrarMe: "Remember-me",
    liberarCotacaoCliente: "Release Quote for Customer",
    limiteCreditoDisponivel: "Available Credit Limit",
    limiteCreditoDisponivelParaPedidos:
      "Your Credit Limit Available for Orders",
    limiteDisponivel: "R$ Available Limit",
    limiteUtilizado: "R$ Limit Used",
    limparTudo: "Clean all",
    listar: "List",
    litrosComprados: "M³/Liters Purchased",
    litrosVendidos: "M³/Liters Sold",
    local: "Place",
    logradouro: "Public street",
    matriz: "Matrix",
    melhoresPrecosHoje: "Today's Best Prices - Updated",
    mesaOperacao: "Operation Table",
    messageHomeCliente:
      "Please insert your order (quote) until 14:00 to get better negotiations.",
    messageHomeDistribuidor:
      "Please update your daily prices and reply to the quotations received.",
    messageHomeSodexo:
      "Please check the quotations to be met and validate the information.",
    messageLogin: "Log in to continue",
    meusPedidos: "My Orders",
    minimoSeisParaSenha: "Your password must be at least 6 digits",
    modalidadeTransporte: "Mode of Transport",
    modoAbastecimento: "Type of Transport",
    motivos: "Reasons",
    motivosCancelamento: "Reasons for Cancellation",
    motivosReprovacao: "Disapproval Reasons",
    naoExistePrecosCadastrados: "There are no registered prices",
    naoPermitidoExcederPrazo: "It is not allowed to exceed the maximum term",
    navegacao: "Navigation",
    nenhumPedidoSelecionado: "No orders selected",
    nenhumRegistroEncontrado: "No records found",
    nfDistribuidorTRR: "Invoice Distributor/TRR",
    nfeCompra: "Purchase Invoice",
    nfeServico: "Service Invoice",
    nivel: "Level",
    nome: "Name",
    nomeCertificado: "Certified name",
    nomeFantasia: "Fantasy name",
    nomePerfil: "Profile Name",
    notaFiscal: "Invoice",
    novoPedido: "New Order",
    numPedidoBase: "Base Order No.",
    numPedidoCliente: "Customer Order No.",
    numPedidoSistema: "System Order No.",
    numPedidoIntegrado: "Integrate Order No.",
    numero: "Number",
    numeroCotacao: "Quotation Number",
    numeroNFDistribuidores: "Invoice Number Distributor/TRR",
    numeroPedidoCliente: "Customer Order Number",
    numeroPedidoSistema: "System Order Number",
    observacoes: "Comments",
    opcaoSim: "Yes",
    opcaoNao: "No",
    operacao: "Operation",
    operador: "Operator",
    pagamento: "Payment",
    paginaNaoEncontrada: "Page not found",
    painelControle: "Control panel",
    parametrosGeracao: "Generation Parameters",
    passwordEditadoComSucesso: "Password updated successfully.",
    passwordNaoConfere: "Passwords do not match",
    pedidoCotacao: "Order (quotation)",
    pedidoCriadoComSucesso: "Order successfully created",
    pedidoDetalhado: "Detailed Orders",
    pedidoProgramadoComSucesso: "Order Scheduled successfully",
    pedidos: "Orders",
    statusPedidos: "Status Order",
    pedidosFaturados: "Billed Orders",
    pedidosProgramado: "Scheduled orders",
    pedidosVendidos: "Orders Sold",
    perfil: "Profile",
    perfilCriadoComSucesso: "Profile successfully created",
    perfilDeAcessoCliente: "Client Access Profiles",
    perfilDeAcessoDistribuidor: "Distributor/TRR Access Profiles",
    perfilDeAcessoSodexo: "Pluxee Access Profiles",
    perfilEditadoComSucesso: "Profile edited successfully",
    perfilExcluidoComSucesso: "Profile excluded as successor",
    perfis: "Profiles",
    periodo: "Period",
    periodoDesejado: "Desired Period",
    porcentagemTotal: "Average Rate (%)",
    portugues: "Portuguese",
    posicao: "Position",
    prazo: "Term",
    prazoPagamento: "Payment Term",
    prazoPagamentoEmDias: "Payment Term (In Days)",
    preco: "Price",
    precoLitro: "Price (Liter)",
    precoFinalLitro: "Final Price (Liter)",
    precoFreteLitro: "Shipping Price (Liter)",
    precoMedio: "Average price",
    precoMedioCombustiveis: "Average Fuel Price",
    precoNegociadoLitro: "Negotiated Price Liter",
    precoReferenciaLitro: "Price Reference Liter (purchase)",
    precoReferencia: "Price Reference Liter",
    precosCombustiveis: "Fuel Prices",
    precosCombustiveisExportar: "Export Fuel Prices Template",
    privado: "Private",
    produto: "Product",
    produtos: "Products",
    programacaoPedido: "Order Schedule",
    programar: "Schedule",
    proximo: "Next",
    publico: "Public",
    quantidade: "Quantity",
    quantidadeTotal: "Total Orders",
    quanto: "How much?",
    rankingDistribuidores: "Distributor/TRR Ranking",
    razaoSocial: "Company",
    realizados: "Performed",
    receita: "Recipe",
    redefinirSenha: "Redefine Password",
    reembolso: "Refund",
    regiao: "Region",
    registroANP: "ANP Registration",
    registroCriadoComSucesso: "Record Created Successfully",
    registroEditadoComSucesso: "Record Edited Successfully",
    registroExcluidoComSucesso: "Record Successfully Deleted",
    reintegrar: "Reintegrate",
    relatorio: "Reports",
    relatorioCriadoComSucesso: "Report successfully created",
    reprovado: "Disapproved",
    reprovar: "Disapprove",
    reprovarPedido: "Disapprove Order",
    aprovarPedido: "Approve Order",
    toleranciaMaxima: "Maximum Threshold Tolerance",
    rotina: "Routine",
    saida: "Output",
    saldoCreditoDisponivel: "Available Credit Balance",
    salvar: "Save",
    searchEmail: "Search e-mail",
    selecionar: "Select",
    selecionarCotacao: "Select Quotation",
    selecionarUmaOpcaoAcima:
      "It is necessary to select one of the options above",
    selecione: "Select",
    selecioneCidadeCarregarBasesDistribuicao:
      "Select a city to load the distribution bases",
    selecioneDistribuidorParaDownload: "Select a distributor/TRR to download",
    selecioneEmpresaParaInserirPerfil: "Select a company to insert profiles",
    selecioneEstadoCarregar: "Select a state to load",
    selecionePrazoLiberarPedido: "Select a term to release the order",
    selecioneRegiaoCarregar: "Select a region to load",
    semUsuariosCadastrados: "There are no registered users",
    senha: "Password",
    senhaAlteradaComSucesso: "Password changed successfully",
    seuLimiteSaldoDisponivel: "Balance Available for Orders",
    status: "Status",
    tabelas: "Tables",
    tanquesProprios: "Own Tanks",
    taxaAdministrativa: "Administrate tax",
    taxaFinanceira: "Financial Rate",
    taxaMedia: "Average Rate",
    taxaTotal: "Total Rate",
    telefone: "Phone",
    tipo: "Type",
    tipoRecebimento: "Receipt Type",
    todos: "All",
    topClientes: "Top Clients",
    topDistribuidores: "Top Distributors",
    usuario: "User",
    usuarioCriadoComSucesso: "User created successfully",
    usuarioEditadoComSucesso: "User edited successfully",
    usuarios: "Users",
    validadeCertificado: "Certificate validity",
    valorCotacoes: "Value Ordered",
    valorFaturadoComTaxas: "BVI - Amount billed with fees",
    valorFaturadoSemTaxas: "BV – Billed amount without fees",
    valorPedido: "Order amount",
    valorTotalPedidoTaxas: "Total amount Ordered with fees",
    vendidos: "Sold",
    voltar: "Back",
    voceTemCerteza: "Are you sure?",
    volteLogin: "Go back to login",
    volumeCombustivelLitrosValorReais:
      "Fuel volume in liters and value in reais",
    volumeComprasMensal: "Monthly Purchase Volume",
    volumeM2: "Volume M³",
    volumeNaoVendido: "Unsold volume",
    volumeVendido: "Volume sold",
    volumeVendidoENaoVendidoDistribuidor:
      "Volume Sold and Unsold by Distributor/TRR",
    selecioneClienteCarregar: "Select a customer to upload",
    editarPerfilAcessoDistribuidor: "Edit Distributor/TRR Access Profile",
    prazoCotacaoNaoEditado:
      "Quotation deadline not yet edited by the distributor/TRR, wait to release.",
    historicoNegociacao: "Trading History",
    dadosCliente: "Client's data",
    analise: "Analyze",
    entregue: "Delivered",
    reanalise: "Reanalysis Distributor/TRR",
    analiseCliente: "Customer Analysis",
    naoExisteTopClientes: "No Top Customers",
    naoExisteTopDistribuidores: "No Top Distributors",
    naoExisteMelhorPreco: "No better prices today",
    selecioneCliente: "Select a customer that has prices registered in base",
    sodexo: "Pluxee",
    direitaAlta: "High Right",
    direitaBaixa: "Low Right",
    rodape: "Baseboard",
    centro: "Center",
    totalRegistros: "Total register",
    notaServiço: "Service Invoice",
    notaDistribuidor: "Invoice Distributor/TRR",
    criador: "Created by",
    selecioneArquivo: "Select the file",
    fazerUpload: "Make Upload",
    infoMotorista: "Driver Information",
    nomeMotorista: "Name Driver",
    rgMotorista: "RG Driver",
    cpfMotorista: "CPF Driver",
    placaVeiculo: "Vehicle Plate",
    atualizarTaxas: "Update order rates",
    dias: "days",
    dataCriacao: "Creation Date",
    totalLitrosPendentes: "M³/Litros Pending"
  },
  es: {
    cadastroGeral: "Registro general",
    editarDadosCadastrais: "Editar datos de registro",
    editarDadosEndereco: "Editar datos de dirección",
    editarDadosEntrega: "Editar datos de entrega",
    editarDadosCobrança: "Editar datos de facturación",
    editarConsumoCapacidade: "Editar consumo y capacidad",
    editarCertificado: "Editar certificado digital",
    editarBasesDistribuicao: "Editar bases de distribución",
    editarPedido: "Editar orden",
    acessos: "Acceso",
    acoes: "Acciones",
    aguardandoIntegracao: "Esperando integración",
    aguardando: "Esperando Facturación",
    aguardandoAprovacao: "Esperando aprobación",
    ajusteDistribuidor: "Solicitar ajuste de distribuidor/TRR",
    algoErradoExcluirPerfil: "Algo anda mal al intentar borrar el perfil",
    algoErradoExcluirRegistro: "Algo anda mal al intentar borrar el registro",
    alterarSenhaUsuario: "Cambiar contraseña de usuario",
    anterior: "Anterior",
    aprovar: "Aprobar",
    aprovado: "Aprovado",
    arquivo: "Archivo",
    arquivoImportadoComSucesso: "Archivo importado correctamente",
    asPasswordsNaoCorrespondem:
      "Los campos Contraseña y Confirmar contraseña no coinciden",
    ate: "Hasta",
    ativo: "Activo",
    bairro: "Barrio",
    banner: "Bandera",
    baseDistribuicao: "Bases de Distribución",
    base: "Base",
    baseDistribuiçãoCriadoSucesso: "Base de distribución creada con éxito",
    basesDistribuicao: "Bases de distribución",
    boleto: "Carta de pago",
    cadastrar: "Registrar",
    cadastrarBaseDistribuicao: "Registro de la base de distribución",
    cadastrarClassificacaoCliente: "Registrar clasificación de clientes",
    cadastrarClassificacaoDistribuidores:
      "Registro de Clasificación de Distribuidores",
    cadastrarCliente: "Cliente Cadastrar",
    cadastrarComunicado: "Registrar comunicación",
    cadastrarConsultor: "Registrar consultor",
    cadastrarDistribuidorTRR: "Registrar distribuidores/TRR",
    cadastrarMesaOperacao: "Registrar tabla de operaciones",
    cadastrarPerfilAcessoCliente: "Registrar perfil de acceso de cliente",
    cadastrarPerfilAcessoDistribuidor:
      "Registrar perfil de acceso de distribuidor/trr",
    cadastrarPerfilAcessoSodexo: "Registrar el perfil de acceso de Pluxee",
    cadastrarPrecos: "Precios de registro",
    cadastrarProduto: "Registración del producto",
    relatorioCadastroCliente: "Informe de registro de cliente",
    relatorioCadastroDistribuidorTRR: "Informe de registro de Distribuidor/TRR",
    relatorioCondicoesComerciaisClientes:
      "Clientes del Informe de Condiciones Comerciales",
    relatorioExtratoReembolsoDistribuidoresTRRs:
      "Extraer informe de reembolso Distribuidores / TRR",
    relatorioHistoricoNegociacoesDistribuidoresTRRs:
      "Informe histórico de negociaciones con distribuidores / TRR",
    relatorioLog: "Informe de registros",
    relatorioPedidosDetalhados: "Informe detallado de pedidos",
    relatorioPrecoMedioCombustiveis: "Informe de precio medio del combustible",
    relatorioSaving: "Saving informe",
    acao: "Acción",
    statusAtivo: "Activo",
    statusInativo: "Inactivo",
    nivelMaster: "Master",
    nivelComum: "Common",
    cadastro: "Registrarse",
    cadastroCliente: "Base de clientes",
    cadastroDistribuidorTRR: "Registro de distribuidores/TRR",
    cadastros: "Registro",
    cancelado: "Cancelado",
    cancelarEdicao: "Cancelar Editar",
    capacidadeTanque: "Capacidad del tanque",
    carregando: "Cargando...",
    celular: "Teléfono móvil",
    cep: "Código postal",
    certificadoDigital: "Certificado Digital",
    certificadoDigital30: "Certificados que vencen: 30 días",
    certificadoDigital60: "Certificados que vencen: 60 días",
    certificadoDigitalA1: "Certificado Digital A1  (.pfx)",
    cidade: "Ciudad",
    classificacao: "Clasificación",
    classificacaoClientes: "Clasificación de clientes",
    classificacaoDistribuidores: "Clasificación de distribuidores",
    cliente: "Cliente",
    clienteCriadoComSucesso: "Cliente creado con éxito",
    clientes: "Clientes",
    cliqueAqui: "Haga clic aquí",
    cnpjInvalido: "CNPJ invalído",
    codigo: "Código",
    codigoExterno: "Código externo",
    complemento: "Complemento",
    comunicacao: "Comunicación",
    comunicadoInformacoes: "Comunicados e Información",
    condicaoComercialCliente: "Condiciones comerciales del cliente",
    confirmacaoSenha: "confirmar seña",
    consultaPedidoCotacao: "Consulta de Pedido/Cotización",
    consultaPrecosCombustiveis: "Consultar precios de combustible",
    consultaPrecosDia: "Consultar precios del día",
    consultor: "Consultor",
    consultores: "Consultores",
    consumoCapacidade: "Consumo y capacidad",
    contato: "Contacto",
    copyright: "Trade Pluxee. Todos los derechos reservados 2022",
    cotacao: "Precio",
    statusCotacao: "Status Precio",
    cotacaoEnviadaComSucesso: "Cotización enviada con éxito",
    cotacaoRedirecionadaCliente: "Presupuesto redirigido al cliente",
    cancelar: "Cancelar",
    cotacaoEnviadaCliente: "Este presupuesto ya ha sido enviado al cliente",
    cotacaoRedirecionadaDistribuidor: "Presupuesto redirigido al distribuidor",
    cotacoesAprovar: "Cotizaciones para aprobar",
    cotacoesResponder: "Cotizaciones para responder",
    cotacoesRespondidas: "Cotações Respondidas",
    cotacoesValidar: "Cotizaciones para validar",
    criar: "Crear",
    criarNovoUsuario: "Crea un nuevo usuario",
    criarPerfilAcesso: "Crea un nuevo perfil de acceso",
    dadosCadastrais: "Datos de registro",
    dadosCapacidade: "Capacidad de datos",
    dadosCobranca: "Recopilación de datos",
    dadosEndereco: "Datos de dirección",
    dadosEntrega: "Detalles de la entrega",
    dadosPedido: "Datos de los pedidos",
    data: "Fecha",
    dataEntrega: "Fecha de entrega",
    dataEntregaDesejada: "Fecha de entrega deseada",
    dataEntregaInvalidaPedido: "Fecha de entrega no válida para este pedido",
    dataFim: "Fecha de finalización",
    dataInicio: "Fecha de inicio",
    de: "En",
    descricao: "Descripción",
    descricaoPerfil: "Descripción del perfil",
    desejaExcluirRegistro: "Deseja realmente excluir o registro?",
    detalhes: "Detalles",
    detalhesProdutos: "Detalles del producto",
    digiteNcM: "Ingrese uno o más NCM",
    distanciaKM:
      "Distancia km (Dirección del Cliente x Dirección del Distribuidor/TRR)",
    distribuidor: "Distribuidor/TRR",
    distribuidorOnly: "Distribuidor",
    distribuidorPreferencia: "Distribuidor preferencial",
    distribuidores: "Distribuidores/TRR",
    distribuidores_trrs: "Distribuidores/TRRs",
    editar: "Editar",
    editarBaseDistribuicao: "Editar base de distribución",
    editarClassificacaoCliente: "Editar clasificación de cliente",
    editarClassificacaoDistribuidores:
      "Editar de clasificación de distribuidores",
    editarCliente: "Editar cliente",
    editarComunicado: "Editar anuncio",
    editarConsultor: "Editar consultor",
    editarDistribuidorTRR: "Editar distribuidores/TRR",
    editarMesaOperacao: "Editar tabla de operaciones",
    editarPerfilAcesso: "Editar perfil de acceso",
    editarPerfilAcessoCliente: "Editar perfil de acceso del cliente",
    editarPerfilAcessoSodexo: "Editar Perfil de Acesso Pluxee",
    editarProduto: "Editar producto",
    editarUsuario: "Editar Usuario",
    emailInvalido: "Utilice un correo electrónico válido",
    emailObrigatorio: "Correo electrónico requerido",
    erroAoEnviarEmail: "Error al intentar enviar correo electrónico",
    liberarPedido: "Orden de libertad",
    cancelarPedido: "Cancelar pedido/cotización",
    empresa: "Empresa",
    empresaMatriz: "Compañía matriz",
    encontreSuaSenha: "Encuentre su cuenta Trade",
    endereco: "Habla a",
    entrar: "Iniciar sesión",
    entrega: "Entrega",
    enviar: "Enviar",
    erroAlterarSenha: "Error al cambiar la contraseña",
    erroAtualizarSenha: "Error al actualizar la contraseña",
    erroCadastrarPerfil: "Error al registrar el perfil",
    erroCarregarRegioes: "Error al cargar regiones",
    erroCredenciais: "Algo está mal. Verifica tus datos",
    erroCriarPedidoProgramado: "Error al crear el pedido programado",
    erroCriarRegistro: "Error al crear el registro",
    erroDeCriarUsuario: "Error al crear el usuario",
    erroDeEditarPassword: "Error al editar el contraseña.",
    erroDeEditarUsuario: "Error al editar usuario",
    erroEditarRegistro: "Error al editar el registro",
    erroCadastroIncompleto:
      "Registro incompleto. Póngase en contacto con la mesa de operaciones",
    erroExcluirRegistro: "Error al eliminar el registro",
    erroImportarRegistro: "Error al importar el registro",
    errorExportarRelatorio: "Error al exportar el informe",
    escolhaSaidaGerarRelatorio: "Elija una salida para generar el informe",
    espanhol: "Español",
    esqueceuSenha: "Olvidaste tu contraseña?",
    estado: "Estado",
    excluir: "Eliminar",
    exibicao: "Exposición",
    exibir: "Monitor",
    exibirBaseDistribuicao: "Base de distribución de pantalla",
    exibirClassificacaoCliente: "Mostrar clasificación de cliente",
    exibirClassificacaoDistribuidores:
      "Mostrar de clasificación de distribuidor",
    exibirCliente: "Ver cliente",
    exibirComunicado: "Display Announcement",
    exibirConsultor: "Ver consultor",
    exibirDistribuidorTRR: "Display Distributors/TRR",
    exibirMesaOperacao: "Display Operating Table",
    maximoDe: "Tamaño máximo de",
    exibirPara: "Mostrar para",
    exibirPerfilAcesso: "Ver perfil de acceso",
    exibirPerfilAcessoCliente: "Ver perfil de acceso del cliente",
    exibirPerfilAcessoDistribuidor: "Ver perfil de acceso de distribuidor/TRR",
    exibirPerfilAcessoSodexo: "Ver perfil de acceso de Pluxee ",
    exibirPreco: "Ver precios",
    exibirProduto: "Ver el producto",
    exportarExcel: "Exportar Excel",
    exportarTemplate: "Exportar plantilla",
    extratoReembolsoDistribuidor:
      "Declaración de reembolso del distribuidor/TRR",
    faturado: "Facturado",
    faturados: "Facturado",
    filtrar: "Filtrar",
    financeiro: "Financiero",
    frete: "Flete",
    gerar: "Generar",
    grupo: "Grupo",
    grupoBaseDistribuicaoReceita: "Distribución del grupo base: ingresos",
    grupoBasesDistFaturamento: "Grupo Bases Distribuição - Ingresos en R$",
    grupoBasesDistPrecosMedios:
      "Grupo Bases Distribuição - Precios promedio de litros",
    grupoBasesDistMediaCliente:
      "Grupo Bases Distribuição - Precios promedio de litros",
    grupoBasesDistVolumeCombustivel:
      "Grupo Bases Distribuição - Volumen de combustible",
    grupoCombustiveisPrecosMediosLitros:
      "Grupo de combustible - Precios promedio de litros",
    grupoCombustivelFaturamento: "Grupo Combustível - Ingresos en R$",
    grupoCombustivelVolume: "Grupo de combustible - Volumen de combustible",
    grupoDistFaturamento: "Grupo de distribución - Ingresos en",
    grupoDistPrecosMedios: "Grupo de distribución - Precios promedio de litros",
    grupoDistVolumeCombustivel:
      "Grupo de distribución - Volumen de combustible",
    grupoEconomico: "Grupo economico",
    grupoGeralFaturamento: "Grupo general - Ingresos en R$",
    grupoGeralReceita: "Grupo general - Ingresos R$",
    grupoGeralTaxasMediasClientes:
      "Grupo general - Tarifas promedio para clientes R$",
      grupoGeralTaxasMediasClientesPorcentagem:  "Grupo general - Tarifas promedio para clientes %",
    grupoGeralVolumeCombustiveisLitrosReais:
      "Grupo general - Volumen de combustible en litros",
    grupoGeralVolumeVendidoENaoVendidoDistTRR:
      "Grupo general: volumen vendido y no vendido",
    grupoNegociacoesRealizadosXPedidosFaturados:
      "Grupo general: cotações realizadas x pedidos facturados",
    grupoPrazoMediosPagamentoReembolso:
      "Grupo general: plazos medios de pago y reembolso",
    grupoPrecosMedio: "Grupo general - Precios promedio de litros",
    grupoRankingClientes: "Clasificación de grupo - Clasificación de clientes",
    grupoRankingDistribuidores:
      "Clasificación de grupo - Clasificación de distribuidores/TRR",
    grupos: "Grupos",
    historicoNavegacao: "navegación de la historia",
    historicoNegociacaoDistribuidor: "Historial comercial dist/TRR",
    horario: "Horario",
    horarioDesejado: "Tiempo deseado",
    imagem: "Imagen",
    importar: "Importar",
    inativo: "Inactivo",
    incluir: "Incluir",
    informacoesEndereco: "Dirección de información",
    informativo: "Informativo",
    informeMotivoReprovacaoCotacao:
      "Ingrese el motivo del rechazo del pedido/cotización",
    ingles: "Inglés",
    inscricaoEstadual: "Inscripción Estadual",
    insiraPermissoes: "Ingrese los permisos",
    integracao: "Integración",
    integrado: "Integrado",
    integrar: "Integrar",
    interativo: "Interactivo",
    justifique: "Justificar",
    lembrarMe: "Recuérdame",
    liberarCotacaoCliente: "Release Quote for Customer",
    limiteCreditoDisponivel: "Límite de crédito disponible",
    limiteCreditoDisponivelParaPedidos:
      "Su límite de crédito disponible para pedidos",
    limiteDisponivel: "R$ Límite disponible",
    limiteUtilizado: "R$ Límite utilizado",
    limparTudo: "Limpiar todo",
    listar: "Lista",
    litrosComprados: "M³/litros comprados",
    litrosVendidos: "M³/Litros vendidos",
    local: "Sitio",
    logradouro: "Calle público",
    matriz: "Matriz",
    melhoresPrecosHoje: "Los mejores precios de hoy - actualizados",
    mesaOperacao: "Tabla de operaciones",
    messageHomeCliente:
      "Introduzca su pedido (cotización) hasta las 14:00 para mejorar las negociaciones.",
    messageHomeDistribuidor:
      "Actualice sus precios diarios y responda a las cotizaciones recibidas.",
    messageHomeSodexo:
      "Verifique las cotizaciones a cumplir y valide la información.",
    messageLogin: "Inicia sesión para continuar",
    meusPedidos: "Mis pedidos",
    minimoSeisParaSenha: "Tu contraseña debe tener al menos 6 dígitos",
    modalidadeTransporte: "Modo de transporte",
    modoAbastecimento: "Tipo de transporte",
    motivos: "Razones",
    motivosCancelamento: "Motivos de la cancelación",
    motivosReprovacao: "Motivos del rechazo",
    naoExistePrecosCadastrados: "No hay precios registrados",
    naoPermitidoExcederPrazo: "No se permite exceder el plazo máximo",
    navegacao: "Navegación",
    nenhumPedidoSelecionado: "No se seleccionaron pedidos",
    nenhumRegistroEncontrado: "No se encontraron registros",
    nfDistribuidorTRR: "Distribuidor/TRR de facturas",
    nfeCompra: "Factura de compra",
    nfeServico: "Factura de servicio",
    nivel: "Nivel",
    nome: "Nombre",
    nomeCertificado: "Nombre certificado",
    nomeFantasia: "Nombre de fantasía",
    nomePerfil: "Nombre del perfil",
    notaFiscal: "Nota fiscal",
    novoPedido: "Nuevo pedido",
    numPedidoBase: "Número de orden base",
    numPedidoCliente: "No. de pedido del cliente",
    numPedidoSistema: "No. de orden del sistema",
    numPedidoIntegrado: "No. de integrado del sistema",
    numero: "Número",
    numeroCotacao: "Número de cotización",
    numeroNFDistribuidores: "Distribuidor/TRR de número de factura",
    numeroPedidoCliente: "Número de pedido del cliente",
    numeroPedidoSistema: "Número de orden del sistema",
    observacoes: "Comentarios",
    opcaoSim: "Si",
    opcaoNao: "No",
    operacao: "Operación",
    operador: "Operador",
    pagamento: "Pago",
    paginaNaoEncontrada: "Página no encontrada",
    painelControle: "Panel de control",
    parametrosGeracao: "Parámetros de generación",
    passwordEditadoComSucesso: "Contraseña editada correctamente.",
    passwordNaoConfere: "Las contraseñas no coinciden",
    pedidoCotacao: "Solicitud (cotización)",
    pedidoCriadoComSucesso: "Pedido creado correctamente",
    pedidoDetalhado: "Órdenes detalladas",
    pedidoProgramadoComSucesso: "Pedido programado correctamente",
    pedidos: "Peticiones",
    statusPedidos: "Status Peticiones",
    pedidosFaturados: "Pedidos Faturados",
    pedidosProgramado: "Pedidos programados",
    pedidosVendidos: "Pedidos vendidos",
    perfil: "Perfil",
    perfilCriadoComSucesso: "Perfil creado con éxito",
    perfilDeAcessoCliente: "Perfiles de acceso de clientes",
    perfilDeAcessoDistribuidor: "Perfiles de acceso de distribuidor/TRR",
    perfilDeAcessoSodexo: "Perfiles de acceso de Pluxee",
    perfilEditadoComSucesso: "Perfil editado com sucesso",
    perfilExcluidoComSucesso: "Perfil excluído com sucesso",
    perfis: "Perfiles",
    periodo: "Período",
    periodoDesejado: "Período deseado",
    porcentagemTotal: "Tasa promedio (%)",
    portugues: "Portugués",
    posicao: "Posición",
    prazo: "Plazo",
    prazoPagamento: "Plazo de pago",
    prazoPagamentoEmDias: "Plazo de pago (en días)",
    preco: "Precio",
    precoLitro: "Precio (Litros)",
    precoFinalLitro: "Precio final (litros)",
    precoFreteLitro: "Precio de envío (litros)",
    precoMedio: "Precio medio",
    precoMedioCombustiveis: "Precio medio del combustible",
    precoNegociadoLitro: "Precio Negociado Litro",
    precoReferenciaLitro: "Precio Referencia Litro (compra)",
    precoReferencia: "Precio Referencia Litro",
    precosCombustiveis: "Precios Combustible",
    precosCombustiveisExportar: "Plantilla de Precios Combustible Exportación",
    privado: "Privado",
    produto: "Producto",
    produtos: "Productos",
    programacaoPedido: "Programación de pedidos",
    programar: "Calendario",
    proximo: "siguiente",
    publico: "Público",
    quantidade: "Cantidad",
    quantidadeTotal: "Cantidad Pedidos",
    quanto: "¿Cuánto cuesta?",
    rankingDistribuidores: "Ranking de distribuidores/TRR",
    razaoSocial: "Empresa",
    realizados: "Realizado",
    receita: "Receita",
    redefinirSenha: "Redefinir contraseña",
    reembolso: "Reembolso",
    regiao: "Región",
    registroANP: "Registro ANP",
    registroCriadoComSucesso: "Registro creado con éxito",
    registroEditadoComSucesso: "Registro editado con éxito",
    registroExcluidoComSucesso: "Registro eliminado correctamente",
    reintegrar: "Reintegrar",
    relatorio: "Informes",
    relatorioCriadoComSucesso: "Informe creado correctamente",
    reprovado: "Desaprobado",
    reprovar: "Desaprobar",
    reprovarPedido: "Rechazar pedido",
    aprovarPedido: "Aprobar pedido",
    toleranciaMaxima: "Tolerancia de umbral máxima",
    rotina: "Rutina",
    saida: "Salida",
    saldoCreditoDisponivel: "Saldo de crédito disponible",
    salvar: "Guardar",
    searchEmail: "Buscar correo electrónico",
    selecionar: "Seleccione",
    selecionarCotacao: "Seleccionar cotización",
    selecionarUmaOpcaoAcima:
      "Es necesario seleccionar una de las opciones anteriores",
    selecione: "Seleccione",
    selecioneCidadeCarregarBasesDistribuicao:
      "Seleccione una ciudad para cargar las bases de distribución",
    selecioneDistribuidorParaDownload:
      "Seleccione un distribuidor/TRR para descargar",
    selecioneEmpresaParaInserirPerfil:
      "Seleccione una empresa para insertar perfiles",
    selecioneEstadoCarregar: "Seleccione un estado para cargar",
    selecionePrazoLiberarPedido: "Seleccione un término para liberar la orden",
    selecioneRegiaoCarregar: "Seleccione una región para cargar",
    semUsuariosCadastrados: "No hay usuarios registrados",
    senha: "Contraseña",
    senhaAlteradaComSucesso: "Contraseña alterada con éxito",
    seuLimiteSaldoDisponivel: "Saldo disponible para pedidos",
    status: "Status",
    tabelas: "Mesas",
    tanquesProprios: "Tanques propios",
    taxaAdministrativa: "Administrar impuestos",
    taxaFinanceira: "Tasa financiera",
    taxaMedia: "Tasa promedio",
    taxaTotal: "Tarifa total",
    telefone: "Teléfono",
    tipo: "Tipo",
    tipoRecebimento: "Tipo de recibo",
    todos: "Todas",
    topClientes: "Clientes principales",
    topDistribuidores: "Principales distribuidores",
    usuario: "Usuario",
    usuarioCriadoComSucesso: "Usuario creado con éxito",
    usuarioEditadoComSucesso: "Usuario editado correctamente",
    usuarios: "Usuarios",
    validadeCertificado: "Validez del certificado",
    valorCotacoes: "Valor Peticiones",
    valorFaturadoComTaxas: "BVI - Monto facturado con tarifas",
    valorFaturadoSemTaxas: "BV – Importe facturado sin comisiones",
    valorPedido: "Importe del pedido",
    valorTotalPedidoTaxas: "Monto total ordenado con tarifas",
    vendidos: "Vendido",
    voltar: "Volver",
    volteLogin: "Regrese e inicie sesión",
    voceTemCerteza: "¿Estás seguro?",
    volumeCombustivelLitrosValorReais:
      "Volumen de combustible en litros y valor en reales",
    volumeComprasMensal: "Volumen de compra mensual",
    volumeM2: "Volumen M³",
    volumeNaoVendido: "Volumen no vendido",
    volumeVendido: "Volumen vendido",
    volumeVendidoENaoVendidoDistribuidor:
      "Volumen vendido y no vendido por distribuidor/TRR",
    selecioneClienteCarregar: "Seleccione un cliente para cargar",
    editarPerfilAcessoDistribuidor:
      "Editar perfil de acceso de distribuidor/TRR",
    prazoCotacaoNaoEditado:
      "La fecha límite de cotización aún no ha sido editada por el distribuidor/TRR, espere a que se publique.",
    historicoNegociacao: "Historial comercial",
    dadosCliente: "Datos del Cliente",
    analise: "Analizar",
    entregue: "Entregue",
    reanalise: "Reanálisis Distribuidor/TRR",
    analiseCliente: "Análisis Cliente",
    naoExisteTopClientes: "No hay Top Clientes",
    naoExisteTopDistribuidores: "No hay Top Distribuidores",
    naoExisteMelhorPreco: "No hay mejores precios hoy",
    selecioneCliente:
      "Seleccione un cliente que tenga precios registrados en la base",
    sodexo: "Pluxee",
    direitaAlta: "Derecha Alta",
    direitaBaixa: "Derecha Baja",
    rodape: "Rodapé",
    centro: "Centro",
    totalRegistros: "Registros totales",
    notaServiço: "Nota fiscal Servicio",
    notaDistribuidor: "Nota Fiscal Distribuidor/TRR",
    criador: "Criado por",
    selecioneArquivo: "Seleccione el archivo",
    fazerUpload: "Hacer Upload",
    infoMotorista: "Información Conductor",
    nomeMotorista: "Nombre Conductor",
    rgMotorista: "RG Conductor",
    cpfMotorista: "CPF Conductor",
    placaVeiculo: "Placa Vehículo",
    atualizarTaxas: "Actualizar tarifas de pedidos",
    dias: "dias",
    dataCriacao: "Fecha de creación",
    totalLitrosPendentes: "M³/Litros Pendientes"
  },
};

export default lang;
