import React, { useEffect, useState, useContext, useCallback } from "react";
import ImageBanner from "../../../components/ImageBanner";
import Card from "../../../components/Card";
import { Modal } from "react-bootstrap";
import moment from "moment";
import "moment/locale/pt-br";
import "moment/locale/es";
import "moment/locale/en-au";

import { FormattedMessage, injectIntl } from "react-intl";

import { Container, ContainerInfo, ContainerPage, Table } from "./styles";

import api from "../../../services/api";

import ContentSpinner from "../../../components/Spinner/Content";
import { useHistory } from "react-router-dom";
import Alert from "./../../../assets/images/alert-branco.png";

import Language from "../../../context/language";

import GraphBV from "../../DashboardGraphs/SodexoFaturamento";
import GraphBVDistribuidor from "../../DashboardGraphs/SodexoFaturamentoDistribuidor";
import GraphBVProduto from "../../DashboardGraphs/SodexoFaturamentoProduto";
import GraphBVBase from "../../DashboardGraphs/SodexoFaturamentoBase";
import GraphReceita from "../../DashboardGraphs/SodexoReceita";
import GraphReceitaBase from "../../DashboardGraphs/SodexoReceitaBase";
import GraphTaxaMediaCliente from "../../DashboardGraphs/SodexoTaxaMediaCliente";
import GraphTaxaMediaClientePorcentagem from "../../DashboardGraphs/SodexoTaxaMediaClientePorcentagem";
import GraphTaxaMediaClienteBase from "../../DashboardGraphs/SodexoTaxaMediaClienteBase";
import GraphPrecoMedioLitro from "../../DashboardGraphs/SodexoPrecoMedioLitro";
import GraphPrecoMedioLitroDistribuidor from "../../DashboardGraphs/SodexoPrecoMedioLitroDistribuidor";
import GraphPrecoMedioLitroProduto from "../../DashboardGraphs/SodexoPrecoMedioLitroProduto";
import GraphPrecoMedioLitroBase from "../../DashboardGraphs/SodexoPrecoMedioLitroBase";
import GraphPrazoMedio from "../../DashboardGraphs/SodexoPrazosMediosPagamento";
import GraphNegociacoes from "../../DashboardGraphs/SodexoNegociacoes";
import GraphVolumeCombustivel from "../../DashboardGraphs/SodexoVolumeCombustivel";
import GraphVolumeCombustivelDistribuidor from "../../DashboardGraphs/SodexoVolumeCombustivelDistribuidor";
import GraphVolumeCombustivelBase from "../../DashboardGraphs/SodexoVolumeCombustivelBase";
import GraphVolumeCombustivelProduto from "../../DashboardGraphs/SodexoVolumeCombustivelProduto";
import GraphRankingClientes from "../../DashboardGraphs/SodexoRankingClientes";
import GraphRankingDistribuidores from "../../DashboardGraphs/SodexoRankingDistribuidores";

import { FiFileText } from "react-icons/fi";

const ListHome = ({ intl }) => {
  const [topClientes, setTopClientes] = useState([]);
  const [topDistribuidores, setTopDistribuidores] = useState([]);

  const [modal, setModal] = useState(false);
  const [bannerLeftTop, setBannerLeftTop] = useState(false);
  const [bannerLeftBottom, setBannerLeftBottom] = useState(false);
  const [bannerBottom, setBannerBottom] = useState(false);

  const [srcModal, setSrcModal] = useState("");
  const [srcLeftTop, setSrcLeftTop] = useState("");
  const [srcLeftBottom, setSrcLeftBottom] = useState("");
  const [srcBottom, setSrcBottom] = useState("");

  const [linkModal, setLinkModal] = useState(null);
  const [linkLeftTop, setLinkLeftTop] = useState(null);
  const [linkLeftBottom, setLinkLeftBottom] = useState(null);
  const [linkBottom, setLinkBottom] = useState(null);

  const [loadingList, setLoadingList] = useState(true);

  const [cotacoesValidar, setCotacoesValidar] = useState("");
  const [cotacoesResponder, setCotacoesResponder] = useState("");
  const [pedidosFaturados, setPedidosFaturados] = useState("");
  const [M3LitrosVendidos, setM3LitrosVendidos] = useState("");
  const [reaisFaturados, setReaisFaturados] = useState("");
  const [reaisReceita, setReaisReceita] = useState("");
  const [certificadoDigital30Dias, setCertificadoDigital30Dias] = useState("");
  const [aprovacao, setAprovacao] = useState("");


  const [dadosCliente, setDadosCliente] = useState(true);
  const [dadosDistribuidor, setDadosDistribuidor] = useState(true);

  const [graph, setGraph] = useState("");

  const history = useHistory();

  const { language } = useContext(Language);

  const receitaPlaceholder = intl.formatMessage({
    id: "receita",
  });
  const faturadosPlaceholder = intl.formatMessage({
    id: "faturados",
  });

  useEffect(() => {
    (async () => {
      try {
        const dateFilter = moment().format("YYYY-MM-DD");
        const { data: response } = await api.post("/comunicacao/filter", {
          exibir_sodexo: 1,
          data_inicio_fim: dateFilter,
        });

        response.data.forEach((item) => {
          if (item.posicao === "Rodapé" && item.local === "Home") {
            setBannerBottom(true);
            setSrcBottom(item.link_arquivo);
            if (item.link !== null) {
              setLinkBottom(item.link);
            }
          }

          if (item.posicao === "Direita Alta" && item.local === "Home") {
            setBannerLeftTop(true);
            setSrcLeftTop(item.link_arquivo);
            if (item.link !== null) {
              setLinkLeftTop(item.link);
            }
          }

          if (item.posicao === "Direita Baixa" && item.local === "Home") {
            setBannerLeftBottom(true);
            setSrcLeftBottom(item.link_arquivo);
            if (item.link !== null) {
              setLinkLeftBottom(item.link);
            }
          }

          if (item.posicao === "Centro" && item.local === "Home") {
            setModal(true);
            setSrcModal(item.link_arquivo);
            if (item.link !== null) {
              setLinkModal(item.link);
            }
          }
        });

        const { data: responseGraph } = await api.get("/favorito-home");

        setGraph(responseGraph?.entity_id);

      } catch (error) {
        console.log(error);
      }

      try {
        const { data: response } = await api.get("/home");

        setCotacoesValidar(response.pedidos_validar);
        setCotacoesResponder(response.pedidos_pendentes);
        setPedidosFaturados(response.pedidos_faturados);
        setM3LitrosVendidos(
          response.M3LitrosVendidos / 1000 + " / " + (parseFloat(response.M3LitrosVendidos)).toLocaleString('pt-BR')
        );
        setReaisFaturados(response.ReaisFaturados);
        setReaisReceita(response.ReaisReceita);
        setCertificadoDigital30Dias(response.CertificadoDigital30dias);

        setAprovacao(
          response.realizados_vs_faturados.porcentagem_faturados.toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
          }) + "% " +
          "("+ response.realizados_vs_faturados.total_faturados + "/"
          + response.realizados_vs_faturados.realizado + ")")

        if (response.TopClientes === 0) {
          setDadosCliente(false);
        } else {
          const formattedListCliente = response.TopClientes.map((cliente) => {
            const obj = {};
            Object.assign(obj, {
              id: cliente.id,
              dadosComprador: cliente.dadosComprador,
              qtdCotacoesCompradores: cliente.qtdPedidosCompradores,
              qtdSomaProdutos: cliente.qtdSomaProdutos,
            });

            return obj;
          });

          setTopClientes(formattedListCliente);
        }

        if (response.TopDistribuidoresTRRs === 0) {
          setDadosDistribuidor(false);
        } else {
          const formattedListDistribuidores =
            response.TopDistribuidoresTRRs.map((distribuidor) => {
              const obj = {};
              Object.assign(obj, {
                id: distribuidor.id,
                dadosVendedor: distribuidor.dadosVendedor,
                qtdCotacoesVendedores: distribuidor.qtdPedidosVendedores,
                qtdSomaProdutos: distribuidor.qtdSomaProdutos,
              });

              return obj;
            });
          setTopDistribuidores(formattedListDistribuidores);
        }

        setLoadingList(false);
      } catch (error) {
        console.log(error);
        setLoadingList(false);
      }
    })();
  }, []);

  if (language === "en") {
    moment.locale("en-au");
  } else if (language === "es") {
    moment.locale("es");
  } else if (language === "pt_BR") {
    moment.locale("pt-br");
  }

  const date = moment().format("MMMM/YYYY");

  const mesAno = date[0].toUpperCase() + date.substr(1);

  const handlePainelFilter = useCallback(
    (statusCard) => {
      const status = {
        statusCard,
      };

      history.push("/orders/painel", status);
    },
    [history]
  );

  return (
    <>
      <Modal
        centered={true}
        show={modal}
        onHide={() => setModal(false)}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton></Modal.Header>

        <Modal.Body>
          <ImageBanner
            src={srcModal}
            height={500}
            width={"100%"}
            display={modal ? 0 : 1}
            link={linkModal !== null ? linkModal : null}
            style={{ margin: "-10px 0", padding: "5px 0px" }}
          />
        </Modal.Body>
      </Modal>

      <Container>
        <header>
          <nav>
            <h2>Home - {mesAno}</h2>
          </nav>
        </header>

        {loadingList ? (
          <ContentSpinner />
        ) : (
          <>
            <ContainerPage columns={srcLeftTop || srcLeftBottom ? true : false}>
              <ContainerInfo>
                <div className="grid grid-template-columns-cards">

                  <div onClick={() => handlePainelFilter("Análise Mesa de Operação")} style={{cursor: "pointer"}}>
                    <Card
                      text={<FormattedMessage id="cotacoesValidar" />}
                      number={cotacoesValidar}
                      progress={"100%"}
                      icon={"fa fa-globe fa-fw"}
                      permission="cards-home[1]"
                    />
                  </div>

                  <div onClick={() => handlePainelFilter("Análise Distribuidor/TRR")} style={{cursor: "pointer"}}>
                    <Card
                      text={<FormattedMessage id="cotacoesResponder" />}
                      number={cotacoesResponder}
                      progress={"100%"}
                      icon={"fa fa-globe fa-fw"}
                      href={"/orders/painel"}
                      permission="cards-home[1]"
                    />
                  </div>

                  <div onClick={() => handlePainelFilter("Faturado")} style={{cursor: "pointer"}}>
                  <Card
                    text={<FormattedMessage id="pedidosFaturados" />}
                    number={pedidosFaturados}
                    progress={"100%"}
                    icon={"fa fa-exchange-alt"}
                    href={"/orders/painel"}
                    permission="cards-home[1]"
                    subtitle="base data de faturamento"
                  />
                  </div>

                  <Card
                    text={<FormattedMessage id="litrosVendidos" />}
                    number={M3LitrosVendidos}
                    progress={"100%"}
                    icon={"fa fa-chart-line fa-fw"}
                    permission="cards-home[1]"
                  />

                  <Card
                    text={"R$ " + faturadosPlaceholder}
                    number={reaisFaturados}
                    progress={"100%"}
                    icon={"fa fa-dollar-sign fa-fw"}
                    permission="cards-home[1]"
                  />

                  <Card
                    text={"R$ " + receitaPlaceholder}
                    number={reaisReceita}
                    progress={"100%"}
                    icon={"fa fa-dollar-sign fa-fw"}
                    permission="cards-home[1]"
                  />

                  <Card
                    text={"Índice de Aprovação (Aprovados x Real)"}
                    number={aprovacao}
                    progress={"100%"}
                    icon={"fa fa-chart-line fa-fw"}
                    permission="cards-home[1]"
                    subtitle="base data de entrega"
                  />

                  <Card
                    text={<FormattedMessage id="certificadoDigital30" />}
                    number={certificadoDigital30Dias}
                    progress={"100%"}
                    icon={"fa fa-user-check fa-fw"}
                    href={"/register/sodexo/customers/list"}
                    permission="cards-home[1]"
                  />
                </div>

                <div className="grid grid-template-columns-important">
                  {/* <i className="fa fa-exclamation-circle fa-2x" /> */}
                  <img  src={Alert} alt="" />
                  <h3 className="text">
                    {<FormattedMessage id="messageHomeSodexo" />}
                  </h3>
                </div>

                <div className="grid grid-template-columns-2">
                  <ImageBanner
                    src={srcLeftTop}
                    height={300}
                    width={"100%"}
                    display={bannerLeftTop ? 0 : 1}
                  />
                  <ImageBanner
                    src={srcLeftBottom}
                    height={300}
                    width={"100%"}
                    display={bannerLeftBottom ? 0 : 1}
                  />
                </div>

                <div className="graph-div">
                  {graph === "FaturamentoGeral" ? <GraphBV /> : ""}
                  {graph === "VolumeCombustivelGeral" ? (
                    <GraphVolumeCombustivel />
                  ) : (
                    ""
                  )}
                  {graph === "ReceitaGeral" ? <GraphReceita /> : ""}
                  {graph === "TaxasMediasGeral" ? (
                    <GraphTaxaMediaCliente />
                  ) : (
                    ""
                  )}
                  {graph === "TaxasMediasGeralPorcentagem" ? (
                    <GraphTaxaMediaClientePorcentagem />
                  ) : (
                    ""
                  )}
                  {graph === "PrecoMedioGeral" ? <GraphPrecoMedioLitro /> : ""}
                  {graph === "PrazosMedioGeral" ? <GraphPrazoMedio /> : ""}
                  {graph === "NegociacoesPedidosGeral" ? (
                    <GraphNegociacoes />
                  ) : (
                    ""
                  )}
                  {graph === "FaturamentoCombustível" ? <GraphBVProduto /> : ""}
                  {graph === "VolumeCombustivelCombustivel" ? (
                    <GraphVolumeCombustivelProduto />
                  ) : (
                    ""
                  )}
                  {graph === "PrecoMedioCombustivel" ? (
                    <GraphPrecoMedioLitroProduto />
                  ) : (
                    ""
                  )}
                  {graph === "FaturamentoDistribuidor" ? (
                    <GraphBVDistribuidor />
                  ) : (
                    ""
                  )}
                  {graph === "VolumeCombustivelDistribuidor" ? (
                    <GraphVolumeCombustivelDistribuidor />
                  ) : (
                    ""
                  )}
                  {graph === "PrecoMedioDistribuidor" ? (
                    <GraphPrecoMedioLitroDistribuidor />
                  ) : (
                    ""
                  )}
                  {graph === "FaturamentoBase" ? <GraphBVBase /> : ""}
                  {graph === "VolumeCombustivelBase" ? (
                    <GraphVolumeCombustivelBase />
                  ) : (
                    ""
                  )}
                  {graph === "ReceitaBase" ? <GraphReceitaBase /> : ""}
                  {graph === "TaxasMediasBase" ? (
                    <GraphTaxaMediaClienteBase />
                  ) : (
                    ""
                  )}
                  {graph === "PrecoMedioBase" ? (
                    <GraphPrecoMedioLitroBase />
                  ) : (
                    ""
                  )}
                  {graph === "RankingCliente" ? <GraphRankingClientes /> : ""}
                  {graph === "RankingDistribuidor" ? (
                    <GraphRankingDistribuidores />
                  ) : (
                    ""
                  )}
                </div>

                <div className="grid grid-template-columns-table">
                  <Table>
                    <main>
                      {dadosCliente ? (
                        <table id="table-to-xls">
                          <thead>
                            <tr>
                              <th>
                                <FormattedMessage id="topClientes" />
                              </th>
                              <th>
                                <FormattedMessage id="quantidadeTotal" />
                              </th>
                              <th>
                                <FormattedMessage id="valorCotacoes" />
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {topClientes.map((data, index) => {
                              return (
                                <tr key={index}>
                                  <td>{data.dadosComprador}</td>
                                  <td>{data.qtdCotacoesCompradores}</td>
                                  <td>R$ {data.qtdSomaProdutos}</td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      ) : (
                        <div
                          style={{
                            height: "100%",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            marginTop: "-4%",
                          }}
                        >
                          <FiFileText size={25} color="#B30000" />
                          <h3
                            style={{
                              color: "#B30000",
                              marginLeft: "10px",
                              marginTop: 10,
                              fontSize: 16
                            }}
                          >
                            <FormattedMessage id="naoExisteTopClientes" />
                          </h3>
                        </div>
                      )}
                    </main>
                  </Table>

                  <Table>
                    <main>
                      {dadosDistribuidor ? (
                        <table id="table-to-xls">
                          <thead>
                            <tr>
                              <th>
                                <FormattedMessage id="topDistribuidores" />
                              </th>
                              <th>
                                <FormattedMessage id="quantidadeTotal" />
                              </th>
                              <th>
                                <FormattedMessage id="valorCotacoes" />
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {topDistribuidores.map((data, index) => {
                              return (
                                <tr key={index}>
                                  <td>{data.dadosVendedor}</td>
                                  <td>{data.qtdCotacoesVendedores}</td>
                                  <td>R$ {data.qtdSomaProdutos}</td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      ) : (
                        <div
                          style={{
                            height: "100%",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            marginTop: "-4%",
                          }}
                        >
                          <FiFileText size={25} color="#B30000" />
                          <h3
                            style={{
                              color: "#B30000",
                              marginLeft: "10px",
                              marginTop: 10,
                              fontSize: 16
                            }}
                          >
                            <FormattedMessage id="naoExisteTopDistribuidores" />
                          </h3>
                        </div>
                      )}
                    </main>
                  </Table>
                </div>
              </ContainerInfo>
              <div className="hidden">
                <ImageBanner
                  src={srcLeftTop}
                  height={300}
                  width={"100%"}
                  display={bannerLeftTop ? 0 : 1}
                  link={linkLeftTop !== null ? linkLeftTop : null}
                />
                <ImageBanner
                  src={srcLeftBottom}
                  height={300}
                  width={"100%"}
                  display={bannerLeftBottom ? 0 : 1}
                  link={linkLeftBottom ? linkLeftBottom : null}
                />
              </div>
            </ContainerPage>
          </>
        )}
        <ImageBanner
          src={srcBottom}
          height={"100%"}
          width={"100%"}
          display={bannerBottom ? 0 : 1}
          link={linkBottom ? linkBottom : null}
        />
      </Container>
    </>
  );
};

export default injectIntl(ListHome);
