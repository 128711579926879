import React from "react";

import { List, Container, MessageError } from "./styles";

const customStyles = {
  menu: (provided, state) => ({
    ...provided,
    // borderBottom: "1px solid pink",
  }),
};

const ListSelect = ({ labelPlaceholder, geterror, zIndex, required, ...rest }) => {
  return (
    <Container style={{ zIndex: zIndex }}>
      {labelPlaceholder &&  <p>{labelPlaceholder} {required && <span style={{color: "#B30000"}}>*</span>}</p>}
      <List
        {...rest}
        styles={customStyles}
        classNamePrefix='filter'
        theme={(theme) => ({
          ...theme,
          borderRadius: 0,
          // border: "#221c46",
          colors: {
            ...theme.colors,
            primary25: "#FcF1F0 !important",
            primary: "#FF7375  !important",
            border: "#221c46 !important"
          },
        })}
        maxMenuHeight={220}
      />
      {geterror && (
        <MessageError>
          <div>
            <p>{geterror}</p>
          </div>
        </MessageError>
      )}
    </Container>
  );
};

export default ListSelect;
