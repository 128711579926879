import React, { useCallback, useEffect, useState, useContext } from "react";

import { useHistory } from "react-router-dom";
import { FormattedMessage, injectIntl } from "react-intl";

import ListSelect from "./../../../components/ListSelect";
import InputLabel from "./../../../components/InputLabel";

import api from "./../../../services/api";
import { Container, CreateForm, CreateFooter } from "./styles";

import Button from "./../../../components/ButtonDefault";

import AsyncListSelect from "../../../components/ListSelect/AsyncListSelect";
import { toast } from "react-toastify";

import AuthContext from "./../../../context/auth";

export const CreateOrders = ({ intl }) => {
  const [dataInicial, setDataInicial] = useState("");
  const [dataFinal, setDataFinal] = useState("");

  const [checkUser, setCheckUser] = useState(false);
  const [listaUsuarios, setListaUsuarios] = useState("");

  const [cliente, setCliente] = useState("");
  const [distribuidor, setDistribuidor] = useState("");
  const [sodexo, setSodexo] = useState("");
  const [usuarios, setUsuarios] = useState("");
  const [nivel, setNivel] = useState("");
  const [perfil, setPerfil] = useState("");

  const [rotina, setRotina] = useState("");
  const [acao, setAcao] = useState("");

  const [status, setStatus] = useState("");

  // const [cadastroId, setCadastroId] = useState("");

  const [loadingButton, setLoadingButton] = useState(false);

  const history = useHistory();

  const { user } = useContext(AuthContext);

  const todosPlaceholder = intl.formatMessage({
    id: "todos",
  });

  const statusOptions = [
    { value: 1, label: <FormattedMessage id="statusAtivo" /> },
    { value: 0, label: <FormattedMessage id="statusInativo" /> },
  ];

  const nivelOptions = [
    { value: 1, label: <FormattedMessage id="nivelMaster" /> },
    { value: 2, label: <FormattedMessage id="nivelComum" /> },
  ];

  const sodexoOptions = [
    { value: 1, label:       `${user?.cnpj?.replace(
      /(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g,
      "$1.$2.$3/$4-$5"
    )} - ${user?.razao_social}` },
  ];

  useEffect(() => {
    if (parseInt(user.cadastro_tipo_id) === 3) {
      setCliente({ label: user?.razao_social, value: user?.cadastro_id });
      setListaUsuarios(user?.cadastro_id)
    }

    if (parseInt(user.cadastro_tipo_id) === 2) {
      setDistribuidor({ label: user?.razao_social, value: user?.cadastro_id });
      setListaUsuarios(user?.cadastro_id)
    }

  }, [user]);

  const loadAcoes = useCallback(async () => {
    try {

      const { data: response } = await api.get("/relatorio/options-log");

      const acoesFormatted = response.acoes.map((acao) => {
        const objeto = {
          value: acao.acao_feita,
          label: acao.acao_feita,
        };

        return objeto;
      });

      return acoesFormatted;
    } catch (error) {}
  }, []);

  const loadRotinas = useCallback(async () => {
    try {

      const { data: response } = await api.get("/relatorio/options-log");

      const rotinasFormatted = response.rotinas.map((rotina) => {
        const objeto = {
          value: rotina.nome_rotina,
          label: rotina.nome_rotina,
        };

        return objeto;
      });

      return rotinasFormatted;
    } catch (error) {}
  }, []);

  const handleBackButton = useCallback(() => {
    history.goBack();
  }, [history]);

  const handleCreate = useCallback(async () => {
    setLoadingButton(true);

    var cadastro_id;

    const checkedSodexo = !sodexo?.value ? "" : sodexo?.value;
    const checkedCliente = !cliente?.value ? "" : cliente?.value;
    const checkedDistribuidor = !distribuidor?.value ? "" : distribuidor?.value;

    if (checkedSodexo) {
      cadastro_id = sodexo.value
    } else if (checkedDistribuidor) {
      cadastro_id = distribuidor.value
    } else if (checkedCliente) {
      cadastro_id = cliente.value
    }

    const checkedUser = !usuarios?.value ? "" : usuarios?.value;

    const data = {
      saida: "excel",
      dataDe: dataInicial,
      dataAte: dataFinal,
      usuario_id: checkedUser,
      nivel: nivel?.value || "",
      nome_rotina: rotina?.value || "",
      acao_feita: acao?.value || "",
      status: status || "",
      cadastro_id: cadastro_id || ""
    };

    try {

        await api
          .get(`/relatorio/logs`, {
            params: data,
            responseType: "blob",
          })
          .then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", "Relatorio-Logs.xlsx");
            document.body.appendChild(link);
            link.click();

            toast.success(<FormattedMessage id="relatorioCriadoComSucesso" />);
          });

      setLoadingButton(false);
    } catch (error) {
      setLoadingButton(false);

      toast.error(<FormattedMessage id="errorExportarRelatorio" />);
    }
  }, [
    acao,
    dataFinal,
    dataInicial,
    nivel,
    rotina,
    usuarios,
    status,
    distribuidor,
    cliente,
    sodexo,
  ]);

  const handleLoadUsuarios = useCallback(async (id) => {
    if (!id) {
      return;
    }

    try {
      const { data: response } = await api.post(`/cadastro/filter`, {
        id,
      });

      const cliente = response.data[0].users;

      setUsuarios(null);

      if (cliente.length === 0) {
        setCheckUser(true);
        toast.warning("Não tem usuários cadastrados para esse cliente.");
      } else {
        setCheckUser(false);
      }

      const user = cliente.map((item) => {
        const obj = {};

        Object.assign(obj, {
          value: item.id,
          label: item.name,
        });

        return obj;
      });
      return user;
    } catch (error) {}
  }, []);

  const loadListRoles = useCallback(async () => {
    try {
      const { data: response } = await api.post(`/role/filter`, {
        ativo: 1,
      });

      var resp;
      resp = response.data;

      const rolesDB = resp.map((role) => {
        const objeto = {
          value: role.roleId,
          label: role.label,
        };
        return objeto;
      });

      return rolesDB;
    } catch (error) {}
  }, []);

  return (
    <Container>
      <CreateForm>
        <header>
          <h4>
            <FormattedMessage id="relatorioLog" />
          </h4>
        </header>

        <form>
          <div className="sideBySide">
            <InputLabel
              name="date"
              label={<FormattedMessage id="dataInicio" />}
              type="date"
              style={{ color: "hsl(0,0%,50%)" }}
              value={dataInicial}
              onChange={(e) => setDataInicial(e.target.value)}
              isClearable
            />

            {parseInt(user.cadastro_tipo_id) === 1 && (
              <ListSelect
                name="sodexo"
                label={"Sodexo"}
                type="text"
                labelPlaceholder={"Sodexo"}
                placeholder={<FormattedMessage id="selecionar" />}
                value={sodexo}
                onChange={(e) => {
                  setSodexo(e);
                  setListaUsuarios(e?.value);
                  setCliente("");
                  setDistribuidor("");
                }}
                options={sodexoOptions}
                isClearable
              />
            )}

            {/* Exibi na visão Sodexo e Distribuidor */}
            {parseInt(user.cadastro_tipo_id) !== 3 && (
              <AsyncListSelect
                value={distribuidor}
                labelPlaceholder={<FormattedMessage id="distribuidores" />}
                placeholder={todosPlaceholder}
                onChange={(e) => {
                  setDistribuidor(e);
                  setListaUsuarios(e?.value);
                  setCliente("");
                  setSodexo("");
                }}
                myOptions={"distribuidores"}
                cacheOptions
                defaultOptions
                isDisabled={
                  parseInt(user.cadastro_tipo_id) === 2 ? true : false
                }
                isClearable
              />
            )}

            <ListSelect
              name="nivel"
              labelPlaceholder={<FormattedMessage id="nivel" />}
              placeholder={<FormattedMessage id="selecionar" />}
              cacheOptions
              defaultOptions
              options={nivelOptions}
              value={nivel}
              onChange={(e) => {
                setNivel(e)
                setPerfil("");
              }}
              isClearable
            />

            <AsyncListSelect
              value={rotina}
              labelPlaceholder={<FormattedMessage id="rotina" />}
              placeholder={todosPlaceholder}
              onChange={(e) => {
                setRotina(e);
              }}
              loadOptions={loadRotinas}
              cacheOptions
              defaultOptions
              isClearable
            />

            <ListSelect
              name="status"
              labelPlaceholder={"Status"}
              placeholder={todosPlaceholder}
              cacheOptions
              defaultOptions
              options={statusOptions}
              value={status}
              onChange={(e) => setStatus(e)}
              isClearable
            />
          </div>

          <div className="sideBySideTwo">
            <InputLabel
              name="date"
              label={<FormattedMessage id="dataFim" />}
              type="date"
              style={{ color: "hsl(0,0%,50%)" }}
              value={dataFinal}
              onChange={(e) => setDataFinal(e.target.value)}
            />

            {/* Exibi na visão Sodexo e Cliente */}
            {parseInt(user.cadastro_tipo_id) !== 2 && (
              <AsyncListSelect
                value={cliente}
                labelPlaceholder={<FormattedMessage id="cliente" />}
                placeholder={todosPlaceholder}
                onChange={(e) => {
                  setCliente(e);
                  setListaUsuarios(e?.value);
                  setDistribuidor("");
                  setSodexo("");
                }}
                myOptions={"clientes"}
                cacheOptions
                defaultOptions
                isDisabled={
                  parseInt(user.cadastro_tipo_id) === 3 ? true : false
                }
                isClearable
              />
            )}

            <AsyncListSelect
              key={listaUsuarios}
              labelPlaceholder={"Usuários"}
              placeholder={
                checkUser ? (
                  <FormattedMessage id="semUsuariosCadastrados" />
                ) : (
                  <FormattedMessage id="selecionar" />
                )
              }
              value={usuarios}
              onChange={(e) => {
                setUsuarios(e);
              }}
              cacheOptions
              defaultOptions
              loadOptions={() => {
                return handleLoadUsuarios(listaUsuarios);
              }}
              noOptionsMessage={() => (
                <FormattedMessage id="Selecione uma empresa para carregar" />
              )}
              loadingMessage={() => <FormattedMessage id="carregando" />}
              isSearchable={false}
              isDisabled={checkUser}
              isClearable
            />

            <AsyncListSelect
              labelPlaceholder={"Perfil"}
              placeholder={<FormattedMessage id="selecione" />}
              onChange={(e) => {
                setPerfil(e);
              }}
              cacheOptions
              defaultOptions
              value={perfil}
              loadOptions={loadListRoles}
              isDisabled={ nivel?.value !== 2 ? true : false }
              isClearable
            />

            <AsyncListSelect
              value={acao}
              labelPlaceholder={<FormattedMessage id="acoes" />}
              placeholder={todosPlaceholder}
              onChange={(e) => {
                setAcao(e);
              }}
              loadOptions={loadAcoes}
              cacheOptions
              defaultOptions
              isClearable
            />
          </div>
        </form>

        <hr style={{ marginTop: "75px" }} />

        <CreateFooter>
          <div>
            <Button type="button" themeType="back" onClick={handleBackButton}>
              <FormattedMessage id="voltar" />
            </Button>
          </div>
          <div>
            <Button
              type="button"
              onClick={handleCreate}
              loading={loadingButton}
            >
              {<FormattedMessage id="gerar" />}
            </Button>
          </div>
        </CreateFooter>
      </CreateForm>
    </Container>
  );
};

export default injectIntl(CreateOrders);
