import styled from "styled-components";

export const Container = styled.div`
  height: 80vh;
  display: flex;
  justify-content: center;
  align-items: center;

  > img {
    height: 100px;
    width: 200px;
    object-fit: contain;
  }

  > div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    > img {
      height: 200px;
      width: 400px;
      object-fit: contain;
    }
  }
`;
