import React from "react";
import { Input, Container, Error } from "./styles";

const InputDefault = ({ geterror, ...rest }) => {
  return (
    <Container>
      <Input {...rest} />
      {geterror ? <Error>{geterror}</Error> : null}
    </Container>
  );
};

export default InputDefault;
