import React, { createContext, useCallback } from "react";

import api from "./../../../services/api";

const QueriesContext = createContext();

export const UserOperationSelect = ({ children }) => {
  const userOperationOptions = useCallback(async (inputValue) => {
    try {
      const { data: response } = await api.get(`/usuario/operadores-sem-mesa`);

      const arr = response.map((user) => {
        const obj = {};

        Object.assign(obj, {
          value: String(user.id),
          label: user.name,
        });

        return obj;
      });

      return new Promise((resolve) => {
        return resolve(
          arr.filter((i) =>
            i.label.toLowerCase().includes(inputValue.toLowerCase())
          )
        );
      });
    } catch (error) {}
  }, []);

  return (
    <QueriesContext.Provider value={{ userOperationOptions }}>
      {children}
    </QueriesContext.Provider>
  );
};

export default QueriesContext;
