import React, { useCallback, useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import MaskInput from "./../../../config/input-mask";
import { FormattedMessage, injectIntl } from "react-intl";

import { toast } from "react-toastify";

import api from "./../../../services/api";

import { Container, CreateForm, CreateFooter } from "./styles";

import InputLabel from "./../../../components/InputLabel";
import Button from "./../../../components/ButtonDefault";
import ListSelect from "./../../../components/ListSelect";
import AsyncListSelect from "./../../../components/ListSelect/AsyncListSelect";
import { getErrorsApiValidation } from "./../../../utils/getErrorsValidation";

export const CreateUser = ({ intl }) => {
  const [loadingButton, setLoadingButton] = useState(false);

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [passwordConfirmation, setPasswordConfirmation] = useState("");
  const [celular, setCelular] = useState("");
  const [telefone, setTelefone] = useState("");
  const [nivel, setNivel] = useState("");
  const [roles, setRoles] = useState("");
  const [status, setStatus] = useState({
    value: 1,
    label: <FormattedMessage id="ativo" />,
  });
  const [errors, setErrors] = useState();

  const history = useHistory();

  /** obter os dados do usuario logado */

  //Placeholders internacionalizados
  const passwordPlaceholder = intl.formatMessage({ id: "senha" });
  const passwordConfirmationPlaceholder = intl.formatMessage({
    id: "confirmacaoSenha",
  });
  const empresaPlaceholder = intl.formatMessage({ id: "empresa" });
  const nivelPlaceholder = intl.formatMessage({ id: "nivel" });
  const perfilPlaceholder = intl.formatMessage({ id: "perfil" });
  const statusPlaceholder = intl.formatMessage({ id: "status" });
  const nomePlaceholder = intl.formatMessage({ id: "nome" });

  const [userTipos, setUserTipos] = useState("");

  const [listaEmpresasSelecionadas, setListaEmpresas] = useState("");
  const [empresas, setEmpresas] = useState([]);

  const statusOptions = [
    { value: "1", label: <FormattedMessage id="ativo" /> },
    { value: "0", label: <FormattedMessage id="inativo" /> },
  ];

  useEffect(() => {
    async function loadEmpresas() {
      try {
        const { data: response } = await api.post(`/cadastro/filter`, {
          cadastro_tipo_id: 1,
        });
        const resp = response.data;
        const basesFormatted = resp.map((base) => {
          return base.id;
        });

        setListaEmpresas(basesFormatted);

        const arr = response.data.map((item) => {
          const obj = {};

          Object.assign(obj, {
            value: item.id,
            label: item.razao_social,
          });

          return obj;
        });
        setEmpresas(arr);
      } catch (error) {}
    }
    loadEmpresas();

    async function loadUserTipos() {
      try {
        const { data: response } = await api.get("usuario/list-types");
        const levelOptions = [];
        const resp = response.userTipo;
        resp.map((tipouser) => {
          return levelOptions.push({
            value: tipouser.id,
            label: tipouser.nome,
          });
        });
        setUserTipos(levelOptions);
      } catch (error) {}
    }
    loadUserTipos();
  }, []);

  const handleCreateUser = useCallback(async () => {
    setLoadingButton(true);
    if (password !== passwordConfirmation) {
      setLoadingButton(false);
      toast.error(<FormattedMessage id="asPasswordsNaoCorrespondem" />);
    } else {
      let empresaid = empresas.map(function (empresa) {
        return empresa.value;
      });

      empresaid = empresaid.filter((i) => i !== 0);

      const data = {
        cadastro: empresas === [] ? listaEmpresasSelecionadas : empresaid,
        name: name,
        email: email,
        celular: celular,
        telefone: telefone,
        tipo_id: nivel.value,
        roles: nivel.value === 1 ? "" : [parseInt(roles?.value)],
        password: password,
        ativo: status.value,
        cadastro_tipo_id: 1
      };

      try {
        await api.post("/usuario/store", data);

        setName("");
        setEmail("");
        setPassword("");
        setPasswordConfirmation("");
        setCelular("");
        setTelefone("");
        setNivel("");
        setStatus({ value: 1, label: <FormattedMessage id="ativo" /> });
        setRoles("");

        setErrors(null);
        setLoadingButton(false);
        toast.success(<FormattedMessage id="usuarioCriadoComSucesso" />);
      } catch (error) {
        setLoadingButton(false);
        setErrors(getErrorsApiValidation(error));

        if (error.response.data.erro_email) {
          setName("");
          setEmail("");
          setPassword("");
          setPasswordConfirmation("");
          setCelular("");
          setTelefone("");
          setNivel("");
          setStatus({ value: 1, label: <FormattedMessage id="ativo" /> });
          setRoles("");

          setErrors(null);
          setLoadingButton(false);
          toast.success(<FormattedMessage id="usuarioCriadoComSucesso" />);
          toast.error("Erro ao enviar o e-mail");
          return;
        }

        toast.error(<FormattedMessage id="erroDeCriarUsuario" />);

        return;
      }
    }
  }, [
    empresas,
    listaEmpresasSelecionadas,
    nivel,
    name,
    email,
    password,
    passwordConfirmation,
    celular,
    telefone,
    status,
    roles,
  ]);

  const handleBackButton = useCallback(() => {
    history.goBack();
  }, [history]);

  /** Para carregar as Empresas - Cadastros */
  const loadEmpresas = useCallback(async () => {
    //alert(user.cadastro_tipo_id);
    try {
      /** Se usuario logado for Sodexo listará todas as Empresas. Se não listará somente os tipos de empresas do tipo cadastro logado */
      var response;
      //if (parseInt(user.cadastro_tipo_id) == 1) {
      //response = await api.get(`/cadastro`);
      //} else if (parseInt(user.cadastro_tipo_id) == 2) {
      /** 2020-10-07 - 16:20 - Bruno Nogueira - Conforme orientação do Delvi quando empresa Sodexo sempre trazer a Empresa com ID 1 */
      response = await api.post(`/cadastro/filter`, { cadastro_tipo_id: 1 });
      //} else if (parseInt(user.cadastro_tipo_id) == 3) {
      //response = await api.post(`/cadastro/filter`, { cadastro_tipo_id: 3 });
      //}

      var resp;
      if (typeof response.data.data != "undefined") {
        resp = response.data.data;
      } else {
        resp = response.data;
      }

      const empresasFormatted = resp.map((empresa) => {
        const objeto = {
          value: empresa.id,
          label: empresa.cpf_cnpj + " - " + empresa.razao_social,
        };

        return objeto;
      });

      return empresasFormatted;
    } catch (error) {}
  }, []);

  /** Para carregar os perfis cadastrados */
  const loadPerfis = useCallback(async () => {
    try {
      /** Se usuario logado for Sodexo listará todas as Empresas. Se não listará somente os tipos de empresas do tipo cadastro logado */
      var response;

      response = await api.post(`/role/filter`, {
        cadastro_tipo_id: 1,
        ativo: 1,
      });

      var resp;
      resp = response.data.data;

      const perfis = resp.map((perfil) => {
        const objeto = {
          value: perfil.roleId,
          label: perfil.label,
        };
        return objeto;
      });

      return perfis;
    } catch (error) {
      console.log(error);
    }
  }, []);

  const handleChangeEmpresa = useCallback((item) => {
    if (!item || item?.length === 0) {
      setEmpresas([{ value: 0, label: <FormattedMessage id="selecione" /> }]);
    } else {
      if (item) {
        const empresas = item.filter((empresa) => empresa.value !== 0);

        setEmpresas(empresas);
      }
    }
  }, []);

  return (
    <Container>
      <CreateForm>
        <header>
          <h4>
            <FormattedMessage id="criarNovoUsuario" /> Sodexo
          </h4>
        </header>

        <form>
          <AsyncListSelect
            labelPlaceholder={empresaPlaceholder}
            required={true}
            isMulti
            cacheOptions
            defaultOptions
            loadOptions={loadEmpresas}
            value={empresas}
            onChange={handleChangeEmpresa}
            geterror={errors?.cadastro}
          />
          <InputLabel
            name="name"
            type="text"
            label={nomePlaceholder}
            required={true}
            onChange={(e) => setName(e.target.value)}
            value={name}
            geterror={errors?.name}
            maxLength={100}
          />
          <InputLabel
            name="email"
            type="text"
            label={"Email *"}
                  required={true}
            onChange={(e) => setEmail(e.target.value)}
            value={email}
            geterror={errors?.email}
            maxLength={80}
          />
          <InputLabel
            name="celular"
            label={<FormattedMessage id="celular" />}
            type="text"
            mask={MaskInput.cellphone}
            onChange={(e) => {
              return setCelular(e.target.value.replace(/[^\d]+/g, ""));
            }}
            value={celular}
            geterror={errors?.celular}
          />
          <InputLabel
            name="telefone"
            label={<FormattedMessage id="telefone" />}
            type="text"
            mask={MaskInput.phone}
            onChange={(e) => setTelefone(e.target.value.replace(/[^\d]+/g, ""))}
            value={telefone}
            geterror={errors?.telefone}
          />
          <ListSelect
            labelPlaceholder={nivelPlaceholder}
            required={true}
            onChange={(e) => {
              setNivel(e);
              setRoles("");
            }}
            options={userTipos}
            placeholder={<FormattedMessage id="selecionar" />}
            cacheOptions
            defaultOptions
            value={nivel}
            geterror={errors?.tipo_id}
          />
          <AsyncListSelect
            labelPlaceholder={perfilPlaceholder}
            onChange={(e) => setRoles(e)}
            loadOptions={loadPerfis}
            isDisabled={nivel.value === 1 ? true : false}
            cacheOptions
            placeholder={<FormattedMessage id="selecionar" />}
            defaultOptions
            value={roles}
            geterror={errors?.roles}
          />
          <InputLabel
            name="password"
            type="password"
            label={passwordPlaceholder}
            required={true}
            onChange={(e) => setPassword(e.target.value)}
            value={password}
            maxLength={20}
            geterror={errors?.password}
          />
          <InputLabel
            name="password_confirmation"
            type="password"
            label={passwordConfirmationPlaceholder}
            required={true}
            onChange={(e) => setPasswordConfirmation(e.target.value)}
            value={passwordConfirmation}
            maxLength={20}
          />
          <ListSelect
            labelPlaceholder={statusPlaceholder}
            onChange={(e) => setStatus(e)}
            options={statusOptions}
            value={status}
          />
        </form>
        <hr />
        <CreateFooter>
          <div>
            <Button type="button" themeType="back" onClick={handleBackButton}>
              <FormattedMessage id="voltar" />
            </Button>
          </div>
          <div>
            <Button
              type="submit"
              onClick={handleCreateUser}
              loading={loadingButton}
            >
              <FormattedMessage id="salvar" />
            </Button>
          </div>
        </CreateFooter>
      </CreateForm>
    </Container>
  );
};

export default injectIntl(CreateUser);
