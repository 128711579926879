import styled from "styled-components";
//import BackgroundImage from "./../../assets/images/background2.png";

export const Container = styled.div`
  height: 100vh;
  display: flex;
  position: relative;


  .rodape{
    width: 100%;
    bottom: 0px;
    background: #221c46f5;
    padding-bottom: 4px;

    @media (max-width: 500px) {
      width: 100%
    }

    @media (min-height: 500px) {
      position: absolute;
    }
  }

  .grid{
    display: grid;
    gap: 2%;
    margin: 4px;

    @media (min-height: 500px) {
      grid-template-columns: 10% 8% 12% 10% 10% 10%;
    }

    @media (max-width: 500px) {
      grid-template-columns: 30% 30% 30%;
    }
  }
  .textLgpd {
    color: #fff;

    a {
      color: #fff
    }
  }

  .copyright {
    color: #fff;
    padding-left: 4px
  }
`;

export const TagVersion = styled.span`
  /* font-weight: bold; */
  opacity: 50%;
  /* position: absolute; */
  /* bottom: 8px; */
  margin-left: 15px;
`;

export const SignContainer = styled.div`
  height: 100%;
  width: 100%;
  max-width: 500px;
  background-color: #fff;

  > img {
    position: absolute;
    /* width: 200px; */
    height: 60px;
    top:0;
    left: 0;
  }

  @media (max-height: 500px) {
    max-width: none;
    flex-direction: initial;
    align-items: flex-start;
    height: 150vh;
  }
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  > form {
    // display: flex;
    flex-direction: column;
    width: 75%;
    header {
      margin-top: 5px;
      margin-bottom: 24px;
    }
    footer {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      p {
        margin-top: 10px;
        color: #707478;
      }
    }
    > span {
      color: #707478;
      margin-top: 15px;
      text-align: center;
    }
  }
`;

export const Background = styled.div`
  flex: 1;
  width: auto;
  height: 100%;
  z-index: -1;
  background-color: #fcfeff;
  img {
    width: 100%;
    height: 100%;
    @media (max-width: 850px) {
      object-fit: cover;
    }
  }
  @media (max-width: 500px) {
    display: none;
  }
`;

export const Separator = styled.div`
  width: 100%;
  border-top: 1px solid #ededed;
`;

export const LogoContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin-bottom: 8px;
  > :nth-child(1) {
    width: 200px;
    height: 60px;
    margin-bottom: 7px;
    display: none;
    @media (max-width: 750px) {
      display: block;
      width: 180px;
      height: 80px;
    }
  }
  > :nth-child(2) {
    width: 200px;
    height: 60px;
    margin-bottom: 7px;
    @media (max-width: 515px) {
      width: 120px;
      height: 40px;
    }
  }
`;
