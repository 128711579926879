import React, { useCallback, createRef, useState } from "react";
import { FiChevronDown, FiChevronRight } from "react-icons/fi";
import TreeComponent from "./../TreeComponent";
import { ListWithChildren, ListWithoutChildren, ContainerItem } from "./styles";

const TreeItem = ({ item, children }) => {
  const [visible, setVisible] = useState(false);
  const ref = createRef(null);

  const handleOpen = useCallback(() => {
    setVisible(!visible);
  }, [visible]);

  return (
    <>
      {item.children ? (
        <ListWithChildren ref={ref}>
          <ContainerItem onClick={handleOpen} visible={visible}>
            <li>
              <button onClick={(e) => e.preventDefault()}>{item.title}</button>
            </li>
            {!visible ? <FiChevronRight /> : <FiChevronDown />}
          </ContainerItem>
          {visible && (
            <>
              <TreeComponent data={item.children} />
            </>
          )}
        </ListWithChildren>
      ) : (
        <>
          <ListWithoutChildren onClick={handleOpen} ref={ref}>
            <div>
              <span>{item.title && item.title}</span>
              {item.component && item.component}
            </div>
          </ListWithoutChildren>
        </>
      )}
    </>
  );
};

export default TreeItem;
