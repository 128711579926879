import React, { createContext, useCallback } from "react";

import api from "./../../../services/api";

const QueriesContext = createContext();

export const ConsultantsClassificationListSelect = ({ children }) => {
  const timeoutRef = React.useRef(null);

  const consultantsClassificationOptions = useCallback(async (inputValue) => {

    try {

      let consultantFormatted = [];

      if (timeoutRef.current !== null) {
        clearTimeout(timeoutRef.current);
      }

      if (inputValue !== "") {
        const consultor = () => new Promise((resolve, reject) => {
          timeoutRef.current = setTimeout(async ()=> {
            timeoutRef.current = null;

            const { data: response } = await api.post(`/tabela-consultor/filter`, {
              descricao: inputValue,
            });

            var resp;
            if (typeof response.data != "undefined") {
              resp = response.data;
            }

            consultantFormatted = resp.map((mesa) => {
              const objeto = {
                value: String(mesa.id),
                label: mesa.descricao,
              };

              return objeto;
            });

            resolve(consultantFormatted)

          }, 1000);

        }).then(
          resolve => {
            return resolve
          }
        ).catch(
          reject => {
            console.log(reject)
          }
        )

        await consultor()

        return consultantFormatted

      } else {

        const { data: response } = await api.post(`/tabela-consultor/filter`, {
          descricao: inputValue,
        });

        var resp;
        if (typeof response.data.data != "undefined") {
          resp = response.data.data;
        } else {
          resp = response.data;
        }

        consultantFormatted = resp.map((mesa) => {
          const objeto = {
          value: String(mesa.id),
          label: mesa.descricao,
          };

          return objeto;
        });

        return consultantFormatted
      }

    } catch (error) {}
  }, []);

  return (
    <QueriesContext.Provider value={{ consultantsClassificationOptions }}>
      {children}
    </QueriesContext.Provider>
  );
};

export default QueriesContext;
