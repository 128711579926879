import React, { useCallback, useEffect, useState, useContext } from "react";
import { useHistory, Link } from "react-router-dom";
import { FormattedMessage, injectIntl } from "react-intl";
import InputLabel from "./../../../../components/InputLabel";
import AuthContext from "./../../../../context/auth";
import ContentSpinner from "./../../../../components/Spinner/Content";

import api from "./../../../../services/api";
import {
  Container,
  CreateForm,
  CreateFooter,
  TitleTax,
  FormProducts,
  PrazoGrid,
  GridInput,
  GridInput3,
} from "./styles";

import Button from "./../../../../components/ButtonDefault";

export const OrdersPainelHistory = ({ intl }) => {
  const [listaCotacao, setListaCotacao] = useState([]);
  const [numeroPedido, setNumeroPedido] = useState("");
  const [data, setData] = useState("");
  const [status, setStatus] = useState("");
  const [prazo, setPrazo] = useState("");
  const [loadingList, setLoadingList] = useState(false);

  const history = useHistory();
  const { user } = useContext(AuthContext);

  const quantidadePlaceholder = intl.formatMessage({
    id: "quantidade",
  });

  const handleBackButton = useCallback(() => {
    const painelControle = {
      painelControle: "painelControle"
    };

    history.push("/orders/painel", painelControle);
  },
  [history]
);

  useEffect(() => {
    (async () => {
      try {
        setLoadingList(true);
        const { data: response } = await api.post(`/pedido/filter`, {
          id: history.location.state.pedido,
        });

        const { data: responseCadastro } = await api.get(
          `/cadastro/${response.dados.data[0].cotacoes[0].cliente.id}`
        );

        const taxFinanceira = responseCadastro.taxa_financeira;

        const formattedListCotacao = response.dados.data[0].cotacoes.map(
          (item) => {
            const taxAdm = item?.taxa_administrativa_unitaria;

            const formattedList = item.prazos.map((prazo) => {
              let precoLitroFinal = 0;
              let totalLinhaSemTaxas = 0;
              let totalLinhaProduto = 0;
              let totalProdutoSemTaxa = 0;
              let totalProdutoComTaxa = 0;
              let idPrazo = prazo.id;
              let prazoCotacao = prazo.prazo;
              let totalLinhaFrete = 0;
              let totalLinhaTaxaFinanceira = 0;
              let totalLitros = 0;
              let taxaFinan = "";

              const arr = prazo.produtos.map((prod) => {
                const obj = {};
                let filterTaxa = [];

                //Total Litros/Quantidade da Cotação
                totalLitros = prazo.produtos.reduce((total, b) => {
                  const totalM3 = parseFloat(b.quantidade);
                  return total + totalM3;
                }, 0);

                const ProdutoCotacao = item.produtos_cotacao.filter(
                  (cot_prod) => {
                    return cot_prod.produto_id === prod.produto_id;
                  }
                );

                if (!prod.taxa_financeira_percentual) {
                  filterTaxa = taxFinanceira.filter((tax) => {
                    if (
                      parseInt(prazo.prazo) >=
                        parseInt(tax.taxa_financeira_de) &&
                      parseInt(prazo.prazo) <= parseInt(tax.taxa_financeira_ate)
                    ) {
                      return true;
                    }

                    return false;
                  });

                  taxaFinan = filterTaxa[0]?.taxa_financeira_percentual || 0;
                } else {
                  taxaFinan = prod.taxa_financeira_percentual;
                }

                if (parseInt(user.cadastro_tipo_id) !== 2) {
                  // Valor Preço Litro Final
                  precoLitroFinal = (
                    parseFloat(
                      parseFloat(prod?.valor_unitario) +
                        parseFloat(prod.valor_frete?.replaceAll(",", ".") || 0)
                    ) *
                      (parseFloat(taxaFinan) / 100 + 1) +
                    parseFloat(taxAdm)
                  ).toLocaleString("pt-br", {
                    minimumFractionDigits: 4,
                  });
                } else {
                  // Valor Preço Litro Final
                  precoLitroFinal = (
                    parseFloat(prod?.valor_unitario) +
                    parseFloat(prod.valor_frete?.replaceAll(",", ".") || 0)
                  ).toLocaleString("pt-br", {
                    minimumFractionDigits: 4,
                  });
                }

                // Valor Total sem taxas produto linha
                totalLinhaSemTaxas = (
                  parseFloat(prod?.valor_unitario) *
                  parseFloat(prod?.quantidade)
                ).toLocaleString("pt-br", {
                  minimumFractionDigits: 2,
                });

                if (parseInt(user.cadastro_tipo_id) !== 2) {
                  // Valor Total produto linha
                  totalLinhaProduto = (
                    parseFloat(
                      (
                        (parseFloat(prod?.valor_unitario) +
                          parseFloat(prod?.valor_frete || 0)) *
                        (parseFloat(taxaFinan) / 100 + 1)
                      ).toFixed(4)
                    ) *
                      parseFloat(prod?.quantidade) +
                    parseFloat(prod?.quantidade) * parseFloat(taxAdm)
                  ).toLocaleString("pt-br", {
                    minimumFractionDigits: 2,
                  });
                } else {
                  // Valor Total produto linha
                  totalLinhaProduto = (
                    (parseFloat(prod?.valor_unitario) +
                      parseFloat(prod?.valor_frete || 0)) *
                    parseFloat(prod?.quantidade)
                  ).toLocaleString("pt-br", {
                    minimumFractionDigits: 2,
                  });
                }

                //Soma total Sem taxas Com Frete
                totalProdutoSemTaxa =
                  totalProdutoSemTaxa +
                  parseFloat(prod?.quantidade) *
                    (parseFloat(prod?.valor_unitario) +
                      parseFloat(prod?.valor_frete || 0));

                //Soma total Com taxas + Frete
                // Calcula o total do pedido inteiro com todas as taxas.
                // Valor total da cotação com todas as taxas
                totalProdutoComTaxa =
                  parseFloat(totalProdutoComTaxa) +
                  parseFloat(
                    totalLinhaProduto.replaceAll(".", "").replaceAll(",", ".")
                  );

                totalLinhaFrete =
                  parseFloat(prod?.valor_frete || 0) *
                  parseFloat(prod?.quantidade);

                totalLinhaTaxaFinanceira =
                  parseFloat(
                    (
                      (parseFloat(prod?.valor_unitario) +
                        parseFloat(prod?.valor_frete)) *
                      (parseFloat(taxaFinan) / 100)
                    ).toFixed(4)
                  ) * parseFloat(prod?.quantidade);

                totalLinhaTaxaFinanceira = parseFloat(
                  totalLinhaTaxaFinanceira.toFixed(2)
                );

                Object.assign(obj, {
                  id: prod?.id,
                  id_produto: prod?.produto_id,
                  id_cotacao_produto: ProdutoCotacao[0]?.id || "",
                  descricao: prod.produto.descricao,
                  prazo: prazo.prazo,
                  quantidade: prod.quantidade || 0,
                  valor_referencia: prod?.valor_referencia
                    ? parseFloat(prod?.valor_referencia)
                        .toFixed(4)
                        ?.replaceAll(".", ",")
                    : (0).toFixed(4).replaceAll(".", ","),
                  valor_unitario: prod?.valor_unitario
                    ? parseFloat(prod?.valor_unitario)
                        .toFixed(4)
                        ?.replaceAll(".", ",")
                    : (0).toFixed(4).replaceAll(".", ","),
                  valor_frete: prod?.valor_frete
                    ? parseFloat(prod?.valor_frete)
                        ?.toFixed(4)
                        ?.replaceAll(".", ",")
                    : (0).toFixed(4).replaceAll(".", ","),
                  total_frete: totalLinhaFrete,
                  total_taxa_financeira: totalLinhaTaxaFinanceira,
                  preco_litro_final: precoLitroFinal,
                  total: totalLinhaProduto || 0,
                  total_sem_taxas: totalLinhaSemTaxas,
                  taxa_financeira: taxaFinan || 0,
                  cotacaoIdProduto: history.location.state.cotacaoIdProduto,
                });

                return obj;
              });

              // soma total do pedido com taxa e sem taxa
              arr.map((prod) => {
                prod.total_cotacao_sem_taxa =
                  parseFloat(totalProdutoSemTaxa.toFixed(3)) || 0;
                prod.total_cotacao_com_taxa =
                  parseFloat(totalProdutoComTaxa.toFixed(3)) || 0;
                return prod;
              });

              return { id: idPrazo, prazo: prazoCotacao, produtos: arr };
            });

            const obj = {};
            Object.assign(obj, {
              numeroCotacao: item.numero_cotacao_sistema,
              cliente:
                item.cliente.cpf_cnpj.replace(
                  /(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g,
                  "$1.$2.$3/$4-$5"
                ) +
                " - " +
                item.cliente.fantasia,
              distribuidor:
                item.distribuidor.cpf_cnpj.replace(
                  /(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g,
                  "$1.$2.$3/$4-$5"
                ) +
                " - " +
                item.distribuidor.razao_social,
              status: item.status_cotacao,
              original: item?.cotacao_original,
              filho: item?.cotacao_filhos,
              listaProdutos: item.produtos_cotacao,
              prazo: item.prazos,
              responsavel: item?.ultimo_editor_preco?.name,
              valorTotal: formattedList,
              cotacaoIdProduto: history.location.state.cotacaoIdProduto,
            });
            return obj;
          }
        );


        // Ordena o PEDIDO APROVADO para primeira posição
        var pedidoNaoAprovado = formattedListCotacao.filter((item) => {
          return item.status !== "Aprovado" && item.status !== "Faturado";
        });

        var pedidoAprovado = formattedListCotacao.filter((item) => {
          return item.status === "Aprovado" || item.status === "Faturado";
        });

        if (pedidoAprovado.length !== 0) {
          pedidoNaoAprovado.unshift(pedidoAprovado[0]);
        }

        // Ordena o PRAZO APROVADO para primeira posição
        if (
          response.dados.data[0].status_pedido === "Aprovado" ||
          response.dados.data[0].status_pedido === "Aguardando Faturamento" ||
          response.dados.data[0].status_pedido === "Faturado"
        ) {
          var prazoAprovado = pedidoAprovado[0]?.valorTotal.filter((item) => {
            return item.prazo === response?.dados?.data[0]?.prazo;
          });

          var prazoNaoAprovado = pedidoAprovado[0]?.valorTotal.filter(
            (item) => {
              return item.prazo !== response?.dados?.data[0]?.prazo;
            }
          );

          prazoNaoAprovado.unshift(prazoAprovado[0]);

          pedidoNaoAprovado[0].valorTotal = prazoNaoAprovado;
        }

        setListaCotacao(pedidoNaoAprovado);
        setPrazo(response.dados.data[0].prazo);
        setNumeroPedido(response.dados.data[0].numero_pedido_sistema);
        setData(response.dados.data[0].data_entrega_desejada);
        setStatus(response.dados.data[0].status_pedido);

        setLoadingList(false);
      } catch (error) {
        setLoadingList(false);
        console.log(error);
      }
    })();
  }, [history, history.location.state, user.cadastro_tipo_id]);

  const PedidoLinkOriginal = useCallback(({ item }) => {
    return (
      <Link
        to={{
          pathname: "/orders/painel/show",
          state: {
            numeroPedido: item?.pedido?.id,
            pedido: item.pedido,
            cliente: item?.pedido?.cadastro_id,
            isProgramming: true,
            isHistory: true,
            cotacaoIdProduto: history.location.state.cotacaoIdProduto
          },
        }}
      >
        {item?.pedido?.numero_pedido_sistema}
      </Link>
    );
  }, [history]);

  const PedidoLinkFilho = useCallback(({ item }) => {
    return (
      <Link
        to={{
          pathname: "/orders/painel/show",
          state: {
            numeroPedido: item[0]?.pedido?.id,
            pedido: item[0]?.pedido,
            cliente: item[0]?.pedido?.cadastro_id,
            isProgramming: true,
            isHistory: true,
            cotacaoIdProduto: history.location.state.cotacaoIdProduto
          },
        }}
      >
        {item[0]?.pedido?.numero_pedido_sistema}
      </Link>
    );
  }, [history]);

  return (
    <Container>
      <CreateForm>
        <header>
          <h4>
            <FormattedMessage id="historicoNegociacao" />
          </h4>
        </header>

        {loadingList ? (
          <ContentSpinner />
        ) : (
          <>
            <div style={{ textAlign: "-webkit-center" }}>
              <header className="header-red">
                <h4>
                  <FormattedMessage id="dadosPedido" />
                </h4>
              </header>
            </div>

            <form>
              <GridInput3>
                <InputLabel
                  name="pedido"
                  maxLength={100}
                  type="text"
                  label={<FormattedMessage id="numeroPedidoSistema" />}
                  value={numeroPedido}
                  readOnly
                />
                <InputLabel
                  name="data"
                  maxLength={100}
                  type="date"
                  label={<FormattedMessage id="data" />}
                  value={data}
                  readOnly
                />

                <InputLabel
                  name="status"
                  maxLength={100}
                  type="text"
                  label={<FormattedMessage id="statusPedidos" />}
                  value={status}
                  readOnly
                />
              </GridInput3>
            </form>

            <form>
              {listaCotacao.map((dataCotacao, index) => {
                return (
                  <div key={index} style={{ marginTop: "8%" }}>
                    <header
                      className="header-selected"
                      style={
                        dataCotacao?.status === "Aprovado" ||
                        dataCotacao?.status === "Faturado"
                          ? { backgroundColor: "green" }
                          : { backgroundColor: "#2d353c" }
                      }
                    >
                      <h4>
                        Dados da Cotação {dataCotacao?.numeroCotacao}
                        <p
                          style={
                            dataCotacao?.status === "Aprovado" ||
                            dataCotacao?.status === "Faturado"
                              ? { display: "block", float: "right" }
                              : { display: "none" }
                          }
                        >
                          - {dataCotacao?.status}
                        </p>
                        <p
                          style={
                            dataCotacao?.status === "Reprovado"
                              ? { display: "block", float: "right" }
                              : { display: "none" }
                          }
                        >
                          - {dataCotacao?.status}
                        </p>
                      </h4>
                    </header>

                    <GridInput>
                      <div>
                        <strong>{<FormattedMessage id="cliente" />}</strong>
                        <br />
                        <p>{dataCotacao?.cliente}</p>
                      </div>

                      <div>
                        <strong>
                          {<FormattedMessage id="distribuidor" />}
                        </strong>
                        <br />
                        <p>{dataCotacao?.distribuidor}</p>
                      </div>

                      <div>
                        <strong>
                          <FormattedMessage id="statusCotacao" />
                        </strong>
                        <br />
                        <p>{dataCotacao?.status}</p>
                      </div>

                      <div>
                        <strong>Responsável Cotação</strong>
                        <br />
                        <p>{dataCotacao?.responsavel}</p>
                      </div>

                      {dataCotacao?.original !== null ? (
                        <div>
                          <strong>Pedido original</strong>
                          <br />
                          <PedidoLinkOriginal item={dataCotacao?.original} />
                        </div>
                      ) : (
                        ""
                      )}

                      {dataCotacao?.filho?.length !== 0 ? (
                        <div>
                          <strong>Pedido criado</strong>
                          <br />
                          <PedidoLinkFilho item={dataCotacao?.filho} />
                        </div>
                      ) : (
                        ""
                      )}
                    </GridInput>

                    <FormProducts>
                      {dataCotacao?.valorTotal.map((data, index) => {
                        return (
                          <div key={index} style={{ margin: "1%" }}>
                            <h4
                              style={
                                data?.prazo === prazo &&
                                (dataCotacao?.status === "Aprovado" ||
                                  dataCotacao?.status === "Faturado")
                                  ? { color: "green" }
                                  : { color: "#2d353c" }
                              }
                            >
                              {dataCotacao?.status === "Aprovado" ||
                              dataCotacao?.status === "Faturado" ||
                              dataCotacao?.status === "Reprovado"
                                ? data?.prazo === prazo &&
                                  (dataCotacao?.status === "Aprovado" ||
                                    dataCotacao?.status === "Faturado")
                                  ? "Prazo aprovado:"
                                  : "Prazo reprovado:"
                                : "Prazo:"}{" "}
                              {data?.prazo} dias
                            </h4>

                            <PrazoGrid>
                              <li>
                                <TitleTax>
                                  <FormattedMessage id="produto" />
                                </TitleTax>
                              </li>
                              <li>
                                <TitleTax>
                                  {quantidadePlaceholder} (M³)
                                </TitleTax>
                              </li>
                              <li>
                                <TitleTax>
                                  <FormattedMessage id="precoReferenciaLitro" />
                                </TitleTax>
                              </li>
                              <li>
                                <TitleTax>
                                  <FormattedMessage id="precoNegociadoLitro" />
                                </TitleTax>
                              </li>
                              <li>
                                <TitleTax>
                                  <FormattedMessage id="precoFreteLitro" />
                                </TitleTax>
                              </li>
                              <li>
                                <TitleTax>
                                  <FormattedMessage id="precoFinalLitro" />
                                </TitleTax>
                              </li>
                              <li>
                                <TitleTax>Total</TitleTax>
                              </li>
                            </PrazoGrid>
                            <ul>
                              {data?.produtos.map((data, index) => {
                                return (
                                  <PrazoGrid key={index}>
                                    <li>
                                      {data?.descricao}
                                    </li>
                                    <li>
                                      {data?.quantidade / 1000}
                                    </li>
                                    <li>
                                        {data?.valor_referencia?.replace(
                                          ".",
                                          ","
                                        )}
                                    </li>
                                    <li
                                     style={
                                      data?.cotacaoIdProduto.find( id => id === data?.id_cotacao_produto)
                                        ? { padding: "5px" }
                                        : {  }
                                    }
                                    className={
                                      data?.cotacaoIdProduto.find( id => id === data?.id_cotacao_produto)
                                        ? 'blink'
                                        : ''
                                    }>
                                        {data?.valor_unitario?.replace(
                                          ".",
                                          ","
                                        )}
                                    </li>
                                    <li>
                                        {data?.valor_frete?.replace(".", ",")}
                                    </li>
                                    <li>
                                        {data?.valor_unitario !== "0,0000" ? data?.preco_litro_final : "0,0000"}
                                    </li>
                                    <li>
                                      {data?.valor_unitario !== "0,0000" ? data?.total : "0,0000"}
                                    </li>
                                  </PrazoGrid>
                                );
                              })}
                            </ul>
                            <h5>
                              {data.produtos[0].valor_unitario !== "0,0000" ?
                                 data.produtos[0].total_cotacao_com_taxa
                                  ? "Total com taxas:ﾠ" + data.produtos[0].total_cotacao_com_taxa.toLocaleString(
                                      "pt-br",
                                      {
                                        style: "currency",
                                        currency: "BRL",
                                      }
                                    )
                                  : "R$ 0,00"
                              : "" }
                            </h5>
                          </div>
                        );
                      })}
                    </FormProducts>
                  </div>
                );
              })}
              <br />
            </form>

            <hr />
          </>
        )}

        <CreateFooter>
          <div>
            <Button type="button" themeType="back" onClick={handleBackButton}>
              <FormattedMessage id="voltar" />
            </Button>
          </div>
        </CreateFooter>
      </CreateForm>
    </Container>
  );
};

export default injectIntl(OrdersPainelHistory);
