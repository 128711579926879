import React from "react";

const TextTableFormatted = ({ labelFormatted }) => {
  return (
    <>
      {labelFormatted.map((label, index) => {
        return <div key={index}>{label}</div>;
      })}
    </>
  );
};

export default TextTableFormatted;
