import React, { useEffect, useState, useContext } from "react";
import Chart from "react-apexcharts";
import api from "../../services/api";
import ContentSpinner from "../../components/Spinner/Content";
import {
  Panel,
  PanelHeader,
  PanelBody,
} from "./../../components/panel/panel.jsx";

import { format } from "date-fns";
import { ptBR } from "date-fns/locale";
import moment from "moment";
import AuthContext from "./../../context/auth";

const GraphReceita = ({ dataFilter }) => {
  const [loadingList, setLoadingList] = useState(true);
  const { user } = useContext(AuthContext);

  const [labels, setLabels] = useState([]);
  const [graph, setGraph] = useState([]);
  let loadGraph = {
    series: [
      {
        name: "Valor Total Reais",
        data: graph,
      },
    ],
    options: {
      chart: {
        height: 300,
        type: 'line',
        toolbar: {
          show: false,
        },
      },
      colors: ["#FF7375"],
      dataLabels: {
        enabled: true,
        enabledOnSeries: undefined,
        formatter: function (val) {
          return (parseFloat(val)).toLocaleString(undefined, {
            minimumFractionDigits: 4,
            maximumFractionDigits: 4
          })
        },
        textAnchor: 'middle',
        style: {
            fontSize: '11px',
            fontWeight: 'bold',
            colors: undefined
        },
        background: {
          enabled: true,
          foreColor: '#fff',
          padding: 4,
          opacity: 0.9,

        },
        dropShadow: {
            enabled: false,
            top: 1,
            left: 1,
            blur: 1,
            color: '#000',
            opacity: 0.45
        }
      },
      yaxis: {
        labels: {
          formatter: function (val) {
            return "R$ " + (parseFloat(val)).toLocaleString(undefined, {
              minimumFractionDigits: 4,
              maximumFractionDigits: 4
            })
          }
        }
      },
      // legend: {
      //   show: true,
      //   showForSingleSeries: true,
      // },
      grid: {
        padding: {
          left: 45,
        },
      },
      labels: labels,
      tooltip: {
        y: {
          formatter: function (val) {
            return "R$ " + (parseFloat(val)).toLocaleString(undefined, {
              minimumFractionDigits: 4,
              maximumFractionDigits: 4
            })
          }
        }
      },
    },
  };

  useEffect(() => {
    (async () => {
      try {
        const dataDeRandom = moment()
          .subtract(2, "months")
          .format("YYYY-MM-DD 00:00:00");
        const dataDe = moment(dataDeRandom).startOf('month').format('YYYY-MM-DD 00:00:00');
        const dataAte = moment().format("YYYY-MM-DD 23:59:59");

        const data = [];
        const labels = [];

        const { data: response } = await api.get(
          `/dashboard-cliente/volume-combustivel`,
          {
            params: {
              data_de: !dataFilter?.dataDeFilter
                ? dataDe
                : dataFilter?.dataDeFilter + " 00:00:00",
              data_ate: !dataFilter?.dataAteFilter
                ? dataAte
                : dataFilter?.dataAteFilter + " 23:59:59",
              cpf_cnpj: dataFilter?.cnpjFilter
                ?.replaceAll(".", "")
                ?.replaceAll("/", "")
                ?.replaceAll("-", "")
                ?.replaceAll("_", ""),
              grupo_economico: dataFilter?.grupoEconomicoFilter,
              municipio_id: dataFilter?.cidadeBasesDist?.value,
              estado_id: dataFilter?.estadoBasesDist?.value,
              cadastro_cliente_id: user.cadastro_id
            },
          }
        );

        Object.values(response).map((item) => {
          const obj = {};
          const date = item.data_group.split("-");

          Object.assign(obj, {
            data: data.push(item.total_com_taxa),
            labels: labels.push(
              format(new Date(date[0], date[1] - 1, 1), "MMMM/yyyy", {
                locale: ptBR,
              })
            ),
          });

          return obj;
        });

        setGraph(data);
        setLabels(labels);

        setLoadingList(false);
      } catch (error) {
        setLoadingList(false);
      }
    })();
  }, [dataFilter, user]);

  return (
    <>
      {loadingList ? (
        <ContentSpinner />
      ) : (
        <>
          <Panel>
            <PanelHeader>
              Volume Combustível em Reais (R$)
            </PanelHeader>
            <PanelBody className="p-0">
              <Chart
                options={loadGraph.options}
                series={loadGraph.series}
                type="line"
                width="100%"
                height="300px"
                className="graph"
              />
            </PanelBody>
          </Panel>
        </>
      )}
    </>
  );
};

export default GraphReceita;
