import React, { useEffect, useState, useContext } from "react";
import Chart from "react-apexcharts";
import api from "../../services/api";
import ContentSpinner from "../../components/Spinner/Content";
import {
  Panel,
  PanelHeader,
  PanelBody,
} from "./../../components/panel/panel.jsx";

import { format } from "date-fns";
import { ptBR, enAU, es } from "date-fns/locale";

import moment from "moment";

import { FormattedMessage, injectIntl } from "react-intl";

import Language from "../../context/language";

const GraphReceita = ({ dataFilter, intl }) => {
  const [loadingList, setLoadingList] = useState(true);

  const [labels, setLabels] = useState([]);
  const [graph, setGraph] = useState([]);

  const { language } = useContext(Language);

  //Placeholders internacionalizados
  const precoMedioPlaceholder = intl.formatMessage({
    id: "precoMedio",
  });

  let loadGraph = {
    series: [
      {
        name: precoMedioPlaceholder,
        data: graph,
      },
    ],
    options: {
      chart: {
        type: "line",
        toolbar: {
          show: false,
        },
      },
      colors: ["#FF7375"],
      yaxis: {
        labels: {
          formatter: function(val, index) {
            return (parseFloat(val)).toLocaleString(undefined, {
              minimumFractionDigits: 4,
              maximumFractionDigits: 4
            })
          }
        }
      },
      dataLabels: {
        enabled: true,
        enabledOnSeries: undefined,
        formatter: function (val) {
          return (parseFloat(val)).toLocaleString(undefined, {
            minimumFractionDigits: 4,
            maximumFractionDigits: 4
          })
        },
      },
      stroke: {
        curve: "smooth",
        width: 3,
      },
      xaxis: {
        categories: labels,
      },
      grid: {
        padding: {
          left: 45,
        },
      },
      tooltip: {
        y: {
          formatter: function (val) {
            return (parseFloat(val)).toLocaleString(undefined, {
              minimumFractionDigits: 4,
              maximumFractionDigits: 4
            })
          },
        }
      },
    },
  };

  useEffect(() => {
    (async () => {
      try {
        const dataDeRandom = moment()
        .subtract(2, "months")
        .format("YYYY-MM-DD 00:00:00");
        const dataDe = moment(dataDeRandom).startOf('month').format('YYYY-MM-DD 00:00:00');
        const dataAte = moment().format("YYYY-MM-DD 23:59:59");

        const data = [];
        const labels = [];

        const { data: response } = await api.get(
          `/dashboard/precos-medios-litros`,
          {
            params: {
              data_de: !dataFilter?.dataDeFilter
                ? dataDe
                : dataFilter?.dataDeFilter + " 00:00:00",
              data_ate: !dataFilter?.dataAteFilter
                ? dataAte
                : dataFilter?.dataAteFilter + " 23:59:59",
              cpf_cnpj: dataFilter?.cnpjFilter
                ?.replaceAll(".", "")
                ?.replaceAll("/", "")
                ?.replaceAll("-", "")
                ?.replaceAll("_", ""),
              grupo_economico: dataFilter?.grupoEconomicoFilter,
              base_distribuicao_id: dataFilter?.bases?.value,
              municipio_id: dataFilter?.cidadeBasesDist?.value,
              estado_id: dataFilter?.estadoBasesDist?.value,
            },
          }
        );

        let setLanguage = ptBR;
        if (language === "en") {
          setLanguage = enAU;
        } else if (language === "es") {
          setLanguage = es;
        } else if (language === "pt_BR") {
          setLanguage = ptBR;
        }

        response.map((item) => {
          const obj = {};
          const date = item.data_group.split("-");

          Object.assign(obj, {
            data: data.push(item.precosMedioLitros),
            labels: labels.push(
              format(new Date(date[0], date[1] - 1, 1), "MMMM/yyyy", {
                locale: setLanguage,
              })
            ),
          });

          return obj;
        });

        setGraph(data);
        setLabels(labels);

        setLoadingList(false);
      } catch (error) {
        setLoadingList(false);
      }
    })();
  }, [dataFilter, language]);

  return (
    <>
      {loadingList ? (
        <ContentSpinner />
      ) : (
        <>
          <Panel>
            <PanelHeader>
              <FormattedMessage id="grupoPrecosMedio" />
            </PanelHeader>
            <PanelBody className="p-0">
              <Chart
                options={loadGraph.options}
                series={loadGraph.series}
                type="line"
                width="100%"
                height="300px"
                className="graph"
              />
            </PanelBody>
          </Panel>
        </>
      )}
    </>
  );
};

export default injectIntl(GraphReceita);
