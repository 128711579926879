import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  position: relative;

  .custom-modal {
    width: 100vw;
    height: 100vh;
  }

  header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;

    nav {
      display: flex;

            h2 {
        margin: 0;
        padding: 0;
        margin-left: 15px;
        text-align: center;
        color: #221c46;
      }
    }
  }
`;

export const ContainerPage = styled.div`
  display: grid;
  grid-template-columns: 80% 18%;
  grid-template-columns: ${(props) => (props.columns ? "80% 18%" : "100%")};
  gap: 2%;

  @media screen and (min-width: 1200px) {
    grid-template-columns: ${(props) => (props.columns ? "78% 20%" : "100%")};
  }

  @media screen and (max-width: 1200px) {
    grid-template-columns: ${(props) => (props.columns ? "75% 23%" : "100%")};
  }

  @media screen and (max-width: 1100px) {
    grid-template-columns: ${(props) =>
      props.columns ? "73.5% 24.5%" : "100%"};
  }

  @media screen and (max-width: 1050px) {
    grid-template-columns: ${(props) => (props.columns ? "72% 26%" : "100%")};
  }

  @media screen and (max-width: 1000px) {
    grid-template-columns: ${(props) => (props.columns ? "70% 28%" : "100%")};
  }

  @media screen and (max-width: 950px) {
    grid-template-columns: 100%;
    .hidden {
      display: none;
    }
  }
`;

export const ContainerInfo = styled.div`
  width: 100%;
  position: relative;

  .graph-div {
    width: 100%;
    text-align: center;
  }

  .graph {
    background: #f5f5f5;
    padding: 9px 5px;
  }

  .grid {
    display: grid;
  }

  .grid-template-columns-important {
    grid-template-columns: 10% 90%;
    background-color: #B30000;
    padding: 10px 0;
    color: #fff;
    margin-bottom: 20px;
    img {
      justify-self: center;
      place-self: center;
    }
  }

  .grid-template-columns-table {
    grid-template-columns: 49% 49%;
    gap: 1%;

    @media screen and (max-width: 950px) {
      grid-template-columns: 100%;
    }
  }

  .grid-template-columns-2 {
    grid-template-columns: 49% 49%;
    gap: 1%;
    display: none;
    @media screen and (max-width: 950px) {
      display: grid;
      margin-top: 15px;
      margin-bottom: -7px;
    }
  }

  .grid-template-columns-cards {
    grid-template-columns: 24.5% 24.5% 24.5% 24.5%;
    text-align: center;
    gap: 0.5%;
  }

  .text {
    font-size: 18px;
    margin-top: 8px;
  }

  header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
    nav {
      display: flex;
    }
  }
`;

export const Table = styled.div`
  margin-top: 15px;

  main {
    margin-bottom: 25px;
    table {
      table-layout: auto;
      border-collapse: collapse;
      width: 100%;
      border: 1px solid #dddddd;
      position: relative;

      thead {
        tr {
          th {
            height: 40px;
            text-align: left;
            padding: 8px;
            position: -webkit-sticky;
            position: sticky;
            background-color: #221c46;
            color: #fff;
            text-align: center;
          }
        }
      }

      tbody {
        td {
          border: 1px solid #dddddd;
          width: max-content;
        }

        tr {
          height: 45px;
          background-color: #FCECEA;

          td {
            padding-left: 10px;
          }
        }

        tr:disabled {
          background-color: grey;
        }

        tr:nth-child(even) {
          background-color: #fff;
        }
      }

      th {
        top: 0;
        z-index: 5;
        background: #fff;
        border: 1px solid #dddddd;
        width: 100px;
      }
    }
  }
`;

export const Card = styled.div`
  header {
    width: 100%;
    height: 34px;
    background-color: #221c46!important;
        /* border-radius: 10px 10px 0 0; */

    display: flex;
    justify-content: center;
    align-items: center;
  }

  h4 {
    width: max-content;
    height: max-content;
    margin-left: 10px;
    color: #f8f8f8;
    position: absolute;
    left: 0;
    margin-top: 8px;
  }
`;

export const FormProducts = styled.div`
  width: 100%;
  padding: 0 35px;

  > ul {
    div {
      display: flex;
      margin: 0;
      margin-left: -5px;

      li {
        height: 100%;
        width: 100%;
        padding: 0 5px;
        text-align: center;
        margin-top: 5px;
      }
    }
  }
`;

export const TitleTax = styled.strong``;
