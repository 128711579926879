import React, { createContext, useCallback } from "react";

import api from "./../../../services/api";

const QueriesContext = createContext();

export const MerchantSelect = ({ children }) => {
  const merchantOptions = useCallback(async (inputValue) => {
    try {
      const { data: response } = await api.post(`/tabela-consultor/filter`, {
        tipo: "Merchant",
        descricao: inputValue
      });

      console.log(response)

      const arr = response.data.map((consultor) => {
        const obj = {};

        Object.assign(obj, {
          value: consultor.id,
          label: consultor.descricao,
        });

        return obj;
      });

      return new Promise((resolve) => {
        return resolve(
          arr.filter((i) =>
            i.label.toLowerCase().includes(inputValue.toLowerCase())
          )
        );
      });
    } catch (error) {}
  }, []);

  return (
    <QueriesContext.Provider value={{ merchantOptions }}>
      {children}
    </QueriesContext.Provider>
  );
};

export default QueriesContext;
