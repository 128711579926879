import React, { useEffect, useState, useCallback, useContext } from "react";
import Chart from "react-apexcharts";
import api from "../../services/api";
import ContentSpinner from "../../components/Spinner/Content";
import {
  Panel,
  PanelHeader,
  PanelBody,
} from "../../components/panel/panel.jsx";

import { format } from "date-fns";
import { ptBR, enAU, es } from "date-fns/locale";
import moment from "moment";
import AsyncListSelect from "../../components/ListSelect/AsyncListSelect";
import { Search } from "./styles";
import Language from "../../context/language";

import { FormattedMessage } from "react-intl";

const SodexoFaturamento = ({ dataFilter, intl }) => {
  const timeoutRef = React.useRef(null);
  const [loadingList, setLoadingList] = useState(true);
  const [dataDeState, setDataDeState] = useState("");

  const { language } = useContext(Language);

  const [labels, setLabels] = useState([]);
  const [graphSoma, setGraphSoma] = useState([]);
  const [graphADM, setGraphADM] = useState([]);
  const [graphFI, setGraphFI] = useState([]);
  const [total, setTotal] = useState([]);

  const [maximo, setMaximo] = useState("");

  let loadGraph = {
    series: [
      {
        name: "Taxa Administrativa",
        data: graphADM,
      },
      {
        name: "Taxa Financeira",
        data: graphFI,
      },
      {
        name: "Taxa Total Mês",
        data: graphSoma,
      },
      {
        name: "Total Receita",
        data: total,

      },
    ],
    options: {
      chart: {
        height: 300,
        type: 'line',
        toolbar: {
          show: false,
        },
        zoom: {
          enabled: false,
          autoScaleYaxis: false,
        },
      },
      dataLabels: {
        enabled: true,
        enabledOnSeries: undefined,
        formatter: function (val) {
          return (parseFloat(val)).toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
          })
        },
        textAnchor: 'middle',
        style: {
            fontSize: '11px',
            fontWeight: 'bold',
            colors: undefined
        },
        background: {
          enabled: true,
          foreColor: '#fff',
          padding: 4,
          opacity: 0.9,
          zIndex: 1
        },
        dropShadow: {
            enabled: false,
            top: 1,
            left: 1,
            blur: 1,
            color: '#000',
            opacity: 0.45
        }
      },
      yaxis: {
        labels: {
          formatter: function(val, index) {
            return (parseFloat(val)).toLocaleString(undefined, {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2
            })
          },
        },
        min: 0,
        max: maximo[0],
      },
      tooltip: {
        y: {
          formatter: function(val, { series, seriesIndex, dataPointIndex, w }) {
            var porcentagem = (parseFloat(((val/(w.globals.series[3][dataPointIndex])) * 100))).toLocaleString(undefined, {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2
            })

             if(isNaN(parseFloat(porcentagem))  === true){
               porcentagem = 0
             }

            return "R$ " + (parseFloat(val)).toLocaleString(undefined, {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2
            }) + " - " + porcentagem + "%"
          }
        }
      },
      colors: ["#FF7375", "#221C46", "#ffdc37", "#2ea100",],
      xaxis: {
        categories: labels,
      },
      grid: {
        padding: {
          left: 45,
        },
      },

      stroke: {
        width: [3, 3, 3, 0]
      }
    },
  };

  const loadBase = useCallback(async (dataDeState, e) => {
    try {
      const dataDe = moment()
      .subtract(2, "months")
      .format("YYYY-MM-DD 00:00:00");
      const dataAte = moment().format("YYYY-MM-DD 23:59:59");


      let baseFormatted = [];

      if (timeoutRef.current !== null) {
        clearTimeout(timeoutRef.current);
      }

      if (e !== "") {
        const base = () => new Promise((resolve, reject) => {
          timeoutRef.current = setTimeout(async ()=> {
            timeoutRef.current = null;

            const { data: response } = await api.get("/utilitarios/get-base-faturado",
            {
              params: {
                data_de: !dataFilter?.dataDeFilter
                  ? dataDe
                  : dataFilter?.dataDeFilter + " 00:00:00",
                data_ate: !dataFilter?.dataAteFilter
                  ? dataAte
                  : dataFilter?.dataAteFilter + " 23:59:59",
              },
            }
          );

          baseFormatted = response.map((base) => {
            const objeto = {
              value: base.base_id,
              label: base.nome_base,
            };

            return objeto;
          });

            resolve(baseFormatted)

          }, 1000);

        }).then(
          resolve => {
            return resolve
          }
        ).catch(
          reject => {
            console.log(reject)
          }
        )

        await base()

        return new Promise((resolve) => {
          return resolve(
            baseFormatted?.filter((i) =>
              i?.label?.toLowerCase().includes(e?.toLowerCase())
            )
          );
        });
      } else {
        const { data: response } = await api.get("/utilitarios/get-base-faturado",
        {
          params: {
            data_de: !dataFilter?.dataDeFilter
              ? dataDe
              : dataFilter?.dataDeFilter + " 00:00:00",
            data_ate: !dataFilter?.dataAteFilter
              ? dataAte
              : dataFilter?.dataAteFilter + " 23:59:59",
          },
        }
      );

      baseFormatted = response.map((base) => {
        const objeto = {
          value: base.base_id,
          label: base.nome_base,
        };

        return objeto;
      });

      return new Promise((resolve) => {
        return resolve(
          baseFormatted?.filter((i) =>
            i?.label?.toLowerCase().includes(e?.toLowerCase())
          )
        );
      });

      }
    } catch (error) {}
  }, [dataFilter]);

  useEffect(() => {
    (async () => {
      try {

        setDataDeState(dataFilter?.dataDeFilter)

        loadBase()

        setLoadingList(false);
      } catch (error) {
        setLoadingList(false);
      }
    })();
  }, [dataFilter, loadBase]);

  const handleFilter = useCallback(async (e) => {
    try {
      const dataDeRandom = moment()
      .subtract(2, "months")
      .format("YYYY-MM-DD 00:00:00");
      const dataDe = moment(dataDeRandom).startOf('month').format('YYYY-MM-DD 00:00:00');
      const dataAte = moment().format("YYYY-MM-DD 23:59:59");

      const dataSoma = [];
      const dataADM = [];
      const dataFI = [];
      const totalGeral = [];
      const maximoEscala = []

      const labels = [];

      const response  = await api.get(
        `/dashboard/receita-base-distribuidor`,
        {
          params: {
            data_de: !dataFilter?.dataDeFilter
              ? dataDe
              : dataFilter?.dataDeFilter + " 00:00:00",
            data_ate: !dataFilter?.dataAteFilter
              ? dataAte
              : dataFilter?.dataAteFilter + " 23:59:59",
            cpf_cnpj: dataFilter?.cnpjFilter
              ?.replaceAll(".", "")
              ?.replaceAll("/", "")
              ?.replaceAll("-", "")
              ?.replaceAll("_", ""),
            grupo_economico: dataFilter?.grupoEconomicoFilter,
            base_distribuicao_id: e?.value,
            municipio_id: dataFilter?.cidadeBasesDist?.value,
            estado_id: dataFilter?.estadoBasesDist?.value,
          },
        }
      );

      let setLanguage = ptBR;
      if (language === "en") {
        setLanguage = enAU;
      } else if (language === "es") {
        setLanguage = es;
      } else if (language === "pt_BR") {
        setLanguage = ptBR;
      }

      Object.values(response.data).map((item, index) => {
        const obj = {};
        const date = item.data_group.split("-");


        Object.assign(obj, {
          dataSoma: dataSoma.push(item.total_com_taxa),
          dataADM: dataADM.push(item.total_taxa_administrativa),
          dataFI: dataFI.push(item.total_taxa_financeira),
          total: totalGeral.push(parseInt(item.receita_mes)),
          maximoEscala: maximoEscala.push(parseFloat(item.escala)),
          labels: labels.push(
            format(new Date(date[0], date[1] - 1, 1), "MMMM/yyyy", {
              locale: setLanguage,
            })
          ),
        });
        return obj
      });

      setMaximo(maximoEscala);
      setGraphSoma(dataSoma);
      setGraphADM(dataADM);
      setGraphFI(dataFI);
      setTotal(totalGeral)
      setLabels(labels);

      setLoadingList(false);
    } catch (error) {
      setLoadingList(false);
    }
}, [dataFilter, language])

  return (
    <>
      {loadingList ? (
        <ContentSpinner />
      ) : (
        <>
          <Panel>
            <PanelHeader>
              <FormattedMessage id="grupoBaseDistribuicaoReceita" />
            </PanelHeader>
            <PanelBody className="p-0">

              <Search>
                <AsyncListSelect
                  key={dataDeState}
                  placeholder={"Selecione uma base de distribuição para carregar o gráfico"}
                  onChange={(e) => {
                    handleFilter(e)
                  }}
                  loadOptions={(e) => {
                    return loadBase(dataDeState, e);
                  }}
                  cacheOptions
                  defaultOptions
                />
              </Search>

              <Chart
                options={loadGraph.options}
                series={loadGraph.series}
                type="line"
                width="100%"
                height="400px"
                className="graph"
              />
            </PanelBody>
          </Panel>
        </>
      )}
    </>
  );
};

export default SodexoFaturamento;
