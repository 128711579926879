const inputMask = {
  date: "99/99/9999",
  cellphone: "(99) 99999-9999",
  phone: "(99) 9999-9999",
  CNPJ: "99.999.999/9999-99",
  CEP: "99999-999",
  CPF: "999.999.999-99",
  RG: "99.999.999-9",
  FieldNumber1: "9",
  FieldNumber2: "99",
  FieldNumber3: "999",
  FieldNumber4: "9999",
  FieldNumber5: "99999",
  FieldNumber6: "999999",
  FieldNumber7: "9999999",
  FieldNumber8: "99999999",
  FieldNumber9: "999999999",
  FieldNumber10: "9999999999",
  hour: "99:99",
};
export default inputMask;
