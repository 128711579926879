import React, { useCallback, useState, useContext, useEffect } from "react";

import { useHistory } from "react-router-dom";
import { FormattedMessage, injectIntl } from "react-intl";

import ListSelect from "./../../../components/ListSelect";
import InputLabel from "./../../../components/InputLabel";
import InputMaskDefault from "./../../../components/InputMaskDefault";
import ButtonIcon from "../../../components/ButtonIcon";

import api from "./../../../services/api";
import { Container, CreateForm, CreateFooter } from "./styles";
import { FiTrash } from "react-icons/fi";

import Button from "./../../../components/ButtonDefault";

import AsyncListSelect from "../../../components/ListSelect/AsyncListSelect";
import { toast } from "react-toastify";

import AuthContext from "./../../../context/auth";

export const CreateOrders = ({ intl }) => {
  //Estado Datas
  const [cadastroDataInicial, setCadastroDataInicial] = useState("");
  const [cadastroDataFinal, setCadastroDataFinal] = useState("");
  const [entregaDataInicial, setEntregaDataInicial] = useState("");
  const [entregaDataFinal, setEntregaDataFinal] = useState("");
  const [integracaoDataInicial, setIntegracaoDataInicial] = useState("");
  const [integracaoDataFinal, setIntegracaoDataFinal] = useState("");

  const [cliente, setCliente] = useState("");
  const [distribuidor, setDistribuidor] = useState("");
  const [basesDistribuicao, setBasesDistribuicao] = useState("");
  const [pedido, setPedido] = useState("");
  const [pedidoCliente, setPedidoCliente] = useState("");
  const [statusPedido, setStatusPedido] = useState("");
  const [statusCotacao, setStatusCotacao] = useState("");
  const [integrado, setIntegrado] = useState("");

  const [loadingButton, setLoadingButton] = useState(false);

  const history = useHistory();

  const { user } = useContext(AuthContext);

  const todosPlaceholder = intl.formatMessage({
    id: "todos",
  });

  const selecione = intl.formatMessage({
    id: "selecione",
  });

  const statusOptionsPedido = [
    { value: "Aprovado", label: <FormattedMessage id="aprovado" /> },
    { value: "Reprovado", label: <FormattedMessage id="reprovado" /> },
    { value: "Cancelado", label: <FormattedMessage id="cancelado" /> },
    {
      value: "Aguardando Faturamento ",
      label: <FormattedMessage id="aguardandoFaturamento" />,
    },
    { value: "Faturado", label: <FormattedMessage id="faturado" /> },
  ];

  const statusOptionsCotacao = [
    { value: "faturado", label: <FormattedMessage id="faturado" /> },
    { value: "Aprovado", label: <FormattedMessage id="aprovado" /> },
    { value: "Reprovado", label: <FormattedMessage id="reprovado" /> },
    { value: "cancelado", label: <FormattedMessage id="cancelado" /> },
  ];

  const integradoOptions = [
    { value: 1, label: <FormattedMessage id="opcaoSim" /> },
    { value: 0, label: <FormattedMessage id="opcaoNao" /> },
  ];

  const handleBackButton = useCallback(() => {
    history.goBack();
  }, [history]);

  useEffect(() => {
    (async () => {
      if (parseInt(user.cadastro_tipo_id) === 3) {
        try {
          const { data: response } = await api.get(
            `/cadastro/${user.cadastro_id}`
          );

          const cliente = response?.cpf_cnpj + " - " + response?.razao_social;

          setCliente({
            value: response.id,
            label: cliente,
          });
        } catch (error) {
          console.log(error);
        }
      }

      if (parseInt(user.cadastro_tipo_id) === 2) {
        try {
          const { data: response } = await api.get(
            `/cadastro/${user.cadastro_id}`
          );

          const distribuidor =
            response?.cpf_cnpj + " - " + response?.razao_social;

          setDistribuidor({
            value: response.id,
            label: distribuidor,
          });
        } catch (error) {
          console.log(error);
        }
      }
    })();
  }, [user.cadastro_id, user.cadastro_tipo_id]);

  const loadDistribuidores = useCallback(async () => {
    try {
      const { data: responseDistribuidor } = await api.get(
        `/utilitarios/get-cadastros-by-clientes-id/${user.cadastro_id}`
      );

      const empresasFormatted = responseDistribuidor.map((empresa) => {
        const objeto = {
          value: empresa.id,
          label: empresa.cpf_cnpj + " - " + empresa.razao_social,
        };

        return objeto;
      });

      return empresasFormatted;
    } catch (error) {
      console.log(error);
    }
  }, [user.cadastro_id]);

  const loadClientes = useCallback(async () => {
    try {
      const { data: responseCliente } = await api.get(
        `/utilitarios/get-cadastros-by-distribuidor-id/${user.cadastro_id}`
      );

      const empresasFormatted = responseCliente.map((empresa) => {
        const objeto = {
          value: empresa.id,
          label: empresa.cpf_cnpj + " - " + empresa.razao_social,
        };

        return objeto;
      });

      return empresasFormatted;
    } catch (error) {
      console.log(error);
    }
  }, [user.cadastro_id]);

  const handleCreate = useCallback(async () => {
    setLoadingButton(true);

    const checkedCadastroDataInicial = cadastroDataInicial === "dd/mm/aaaa" ? "" : cadastroDataInicial;
    const checkedCadastroDataFinal = cadastroDataFinal === "dd/mm/aaaa" ? "" : cadastroDataFinal;
    const checkedEntregaDataInicial = entregaDataInicial === "dd/mm/aaaa" ? "" : entregaDataInicial;
    const checkedEntregaDataFinal = entregaDataFinal === "dd/mm/aaaa" ? "" : entregaDataFinal;
    const checkedIntegracaoDataInicial = integracaoDataInicial === "dd/mm/aaaa" ? "" : integracaoDataInicial;
    const checkedIntegracaoDataFinal = integracaoDataFinal === "dd/mm/aaaa" ? "" : integracaoDataFinal;

    try {
      const data = {
        saida: "excel",
        cadastro_tipo_id: user?.cadastro_tipo_id,
        dataCadastroDe: checkedCadastroDataInicial,
        dataCadastroAte: checkedCadastroDataFinal,
        dataEntregaDe: checkedEntregaDataInicial,
        dataEntregaAte: checkedEntregaDataFinal,
        dataIntegracaoDe:checkedIntegracaoDataInicial,
        dataIntegracaoAte: checkedIntegracaoDataFinal,
        filtroCliente: cliente?.value || "",
        filtroDistribuidor: distribuidor?.value || "",
        baseDistribuicao: basesDistribuicao?.value || "",
        integrado: integrado?.value || "",
        numeroPedido: pedido || "",
        numeroPedidoCliente: pedidoCliente || "",
        statusPedido: statusPedido?.value || "",
        status_cotacao: statusCotacao?.value || "",
      };

      await api
        .get(`/relatorio/pedidos-detalhados`, {
          params: data,
          responseType: "blob",
        })
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "Relatorio-Pedidos.xlsx");
          document.body.appendChild(link);
          link.click();

          toast.success(<FormattedMessage id="relatorioCriadoComSucesso" />);
        })
        .catch((err) => console.log(err));

      setLoadingButton(false);
    } catch (error) {
      setLoadingButton(false);
      console.log(error);

      toast.error(<FormattedMessage id="errorExportarRelatorio" />);
    }
  }, [
    basesDistribuicao.value,
    cadastroDataFinal,
    cadastroDataInicial,
    cliente,
    distribuidor,
    entregaDataFinal,
    entregaDataInicial,
    integracaoDataFinal,
    integracaoDataInicial,
    integrado,
    pedido,
    pedidoCliente,
    statusCotacao,
    statusPedido,
    user.cadastro_tipo_id,
  ]);

  const handleClean = useCallback((e) => {
    try {
      e.preventDefault();
      setCadastroDataInicial("dd/mm/aaaa");
      setCadastroDataFinal("dd/mm/aaaa");
      setEntregaDataInicial("dd/mm/aaaa");
      setEntregaDataFinal("dd/mm/aaaa");
      setIntegracaoDataInicial("dd/mm/aaaa");
      setIntegracaoDataFinal("dd/mm/aaaa");
    } catch (error) {
      console.log(error);
    }
  }, []);

  return (
    <Container>
      <CreateForm>
        <header>
          <h4>
            <FormattedMessage id="relatorioPedidosDetalhados" />
          </h4>
        </header>

        <form>
          <div
            className={user.cadastro_tipo_id === 1 ? "grid-for" : "grid-three"}
          >
            <div>
              <div>
                <header className="header-two">
                  <h4>
                    <FormattedMessage id="cadastro" />
                  </h4>
                </header>
              </div>

              <div className="sideBySide">
                <InputLabel
                  name="DataCadastroInicial"
                  label={<FormattedMessage id="dataInicio" />}
                  type="date"
                  style={{ color: "hsl(0,0%,50%)" }}
                  value={cadastroDataInicial}
                  onChange={(e) => setCadastroDataInicial(e.target.value)}
                />
              </div>

              <div className="sideBySideTwo">
                <InputLabel
                  name="DataCadastroFinal"
                  label={<FormattedMessage id="dataFim" />}
                  type="date"
                  style={{ color: "hsl(0,0%,50%)" }}
                  value={cadastroDataFinal}
                  onChange={(e) => setCadastroDataFinal(e.target.value)}
                />
              </div>
            </div>

            <div>
              <div>
                <header className="header-two">
                  <h4>{<FormattedMessage id="entrega" />}</h4>
                </header>
              </div>

              <div className="sideBySide">
                <InputLabel
                  name="DataEntregaInicial"
                  label={<FormattedMessage id="dataInicio" />}
                  type="date"
                  style={{ color: "hsl(0,0%,50%)" }}
                  value={entregaDataInicial}
                  onChange={(e) => setEntregaDataInicial(e.target.value)}
                />
              </div>

              <div className="sideBySideTwo">
                <InputLabel
                  name="DataEntregaFinal"
                  label={<FormattedMessage id="dataFim" />}
                  type="date"
                  style={{ color: "hsl(0,0%,50%)" }}
                  value={entregaDataFinal}
                  onChange={(e) => setEntregaDataFinal(e.target.value)}
                />
              </div>
            </div>

            {parseInt(user.cadastro_tipo_id) === 1 ? (
              <>
                <div>
                  <div>
                    <header className="header-two">
                      <h4>{<FormattedMessage id="integracao" />}</h4>
                    </header>
                  </div>

                  <div className="sideBySide">
                    <InputLabel
                      name="DataIntegracaoInicial"
                      label={<FormattedMessage id="dataInicio" />}
                      type="date"
                      style={{ color: "hsl(0,0%,50%)" }}
                      value={integracaoDataInicial}
                      onChange={(e) => setIntegracaoDataInicial(e.target.value)}
                    />
                  </div>

                  <div className="sideBySideTwo">
                    <InputLabel
                      name="DataIntegracaoFinal"
                      label={<FormattedMessage id="dataFim" />}
                      type="date"
                      style={{ color: "hsl(0,0%,50%)" }}
                      value={integracaoDataFinal}
                      onChange={(e) => setIntegracaoDataFinal(e.target.value)}
                    />
                  </div>
                </div>
              </>
            ) : (
              ""
            )}

            <div>
              <ButtonIcon
                Icon={FiTrash}
                backgroundColor={"#B30000"}
                color={"#ffffff"}
                onClick={handleClean}
                placeholder={"Limpar datas"}
                style={{marginTop: 62}}
              />
            </div>
          </div>

          <div className="sideBySide">
            {user?.cadastro_tipo_id === 1 ? (
              <>
                <AsyncListSelect
                  value={cliente}
                  labelPlaceholder={<FormattedMessage id="cliente" />}
                  placeholder={todosPlaceholder}
                  onChange={(e) => {
                    setCliente(e);
                  }}
                  myOptions={"clientes"}
                  cacheOptions
                  defaultOptions
                  isClearable
                />
              </>
            ) : (
              ""
            )}

            {user?.cadastro_tipo_id === 2 ? (
              <>
                <AsyncListSelect
                  // value={cliente}
                  labelPlaceholder={<FormattedMessage id="cliente" />}
                  placeholder={todosPlaceholder}
                  onChange={(e) => {
                    setCliente(e);
                  }}
                  loadOptions={loadClientes}
                  cacheOptions
                  defaultOptions
                  isClearable
                />
              </>
            ) : (
              ""
            )}

            {user?.cadastro_tipo_id === 3 ? (
              <>
                <AsyncListSelect
                  value={cliente}
                  labelPlaceholder={<FormattedMessage id="cliente" />}
                  placeholder={selecione}
                  onChange={(e) => {
                    setCliente(e);
                  }}
                  isDisabled
                  cacheOptions
                  defaultOptions
                  isClearable
                />
              </>
            ) : (
              ""
            )}

            <AsyncListSelect
              value={basesDistribuicao}
              labelPlaceholder={<FormattedMessage id="baseDistribuicao" />}
              placeholder={todosPlaceholder}
              onChange={(e) => {
                setBasesDistribuicao(e);
              }}
              myOptions={"basesDistribuição"}
              cacheOptions
              defaultOptions
              isClearable
            />

            <InputMaskDefault
              mask={"int15"}
              label={<FormattedMessage id="numeroPedidoSistema" />}
              type="text"
              value={pedido}
              onChange={(e) => setPedido(e.target.value)}
            />

            <ListSelect
              name="frete"
              labelPlaceholder={"Status Pedido"}
              placeholder={todosPlaceholder}
              cacheOptions
              defaultOptions
              options={statusOptionsPedido}
              value={statusPedido}
              onChange={(e) => setStatusPedido(e)}
              isClearable
            />
          </div>

          <div className="sideBySideTwo">
            {parseInt(user.cadastro_tipo_id) === 1 ? (
              <>
                <AsyncListSelect
                  value={distribuidor}
                  labelPlaceholder={<FormattedMessage id="distribuidores" />}
                  placeholder={<FormattedMessage id="todos" />}
                  onChange={(e) => {
                    setDistribuidor(e);
                  }}
                  myOptions={"distribuidores"}
                  cacheOptions
                  defaultOptions
                  isClearable
                />
              </>
            ) : (
              ""
            )}

            {parseInt(user.cadastro_tipo_id) === 2 ? (
              <>
                <AsyncListSelect
                  value={distribuidor}
                  labelPlaceholder={<FormattedMessage id="distribuidor" />}
                  placeholder={selecione}
                  onChange={(e) => {
                    setDistribuidor(e);
                  }}
                  isDisabled
                  // myOptions={"clientes"}
                  cacheOptions
                  defaultOptions
                  isClearable
                />
              </>
            ) : (
              ""
            )}

            {parseInt(user.cadastro_tipo_id) === 3 ? (
              <>
                <AsyncListSelect
                  labelPlaceholder={<FormattedMessage id="distribuidores" />}
                  placeholder={<FormattedMessage id="todos" />}
                  onChange={(e) => {
                    setDistribuidor(e);
                  }}
                  loadOptions={loadDistribuidores}
                  cacheOptions
                  defaultOptions
                  isClearable
                />
              </>
            ) : (
              ""
            )}

            {parseInt(user.cadastro_tipo_id) === 1 && (
              <ListSelect
                name="integrado"
                labelPlaceholder={<FormattedMessage id="integrado" />}
                placeholder={todosPlaceholder}
                cacheOptions
                defaultOptions
                options={integradoOptions}
                value={integrado}
                onChange={(e) => setIntegrado(e)}
                isClearable
              />
            )}

            <InputMaskDefault
              name="pedidoCliente"
              mask={"int15"}
              label={<FormattedMessage id="numPedidoCliente" />}
              onChange={(e) => setPedidoCliente(e.target.value)}
              type="text"
              value={pedidoCliente}
            />

            <ListSelect
              name="frete"
              labelPlaceholder={"Status Cotação"}
              placeholder={todosPlaceholder}
              cacheOptions
              defaultOptions
              options={statusOptionsCotacao}
              value={statusCotacao}
              onChange={(e) => setStatusCotacao(e)}
              isClearable
            />
          </div>
        </form>

        <hr />

        <CreateFooter>
          <div>
            <Button type="button" themeType="back" onClick={handleBackButton}>
              <FormattedMessage id="voltar" />
            </Button>
          </div>
          <div>
            <Button
              type="button"
              onClick={handleCreate}
              loading={loadingButton}
            >
              <FormattedMessage id="gerar" />
            </Button>
          </div>
        </CreateFooter>
      </CreateForm>
    </Container>
  );
};

export default injectIntl(CreateOrders);
