import React, { createContext, useEffect, useState, useCallback } from "react";

const LanguageContext = createContext();

export const LanguageProvider = ({ children }) => {
  const lang = navigator.language.replace("-", "_");

  const [language, setLanguage] = useState(() =>
    navigator.language ? lang : "pt_BR"
  );

  useEffect(() => {
    const loadLanguage = localStorage.getItem("@wizeoTrade:lang");

    if (loadLanguage) {
      setLanguage(JSON.parse(loadLanguage.replace("-", "_")));
    }
  }, []);

  const handleSetLanguage = useCallback((lang) => {
    localStorage.setItem("@wizeoTrade:lang", JSON.stringify(lang));
    setLanguage(lang);
  }, []);

  return (
    <LanguageContext.Provider value={{ language, handleSetLanguage }}>
      {children}
    </LanguageContext.Provider>
  );
};

export default LanguageContext;

// Componente usado no index.js na raiz, não necessario usar dentro do AppProvider.
