import React, { useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import { IoMdStar } from "react-icons/io";

import { Container } from "./styles";
import api from "../../../services/api";
import AsyncListSelect from "./../../../components/ListSelect/AsyncListSelect";
import ButtonImage from "../../../components/ButtonImage";
import moment from "moment";

import InputLabel from "../../../components/InputLabel";
import ListSelect from "../../../components/ListSelect";
import ContentSpinner from "../../../components/Spinner/Content";
import MaskInput from "./../../../config/input-mask";

import GraphBV from "../../DashboardGraphs/SodexoFaturamento";
import GraphBVDistribuidor from "../../DashboardGraphs/SodexoFaturamentoDistribuidor";
import GraphBVProduto from "../../DashboardGraphs/SodexoFaturamentoProduto";
import GraphBVBase from "../../DashboardGraphs/SodexoFaturamentoBase";
import GraphReceita from "../../DashboardGraphs/SodexoReceita";
import GraphReceitaBase from "../../DashboardGraphs/SodexoReceitaBase";
import GraphTaxaMediaCliente from "../../DashboardGraphs/SodexoTaxaMediaCliente";
import GraphTaxaMediaClienteBase from "../../DashboardGraphs/SodexoTaxaMediaClienteBase";
import GraphPrecoMedioLitro from "../../DashboardGraphs/SodexoPrecoMedioLitro";
import GraphPrecoMedioLitroDistribuidor from "../../DashboardGraphs/SodexoPrecoMedioLitroDistribuidor";
import GraphPrecoMedioLitroProduto from "../../DashboardGraphs/SodexoPrecoMedioLitroProduto";
import GraphPrecoMedioLitroBase from "../../DashboardGraphs/SodexoPrecoMedioLitroBase";
import GraphPrazoMedio from "../../DashboardGraphs/SodexoPrazosMediosPagamento";
import GraphNegociacoes from "../../DashboardGraphs/SodexoNegociacoes";
import GraphVolumeCombustivel from "../../DashboardGraphs/SodexoVolumeCombustivel";
import GraphVolumeCombustivelDistribuidor from "../../DashboardGraphs/SodexoVolumeCombustivelDistribuidor";
import GraphVolumeCombustivelBase from "../../DashboardGraphs/SodexoVolumeCombustivelBase";
import GraphVolumeCombustivelProduto from "../../DashboardGraphs/SodexoVolumeCombustivelProduto";
import GraphVolumeVendido from "../../DashboardGraphs/SodexoVolumeVendido";
import GraphRankingClientes from "../../DashboardGraphs/SodexoRankingClientes";
import GraphRankingClientesReais from "../../DashboardGraphs/SodexoRankingClientesReais";
import GraphRankingDistribuidores from "../../DashboardGraphs/SodexoRankingDistribuidores";
import GraphRankingDistribuidoresReais from "../../DashboardGraphs/SodexoRankingDistribuidoresReais";

import iconFiltro from "./../../../assets/images/icone-filtro.png";
import iconTempo from "./../../../assets/images/icone-tempo.png";
import iconLixeira from "./../../../assets/images/icone-lixeira.png";
import iconVoltar from "./../../../assets/images/icone-voltar.svg";

const Dashboard = () => {
  const [loadingList, setLoadingList] = useState(true);

  const [favorite, setFavorite] = useState("");
  const [data, setData] = useState({});

  const [dataDeFilter, setDataDeFilter] = useState("");
  const [dataAteFilter, setDataAteFilter] = useState("");
  const [graphFilter, setGraphFilter] = useState({
    value: 1,
    label: <FormattedMessage id="selecione" />,
  });
  const [groupFilter, setGroupFilter] = useState({
    value: "Geral",
    label: "Geral",
  });
  const [bases, setBases] = useState("");
  const [cnpjFilter, setCnpjFilter] = useState("");
  const [grupoEconomicoFilter, setGrupoEconomicoFilter] = useState("");
  const [estadoBasesDist, setEstadoBasesDist] = useState("");
  const [cidadeBasesDist, setCidadeBasesDist] = useState("");
  const [listaCidadesBasesDist, setListaCidadesBasesDist] = useState(
    "cidadeBasesDist"
  );

  useEffect(() => {
    (async () => {
      try {
        var response = await api.get("/favorito-home");
        setFavorite(response.data.entity_id);

        setLoadingList(false);
      } catch (error) {
        setLoadingList(false);
      }
    })();
  }, []);

  const graphOptions = [
    { value: "FaturamentoGeral", label: "Grupo Geral - Faturamento" },
    { value: "ReceitaGeral", label: "Grupo Geral - Receita" },
    {
      value: "TaxasMediasGeral",
      label: "Grupo Geral - Taxas Médias Cliente",
    },
    {
      value: "PrecoMedioGeral",
      label: "Grupo Geral - Preço Médio Litro",
    },
    {
      value: "PrazosMedioGeral",
      label: "Grupo Geral - Prazos Medios Pagamento",
    },
    {
      value: "NegociacoesPedidosGeral",
      label: "Grupo Geral - Negociações Realizadas x Pedidos Faturados",
    },
    {
      value: "VolumeCombustivelGeral",
      label: "Grupo Geral - Volume Combustível ",
    },
    {
      value: "FaturamentoCombustível",
      label: "Grupo Combustível - Faturamento",
    },
    {
      value: "PrecoMedioCombustivel",
      label: "Grupo Combustível - Preço Médio Litro",
    },
    {
      value: "VolumeCombustivelCombustivel",
      label: "Grupo Combustível - Volume Combustível",
    },
    {
      value: "FaturamentoDistribuidor",
      label: "Grupo Distribuidor - Faturamento",
    },
    {
      value: "PrecoMedioDistribuidor",
      label: "Grupo Distribuidor - Preço Médio Litro",
    },
    {
      value: "VolumeCombustivelDistribuidor",
      label: "Grupo Distribuidor - Volume Combustível",
    },
    {
      value: "VolumeVendidoDistribuidor",
      label: "Grupo Distribuidor - Volume Vendido e Não Vendido",
    },
    {
      value: "FaturamentoBase",
      label: "Base Distribuição - Faturamento",
    },
    {
      value: "ReceitaBase",
      label: "Base Distribuição - Receita",
    },
    {
      value: "TaxasMediasBase",
      label: "Base Distribuição - Taxas Médias Cliente",
    },
    {
      value: "PrecoMedioBase",
      label: "Base Distribuição - Preço Médio Litro",
    },
    {
      value: "VolumeCombustivelBase",
      label: "Base Distribuição - Volume Combustível",
    },
    {
      value: "RankingCliente",
      label: "Grupo Ranking - Ranking Cliente",
    },
    {
      value: "RankingDistribuidor",
      label: "Grupo Ranking - Ranking Distribuidor",
    },
  ];

  const groupOptions = [
    { value: "Todos", label: <FormattedMessage id="todos" /> },
    { value: "Geral", label: "Geral" },
    { value: "Combustível", label: "Combustível" },
    { value: "Distribuidor", label: "Distribuidor/TRR" },
    { value: "Base", label: "Base de Distribuição" },
    { value: "Ranking", label: "Ranking" },
  ];

  const handleLoadUF = useCallback(async () => {
    try {
      const { data } = await api.get(`/estado`);

      const arr = data.map((uf) => {
        const obj = {};

        Object.assign(obj, {
          value: String(uf.id),
          label: uf.sigla,
        });

        return obj;
      });

      return arr;
    } catch (error) {}
  }, []);

  const handleFilterBasesByUF = useCallback(async (id) => {
    if (!id) {
      return;
    }

    try {
      const { data: response } = await api.post(
        `/tabela-base-distribuicao/filter`,
        {
          estado_id: id,
        }
      );

      const arr = response.data.map((estado) => {
        const obj = {};

        Object.assign(obj, {
          value: String(estado.id),
          label: estado.nome,
        });

        return obj;
      });

      return arr;
    } catch (error) {}
  }, []);

  const handleFilterCitiesByUf = useCallback(async (id) => {
    if (!id) {
      return;
    }

    try {
      const { data } = await api.post(`/municipio/filter`, {
        estado: id,
      });

      const arr = data.map((municipio) => {
        const obj = {};

        Object.assign(obj, {
          value: String(municipio.id),
          label: municipio.nome,
        });

        return obj;
      });

      return arr;
    } catch (error) {}
  }, []);

  const history = useHistory();

  const handleGoBack = useCallback(() => {
    history.goBack();
  }, [history]);

  const handleFavorite = useCallback(async (id) => {
    const data = {
      entity_id: id,
    };

    try {
      await api.post("/favorito-home", data);
      var response = await api.get("/favorito-home");
      setFavorite(response.data.entity_id);
    } catch (error) {
      console.log(error, 'home fav')
    }
  }, []);

  const handleFilter = useCallback(() => {
    try {
      const data = {
        bases,
        cnpjFilter,
        grupoEconomicoFilter,
        estadoBasesDist,
        cidadeBasesDist,
        dataDeFilter,
        dataAteFilter,
      };

      setData(data);
    } catch (error) {
      // setErrors(getErrorsApiValidation(error));
    }
  }, [
    bases,
    cidadeBasesDist,
    cnpjFilter,
    dataAteFilter,
    dataDeFilter,
    estadoBasesDist,
    grupoEconomicoFilter,
  ]);

  const handleClean = useCallback(() => {
    try {
      setDataDeFilter("");
      setDataAteFilter("");
      setBases("");
      setCidadeBasesDist("");
      setCnpjFilter("");
      setEstadoBasesDist("");
      setGrupoEconomicoFilter("");
      setGraphFilter({
        value: 1,
        label: <FormattedMessage id="selecione" />,
      });
      setGroupFilter({
        value: "Geral",
        label: "Geral",
      });

      const dataDeRandom = moment()
      .subtract(2, "months")
      .format("YYYY-MM-DD 00:00:00");
      const dataDeFormat = moment(dataDeRandom).startOf('month').format('YYYY-MM-DD 00:00:00');

      const data = {
        bases: "",
        cnpjFilter: "",
        grupoEconomicoFilter: "",
        estadoBasesDist: "",
        cidadeBasesDist: "",
        dataDeFilter: dataDeFormat,
        dataAteFilter: moment().format("YYYY-MM-DD 23:59:59"),
      };

      setData(data);
    } catch (error) {
      // setErrors(getErrorsApiValidation(error));
    }
  }, []);

  return (
    <>
      <Container>
        <header>
          <nav>
            <ButtonImage
              ImageSrc={iconVoltar}
              border={true}
              color={"#fff"}
              onClick={handleGoBack}
              placeholder={<FormattedMessage id="voltar" />}
            />
            <h2>Dashboard</h2>
          </nav>
        </header>

        {loadingList ? (
          <ContentSpinner />
        ) : (
          <>
            <div className="grid grid-template-columns-4">
              <ListSelect
                placeholder={<FormattedMessage id="selecionar" />}
                labelPlaceholder={"Dashboard"}
                onChange={(e) => {
                  setGroupFilter({
                    value: "Todos",
                    label: <FormattedMessage id="todos" />,
                  });
                  setGraphFilter(e);
                }}
                value={graphFilter}
                options={graphOptions}
              />

              <ListSelect
                placeholder={<FormattedMessage id="selecionar" />}
                labelPlaceholder={"Grupos"}
                onChange={(e) => setGroupFilter(e)}
                value={groupFilter}
                options={groupOptions}
                isDisabled={graphFilter.value !== 1 ? true : false}
              />

              <InputLabel
                name="date"
                label={<FormattedMessage id="dataInicio" />}
                type="date"
                style={{ color: "hsl(0,0%,50%)" }}
                value={dataDeFilter}
                onChange={(e) => setDataDeFilter(e.target.value)}
              />

              <InputLabel
                name="date"
                label={<FormattedMessage id="dataFim" />}
                type="date"
                style={{ color: "hsl(0,0%,50%)" }}
                value={dataAteFilter}
                onChange={(e) => setDataAteFilter(e.target.value)}
              />

              <ButtonImage
                ImageSrc={iconTempo}
                backgroundColor={"#01AD43"}
                color={"#ffffff"}
                placeholder={"Periodo inicial de 3 meses"}
                style={{cursor: 'auto'}}
              />

              <ButtonImage
                ImageSrc={iconFiltro}
                backgroundColor={"#221c46"}
                color={"#ffffff"}
                onClick={handleFilter}
                placeholder={<FormattedMessage id="filtrar" />}
              />
            </div>

            <div className="grid grid-template-columns-5">
              <InputLabel
                name="cnpj"
                type="text"
                label="CNPJ:"
                mask={MaskInput.CNPJ}
                onBlur={(e) => {
                  return setCnpjFilter(e.target.value)
                    ?.replaceAll(".", "")
                    ?.replaceAll("/", "")
                    ?.replaceAll("-", "")
                    ?.replaceAll("_", "");
                }}
              />

              <InputLabel
                name="grupoEconomico"
                type="text"
                label={<FormattedMessage id="grupoEconomico" />}
                maxLength={50}
                value={grupoEconomicoFilter}
                onChange={((e) => setGrupoEconomicoFilter(e.target.value))}
              />

              <AsyncListSelect
                value={bases}
                labelPlaceholder={<FormattedMessage id="baseDistribuicao" />}
                placeholder={<FormattedMessage id="selecionar" />}
                onChange={(e) => {
                  setBases(e);
                }}
                myOptions={"basesDistribuição"}
                cacheOptions
                defaultOptions
              />

              <AsyncListSelect
                value={estadoBasesDist}
                onChange={(e) => {
                  setCidadeBasesDist("");
                  setEstadoBasesDist(e);
                  setListaCidadesBasesDist(e?.label);
                  handleFilterBasesByUF(e.value);
                }}
                onInput={(e) => {}}
                loadOptions={handleLoadUF}
                cacheOptions
                defaultOptions
                labelPlaceholder={<FormattedMessage id="estado" />}
                placeholder={<FormattedMessage id="selecionar" />}
                loadingMessage={() => <FormattedMessage id="carregando" />}
                isSearchable={false}
                // geterror={errors?.estado_id}
              />

              <AsyncListSelect
                key={listaCidadesBasesDist}
                value={cidadeBasesDist}
                cacheOptions
                defaultOptions
                loadOptions={() => {
                  return handleFilterCitiesByUf(listaCidadesBasesDist);
                }}
                onChange={(e) => {
                  setCidadeBasesDist(e);
                }}
                labelPlaceholder={<FormattedMessage id="cidade" />}
                placeholder={<FormattedMessage id="selecionar" />}
                noOptionsMessage={() => (
                  <FormattedMessage id="selecioneEstadoCarregar" />
                )}
                loadingMessage={() => <FormattedMessage id="carregando" />}
                isSearchable={false}
              />

              <ButtonImage
                ImageSrc={iconLixeira}
                backgroundColor={"#B30000"}
                color={"#ffffff"}
                onClick={handleClean}
              placeholder={"Limpar Filtro"}
              />
            </div>

            <div
              style={
                groupFilter.value === "Geral" || groupFilter.value === "Todos"
                  ? { display: "block" }
                  : { display: "none" }
              }
            >



              <div className="grid grid-template-columns-1">
                <div
                  style={
                    graphFilter.value === "FaturamentoGeral" ||
                    graphFilter.value === 1
                      ? { display: "block" }
                      : { display: "none" }
                  }
                >
                  {/* <i
                    className="fa fa-star fa-sharp fa-lg star"
                    onClick={() => handleFavorite("FaturamentoGeral")}
                    style={
                      favorite === "FaturamentoGeral"
                        ? { color: "#FF7375" }
                        : { color: "#bebebe" }
                    }
                  /> */}

                  <IoMdStar
                    className="star"
                    onClick={() => handleFavorite("FaturamentoGeral")}
                    style={
                      favorite === "FaturamentoGeral"
                        ? { color: "#FF7375" }
                        : { color: "#bebebe" }
                    }
                    size={25}
                  />
                  <GraphBV dataFilter={data} />
                </div>

                <div
                  style={
                    graphFilter.value === "VolumeCombustivelGeral" ||
                    graphFilter.value === 1
                      ? { display: "block" }
                      : { display: "none" }
                  }
                >
                  <IoMdStar
                    className="star"
                    onClick={() => handleFavorite("VolumeCombustivelGeral")}
                    style={
                      favorite === "VolumeCombustivelGeral"
                        ? { color: "#FF7375" }
                        : { color: "#bebebe" }
                    }
                    size={25}
                  />
                  <GraphVolumeCombustivel dataFilter={data} />
                </div>

                <div
                  style={
                    graphFilter.value === "ReceitaGeral" ||
                    graphFilter.value === 1
                      ? { display: "block" }
                      : { display: "none" }
                  }
                >
                  <IoMdStar
                    className="star"
                    onClick={() => handleFavorite("ReceitaGeral")}
                    style={
                      favorite === "ReceitaGeral"
                        ? { color: "#FF7375" }
                        : { color: "#bebebe" }
                    }
                    size={25}
                  />
                  <GraphReceita dataFilter={data} />
                </div>

                <div
                  style={
                    graphFilter.value === "TaxasMediasGeral" ||
                    graphFilter.value === 1
                      ? { display: "block" }
                      : { display: "none" }
                  }
                >
                  <IoMdStar
                    className="star"
                    onClick={() => handleFavorite("TaxasMediasGeral")}
                    style={
                      favorite === "TaxasMediasGeral"
                        ? { color: "#FF7375" }
                        : { color: "#bebebe" }
                    }
                    size={25}
                  />
                  <GraphTaxaMediaCliente dataFilter={data} />
                </div>

                <div
                  style={
                    graphFilter.value === "PrecoMedioGeral" ||
                    graphFilter.value === 1
                      ? { display: "block" }
                      : { display: "none" }
                  }
                >
                  <IoMdStar
                    className="star"
                    onClick={() => handleFavorite("PrecoMedioGeral")}
                    style={
                      favorite === "PrecoMedioGeral"
                        ? { color: "#FF7375" }
                        : { color: "#bebebe" }
                    }
                    size={25}
                  />
                  <GraphPrecoMedioLitro dataFilter={data} />
                </div>

                <div
                  style={
                    graphFilter.value === "PrazosMedioGeral" ||
                    graphFilter.value === 1
                      ? { display: "block" }
                      : { display: "none" }
                  }
                >
                  <IoMdStar
                    className="star"
                    onClick={() => handleFavorite("PrazosMedioGeral")}
                    style={
                      favorite === "PrazosMedioGeral"
                        ? { color: "#FF7375" }
                        : { color: "#bebebe" }
                    }
                    size={25}
                  />
                  <GraphPrazoMedio dataFilter={data} />
                </div>

                <div
                  style={
                    graphFilter.value === "NegociacoesPedidosGeral" ||
                    graphFilter.value === 1
                      ? { display: "block" }
                      : { display: "none" }
                  }
                >
                  <IoMdStar
                    className="star"
                    onClick={() => handleFavorite("NegociacoesPedidosGeral")}
                    style={
                      favorite === "NegociacoesPedidosGeral"
                        ? { color: "#FF7375" }
                        : { color: "#bebebe" }
                    }
                    size={25}
                  />
                  <GraphNegociacoes dataFilter={data} />
                </div>

                <div
                  style={
                    graphFilter.value === "VolumeVendidoGeral" ||
                    graphFilter.value === 1
                      ? { display: "block" }
                      : { display: "none" }
                  }
                >
                  <IoMdStar
                    className="star"
                    onClick={() => handleFavorite("VolumeVendidoGeral")}
                    style={
                      favorite === "VolumeVendidoGeral"
                        ? { color: "#FF7375" }
                        : { color: "#bebebe" }
                    }
                    size={25}
                  />
                  <GraphVolumeVendido dataFilter={data} />
                </div>
              </div>
            </div>

            <div
              style={
                groupFilter.value === "Combustível" ||
                groupFilter.value === "Todos"
                  ? { display: "block" }
                  : { display: "none" }
              }
            >
              <div className="grid grid-template-columns-2">
                <div
                  style={
                    graphFilter.value === "FaturamentoCombustível" ||
                    graphFilter.value === 1
                      ? { display: "block" }
                      : { display: "none" }
                  }
                >
                  <IoMdStar
                    className="star"
                    onClick={() => handleFavorite("FaturamentoCombustível")}
                    style={
                      favorite === "FaturamentoCombustível"
                        ? { color: "#FF7375" }
                        : { color: "#bebebe" }
                    }
                    size={25}
                  />
                  <GraphBVProduto dataFilter={data} />
                </div>

                <div
                  style={
                    graphFilter.value === "VolumeCombustivelCombustivel" ||
                    graphFilter.value === 1
                      ? { display: "block" }
                      : { display: "none" }
                  }
                >
                  <IoMdStar
                    className="star"
                    onClick={() =>
                      handleFavorite("VolumeCombustivelCombustivel")
                    }
                    style={
                      favorite === "VolumeCombustivelCombustivel"
                        ? { color: "#FF7375" }
                        : { color: "#bebebe" }
                    }
                    size={25}
                  />
                  <GraphVolumeCombustivelProduto dataFilter={data} />
                </div>

                <div
                  style={
                    graphFilter.value === "PrecoMedioCombustivel" ||
                    graphFilter.value === 1
                      ? { display: "block" }
                      : { display: "none" }
                  }
                >
                  <IoMdStar
                    className="star"
                    onClick={() => handleFavorite("PrecoMedioCombustivel")}
                    style={
                      favorite === "PrecoMedioCombustivel"
                        ? { color: "#FF7375" }
                        : { color: "#bebebe" }
                    }
                    size={25}
                  />
                  <GraphPrecoMedioLitroProduto dataFilter={data} />
                </div>
              </div>
            </div>

            <div
              style={
                groupFilter.value === "Distribuidor" ||
                groupFilter.value === "Todos"
                  ? { display: "block" }
                  : { display: "none" }
              }
            >
              <div className="grid grid-template-columns-2">
                <div
                  style={
                    graphFilter.value === "FaturamentoDistribuidor" ||
                    graphFilter.value === 1
                      ? { display: "block" }
                      : { display: "none" }
                  }
                >
                  <IoMdStar
                    className="star"
                    onClick={() => handleFavorite("FaturamentoDistribuidor")}
                    style={
                      favorite === "FaturamentoDistribuidor"
                        ? { color: "#FF7375" }
                        : { color: "#bebebe" }
                    }
                    size={25}
                  />
                  <GraphBVDistribuidor dataFilter={data} />
                </div>

                <div
                  style={
                    graphFilter.value === "VolumeCombustivelDistribuidor" ||
                    graphFilter.value === 1
                      ? { display: "block" }
                      : { display: "none" }
                  }
                >
                  <IoMdStar
                    className="star"
                    onClick={() =>
                      handleFavorite("VolumeCombustivelDistribuidor")
                    }
                    style={
                      favorite === "VolumeCombustivelDistribuidor"
                        ? { color: "#FF7375" }
                        : { color: "#bebebe" }
                    }
                    size={25}
                  />
                  <GraphVolumeCombustivelDistribuidor dataFilter={data} />
                </div>

                <div
                  style={
                    graphFilter.value === "PrecoMedioDistribuidor" ||
                    graphFilter.value === 1
                      ? { display: "block" }
                      : { display: "none" }
                  }
                >
                  <IoMdStar
                    className="star"
                    onClick={() => handleFavorite("PrecoMedioDistribuidor")}
                    style={
                      favorite === "PrecoMedioDistribuidor"
                        ? { color: "#FF7375" }
                        : { color: "#bebebe" }
                    }
                    size={25}
                  />
                  <GraphPrecoMedioLitroDistribuidor dataFilter={data} />
                </div>
              </div>
            </div>

            <div
              style={
                groupFilter.value === "Base" || groupFilter.value === "Todos"
                  ? { display: "block" }
                  : { display: "none" }
              }
            >
              <div className="grid grid-template-columns-2">
                <div
                  style={
                    graphFilter.value === "FaturamentoBase" ||
                    graphFilter.value === 1
                      ? { display: "block" }
                      : { display: "none" }
                  }
                >
                  <IoMdStar
                    className="star"
                    onClick={() => handleFavorite("FaturamentoBase")}
                    style={
                      favorite === "FaturamentoBase"
                        ? { color: "#FF7375" }
                        : { color: "#bebebe" }
                    }
                    size={25}
                  />
                  <GraphBVBase dataFilter={data} />
                </div>

                <div
                  style={
                    graphFilter.value === "VolumeCombustivelBase" ||
                    graphFilter.value === 1
                      ? { display: "block" }
                      : { display: "none" }
                  }
                >
                  <IoMdStar
                    className="star"
                    onClick={() => handleFavorite("VolumeCombustivelBase")}
                    style={
                      favorite === "VolumeCombustivelBase"
                        ? { color: "#FF7375" }
                        : { color: "#bebebe" }
                    }
                    size={25}
                  />
                  <GraphVolumeCombustivelBase dataFilter={data} />
                </div>

                <div
                  style={
                    graphFilter.value === "ReceitaBase" ||
                    graphFilter.value === 1
                      ? { display: "block" }
                      : { display: "none" }
                  }
                >
                  <IoMdStar
                    className="star"
                    onClick={() => handleFavorite("ReceitaBase")}
                    style={
                      favorite === "ReceitaBase"
                        ? { color: "#FF7375" }
                        : { color: "#bebebe" }
                    }
                    size={25}
                  />
                  <GraphReceitaBase dataFilter={data} />
                </div>

                  <div
                    style={
                      graphFilter.value === "TaxasMediasBase" ||
                      graphFilter.value === 1
                        ? { display: "block" }
                        : { display: "none" }
                    }
                  >
                    <IoMdStar
                      className="star"
                      onClick={() => handleFavorite("TaxasMediasBase")}
                      style={
                        favorite === "TaxasMediasBase"
                          ? { color: "#FF7375" }
                          : { color: "#bebebe" }
                      }
                      size={25}
                    />
                    <GraphTaxaMediaClienteBase dataFilter={data} />
                  </div>

                <div
                  style={
                    graphFilter.value === "PrecoMedioBase" ||
                    graphFilter.value === 1
                      ? { display: "block" }
                      : { display: "none" }
                  }
                >
                  <IoMdStar
                      className="star"
                      onClick={() => handleFavorite("PrecoMedioBase")}
                      style={
                        favorite === "PrecoMedioBase"
                          ? { color: "#FF7375" }
                          : { color: "#bebebe" }
                      }
                      size={25}
                    />
                  <GraphPrecoMedioLitroBase dataFilter={data} />
                </div>
              </div>
            </div>

            <div
              style={
                groupFilter.value === "Ranking" || groupFilter.value === "Todos"
                  ? { display: "block" }
                  : { display: "none" }
              }
            >
              <div className="grid grid-template-columns-2">
                <div
                  style={
                    graphFilter.value === "RankingCliente" ||
                    graphFilter.value === 1
                      ? { display: "block" }
                      : { display: "none" }
                  }
                >
                  <IoMdStar
                    className="star"
                    onClick={() => handleFavorite("RankingCliente")}
                    style={
                      favorite === "RankingCliente"
                        ? { color: "#FF7375" }
                        : { color: "#bebebe" }
                    }
                    size={25}
                  />
                  <GraphRankingClientes dataFilter={data} />
                </div>
               </div>

               <div className="grid grid-template-columns-2">
                <div
                  style={
                    graphFilter.value === "RankingClienteReais" ||
                    graphFilter.value === 1
                      ? { display: "block" }
                      : { display: "none" }
                  }
                >
                  <i
                    className="fa fa-star fa-lg star"
                    onClick={() => handleFavorite("RankingClienteReais")}
                    style={
                      favorite === "RankingClienteReais"
                        ? { color: "#FF7375" }
                        : { color: "#bebebe" }
                    }
                  />

                  <IoMdStar
                    className="star"
                    onClick={() => handleFavorite("RankingClienteReais")}
                    style={
                      favorite === "RankingClienteReais"
                        ? { color: "#FF7375" }
                        : { color: "#bebebe" }
                    }
                    size={25}
                  />
                  <GraphRankingClientesReais dataFilter={data} />
                </div>
               </div>

               <div className="grid grid-template-columns-2">
                <div
                  style={
                    graphFilter.value === "RankingDistribuidor" ||
                    graphFilter.value === 1
                      ? { display: "block" }
                      : { display: "none" }
                  }
                >
                  <IoMdStar
                    className="star"
                    onClick={() => handleFavorite("RankingDistribuidor")}
                    style={
                      favorite === "RankingDistribuidor"
                        ? { color: "#FF7375" }
                        : { color: "#bebebe" }
                    }
                    size={25}
                  />
                  <GraphRankingDistribuidores dataFilter={data} />
                </div>
              </div>

              <div className="grid grid-template-columns-2">
                <div
                  style={
                    graphFilter.value === "RankingDistribuidorReais" ||
                    graphFilter.value === 1
                      ? { display: "block" }
                      : { display: "none" }
                  }
                >
                  <IoMdStar
                    className="star"
                    onClick={() => handleFavorite("RankingDistribuidorReais")}
                    style={
                      favorite === "RankingDistribuidorReais"
                        ? { color: "#FF7375" }
                        : { color: "#bebebe" }
                    }
                    size={25}
                  />
                  <GraphRankingDistribuidoresReais dataFilter={data} />
                </div>
              </div>
            </div>
          </>
        )}
      </Container>
    </>
  );
};

export default Dashboard;
