import styled from "styled-components";

export const Container = styled.div`
  /* max-height: 150px;
  height: 100%; */
`;

export const Image = styled.img`
  display: ${(props) => (props.display ? "none" : "")};
  object-fit: scale-down;
  margin-bottom: 10px;
`;
