import React from "react";
// import { useLocation } from "react-router-dom";

import LogoWizeo from "./../../assets/images/plx-logo.svg";
import { Container } from "./styles";

const NotAuthorized = ({ children }) => {
  //console.log(children);
  // const history = useLocation();

  return (
    <Container>
      <img src={LogoWizeo} alt="not-found" />
      <h2>Sem permissão para acessar está página/conteúdo</h2>
      {/* {history?.state && (
        <span>{`Sem permissão para a rota: ${history.state}`}</span>
      )} */}
    </Container>
  );
};

export default NotAuthorized;
