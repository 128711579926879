import React, { useContext } from "react";
import { FormattedMessage } from "react-intl";
import { List, Container, MessageError } from "./styles";

import CustomerData from "./../../../context/QueriesSelect/store/customers";
import DistributorData from "./../../../context/QueriesSelect/store/distributors";
import DistributionBasesData from "./../../../context/QueriesSelect/store/distributionsBases";
import ProductsData from "./../../../context/QueriesSelect/store/products";
import CustomerClassificationData from "./../../../context/QueriesSelect/store/customerClassification";
import OperationStationClassificationData from "./../../../context/QueriesSelect/store/operationStationClassification";
import DistributionClassificationData from "./../../../context/QueriesSelect/store/distributionClassification";
import ConsultantsClassificationData from "./../../../context/QueriesSelect/store/consultantsClassification";
import userOperationData from "./../../../context/QueriesSelect/store/userOperation";
import OperatorData from "./../../../context/QueriesSelect/store/operator";
import FarmerData from "./../../../context/QueriesSelect/store/farmer";
import HunterData from "./../../../context/QueriesSelect/store/hunter";
import MerchantData from "./../../../context/QueriesSelect/store/merchant";

const AsyncListSelect = React.forwardRef((props, ref, style) => {
  let listOption;
  const customerSearch = useContext(CustomerData);
  const distributorSearch = useContext(DistributorData);
  const operatorSearch = useContext(OperatorData);
  const userOperationSearch = useContext(userOperationData);
  const distributionBasesSearch = useContext(DistributionBasesData);
  const productsSearch = useContext(ProductsData);
  const farmerSearch = useContext(FarmerData);
  const hunterSearch = useContext(HunterData);
  const merchantSearch = useContext(MerchantData);
  const customerClassificationSearch = useContext(CustomerClassificationData);
  const operationClassificationSearch = useContext(
    OperationStationClassificationData
  );
  const distributionClassificationSearch = useContext(
    DistributionClassificationData
  );
  const consultantsClassificationSearch = useContext(
    ConsultantsClassificationData
  );

  if (props.myOptions === "clientes") {
    listOption = customerSearch.customersOptions;
  } else if (props.myOptions === "distribuidores") {
    listOption = distributorSearch.distributorsOptions;
  } else if (props.myOptions === "operadores") {
    listOption = operatorSearch.operatorOptions;
  } else if (props.myOptions === "usuarioOperação") {
    listOption = userOperationSearch.userOperationOptions;
  } else if (props.myOptions === "basesDistribuição") {
    listOption = distributionBasesSearch.distributionBasesOptions;
  } else if (props.myOptions === "produtos") {
    listOption = productsSearch.productsOptions;

  } else if (props.myOptions === "farmer") {
    listOption = farmerSearch.farmerOptions;

  } else if (props.myOptions === "hunter") {
    listOption = hunterSearch.hunterOptions;

  } else if (props.myOptions === "merchant") {
    listOption = merchantSearch.merchantOptions;

  } else if (props.myOptions === "classificaoClientes") {
    listOption = customerClassificationSearch.customerClassificationOptions;
  } else if (props.myOptions === "classificaoMesaOperacao") {
    listOption = operationClassificationSearch.operationClassificationOptions;
  } else if (props.myOptions === "classificaoDistribuidores") {
    listOption =
      distributionClassificationSearch.distributionClassificationOptions;
  } else if (props.myOptions === "classificaoConsultores") {
    listOption =
      consultantsClassificationSearch.consultantsClassificationOptions;
  }

  return (
    <Container style={{ zIndex: props.zIndex }}>
      {props.labelPlaceholder && <p>{props.labelPlaceholder} {props.required && <span style={{color: "#B30000"}}>*</span>}</p>}
      <List
        ref={ref}
        style={style}
        {...props}
        classNamePrefix='filter'
        theme={(theme) => ({
          ...theme,
          borderRadius: 0,
          colors: {
            ...theme.colors,
            primary25: "#FBDDDA !important",
            primary: "#FF7375  !important",
          },
        })}
        maxMenuHeight={220}
        loadOptions={listOption || props.loadOptions}
        cacheOptions
        defaultOptions
        noOptionsMessage={
          props.noOptionsMessage
            ? props.noOptionsMessage
            : () => <FormattedMessage id="nenhumRegistroEncontrado" />
        }
      />
      {props.geterror && (
        <MessageError>
          <div>
            <p>{props.geterror}</p>
          </div>
        </MessageError>
      )}
    </Container>
  );
});

export default AsyncListSelect;



