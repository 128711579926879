import React, {forwardRef } from "react";

import { Container, MessageError } from "./styles";

const MessageDefault = forwardRef((props, ref) => {

  const {
    readOnly,
    geterror,
    ...rest} = props


  return (
    <>
      <Container readOnly={readOnly}           
        {...props}
        {...rest}
      >
        {geterror && (
          <MessageError >
            <div>
              {Array.isArray(geterror) ? (
                <p >{geterror}</p>
              ) : (
                <p>{geterror}</p>
              )}
            </div>
          </MessageError>
        )}
        {/* {geterror && isErrored ? (
          <Error>
            <p onClick={handleFocus}>{geterror}</p>
          </Error>
        ) : null} */}
      </Container>
    </>
  );
});

export default MessageDefault;
