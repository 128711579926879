import React from "react";

import { Container, Image } from "./styles";

const ImageBanner = ({ width, height, src, type, display, link, ...rest }) => {
  return (
    <Container {...rest}>
      <a href={link} target="_blank ">
        <Image
          {...rest}
          width={width}
          height={height}
          src={src}
          type={type}
          display={display}
        />
      </a>

    </Container>
  );
};

export default ImageBanner;
