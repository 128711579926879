import React, { createContext, useCallback } from "react";
import api from "./../../../services/api";

const QueriesContext = createContext();

export const CustomerClassificationListSelect = ({ children }) => {
  const timeoutRef = React.useRef(null);

  const customerClassificationOptions = useCallback(async (inputValue) => {
    try {
    let classificationFormatted = [];

    if (timeoutRef.current !== null) {
      clearTimeout(timeoutRef.current);
    }

    if (inputValue !== "") {
      const classificacao = () => new Promise((resolve, reject) => {
        timeoutRef.current = setTimeout(async ()=> {
          timeoutRef.current = null;

          const { data: response } = await api.post(
            `/tabela-classificacao-cliente/filter`,
            {
              descricao: inputValue,
            }
          );

          var resp;
          if (typeof response.data != "undefined") {
            resp = response.data;
          }

          classificationFormatted = resp.map((classificacao) => {
            const objeto = {
              value: String(classificacao.id),
              label: classificacao.descricao,
            };

            return objeto;
          });

          resolve(classificationFormatted)

        }, 1000);

      }).then(
        resolve => {
          return resolve
        }
      ).catch(
        reject => {
          console.log(reject)
        }
      )

      await classificacao()

      return classificationFormatted

    } else {

      const { data: response } = await api.post(
        `/tabela-classificacao-cliente/filter`,
        {
          descricao: inputValue,
        }
      );

      var resp;
      if (typeof response.data.data != "undefined") {
        resp = response.data.data;
      } else {
        resp = response.data;
      }

      classificationFormatted = resp.map((classificacao) => {
        const objeto = {
        value: String(classificacao.id),
        label: classificacao.descricao,
        };

        return objeto;
      });

      return classificationFormatted
    }

  } catch (error) {}
}, []);

  return (
    <QueriesContext.Provider value={{ customerClassificationOptions }}>
      {children}
    </QueriesContext.Provider>
  );
};

export default QueriesContext;
