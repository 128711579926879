import React, { useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import { FormattedMessage, injectIntl } from "react-intl";
import api from "../../../../services/api";

import { Container, CreateForm, CreateFooter } from "./styles";

import InputLabel from "./../../../../components/InputLabel";
import Button from "./../../../../components/ButtonDefault";
import ListSelect from "./../../../../components/ListSelect";

export const DisplayCustomerClassification = ({ intl }) => {
  //const [codigo, setCodigo] = useState("");
  const [descricao, setDescricao] = useState("");
  const [codigoExterno, setCodigoExterno] = useState("");
  const [status, setStatus] = useState("");

  const history = useHistory();

  useEffect(() => {

    api.get("/tabela-classificacao-cliente/" + history.location.state?.id);


    if (!history.location.state?.id) {
      return history.push(
        "/register/sodexo/tables/customer-classification/list"
      );
    }
    // setCodigo(history.location.state?.codigo);
    setDescricao(history.location.state?.descricao);
    setCodigoExterno(history.location.state?.codigo_externo);
    setStatus(history.location.state?.status === 1 ? "Ativo" : "Inativo");
  }, [history]);

  const statusPlaceholder = intl.formatMessage({ id: "status" });

  const handleBackButton = useCallback(() => {
    history.goBack();
  }, [history]);

  return (
    <Container>
      <CreateForm>
        <header>
          <h4>
            <FormattedMessage id="exibirClassificacaoCliente" />
          </h4>
        </header>

        <form>
          {/* <InputLabel
            name="codigo"
            type="text"
            label={<FormattedMessage id="codigo" />}
            value={codigo}
            readOnly
          /> */}
          <InputLabel
            name="descricao"
            type="text"
            label={<FormattedMessage id="descricao" />}
            value={descricao}
            readOnly
          />
          <InputLabel
            name="codigo_externo"
            type="text"
            label={<FormattedMessage id="codigoExterno" />}
            value={codigoExterno}
            readOnly
          />

          <ListSelect
            isDisabled={true}
            labelPlaceholder={statusPlaceholder}
            value={{ value: status, label: status }}
          />
        </form>

        <hr />

        <CreateFooter>
          <div>
            <Button type="button" themeType="back" onClick={handleBackButton}>
              <FormattedMessage id="voltar" />
            </Button>
          </div>
        </CreateFooter>
      </CreateForm>
    </Container>
  );
};

export default injectIntl(DisplayCustomerClassification);
