import styled from "styled-components";

export const TreeList = styled.ul`
  margin: 0;
  margin-left: 20px;

  cursor: default;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
`;
