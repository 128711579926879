export const defaultMask = {
  prefix: "",
  suffix: "",
  includeThousandsSeparator: true,
  thousandsSeparatorSymbol: ".",
  allowDecimal: false,
  decimalSymbol: ",",
  decimalLimit: 0, // how many digits allowed after the decimal
  integerLimit: 15, // limit length of integer numbers
  allowNegative: true,
  allowLeadingZeroes: false,
};

export const intLimit2 = {
  prefix: "",
  suffix: "",
  includeThousandsSeparator: true,
  thousandsSeparatorSymbol: "",
  allowDecimal: false,
  decimalSymbol: ",",
  decimalLimit: 0, // how many digits allowed after the decimal
  integerLimit: 2, // limit length of integer numbers
  allowNegative: false,
  allowLeadingZeroes: false,
};

export const intLimit3 = {
  prefix: "",
  suffix: "",
  includeThousandsSeparator: true,
  thousandsSeparatorSymbol: "",
  allowDecimal: false,
  decimalSymbol: ",",
  decimalLimit: 0, // how many digits allowed after the decimal
  integerLimit: 3, // limit length of integer numbers
  allowNegative: false,
  allowLeadingZeroes: false,
};

export const intLimit5 = {
  prefix: "",
  suffix: "",
  includeThousandsSeparator: true,
  thousandsSeparatorSymbol: "",
  allowDecimal: false,
  decimalSymbol: ",",
  decimalLimit: 0, // how many digits allowed after the decimal
  integerLimit: 5, // limit length of integer numbers
  allowNegative: false,
  allowLeadingZeroes: false,
};

export const intLimit6 = {
  prefix: "",
  suffix: "",
  includeThousandsSeparator: false,
  thousandsSeparatorSymbol: "",
  allowDecimal: false,
  decimalSymbol: ",",
  decimalLimit: 0, // how many digits allowed after the decimal
  integerLimit: 6, // limit length of integer numbers
  allowNegative: false,
  allowLeadingZeroes: false,
};

export const intLimit15 = {
  prefix: "",
  suffix: "",
  includeThousandsSeparator: true,
  thousandsSeparatorSymbol: "",
  allowDecimal: false,
  decimalSymbol: "",
  decimalLimit: 0, // how many digits allowed after the decimal
  integerLimit: 15, // limit length of integer numbers
  allowNegative: false,
  allowLeadingZeroes: false,
};

export const maskLimit15 = {
  prefix: "",
  suffix: "",
  includeThousandsSeparator: true,
  thousandsSeparatorSymbol: ".",
  allowDecimal: false,
  decimalLimit: 0, // how many digits allowed after the decimal
  integerLimit: 15, // limit length of integer numbers
  allowNegative: false,
  allowLeadingZeroes: false,
};

export const maskCurrencyTwoDecimalLimit = {
  prefix: "",
  suffix: "",
  includeThousandsSeparator: true,
  thousandsSeparatorSymbol: ".",
  allowDecimal: true,
  decimalSymbol: ",",
  decimalLimit: 2, // how many digits allowed after the decimal
  integerLimit: 13, // limit length of integer numbers
  allowNegative: false,
  allowLeadingZeroes: false,
};

export const maskCurrencyTwoDecimalLimitWithNegative = {
  prefix: "",
  suffix: "",
  includeThousandsSeparator: true,
  thousandsSeparatorSymbol: ".",
  allowDecimal: true,
  decimalSymbol: ",",
  decimalLimit: 2, // how many digits allowed after the decimal
  integerLimit: 13, // limit length of integer numbers
  allowNegative: true,
  allowLeadingZeroes: false,
};

export const maskCurrencyTwoDecimalLimitThreeInt = {
  prefix: "",
  suffix: "",
  includeThousandsSeparator: true,
  thousandsSeparatorSymbol: ".",
  allowDecimal: true,
  decimalSymbol: ",",
  decimalLimit: 2, // how many digits allowed after the decimal
  integerLimit: 5, // limit length of integer numbers
  allowNegative: false,
  allowLeadingZeroes: false,
};

export const maskCurrencyFourDecimalLimit = {
  prefix: "",
  suffix: "",
  includeThousandsSeparator: true,
  thousandsSeparatorSymbol: ".",
  allowDecimal: true,
  decimalSymbol: ",",
  decimalLimit: 4, // how many digits allowed after the decimal
  integerLimit: 13, // limit length of integer numbers
  allowNegative: false,
  allowLeadingZeroes: false,
};

export const maskCurrencyTwoDecimalLimit15 = {
  prefix: "",
  suffix: "",
  includeThousandsSeparator: true,
  thousandsSeparatorSymbol: ".",
  allowDecimal: true,
  decimalSymbol: ",",
  decimalLimit: 2, // how many digits allowed after the decimal
  integerLimit: 15, // limit length of integer numbers
  allowNegative: false,
  allowLeadingZeroes: false,
};

export const maskVolume = {
  prefix: "",
  suffix: "",
  includeThousandsSeparator: true,
  thousandsSeparatorSymbol: ".",
  allowDecimal: true,
  decimalSymbol: ",",
  decimalLimit: 2, // how many digits allowed after the decimal
  integerLimit: 6, // limit length of integer numbers
  allowNegative: false,
  allowLeadingZeroes: false,
};

export const maskPrice = {
  prefix: "",
  suffix: "",
  includeThousandsSeparator: true,
  thousandsSeparatorSymbol: ".",
  allowDecimal: true,
  decimalSymbol: ",",
  decimalLimit: 2, // how many digits allowed after the decimal
  integerLimit: 15, // limit length of integer numbers
  allowNegative: false,
  allowLeadingZeroes: false,
};

export const maskPriceReprove = {
  prefix: "",
  suffix: "",
  includeThousandsSeparator: true,
  thousandsSeparatorSymbol: ".",
  allowDecimal: true,
  decimalSymbol: ",",
  decimalLimit: 4, // how many digits allowed after the decimal
  integerLimit: 15, // limit length of integer numbers
  allowNegative: false,
  allowLeadingZeroes: false,
};

export const maskPercent = {
  prefix: "",
  suffix: "",
  includeThousandsSeparator: true,
  thousandsSeparatorSymbol: ".",
  allowDecimal: true,
  decimalSymbol: ",",
  decimalLimit: 4, // how many digits allowed after the decimal
  integerLimit: 3, // limit length of integer numbers
  allowNegative: false,
  allowLeadingZeroes: false,
};

export const maskPriceNew = {
  prefix: "R$ ",
  suffix: "",
  includeThousandsSeparator: true,
  thousandsSeparatorSymbol: ",",
  allowDecimal: true,
  decimalSymbol: ".",
  decimalLimit: 4, // how many digits allowed after the decimal
  integerLimit: 15, // limit length of integer numbers
  allowNegative: false,
  allowLeadingZeroes: false,
};

export const maskPreco = {
  prefix: "",
  suffix: "",
  includeThousandsSeparator: true,
  thousandsSeparatorSymbol: ".",
  allowDecimal: true,
  decimalSymbol: ",",
  decimalLimit: 4, // how many digits allowed after the decimal
  integerLimit: 3, // limit length of integer numbers
  allowNegative: false,
  allowLeadingZeroes: false,
};

export const maskVolumePedido = {
  prefix: "",
  suffix: "",
  includeThousandsSeparator: true,
  thousandsSeparatorSymbol: ".",
  allowDecimal: true,
  decimalSymbol: ",",
  decimalLimit: 2, // how many digits allowed after the decimal
  integerLimit: 2, // limit length of integer numbers
  allowNegative: false,
  allowLeadingZeroes: false,
};


export const taxaPedido = {
  prefix: "",
  suffix: "",
  includeThousandsSeparator: true,
  thousandsSeparatorSymbol: ",",
  allowDecimal: true,
  decimalSymbol: ".",
  decimalLimit: 4, // how many digits allowed after the decimal
  integerLimit: 13, // limit length of integer numbers
  allowNegative: false,
  allowLeadingZeroes: false,
};

// 453869519999999
