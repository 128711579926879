import React from "react";

import { Container, Input, MessageError, Header } from "./styles";
import createNumberMask from "text-mask-addons/dist/createNumberMask";

import {
  defaultMask,
  intLimit2,
  intLimit3,
  intLimit5,
  intLimit6,
  intLimit15,
  maskCurrencyFourDecimalLimit,
  maskCurrencyTwoDecimalLimit,
  maskCurrencyTwoDecimalLimitThreeInt,
  maskVolume,
  maskPrice,
  maskPriceReprove,
  maskPriceNew,
  maskCurrencyTwoDecimalLimit15,
  maskLimit15,
  maskCurrencyTwoDecimalLimitWithNegative,
  maskPercent,
  maskPreco,
  maskVolumePedido,
  taxaPedido
} from "./mask";

const InputDefault = ({
  mask,
  label,
  toolTip,
  placeholder,
  readOnly,
  value,
  required,
  onChange,
  geterror,
  geterrorcolorbackground,
  showError = 0,
  maxLength,
  ...props
}) => {
  // const inputRef = createRef(null);

  let inputMask = createNumberMask(defaultMask);

  if (mask === "int2") {
    inputMask = createNumberMask(intLimit2);
  }

  if (mask === "int3") {
    inputMask = createNumberMask(intLimit3);
  }

  if (mask === "int5") {
    inputMask = createNumberMask(intLimit5);
  }

  if (mask === "int6") {
    inputMask = createNumberMask(intLimit6);
  }

  if (mask === "int15") {
    inputMask = createNumberMask(intLimit15);
  }

  if (mask === "currency2") {
    inputMask = createNumberMask(maskCurrencyTwoDecimalLimit);
  }

  if (mask === "currency2WithNegative") {
    inputMask = createNumberMask(maskCurrencyTwoDecimalLimitWithNegative);
  }

  if (mask === "currency2Int5") {
    inputMask = createNumberMask(maskCurrencyTwoDecimalLimitThreeInt);
  }

  if (mask === "currency4") {
    inputMask = createNumberMask(maskCurrencyFourDecimalLimit);
  }

  if (mask === "currency5") {
    inputMask = createNumberMask(maskVolume);
  }

  if (mask === "currency6") {
    inputMask = createNumberMask(maskPrice);
  }

  if (mask === "currency7") {
    inputMask = createNumberMask(maskPriceReprove);
  }

  if (mask === "currency8") {
    inputMask = createNumberMask(maskPriceNew);
  }

  if (mask === "currency9") {
    inputMask = createNumberMask(maskCurrencyTwoDecimalLimit15);
  }

  if (mask === "currency10") {
    inputMask = createNumberMask(maskLimit15);
  }

  if (mask === "percent") {
    inputMask = createNumberMask(maskPercent);
  }

  if (mask === "precoCombustiveis") {
    inputMask = createNumberMask(maskPreco);
  }

  if (mask === "volumePedido") {
    inputMask = createNumberMask(maskVolumePedido);
  }

  if (mask === "taxaPedido") {
    inputMask = createNumberMask(taxaPedido);
  }

  return (
    <>
      <Container readOnly={readOnly}>
        <Header>
          <p>{label} {required && <span style={{color: "#B30000"}}>*</span>}</p>
          {toolTip && <span>{toolTip}</span>}
        </Header>

        <Input
          //ref={inputRef}
          {...props}
          value={value}
          onChange={onChange}
          readOnly={readOnly}
          mask={inputMask}
          placeholder={placeholder}
          geterrorcolorbackground={geterrorcolorbackground}
        />
        {geterror && (
          <MessageError>
            <div>
              <p>{showError === 0 && geterror}</p>
            </div>
          </MessageError>
        )}
        {/* {geterror && isErrored ? (
          <Error>
            <p onClick={handleFocus}>{geterror}</p>
          </Error>
        ) : null} */}
      </Container>
    </>
  );
};

export default InputDefault;
